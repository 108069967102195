import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DailyChartService} from 'app/services/rszoft/dailychart/daily-chart.service';
import {ReportContext} from 'app/services/rszoft/dailychart/models/context.model';
import * as moment from 'moment-timezone';
import {DateUtil} from '../../utils/date.util';
import {TabOption} from 'app/services/rszoft/report.model';

@Component({
    selector: 'app-weekly-table-print',
    templateUrl: './weekly-table-print.component.html',
    styleUrls: ['../driverreport/driverreport.component.css', '../weekly-table/weekly-table.component.css']
})
export class WeeklyTablePrintComponent implements OnInit, AfterViewInit {
    tab: TabOption;
    reportContext: ReportContext;
    driverDisplayColumns = ['period', 'driving', 'working', 'availability', 'allWorksInDay', 'vehicle'];
    vehicleDisplayColumns = ['period', 'driving', 'kmRange', 'kmSum--long', 'driver'];

    tabOptions = TabOption;
    _moment = moment;
    dateUtil = DateUtil;

    constructor(public dailyChartService: DailyChartService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        const tab = this.activatedRoute.snapshot.paramMap.get('tab');
        this.tab = this.dailyChartService.getDailyChartTabEnumByValue(tab);
        if (!this.tab) {
            console.log('No TAB info, which chart to print?');
            return;
        }

        const labelledReportContext = this.dailyChartService.getReportContext(this.tab);
        if (!labelledReportContext || !labelledReportContext.reportContext) {
            return;
        }
        this.reportContext = labelledReportContext.reportContext;
        this.reportContext.indexOfLastChartToLoad = -1;
    }

    ngAfterViewInit() {
        const format = 'YYYY-MM-DD';
        const startDate = moment(this.reportContext.periodStartTime * 1000).format(format);
        const endDate = moment(this.reportContext.periodEndTime * 1000).format(format);
        const timezone = this.reportContext.dailyChartResponses[0]?.timeZone || 'UTC';
        let entityOrCompany = this.dailyChartService.companyName?.replaceAll(' ', '_') || 'Company_wide';
        if (this.reportContext.dailyChartResponses.length === 1) {
            const entityData = this.reportContext.dailyChartResponses[0];
            if (this.tab === TabOption.DRIVER) {
                entityOrCompany = entityData.driverInfo.cardHolderSurname.toUpperCase() + '_' +
                    entityData.driverInfo.cardHolderFirstNames.replaceAll(' ', '_');
            } else {
                entityOrCompany = entityData.vehicleInfo.registrationNumber.replaceAll(' ', '_');
            }
        }

        const title = document.title;
        document.title = [startDate, endDate, 'Daily', 'chart', this.tab.toLowerCase(), entityOrCompany, 'table', timezone].join('_');
        setTimeout(() => {
            window.print();
            document.title = title;
        }, 500);
    }

    @HostListener('window:afterprint', [])
    onWindowAfterPrint() {
        this.navigateBackToReport();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(_) {
        this.navigateBackToReport();
    }

    navigateBackToReport() {
        this.dailyChartService.printingInProgress = false;
        this.dailyChartService.getReportContext(this.tab).reportContext.chartDataGenerated = false;
        this.router.navigate(['/report/dailychart', {reloadResultList: true}]);
    }

}
