export interface ReportModel {
    // made: number;
    language: string;
    timeZone: string;
}

export class MetaData {
    periodStartDate: number;
    periodEndDate: number;
    made: number;
    language: string;
    timezone: string;
    companyName: string;
}

export enum TabOption {
    DRIVER = 'DRIVER',
    VEHICLE = 'VEHICLE'
}

export interface Person {
    surname?: string;
    firstNames?: string;
}

export interface Activity {
    activityStart?: number;
    activityEnd?: number;
}

export interface VehicleModel {
    registrationNumber: string;
    registrationNation?: string;
    identificationNumber?: string;
}

export interface Usage {
    firstUse?: number;
    lastUse?: number;
}

export interface Odometer {
    odometerBegin?: number;
    odometerEnd?: number;
    odometerSum?: number;
}

export interface ErrorCode {
    errorCode?: string;
}

export interface DueDate {
    nextDueDate: number;
    nextDueDatePerDay: number;
}

export function comparePerson(person1: Person, person2: Person): number {
    if (person1.surname?.toLowerCase() + person1.firstNames?.toLowerCase() < person2.surname?.toLowerCase() + person2.firstNames?.toLowerCase()) { return -1; }
    if (person1.surname?.toLowerCase() + person1.firstNames?.toLowerCase() > person2.surname?.toLowerCase() + person2.firstNames?.toLowerCase()) { return 1; }
    return 0;
}

export function compareActivity(activity1: Activity, activity2: Activity): number {
    if (activity1.activityStart < activity2.activityStart) { return -1; }
    if (activity1.activityStart > activity2.activityStart) { return 1; }
    return 0;
}

export function compareVehicleModel(vehicleModel1: VehicleModel, vehicleModel2: VehicleModel): number {
    if (vehicleModel1.registrationNumber.toLowerCase() < vehicleModel2.registrationNumber.toLowerCase()) { return -1; }
    if (vehicleModel1.registrationNumber.toLowerCase() > vehicleModel2.registrationNumber.toLowerCase()) { return 1; }
    return 0;
}

export function compareUsage(usage1: Usage, usage2: Usage): number {
    if (usage1.firstUse < usage2.firstUse) { return -1; }
    if (usage1.firstUse > usage2.firstUse) { return 1; }
    if (usage1.lastUse < usage2.lastUse) { return -1; }
    if (usage1.lastUse > usage2.lastUse) { return 1; }
    return 0;
}

export function compareErrorCode(errorCode1: ErrorCode, errorCode2: ErrorCode): number {
    if (!!errorCode1.errorCode && !errorCode2.errorCode) { return -1; }
    if (!errorCode1.errorCode && !!errorCode2.errorCode) { return 1; }
    return 0;
}

export function compareDueDate(dueDate1: DueDate, dueDate2: DueDate, tolerance: number): number {
    if (!!tolerance && dueDate1.nextDueDatePerDay <= tolerance || dueDate2.nextDueDatePerDay <= tolerance) {
        if (dueDate1.nextDueDatePerDay < dueDate2.nextDueDatePerDay) { return -1; }
        if (dueDate1.nextDueDatePerDay > dueDate2.nextDueDatePerDay) { return 1; }
    }
    return 0;
}
