import { Person, ReportModel } from '../report.model';

export class DriverAttestationRequestModel {
    clientId: number;
    driverIds: number[];
    startDate: string;
    endDate: string;
}

export class DriverStatementUpdateModel {
    id: number;
    driverId: number;
    clientId: number;
    startDate: number;
    endDate: number;
    statement: string;
}

export class DriverAttestationResponseModel implements ReportModel {
    made: number;
    language: string;
    timeZone: string;
    clientInfo: CompanyModel;
    driverInfos: DriverAttestationModel[];
}

export class CompanyModel {
    name: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    fax: string;
    email: string;
}

export class DriverAttestationModel implements Person {
    driverId: number;
    surname?: string;
    firstNames?: string;
    birthDate: number;
    cardNumber: string;
    lastDriverCardDownload: number;
    statements: DriverStatement[];
    drivingLicenceNumber: string
}

export class DriverStatement {
    driverAttestationId: number;
    printingOn: boolean;
    startDate: number;
    endDate: number;
    duration: number;
    statement: Statement;
}

export enum Statement {
    SICK = 'SICK',
    ANNUAL_LEAVE = 'ANNUAL_LEAVE',
    REST = 'REST',
    DRIVE = 'DRIVE',
    WORK = 'WORK',
    AVAILABLE = 'AVAILABLE'
}
