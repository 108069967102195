import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { LoadingComponent } from './loading/loading.component';
import { SpinnerHorizontalPosition, SpinnerSize, SpinnerVerticalPosition } from './loading.model';

@Directive({
    selector: '[appLoadingSpinner]'
})
export class LoadingSpinnerDirective {
    loadingFactory: ComponentFactory<LoadingComponent>;
    loadingComponent: ComponentRef<LoadingComponent>;

    private loading = false;
    private size: SpinnerSize = SpinnerSize.MEDIUM;
    private verticalPosition: SpinnerVerticalPosition = SpinnerVerticalPosition.CENTER;
    private horizontalPosition: SpinnerHorizontalPosition = SpinnerHorizontalPosition.CENTER;
    private originalPosition;

    @Input() set appLoadingSpinner(loading: boolean) {
        this.vcRef.clear();
        if (loading) {
            this.loading = true;
            this.updateLoadingView();
        } else {
            this.loading = false;
            if (!!this.originalPosition) {
                this.templateRef.elementRef.nativeElement.position = this.originalPosition;
            } else {
                this.originalPosition = this.templateRef.elementRef.nativeElement.position;
            }
            this.vcRef.createEmbeddedView(this.templateRef);
        }
    }
    @Input() set appLoadingSpinnerSize(size: SpinnerSize) {
        if (!!size) { this.size = size; this.updateLoadingView(); }
    }
    @Input() set appLoadingSpinnerVerticalPosition(verticalPosition: SpinnerVerticalPosition) {
        if (!!verticalPosition) { this.verticalPosition = verticalPosition; this.updateLoadingView(); }
    }
    @Input() set appLoadingSpinnerHorizontalPosition(horizontalPosition: SpinnerHorizontalPosition) {
        if (!!horizontalPosition) { this.horizontalPosition = horizontalPosition; this.updateLoadingView(); }
    }

    constructor(private templateRef: TemplateRef<any>,
                private vcRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {
        this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
    }

    private updateLoadingView() {
        if (this.loading) {
            this.vcRef.clear();
            this.loadingComponent = this.vcRef.createComponent(this.loadingFactory);
            this.loadingComponent.instance.size = this.size;
            this.loadingComponent.instance.verticalPosition = this.verticalPosition;
            this.loadingComponent.instance.horizontalPosition = this.horizontalPosition;
            this.setParentStyle();
        }
    }

    private setParentStyle() {
        const style = this.templateRef.elementRef.nativeElement.parentElement.style;
        style.position = 'relative';
        if (!style.minHeight) { style.minHeight = '200px'; }
    }
}
