<div style="width: 100%" [hidden]="!noDataText">
    <div style="text-align: center; margin-top: 15px">
        <span style="font-weight: bold;">{{"NO DATA IN THE CHOSEN PERIOD!" | translate}}</span>
    </div>
</div>
<div style="width: 100%">
    <div style="text-align: center; margin-top: 10px">
        <span style="padding-top: 25px">
<!--            {{formatDateWithMonth(periodStart)}} - {{formatDateWithMonth(periodEnd)}}-->
            <mat-form-field appearance="fill" (click)="picker.open()">
                <mat-label>{{"Connected_service.enterRangeDate" | translate}}</mat-label>
                <mat-date-range-input [formGroup]="rangeInDiagramm" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="startTimeout()">
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="startTimeout()">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </span>

        <mat-form-field appearance="fill" style="width: 70px; margin-left: 10px">
            <mat-label>{{"Hours" | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="startHour" [value]="startHour" (change)="startTimeout()" placeholder="12" min="0" max="23">
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 70px; margin-left: 10px">
            <mat-label>{{"Min" | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="startMin" [value]="startMin" (change)="startTimeout()" placeholder="12" min="0" max="59">
        </mat-form-field>

        <span style="margin-left: 15px; margin-right: 15px"> - </span>

        <mat-form-field appearance="fill" style="width: 70px; margin-left: 10px">
            <mat-label>{{"Hours" | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="endHour" [value]="endHour" (change)="startTimeout()" placeholder="12" min="0" max="23">
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 70px; margin-left: 10px">
            <mat-label>{{"Min" | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="endMin" [value]="endMin" (change)="startTimeout()" placeholder="12" min="0" max="59">
        </mat-form-field>

<!--        <button mat-button style="width: 70px;">-->
<!--            <mat-icon style="height: unset">search</mat-icon>-->
<!--        </button>-->
    </div>
    <div style="text-align: center; margin-top: 15px" class="flex flex-row items-center justify-center">
        <img src="assets/bed_colored.svg" style="vertical-align: top;  height: 25px; margin-right: 15px">
        <span >
            {{'Rest' | translate}}  -  {{formatDurationFromMinutes(summaryRest)}}
        </span>
        <img src="assets/available_colored.svg" style="vertical-align: top;  height: 25px; margin-right: 15px; margin-left: 10px">
        <span >
            {{'Available' | translate}}  -  {{formatDurationFromMinutes(summaryAvailable)}}
        </span>
        <img src="assets/work_colored.svg" style="vertical-align: top;  height: 25px; margin-right: 15px; margin-left: 10px">
        <span >
            {{'Work' | translate}}  -  {{formatDurationFromMinutes(summaryWork)}}
        </span>
        <img src="assets/kormany_colored.svg" style="vertical-align: top;  height: 25px; margin-right: 15px; margin-left: 10px">
        <span >
            {{'Drive' | translate}}  -  {{formatDurationFromMinutes(summaryDrive)}}
        </span>
    </div>



    <div style="text-align: center;"></div>
    <div id="container" class="containerCharts" style="width: 100%"></div>
    <!--<div id="new{{driverId.toString()}}" style="width: 100%"></div>-->
</div>
