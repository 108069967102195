import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService {
    value = new BehaviorSubject<number>(0);
    mode = new BehaviorSubject<ProgressBarMode>('determinate');
    needed = new BehaviorSubject<boolean>(false);
}
