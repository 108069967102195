<div></div>
<div class="attestation-page">
    <div class="attestation-header">
        <h4>{{ 'Report.DriverAttestation.PrintView.AttestationOfActivities' | instantTranslate:printService.language | async }}<sup>1</sup></h4>
        <h4>({{ 'Report.DriverAttestation.PrintView.Regulation561' | instantTranslate:printService.language | async }}<sup>2</sup>)</h4>
        <p>{{ 'Report.DriverAttestation.PrintView.HeaderComment' | instantTranslate:printService.language | async }}</p>
        <p class="false-attestation">{{ 'Report.DriverAttestation.PrintView.FalseAttestation' | instantTranslate:printService.language | async }}</p>
    </div>
    <div class="attestation-content">
        <h4>{{ 'Report.DriverAttestation.PrintView.UndertakingData' | instantTranslate:printService.language | async }}</h4>
        <div class="content-row">
            <div class="content-row__number">(1)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.NameOfUndertaking' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <span *ngIf="!!company?.name">{{ company.name }}</span>
                <span *ngIf="!company || !company.name" class="placeholder"></span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(2)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Address' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <span *ngIf="!!company?.address">{{ company.address }}</span>
                <span *ngIf="!company || !company?.address" class="placeholder"></span>
                <span>,</span>
                <span class="placeholder"></span>
                <span>,</span>
                <span *ngIf="!!company?.city">{{ company.city }}</span>
                <span *ngIf="!company || !company?.city" class="placeholder"></span>
                <span>,</span>
                <span *ngIf="!!company?.country">{{ company.country }}</span>
                <span *ngIf="!company || !company?.country" class="placeholder"></span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(3)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.PhoneNumber' | instantTranslate:printService.language | async }}&nbsp;</span>
                <span>({{ 'Report.DriverAttestation.PrintView.IncludingPrefix' | instantTranslate:printService.language | async }}):&nbsp;</span>
                <span *ngIf="!!company?.phone">{{ company.phone }}</span>
                <span *ngIf="!company || !company?.phone" class="placeholder"></span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(4)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.FaxNumber' | instantTranslate:printService.language | async }}&nbsp;</span>
                <span>({{ 'Report.DriverAttestation.PrintView.IncludingPrefix' | instantTranslate:printService.language | async }}):&nbsp;</span>
                <span *ngIf="!!company?.fax">{{ company.fax }}</span>
                <span *ngIf="!company || !company?.fax" class="placeholder"></span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(5)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Mail' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <span *ngIf="!!profile?.emailAddress">{{ profile.emailAddress }}</span>
                <span *ngIf="(!profile || !profile?.emailAddress) && !!company?.email">{{ company.email }}</span>
                <span *ngIf="(!profile || !profile?.emailAddress) && (!company || !company?.email)" class="placeholder"></span>
            </div>
        </div>
        <h4>{{ 'Report.DriverAttestation.PrintView.UndersignedData' | instantTranslate:printService.language | async }}</h4>
        <div class="content-row">
            <div class="content-row__number">(6)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Name' | instantTranslate:printService.language | async }}:</span>
                <span *ngIf="!!profile?.fullName">{{ profile.fullName }}</span>
                <span *ngIf="!profile || !profile?.fullName" class="placeholder"></span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(7)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.PositionOfUndersigned' | instantTranslate:printService.language | async }}:</span>
                <span *ngIf="!!profile?.positionInTheCompany">{{ profile.positionInTheCompany }}</span>
                <span *ngIf="!profile || !profile?.positionInTheCompany" class="placeholder"></span>
            </div>
        </div>
        <!-- <div class="content-row">
            <div class="content-row__number">(8)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Name' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <span *ngIf="!!driver?.surname">{{ driver.surname.toUpperCase() }}&nbsp;</span>
                <span *ngIf="!!driver?.firstNames">{{ driver.firstNames }}</span>
                <span *ngIf="!driver" class="placeholder"></span>
            </div>
        </div> -->
        <h4>{{ 'Report.DriverAttestation.PrintView.Declaration' | instantTranslate:printService.language | async }}:</h4>
        <div class="content-row">
            <div class="content-row__number">(8)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Name' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <span *ngIf="!!driver?.surname">{{ driver.surname.toUpperCase() }}&nbsp;</span>
                <span *ngIf="!!driver?.firstNames">{{ driver.firstNames }}</span>
                <span *ngIf="!driver" class="placeholder"></span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(9)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.DateOfBirth' | instantTranslate:printService.language | async }}&nbsp;</span>
                <span>({{ 'Report.DriverAttestation.PrintView.DateFormatText' | instantTranslate:printService.language | async }}):&nbsp;</span>
                <span *ngIf="!!driver?.birthDate">{{ _moment(driver.birthDate * 1000).format('Report.DriverAttestation.PrintView.DateFormat' | instantTranslate:printService.language | async) }}</span>
                <div *ngIf="!driver || !driver.birthDate">
                    <span class="placeholder"></span>
                    <span>/</span>
                    <span class="placeholder"></span>
                    <span>/</span>
                    <span class="placeholder"></span>
                </div>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(10)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.DrivingLicence' | instantTranslate:printService.language | async }}:</span>
                <span>{{ driver.drivingLicenceNumber }}</span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(11)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.StartDate' | instantTranslate:printService.language | async }}&nbsp;</span>
                <span>({{ 'Report.DriverAttestation.PrintView.DateFormatText' | instantTranslate:printService.language | async }}):</span>
                <span class="placeholder"></span>
                <span>/</span>
                <span class="placeholder"></span>
                <span>/</span>
                <span class="placeholder"></span>
            </div>
        </div>
        <h4>{{ 'Report.DriverAttestation.PrintView.Period' | instantTranslate:printService.language | async }}:</h4>
        <div class="content-row">
            <div class="content-row__number">(12)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.From' | instantTranslate:printService.language | async }}&nbsp;</span>
                <span>({{ 'Report.DriverAttestation.PrintView.TimeFormatText' | instantTranslate:printService.language | async }}):&nbsp;</span>
                <span *ngIf="!!period?.startDate">
                    <span>{{ _moment(period.startDate * 1000).tz(timezone).format('Report.DriverAttestation.PrintView.TimeFormat' | instantTranslate:printService.language | async) }}&nbsp;</span>
                    <span>({{ timezone }})</span>
                </span>
                <span *ngIf="!period || !period.startDate">
                    <span class="placeholder"></span>
                    <span>/</span>
                    <span class="placeholder"></span>
                    <span>/</span>
                    <span class="placeholder"></span>
                    <span>&nbsp;</span>
                    <span class="placeholder"></span>
                    <span>:</span>
                    <span class="placeholder"></span>
                </span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(13)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.To' | instantTranslate:printService.language | async }}&nbsp;</span>
                <span>({{ 'Report.DriverAttestation.PrintView.TimeFormatText' | instantTranslate:printService.language | async }}):&nbsp;</span>
                <span *ngIf="!!period?.endDate">
                    <span>{{ _moment(period.endDate * 1000).tz(timezone).format('Report.DriverAttestation.PrintView.TimeFormat' | instantTranslate:printService.language | async) }}&nbsp;</span>
                    <span>({{ timezone }})</span>
                </span>
                <span *ngIf="!period || !period.endDate">
                    <span class="placeholder"></span>
                    <span>/</span>
                    <span class="placeholder"></span>
                    <span>/</span>
                    <span class="placeholder"></span>
                    <span>&nbsp;</span>
                    <span class="placeholder"></span>
                    <span>:</span>
                    <span class="placeholder"></span>
                </span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(14)</div>
            <div class="content-row__label">
                <mat-checkbox color="accent" disabled="true" disableRipple
                              [value]="statements.SICK"
                              [checked]="period?.statement === statements.SICK">
                    {{ 'Report.DriverAttestation.PrintView.SickLeave' | instantTranslate:printService.language | async }}*
                </mat-checkbox>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(15)</div>
            <div class="content-row__label">
                <mat-checkbox color="accent" disabled="true" disableRipple
                              [value]="statements.ANNUAL_LEAVE"
                              [checked]="period?.statement === statements.ANNUAL_LEAVE">
                    {{ 'Report.DriverAttestation.PrintView.AnnualLeave' | instantTranslate:printService.language | async }}*
                </mat-checkbox>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(16)</div>
            <div class="content-row__label">
                <mat-checkbox color="accent" disabled="true" disableRipple
                              [value]="statements.REST"
                              [checked]="period?.statement === statements.REST">
                    {{ 'Report.DriverAttestation.PrintView.LeaveOrRest' | instantTranslate:printService.language | async }}*
                </mat-checkbox>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(17)</div>
            <div class="content-row__label">
                <mat-checkbox color="accent" disabled="true" disableRipple
                              [value]="statements.DRIVE"
                              [checked]="period?.statement === statements.DRIVE">
                    {{ 'Report.DriverAttestation.PrintView.DroveAVehicle' | instantTranslate:printService.language | async }}*
                </mat-checkbox>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(18)</div>
            <div class="content-row__label">
                <mat-checkbox color="accent" disabled="true" disableRipple
                              [value]="statements.WORK"
                              [checked]="period?.statement === statements.WORK">
                    {{ 'Report.DriverAttestation.PrintView.OtherWork' | instantTranslate:printService.language | async }}*
                </mat-checkbox>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(19)</div>
            <div class="content-row__label">
                <mat-checkbox color="accent" disabled="true" disableRipple
                              [value]="statements.AVAILABLE"
                              [checked]="period?.statement === statements.AVAILABLE">
                    {{ 'Report.DriverAttestation.PrintView.Available' | instantTranslate:printService.language | async }}*
                </mat-checkbox>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(20)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Place' | instantTranslate:printService.language | async }}:</span>
                <span class="placeholder" style="width: 100px"></span>&nbsp;
                <span>{{ 'Report.DriverAttestation.PrintView.Date' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <span>{{ printService.reportGenerated.format('Report.General.DateFormat' | instantTranslate:printService.language | async) }}</span>
            </div>
        </div>
        <div style="padding: 12px 0 0">
            <span>{{ 'Report.DriverAttestation.PrintView.Signature' | instantTranslate:printService.language | async }}:</span>
            <span class="placeholder" style="width: 200px"></span>
        </div>
    </div>
    <div class="attestation-signature">
        <div class="content-row">
            <div class="content-row__number">(21)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.DriverConfirmation' | instantTranslate:printService.language | async }}</span>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number">(22)</div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.Place' | instantTranslate:printService.language | async }}:</span>
                <span class="placeholder" style="width: 100px"></span>&nbsp;
                <span>{{ 'Report.DriverAttestation.PrintView.Date' | instantTranslate:printService.language | async }}:</span>
                <span class="placeholder"></span>
            </div>
        </div>
        <div style="padding: 12px 0 0">
            <span>{{ 'Report.DriverAttestation.PrintView.SignatureOfDriver' | instantTranslate:printService.language | async }}:</span>
            <span class="placeholder" style="width: 200px"></span>
        </div>
    </div>
    <div class="attestation-footer">
        <div>
            <span class="placeholder" style="width: 200px"></span>
        </div>
        <div class="content-row">
            <div class="content-row__number"><sup>1</sup></div>
            <div class="content-row__label">
                <span>{{ 'Report.DriverAttestation.PrintView.PrintableVersion' | instantTranslate:printService.language | async }}:&nbsp;</span>
                <a href="http://ec.europa.eu">http://ec.europa.eu</a>
            </div>
        </div>
        <div class="content-row">
            <div class="content-row__number"><sup>2</sup></div>
            <div class="content-row__label">{{ 'Report.DriverAttestation.PrintView.Agreement' | instantTranslate:printService.language | async }}</div>
        </div>
        <div class="content-row">
            <div class="content-row__number">*</div>
            <div class="content-row__label">{{ 'Report.DriverAttestation.PrintView.ChooseOnlyOne' | instantTranslate:printService.language | async }}</div>
        </div>
    </div>
</div>
