import { Component, Input, OnInit } from '@angular/core';
import { PrintService } from 'app/services/rszoft/print.service';
import {
    CompanyModel,
    DriverAttestationModel,
    DriverStatement,
    Statement
} from 'app/services/rszoft/driver-attestation/driver-attestation.model';
import * as moment from 'moment-timezone';
import { AttestationProfile } from 'app/services/rszoft/driver-attestation/driver-attestation.service';

@Component({
    selector: 'app-attestation-page',
    templateUrl: './attestation-page.component.html',
    styleUrls: ['./attestation-page.component.css']
})
export class AttestationPageComponent implements OnInit {
    @Input() company: CompanyModel;
    @Input() driver: DriverAttestationModel;
    @Input() period: DriverStatement;
    @Input() timezone: string;
    @Input() profile: AttestationProfile

    statements = Statement;
    _moment = moment;

    constructor(public printService: PrintService) {}

    ngOnInit() {}

}
