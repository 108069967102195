<div class="text-center mat-bg-primary pt-1 pb-1" style="align-items: center;">
    <div fxLayout="row" fxLayoutWrap="wrap">
        <div style="float: left;" fxFlex="99" fxFlex.gt-sm="23">
            <button mat-icon-button class="mat-elevation-z10" (click)="closeNotifications()">
                <mat-icon style="cursor: pointer;">clear
                </mat-icon>
            </button>
        </div>
        <div class="mediumFont" fxFlex="99"
             fxFlex.gt-sm="38" style="font-size: 25px;" translate>Notifications
        </div>
        <div style="float: right; margin-right: 1rem;" fxFlex="99" fxFlex.gt-sm="38">
            <button class="button mat-elevation-z10" mat-raised-button (click)="changeAllNotification()"
                    color="primary">
                <span class="smallestFont">
                    {{'Mark all as Read' | translate}}
                </span>
            </button>
        </div>
    </div>
</div>
<mat-nav-list class="notification-list" role="list">
    <mat-list-item *ngIf="notifications.length === 0 && dataexists" role="listitem" routerLinkActive="open"
                   style="height: auto !important;">
        <div style="text-align: center;">
            <mat-icon color="accent" class="icons">notifications_off</mat-icon>
            <span style="font-size: 20px; font-weight: bolder;">
    {{'YOU DONT HAVE NOTIFICATION' | translate}}
    </span>
            <mat-icon color="accent" class="icons">notifications_off</mat-icon>
        </div>
    </mat-list-item>
    <mat-progress-spinner
        *ngIf="!dataexists"
        mode="indeterminate"
        color="primary"
        style="margin-left: 30%; margin-top: 30%;">
    </mat-progress-spinner>
    <div [@.disabled]="isDisabled">
        <mat-list-item *ngFor="let n of notifications; index as i"
                       role="listitem"
                       [ngClass]="{'notApermanentSolution': (!n.notificationDto.notifType) && n.notificationDto.error != 'EMPTY file'}"
                       routerLinkActive="open"
                       style="height: auto !important;"
                       [@simpleFadeAnimation]="isOpen ? 'open' : 'closed'">
            <mat-icon class="icons" *ngIf="n.severity == 'warning' && n.todo !== 'Verify contents of the card'"
                      style="color: #F99746;" matTooltip='{{"Warning" | translate}}'>
                warning
            </mat-icon>
            <mat-icon class="icons" *ngIf="n.severity == 'problem'" style="color: #F99746;"
                      matTooltip='{{"Problem" | translate}}'>
                warning
            </mat-icon>
            <mat-icon class="icons" *ngIf="n.severity == 'notification'" color="primary"
                      matTooltip='{{"Notification" | translate}}'>notifications
            </mat-icon>
            <mat-icon class="icons" *ngIf="n.severity == 'warning' && n.todo === 'Verify contents of the card'"
                      color="primary" matTooltip='{{"Notification" | translate}}'>
                notifications
            </mat-icon>
            <mat-icon class="icons" *ngIf="n.severity == 'error'" color="warn" matTooltip='{{"Problem" | translate}}'>
                error
            </mat-icon>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem; cursor: default;"
                 *ngIf="n.todo === 'Check driver licence infos in driver information'">
                <a style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/individualview">
                    <div>{{'The uploaded file contains driver licence check information' | translate}}.</div>
                    <div class="text-muted" *ngIf="!n.timeStamp">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo.includes('Check') && n.todo.includes('informations')">
                <a *ngIf="n.notificationDto.entityName && n.notificationDto.notifType === 2"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/individualview/{{n.notificationDto.entityName}}">
                    <div *ngIf="n.notificationDto.regNo">
                        <div>{{n.notificationDto.entityName}}</div>
                        <div style="color: #0072BB; font-weight: 400;">{{'Driver licence was verified successfully' | translate}}.</div>
                        <div class="text-muted" *ngIf="!n.timeStamp">{{formatDate(n.timeStamp)}}</div>
                    </div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem; cursor: default;"
                 *ngIf="n.todo === 'Verify contents of the card'">
                <a style="cursor: pointer;"
                   *ngIf="n.notificationDto.notifType === 2"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/drivers/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div>{{'Uploaded' | translate}}: {{n.notificationDto.fileName}}</div>
                    <div class="text-muted" *ngIf="!n.notificationDto.uploadDateHR">{{formatDate(n.timeStamp)}}</div>
                    <div class="text-muted" *ngIf="n.notificationDto.uploadDateHR">{{'Uploaded' | translate}}
                        : {{formatDate(n.notificationDto.uploadDate)}}</div>
                </a>
                <a style="cursor: pointer;"
                   *ngIf="n.notificationDto.notifType === 1"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/vehicles/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div>{{'Uploaded' | translate}}: {{n.notificationDto.fileName}}</div>
                    <div class="text-muted" *ngIf="!n.notificationDto.uploadDateHR">{{formatDate(n.timeStamp)}}</div>
                    <div class="text-muted" *ngIf="n.notificationDto.uploadDateHR">{{'Uploaded' | translate}}
                        : {{formatDate(n.notificationDto.uploadDate)}}</div>
                </a>
            </div>
            <!--        <div class="mat-list-text smallFont"-->
            <!--             style="line-height: 1rem; font-size: 13px; margin-left: 1rem; cursor: default !important;"-->
            <!--             *ngIf="n.notificationDto && n.todo === 'Renew/Delete the cards' && n.notificationDto.notifType === 1">-->
            <!--            <a routerLink="/vehicles/expired" (click)="changeSeen(n.id, i, n.resolved)">-->
            <!--                <div>{{'renew/Delete the cards' | translate}}</div>-->
            <!--                <div-->
            <!--                    style="color: #F77B14; font-weight: 400;">{{'Found driverCard(s)'| translate: {numberOfCards: ifValueNull(n.notificationDto.numberOfCards)} }}</div>-->
            <!--                <div class="text-muted">{{formatDate(n.timeStamp)}}</div>-->
            <!--            </a>-->
            <!--        </div>-->
            <!--        <div class="mat-list-text smallFont"-->
            <!--             style="line-height: 1rem; font-size: 13px; margin-left: 1rem; cursor: default !important;"-->
            <!--             *ngIf="!n.notificationDto && n.todo === 'renew/Delete the cards' && n.notificationDto.notifType === 2">-->
            <!--            <a routerLink="/drivers/expired" (click)="changeSeen(n.id, i, n.resolved)">-->
            <!--                <div>{{'renew/Delete the cards' | translate}}</div>-->
            <!--                <div style="color: #F77B14; font-weight: 400;">{{'driver card expiring/expired' | translate}}</div>-->
            <!--                <div class="text-muted">{{formatDate(n.timeStamp)}}</div>-->
            <!--            </a>-->
            <!--        </div>-->
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo === 'Check file upload problem'"
                 matTooltip="{{'The file might be damaged or the device may be faulty. Try again.' | translate}}">
                <div>{{n.notificationDto.fileName}}</div>
                <div style="color: #FF0F00; font-weight: 400;">{{'check file upload problem' | translate}}</div>
                <div
                    style="color: #FF0F00; font-weight: 400;">{{'Empty file'| translate}}</div>
                <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo === 'Check new data'">
                <a *ngIf="n.notificationDto.entityName && n.notificationDto.notifType === 2"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/drivers/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div *ngIf="n.notificationDto.regNo">
                        <div>{{n.notificationDto.fileName}}</div>
                        <div
                            style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Driver'| translate: {regNo: ifValueNull(n.notificationDto.regNo)} }}</div>
                        <div style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Data'| translate:
                            {
                                km: n.notificationDto.km,
                                days: n.notificationDto.days,
                                fault: n.notificationDto.faultCount,
                                event: n.notificationDto.eventCount
                            } }}</div>
                    </div>
                </a>
                <a *ngIf="n.notificationDto.entityName && n.notificationDto.notifType === 1"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/vehicles/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div *ngIf="n.notificationDto.regNo">
                        <div>{{n.notificationDto.fileName}}</div>
                        <div
                            style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Driver'| translate: {regNo: ifValueNull(n.notificationDto.regNo)} }}</div>
                        <div style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Data'| translate:
                            {
                                km: n.notificationDto.km,
                                days: n.notificationDto.days,
                                fault: n.notificationDto.faultCount,
                                event: n.notificationDto.eventCount
                            } }}</div>
                    </div>
                </a>
                <a *ngIf="n.notificationDto.entityName && n.notificationDto.notifType === 1"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/vehicles/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div *ngIf="n.notificationDto.entityName && !n.notificationDto.regNo">
                        <div>{{n.notificationDto.fileName}}</div>
                        <div
                            style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Driver'| translate: {regNo: ifValueNull(n.notificationDto.entityName)} }}</div>
                        <div style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Data'| translate:
                            {
                                km: n.notificationDto.km,
                                days: n.notificationDto.days,
                                fault: n.notificationDto.faultCount,
                                event: n.notificationDto.eventCount
                            } }}</div>
                    </div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
                <a *ngIf="n.notificationDto.entityName && n.notificationDto.notifType === 2"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/drivers/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div *ngIf="n.notificationDto.entityName && !n.notificationDto.regNo">
                        <div>{{n.notificationDto.fileName}}</div>
                        <div
                            style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Driver'| translate: {regNo: ifValueNull(n.notificationDto.entityName)} }}</div>
                        <div style="color: #0072BB; font-weight: 400;">{{'Upload finished successfully/Data'| translate:
                            {
                                km: n.notificationDto.km,
                                days: n.notificationDto.days,
                                fault: n.notificationDto.faultCount,
                                event: n.notificationDto.eventCount
                            } }}</div>
                    </div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo === 'Check new data' && n.description.includes('new infringements')">
                <a style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/drivers/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div>{{n.notificationDto.fileName}}</div>
                    <div style="color: #F77B14; font-weight: 400;">{{'Notification/New infringements'| translate: {
                        offenceCount: n.notificationDto.offenceCount,
                        driverName: n.notificationDto.driverName
                    } }}</div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo === 'Check new data' && n.description.includes('new infringements')">
                <a style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/drivers/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div>{{n.notificationDto.fileName}}</div>
                    <div style="color: #F77B14; font-weight: 400;">{{'Notification/New infringements'| translate: {
                        offenceCount: n.notificationDto.offenceCount,
                        driverName: n.notificationDto.driverName
                    } }}</div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo === 'Check new data' && n.summary.includes('events found')">
                <a style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/vehicles/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div>{{n.notificationDto.fileName}}</div>
                    <div style="color: #F77B14; font-weight: 400;">{{'Notification/New events'| translate: {
                        eventCount: n.notificationDto.eventCount,
                        driverName: n.notificationDto.driverName,
                        event: formatEvents(n.notificationDto.event),
                        vehicleRegNumber: n.notificationDto.regNo.vehicleRegistrationNumber.vehicleRegNumber
                    } }}</div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem;"
                 *ngIf="n.todo === 'Check new data' && n.summary.includes('faults found')">
                <a style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/vehicles/{{'---'}}/{{n.notificationDto.entityName}}">
                    <div>{{n.notificationDto.fileName}}</div>
                    <div style="color: #F77B14; font-weight: 400;">{{'Notification/New faults'| translate: {
                        eventCount: n.notificationDto.eventCount,
                        driverName: n.notificationDto.driverName,
                        fault: formatEvents(n.notificationDto.fault),
                        vehicleRegNumber: n.notificationDto.regNo.vehicleRegistrationNumber.vehicleRegNumber
                    } }}</div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="mat-list-text smallFont"
                 style="line-height: 1rem; font-size: 13px; margin-left: 1rem; cursor: default !important;"
                 *ngIf="n.todo === 'Try again with new data' && (n.description.includes('File was already uploaded') || n.description.includes('file was already uploaded'))">
                <a *ngIf="n.notificationDto.notifType === 2"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/uploaded-files/uploadeddriverfiles">
                    <div *ngIf="n.notificationDto.fileName">{{'Uploaded' | translate}}
                        : {{n.notificationDto.fileName}}</div>
                    <div *ngIf="!n.notificationDto.fileName">---</div>
                    <div
                        *ngIf="n.notificationDto">{{'Notification/Already uploaded' | translate: {date: formatDate(n.notificationDto.uploadDate)} }}</div>
                    <div style="color: #F99746; font-weight: 400;">{{n.summary | translate}}</div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
                <a *ngIf="n.notificationDto.notifType === 1"
                   style="cursor: pointer;"
                   (click)="changeSeen(n.id, i, n.resolved)"
                   routerLink="/uploaded-files/uploadedvehiclefiles">
                    <div *ngIf="n.notificationDto.fileName">{{'Uploaded' | translate}}
                        : {{n.notificationDto.fileName}}</div>
                    <div *ngIf="!n.notificationDto.fileName">---</div>
                    <div
                        *ngIf="n.notificationDto">{{'Notification/Already uploaded' | translate: {date: formatDate(n.notificationDto.uploadDate)} }}</div>
                    <div style="color: #F99746; font-weight: 400;">{{n.summary | translate}}</div>
                    <div class="text-muted">{{formatDate(n.timeStamp)}}</div>
                </a>
            </div>
            <div class="smallestDevice">
                <!--            <mat-icon class="material-icons-outlined">fiber_manual_record</mat-icon>-->
                <!--            <mat-icon>fiber_manual_record</mat-icon>-->
                <button class="iconsSmallerDevicesF" mat-icon-button (click)="changeSeen(n.id, i, n.resolved)">
                    <mat-icon
                        class="mat-color-primary"
                        [matTooltip]="n.resolved ? markAsRead : markAsUnread"
                        [ngClass]="{'material-icons-outlined': n.resolved === true, 'material-icons-filled': n.resolved === false}">
                        fiber_manual_record
                    </mat-icon>
                </button>
                <button class="iconsSmallerDevices" matTooltip="{{'Delete' | translate}}" mat-icon-button
                        (click)="deleteNotification(n.id, i, notifications)">
                    <mat-icon style="color: #FF0F00;">delete</mat-icon>
                </button>
            </div>
            <mat-divider></mat-divider>
        </mat-list-item>
    </div>
</mat-nav-list>
