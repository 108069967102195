import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-is-pro-user',
  templateUrl: './is-pro-user.component.html',
  styleUrls: ['./is-pro-user.component.css']
})
export class IsProUserComponent implements OnInit {

    @Output() newItemEvent = new EventEmitter<string>();
    isPro = localStorage.getItem('isPro') === 'true' ;
    isVisible = true;

    constructor(
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        
    }
    async init(){
        const res = await this.authService.asr();
        this.isVisible = res.PRO && !res.ClientWithRemoteUnits;
        this.isPro = localStorage.getItem('isPro') === 'true';
        this.getProUser(this.isPro ? 'true' : 'false');
    }

    getProUser(value: string) {
        this.newItemEvent.emit(value);
    }

}
