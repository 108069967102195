import { Moment } from 'moment-timezone';
import { getVariableValueAsNumber } from 'app/helpers/number_utility';

export class InfoLineitemDescription {
    text: string;
    crossingMinute: number;
    position: number;
    offset: number;
    extraInfo: boolean;
    iconTexts: IconText[];
    lastDownloadMoment: Moment;

    constructor() {
        this.iconTexts = [];
    }
}

export class IconText {
    icon: string;
    text: string;
}

export class Dailychartinput {
    dataset: DataSet[];
    workingTimeRows: OneWorkingTimeRow[];
    workingDetailsForOneDay: WorkingTimeDetails;
    regularWeeklyRest: RegularWeeklyRest;
    dateOfChart: number;
    noDataForToday = false;
    timezone: string;
    borderCrossingItems: InfoLineitemDescription[];
    vehicleItems: InfoLineitemDescription[];
    ferryItems: InfoLineitemDescription[];
    infringments: Infringment[];
    offences: Offence[];
    faults: Fault[];
    drivingsWithoutCard: Fault[];
    noCardInsertedOnDay: InfoLineitemDescription[];
    driverFirstName: string;
    driverLastName: string;
    vehicleRegistrationNumber: string;
    vehicleIdentificationNumber: string;
    driversOnDay: InfoLineitemDescription[];
    odometerStart: number;
    odometerEnd: number;
    odometerSum: number;
    lastDownloadedTime: number;

    constructor() {
        this.workingTimeRows = Array<OneWorkingTimeRow>();
        this.workingDetailsForOneDay = new WorkingTimeDetails();
        this.dataset = [];
        this.regularWeeklyRest = new RegularWeeklyRest();
    }
}

export class OneWorkingTimeRow {
    startTime: number;
    endTime: number;
    startDayString: string;
    endDayString: string;
    workingTimeDetails: WorkingTimeDetails;
    unrecordedResting: number;
}

export class WorkingTimeDetails {
    driving: number;
    working: number;
    resting: number;
    availability: number;
    allWorksInShift: number;
    shiftSumma: number;
    allWorksInDay: number;
    restPeriod: number;

    getSum(): number {
        const items = [
            this.driving, this.working, this.resting, this.availability
        ];
        return this.getSumByItems(items);
    }

    getAllWorksSumInDay(): number {
        const items = [
            this.driving, this.working, this.availability
        ];
        return this.getSumByItems(items);
    }

    getSumOfDrivingWorkingResting(): number {
        const items = [
            this.driving, this.working, this.availability
        ];
        return this.getSumByItems(items);
    }

    getSumByItems(items: any[]) {
        let sum = 0;
        for (const item of items) {
            sum += getVariableValueAsNumber(item)
        }
        return sum;
    }
}

export class RegularWeeklyRest {
    startTime: number;
    endTime: number;
}

export class DataSet {
    data: any[];
    type: DataTypes;

    constructor(type: DataTypes) {
        this.type = type;
        this.data = [];
    }

    getValue(): number {
        switch (this.type) {
            case DataTypes.BREAK_REST:
                return 1;
            case DataTypes.AVAILABILITY:
                return 2;
            case DataTypes.WORK:
                return 3;
            case DataTypes.DRIVING:
                return 4;
            case DataTypes.WORKING_TIME:
                return -1;
        }
    }
}

export enum DataTypes {
    DRIVING = 'DRIVING',
    BREAK_REST = 'BREAK_REST',
    WORK = 'WORK',
    AVAILABILITY = 'AVAILABILITY',
    WORKING_TIME = 'WORKING_TIME',
    CREW_DRIVING = 'CREW_DRIVING',
    INFRINGMENT = 'INFRINGMENT',
    FAKE_ACTIVITY = 'FAKE_ACTIVITY'
}

export class ChartInfo {
    eventsOnDays: OneDayActivity[];
}

export class OneDayActivity {
    recordDate: number;
    offsetOfDay: number;
    dailyDrivingSumInMinutesOnDay: number;
    dailyWorkSumInMinutesOnDay: number;
    dailyAvailabilitySumInMinutesOnDay: number;
    dailySumInMinutesOnDay: number;
    activities: Activity[];
    shifts: Shift[];
    places: Place[];
    ferryEntryTimes: number[];
    driversOnDay: DriversOnDay[];
    infringements: Infringment[];
    offences: Offence[];
    faults: Fault[];
    cardlessDriving: Fault[];
    odometerStart: number;
    odometerEnd: number;
    odometerSum: number;

    constructor() {
        this.activities = [];
        this.shifts = [];
        this.places = [];
        this.ferryEntryTimes = [];
        this.infringements = [];
    }
}

export class Activity {
    start: number;
    end: number;
    activity: DataTypes;
    cardStatus: CardStatus;
    drivingStatus: DrivingStatus;
    slot: Slot;
}

export class Fault {
    duration?: number;
    eventFaultType?: number;
    eventBeginTime: number;
    eventEndTime: number;

    public static compare(fault1: Fault, fault2: Fault) {
        if (!!fault1.eventBeginTime && !!fault2.eventBeginTime) {
            if (fault1.eventBeginTime < fault2.eventBeginTime) { return -1; }
            if (fault1.eventBeginTime > fault2.eventBeginTime) { return 1; }
        }
        if (!!fault1.eventEndTime && !!fault2.eventEndTime) {
            if (fault1.eventEndTime < fault2.eventEndTime) { return -1; }
            if (fault1.eventEndTime > fault2.eventEndTime) { return 1; }
        }
        if (!!fault1.eventFaultType && !!fault2.eventFaultType) {
            if (fault1.eventFaultType < fault2.eventFaultType) { return -1; }
            if (fault1.eventFaultType > fault2.eventFaultType) { return 1; }
        }
        return 0;
    }
}

export class Shift {
    driveInShift: number;
    workInShift: number;
    availabilityInShift: number;
    allWorksInShift: number;
    loggedBreakInShift: number;
    restPeriodForShift: number;
    shiftSumma: number;
    shiftFrom: number;
    shiftTo: number;
    startPlace: Place;
    endPlace: Place;
    shiftVehicleInfo: ShiftVehicleInfo[];

    constructor() {
        this.shiftVehicleInfo = [];
    }
}

export class Place {
    entryTime: number;
    countryShort: string;
}

export class ShiftVehicleInfo {
    registrationNumber: string;
    registrationNation: string;
    odometerBegin: number;
    odometerEnd: number;
    odometerSumma: number;
    avgSpeed: number;
    vehicleChange: boolean;
    vehicleFirstUse: number;
    vehicleLastUse: number;
}

export class Infringment {
    offenceStart: number;
    offenceEnd: number;
    offenceStartMoment: Moment;
    offenceEndMoment: Moment;
    offenceStartDayString: string;
    offenceEndDayString: string;
    typeOfOffence: string;
    drivingStatus: number;
}

export enum Severity {
    MI = 'MI',
    SI = 'SI',
    VSI = 'VSI',
    MSI = 'MSI'
}

export class Offence {
    startTime: number;
    endTime: number;
    type: string;
    duration: number;
    norm: number;
    severity: Severity;

    offenceStartMoment: Moment;
    offenceEndMoment: Moment;
    offenceStartDayString: string;
    offenceEndDayString: string;
}

export class DriversOnDay {
    surName: string;
    firstName: string;
    cardSlot: CardSlot;
    useFrom: number;
    startDateOfDriving: number;
    endDateOfDriving: number;
}

export enum CardStatus {
    INSERTED = 'INSERTED',
    NOT_INSERTED = 'NOT_INSERTED'
}

export enum CardSlot {
    DRIVER = 'driverSlot',
    CODRIVER = 'co-driverSlot'
}

export enum DrivingStatus {
    SINGLE = 'SINGLE',
    CREW = 'CREW',
    KNOWN = 'KNOWN',
    UNKNOWN = 'UNKNOWN'
}

export enum Slot {
    CODRIVER= 'CO-DRIVER',
    DRIVER = 'DRIVER'
}
