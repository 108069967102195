<div *ngIf="currentLang" [ngClass]="isPrinting ? 'dailyChartOuter print' : 'dailyChartOuter'">
    <div class="dailyChartOuterCont" *appLoadingSpinner="!dailyChartInput">
        <div class="dailyChartCalendar" *ngIf="isPrinting && typeOfChart === tabOptions.DRIVER &&
            dailyChartService.getReportContext(printService.tab).reportContext.dailyChartResponses.length > 1">
            {{ '' | nameChart:dailyChartInput.driverFirstName:dailyChartInput.driverLastName:('Report.DailyChart.nameFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
        </div>
        <div class="dailyChartCalendar" *ngIf="isPrinting && typeOfChart === tabOptions.VEHICLE &&
            dailyChartService.getReportContext(printService.tab).reportContext.dailyChartResponses.length > 1">
            {{ dailyChartInput.vehicleRegistrationNumber }} / {{ dailyChartInput.vehicleIdentificationNumber }}
        </div>
        <div class="dailyChartTextPane">
            <div class="dailyChartOuterBlocks">
                <div class="dailyChartBlocks" *ngIf="!isVisibleDataOnChart() || dailyChartInput.noDataForToday">
                    <div class="dailyChartCol1">
                        <div class="dailyChartCalendar">
                            <span>{{ startDayInMoment.tz(timezone).format('Report.General.DateFormat' | instantTranslate: currentLang | async) }}</span>&nbsp;
                            <span>{{ nameOfDayInWeek }}</span>
                        </div>
                    </div>
                    <div class="dailyChartCol2">
                        <span *ngIf="!isVisibleDataOnChart() && !dailyChartInput.noDataForToday" style="position:relative;">
                            <mat-icon svgIcon="no_data_icon" class="dailyChartIcons vAlignCenter"></mat-icon>
                            <span style="padding-left: 28px;"> {{ 'Report.DailyChart.noInsertedDataForToday' | instantTranslate: currentLang | async }}</span>
                        </span>
                        <span *ngIf="dailyChartInput.noDataForToday">
                            <mat-icon svgIcon="file_download_off_black_icon" class="dailyChartIcons vAlignCenter"></mat-icon>
                            <span style="padding-left: 28px;"> {{ 'Report.DailyChart.noDownloadedDataForToday' | instantTranslate: currentLang | async }}</span>
                        </span>
                    </div>
                </div>
                <div class="dailyChartBlocks" *ngIf="isVisibleDataOnChart() && !dailyChartInput.noDataForToday">
                    <div class="dailyChartCol">
                        <div class="dailyChartRow">
                            <div class="dailyChartCol1">
                                <div class="dailyChartCalendar">
                                    <span>{{ startDayInMoment.tz(timezone).format('Report.General.DateFormat' | instantTranslate: currentLang | async) }}</span>&nbsp;
                                    <span>{{ nameOfDayInWeek }}</span>
                                </div>
                            </div>
                            <div class="dailyChartCol2 icon-cell" *ngIf="!lastDownloadInfolineDescription">
                            <mat-icon svgIcon="kormany_new_colored_icon"
                                      class="dailyChartIcons icon"
                                      style="transform: translateY(-2px)"
                                      [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(dailyChartInput.workingDetailsForOneDay.driving) }} +</span>
                                <mat-icon svgIcon="work_new_colored_icon"
                                          class="dailyChartIcons icon"
                                          style="transform: translateY(-2px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(dailyChartInput.workingDetailsForOneDay.working) }} +</span>
                                <mat-icon svgIcon="available_colored"
                                          class="dailyChartIcons icon"
                                          style="transform: translateY(-2px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(dailyChartInput.workingDetailsForOneDay.availability) }} =&nbsp;</span>
                                <span>{{ dateUtil.transform(dailyChartInput.workingDetailsForOneDay.allWorksInDay) }}</span>
                            </div>
                            <div *ngIf="typeOfChart === tabOptions.VEHICLE" class="dailyChartCol3 icon-cell">
                                <mat-icon svgIcon="location_icon"
                                          class="dailyChartIcons icon"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span *ngIf="dailyChartInput.odometerStart">
                                    {{dailyChartInput.odometerStart | number: '':'hu'}} km -&nbsp;
                                </span>
                                <span *ngIf="!dailyChartInput.odometerStart">
                                    N/A -&nbsp;
                                </span>
                                <span>{{ dailyChartInput.odometerEnd | number: '':'hu'}} km</span>
                                <span *ngIf="dailyChartInput.odometerSum">
                                    &nbsp;Σ {{dailyChartInput.odometerSum | number: '':'hu'}} km
                                </span>
                                <span *ngIf="!dailyChartInput.odometerSum">
                                    &nbsp;Σ 0 km
                                </span>
                            </div>
                        </div>
                        <div *ngFor="let workingTimeRow of dailyChartInput.workingTimeRows; let i = index;" class="dailyChartRow" >
                            <div class="dailyChartCol1 icon-cell">
                                <mat-icon svgIcon="play_icon"
                                          class="dailyChartIcons icon"
                                          style="margin-left: 10px; transform: translateY(-1px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span *ngIf="!isNameOfDayIsToday(workingTimeRow.startDayString)">&nbsp;{{ workingTimeRow.startDayString }}&nbsp;</span>
                                <span>{{ dateUtil.transform(workingTimeRow.startTime, true) }}&nbsp;-&nbsp;</span>
                                <span *ngIf="!isNameOfDayIsToday(workingTimeRow.endDayString)">&nbsp;{{ workingTimeRow.endDayString }}&nbsp;</span>
                                <span>{{ dateUtil.transform(workingTimeRow.endTime, true) }}</span>
                            </div>
                            <div class="dailyChartCol2 icon-cell">
                                <mat-icon svgIcon="kormany_new_colored_icon"
                                          class="dailyChartIcons icon"
                                          style="transform: translateY(-2px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.driving, false, true) }} +</span>
                                <mat-icon svgIcon="work_new_colored_icon"
                                          class="dailyChartIcons icon"
                                          style="transform: translateY(-2px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.working, false, true) }} +</span>
                                <mat-icon svgIcon="available_colored"
                                          class="dailyChartIcons icon"
                                          style="transform: translateY(-2px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.availability, false, true) }} =&nbsp;</span>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.allWorksInShift, false, true) }} +</span>
                                <mat-icon svgIcon="bed_new_colored_icon"
                                          class="dailyChartIcons icon"
                                          style="transform: translateY(-2px)"
                                          [ngStyle]="{'font-size': isPrinting ? '10px' : '15px'}"></mat-icon>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.resting, false, true) }} =&nbsp;</span>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.shiftSumma, false, true) }}</span>
                                <span style="margin-left: 10px">{{ 'Report.DailyChart.RestPeriod' | instantTranslate: currentLang | async }}:&nbsp;</span>
                                <span>{{ dateUtil.transform(workingTimeRow.workingTimeDetails.restPeriod, false, true) }}</span>
                            </div>
<!--                            <div class="dailyChartCol3">-->
<!--                                {{ 'Report.DailyChart.unrecordedRestingTime' | instantTranslate: currentLang | async}} {{dateUtil.transform(workingTimeRow.unrecordedResting) }}-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Későbbi fejlesztéshez fog kelleni, egyelőre nincs adat hozzá, rendszeres heti pihenés -->
<!--                <div class="dailyChartUnderBlocks" *ngIf="isVisibleDataOnChart() && !dailyChartInput.noDataForToday">-->
<!--                    {{ 'Report.DailyChart.regularWeeklyRest' | instantTranslate: currentLang | async }}-->
<!--                    {{formatUtcToString(dailyChartInput.regularWeeklyRest.startTime)}} - {{formatUtcToString(dailyChartInput.regularWeeklyRest.endTime)}}-->
<!--                    <mat-icon svgIcon="bed_new_colored_icon" class="dailyChartIcons"></mat-icon>-->
<!--                    {{dateUtil.transform((dailyChartInput.regularWeeklyRest.endTime - dailyChartInput.regularWeeklyRest.startTime) / 1000 / 60)}}-->
<!--                </div>-->
            </div>
        </div>
        <div class="infoline" style="margin-top: 10px">
            <app-infoline-component *ngIf="timeOffsetChangingPlus"
                                    [annotationType]="chartAnnotationTypes.TIMEOFFSETCHANGEPLUS"
                                    [currentLangCode]="currentLang"
                                    style="margin-right: -25px; padding-left:8%">
            </app-infoline-component>
            <app-infoline-component *ngIf="timeOffsetChangingMinus"
                                    [annotationType]="chartAnnotationTypes.TIMEOFFSETCHANGEMINUS"
                                    [currentLangCode]="currentLang"
                                    style="margin-right: -25px; padding-left:6.7%">
            </app-infoline-component>
            <app-infoline-component *ngFor="let ferryItem of dailyChartInput.ferryItems"
                                       [annotationType]="chartAnnotationTypes.FERRY"
                                       [currentLangCode]="currentLang"
                                       style="padding-left:calc({{ferryItem.position}}% - 24px)">
            </app-infoline-component>
            <app-infoline-component *ngIf="lastDownloadInfolineDescription"
                                    [innerText]="lastDownloadInfolineDescription.text"
                                    [lastDownloadMoment]="lastDownloadInfolineDescription.lastDownloadMoment"
                                    [annotationType]="chartAnnotationTypes.LASTDOWNLOADEDDATA"
                                    [currentLangCode]="currentLang"
                                    style="padding-left:calc({{lastDownloadInfolineDescription.position}}% - 24px)">
            </app-infoline-component>
        </div>
        <div *ngIf="dailyChartInput.borderCrossingItems && dailyChartInput.borderCrossingItems.length > 0" class="infoline" #borderCrossingContainer>
            <app-infoline-component *ngFor="let borderCrossingItem of dailyChartInput.borderCrossingItems; let i = index;"
                                       innerText="{{borderCrossingItem.text}}"
                                       [annotationType]="chartAnnotationTypes.BORDER_CROSS"
                                       style="padding-left:calc({{ borderCrossingItem.position }}% * {{(borderCrossingContainer.offsetWidth - (!!isPrinting ? 8 : 12)) / borderCrossingContainer.offsetWidth}} - {{ (!!i ? (!!isPrinting ? 16 : 24) : (!!isPrinting ? 8 : 12)) + borderCrossingItem.offset }}px)">
            </app-infoline-component>
        </div>
        <div [ngClass]="isPrinting ? 'dailyChartContainer print' : 'dailyChartContainer'"
            [ngStyle]="{'height': chartHeight + 'px'}">
            <div id="{{elementId}}"
                 [ngClass]="isPrinting ? 'dailychart print' : 'dailychart'"
                 [ngStyle]="{'height': chartHeight + 'px'}">
                 translate>
            </div>
        </div>
        <div class="infoline">
            <span *ngFor="let vehicleItem of dailyChartInput.vehicleItems"
                  style="width: 100%"
                  [ngStyle]="{'text-indent': vehicleItem.position + '%'}">
                <app-infoline-component *ngIf="vehicleItem.position <= 80"
                                        [innerText]="vehicleItem.text"
                                        [annotationType]="chartAnnotationTypes.VEHICLE"
                                        [currentLangCode]="currentLang"
                                        [iconTexts]="vehicleItem.iconTexts"
                                        [extraInfo]="vehicleItem.extraInfo">
                </app-infoline-component>
                <app-infoline-component *ngIf="vehicleItem.position > 80"
                                        [innerText]="vehicleItem.text"
                                        [annotationType]="chartAnnotationTypes.VEHICLE"
                                        [currentLangCode]="currentLang"
                                        [iconTexts]="vehicleItem.iconTexts"
                                        [extraInfo]="vehicleItem.extraInfo"
                                        style="text-align: right; text-indent: 80%">
                </app-infoline-component>
            </span>
            <span *ngFor="let driverItem of dailyChartInput.driversOnDay"
                  style="width: 100%"
                  [ngStyle]="{'text-indent': driverItem.position + '%'}">
                <app-infoline-component *ngIf="!driverItem.position || driverItem.position <= 80"
                                        [innerText]="driverItem.text"
                                        [annotationType]="chartAnnotationTypes.DRIVERS"
                                        [currentLangCode]="currentLang"
                                        [iconTexts]="driverItem.iconTexts"
                                        [extraInfo]="driverItem.extraInfo">
                </app-infoline-component>
                 <app-infoline-component *ngIf="driverItem.position > 80"
                                         [innerText]="driverItem.text"
                                         [annotationType]="chartAnnotationTypes.DRIVERS"
                                         [currentLangCode]="currentLang"
                                         [iconTexts]="driverItem.iconTexts"
                                         [extraInfo]="driverItem.extraInfo"
                                         style="text-align: right; text-indent: 80%">
                </app-infoline-component>
            </span>
            <span *ngFor="let withoutCard of dailyChartInput.noCardInsertedOnDay"
                  style="width: 100%"
                  [ngStyle]="{'text-indent': 'calc(' + withoutCard.position + '% - 15px)'}">
                <app-infoline-component [annotationType]="chartAnnotationTypes.WITHOUT_CARD"
                                        [currentLangCode]="currentLang"
                                        [iconTexts]="withoutCard.iconTexts">
                </app-infoline-component>
            </span>
        </div>

        <div *ngIf="isInfringementToday()">
            <div class="infringmentsContainer">
                <div class="infringmentList" *ngIf="typeOfChart === tabOptions.DRIVER">
                    <table mat-table [dataSource]="dailyChartInput.offences" style="border-color: unset">
                        <ng-container matColumnDef="staticInfringmentsText">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '100px' : '12.5%'}">
                                <div class="icon-cell">
                                    <mat-icon svgIcon="control_icon" class="icon svgIcon" style="transform: translateY(-2px)"></mat-icon>
                                    <span>{{ 'Report.DailyChart.infringmentStaticText' | instantTranslate: currentLang | async }}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="offenceInterval">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Report.DailyChart.Interval' | instantTranslate:currentLang | async }}</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '140px' : '17.5%'}">
                                <span *ngIf="!isNameOfDayIsToday(element.offenceStartDayString)">{{ element.offenceStartDayString }}</span>
                                {{ element.offenceStartMoment.tz(timezone).format('Report.DailyChart.timeFormatInDay' | instantTranslate: currentLang | async) }}
                                -
                                <span *ngIf="!isNameOfDayIsToday(element.offenceEndDayString)">{{ element.offenceEndDayString }}</span>
                                {{ element.offenceEndMoment.tz(timezone).format('Report.DailyChart.timeFormatInDay' | instantTranslate: currentLang | async) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="typeOfOffence">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Code' | instantTranslate:currentLang | async }}</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '80px' : '10%'}"> {{ element.type }} </td>
                        </ng-container>

                        <ng-container matColumnDef="offenceTypeDescription">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Description' | instantTranslate:currentLang | async }}</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '280px' : '35%'}">
                                {{ 'Report.DailyChart.' + element.type | instantTranslate: currentLang | async }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="duration">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Duration' | instantTranslate:currentLang | async }}</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '40px' : '5%'}">
                                {{ dateUtil.transform(math.floor(element.duration / 60), true, true) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="norm">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Norm' | instantTranslate:currentLang | async }}</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '40px' : '5%'}">
                                {{ dateUtil.transform(math.floor(element.norm / 60), true, true) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="difference">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Difference' | instantTranslate:currentLang | async }}</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '40px' : '5%'}">
                                {{ dateUtil.transform(math.floor(math.max(element.duration - element.norm, element.norm - element.duration) / 60), true, true) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="mi">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="icon-cell" style="justify-content: center">{{ 'MI' | instantTranslate:currentLang | async }}</div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '20px' : '2.5%'}">
                                <div class="icon-cell" style="justify-content: center">
                                    <mat-icon *ngIf="element.severity === 'MI'" class="icon" style="margin-right: unset">fiber_manual_record</mat-icon>
                                    <div *ngIf="element.severity !== 'MI'">-</div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="si">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="icon-cell" style="justify-content: center">{{ 'SI' | instantTranslate:currentLang | async }}</div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '20px' : '2.5%'}">
                                <div class="icon-cell" style="justify-content: center">
                                    <mat-icon *ngIf="element.severity === 'SI'" class="icon" style="margin-right: unset">fiber_manual_record</mat-icon>
                                    <div *ngIf="element.severity !== 'SI'">-</div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="vsi">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="icon-cell" style="justify-content: center">{{ 'VSI' | instantTranslate:currentLang | async }}</div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '20px' : '2.5%'}">
                                <div class="icon-cell" style="justify-content: center">
                                    <mat-icon *ngIf="element.severity === 'VSI'" class="icon" style="margin-right: unset">fiber_manual_record</mat-icon>
                                    <div *ngIf="element.severity !== 'VSI'">-</div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="msi">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="icon-cell" style="justify-content: center">{{ 'MSI' | instantTranslate:currentLang | async }}</div>
                            </th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '20px' : '2.5%'}">
                                <div class="icon-cell" style="justify-content: center">
                                    <mat-icon *ngIf="element.severity === 'MSI'" class="icon" style="margin-right: unset">fiber_manual_record</mat-icon>
                                    <div *ngIf="element.severity !== 'MSI'">-</div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="offencesDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: offencesDisplayedColumns"></tr>
                    </table>
                </div>
                <div class="infringmentList" *ngIf="false">
                    <table mat-table [dataSource]="dailyChartInput.infringments" style="border-color: unset">
                        <ng-container matColumnDef="staticInfringmentsText">
                            <td mat-cell *matCellDef="let element" class="w-15">
                                <span style="display: flex">
                                    <mat-icon svgIcon="dashboard_infregments_icon_red" class="dailyChartIcons"></mat-icon>
                                    {{ 'Report.DailyChart.infringmentStaticText' | instantTranslate: currentLang | async }}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="offenceInterval">
                            <td mat-cell *matCellDef="let element" class="w-25">
                                {{ element.offenceStartMoment.tz(timezone).format('Report.General.DateFormat' | instantTranslate: currentLang | async) }}
                                <span *ngIf="!isNameOfDayIsToday(element.offenceStartDayString)">{{element.offenceStartDayString}}</span>
                                {{ element.offenceStartMoment.tz(timezone).format('Report.DailyChart.timeFormatInDay' | instantTranslate: currentLang | async) }}
                                -
                                <!--                                {{ element.offenceEndMoment.tz(timezone).format('Report.General.DateFormat' | instantTranslate: currentLang | async) }}-->
                                <span *ngIf="!isNameOfDayIsToday(element.offenceEndDayString)">{{element.offenceEndDayString}}</span>
                                {{ element.offenceEndMoment.tz(timezone).format('Report.DailyChart.timeFormatInDay' | instantTranslate: currentLang | async) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="typeOfOffence">
                            <td mat-cell *matCellDef="let element" class="w-10"> {{element.typeOfOffence}} </td>
                        </ng-container>

                        <ng-container matColumnDef="offenceTypeDescription">
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isPrinting ? '400px' : '50%'}">
                                {{ 'Report.DailyChart.' + element.typeOfOffence | instantTranslate: currentLang | async }}
                            </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: infringementDisplayedColumns;"></tr>
                    </table>
                </div>
                <div class="infringmentList" *ngIf="typeOfChart === tabOptions.VEHICLE">
                    <table mat-table [dataSource]="faultsAndDrivingsWithoutCard" style="border-color: unset">
                        <ng-container matColumnDef="icon">
                            <td mat-cell *matCellDef="let fault">
                                <div class="icon-cell">
                                    <mat-icon *ngIf="!!fault.duration" class="icon svgIcon" svgIcon="control_icon"></mat-icon>
                                    <mat-icon *ngIf="!fault.duration" class="icon svgIcon" svgIcon="fault_icon"></mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="label">
                            <td mat-cell *matCellDef="let fault">
                                <div *ngIf="!!fault.duration">
                                    {{ 'Report.DailyChart.infringmentStaticText' | instantTranslate: currentLang | async }}
                                </div>
                                <div *ngIf="!fault.duration">
                                    {{ 'Report.DailyChart.Fault' | instantTranslate: currentLang | async }}
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="start">
                            <td mat-cell *matCellDef="let fault">
                                {{ _moment(fault.eventBeginTime * 1000).tz(timezone).format((isSameDay(fault.eventBeginTime, fault.eventEndTime) ? 'Report.DailyChart.timeFormatInDay' : 'Report.General.TimeFormat') | instantTranslate: currentLang | async) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="end">
                            <td mat-cell *matCellDef="let fault">
                                {{ _moment(fault.eventEndTime * 1000).tz(timezone).format((isSameDay(fault.eventBeginTime, fault.eventEndTime) ? 'Report.DailyChart.timeFormatInDay' : 'Report.General.TimeFormat') | instantTranslate: currentLang | async) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="duration">
                            <td mat-cell *matCellDef="let fault">
                                <div *ngIf="!!fault.duration">
                                    {{ dateUtil.transform(fault.duration, true, true) }}
                                </div>
                                <div *ngIf="!fault.duration">
                                    {{ dateUtil.transform(math.floor((fault.eventEndTime - fault.eventBeginTime) / 60), true, true) }}
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="withoutDriverCardIcon">
                            <td mat-cell *matCellDef="let fault">
                                <div *ngIf="!fault.eventFaultType" class="icon-cell">
                                    <mat-icon svgIcon="no_card_icon"
                                              class="icon svgIcon"></mat-icon>
                                    <span>- - -</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <td mat-cell *matCellDef="let fault">
                                <div *ngIf="!!fault.eventFaultType">
                                    {{ ('Report.WeeklyTable.FaultCode.' + fault.eventFaultType) | instantTranslate: currentLang | async }}
                                </div>
                                <div *ngIf="!fault.eventFaultType">
                                    {{ 'Report.DailyChart.DrivingWithoutCard' | instantTranslate: currentLang | async }}
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: faultsDisplayedColumns"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
