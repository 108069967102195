import { Component, OnInit } from '@angular/core';
import { DailyChartService } from 'app/services/rszoft/dailychart/daily-chart.service';
import { Router } from '@angular/router';
import { TabOption } from 'app/services/rszoft/report.model';
import { PrintService } from 'app/services/rszoft/print.service';

@Component({
    selector: 'app-print-layout',
    templateUrl: './print-layout.component.html',
    styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit {
    tabOptions = TabOption;

    constructor(public dailyChartService: DailyChartService,
                public printService: PrintService,
                private router: Router) {}

    ngOnInit(): void {
        if (!this.printService.tab) {
            console.log('No TAB info, which chart to print?');
            this.navigateBack();
        }
    }

    navigateBack() {
        this.router.navigate([this.printService.navigationURL, {reloadResultList: true}]);
    }

}
