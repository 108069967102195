import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {DriversService} from '../../../services/drivers/drivers.service';
import {AppSettings} from '../../../app.settings';
import {egretAnimations} from '../../../helpers/egret-animations';
import {FeedbackService} from '../../../services/feedback/feedback.service';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.css'],
  animations: egretAnimations
})
export class FeedbackPopupComponent implements OnInit {

    loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
    options: string[] = ['Yes', 'No'];
    selectedOption: string;
    windowHeight = window.innerHeight * 45 / 100;
    windowWidth = window.innerWidth * 45 / 100;
    private _subscriptions: Subscription;

    feedbackInformation = {
        clientId: this.loggedInUser.clientId,
        userId: this.loggedInUser.id,
        pageId: this.data,
        isUseful: null,
        comment: null,
        toBeNotified: null
    }
    message: string;
    error = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<FeedbackPopupComponent>,
                private fb: FormBuilder,
                private _feedback: FeedbackService,
                private snackBar: MatSnackBar,
                private driverService: DriversService,
                private _appSettings: AppSettings) {
        this._subscriptions = new Subscription();
    }

  ngOnInit(): void {

  }

  submitInfo() {
    // console.log(this.feedbackInformation);
    this._feedback.postExperimental(this.feedbackInformation).subscribe((response) => {
         this.message = response.result;
         this.openSnackBar(this.message);
    });
  }

  setType (type) {
      (type === 'Yes') ? this.feedbackInformation.isUseful = 1 : this.feedbackInformation.isUseful = 0;
  }

  setNotification(type) {
        // this.feedbackInformation.toBeNotified = type;
      (type === 'Yes') ? this.feedbackInformation.toBeNotified = 1 : this.feedbackInformation.toBeNotified = 0;
  }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'OK', {
            duration: 2000,
        });
    }
}
