<div class="reportContainer checklist-container print">
    <div class="checklist-summary" *ngIf="!!checklistService.data">
        <div class="checklist-content-wrapper">
            <ng-container [ngTemplateOutlet]="summaryTable"></ng-container>
        </div>
        <div class="checklist-content-wrapper" *ngIf="false">
            <ng-container [ngTemplateOutlet]="missingPeriodTable"></ng-container>
        </div>
    </div>
    <div class="checklist-content" *ngIf="!!checklistService.data">
        <div class="checklist-content-wrapper">
            <ng-container [ngTemplateOutlet]="driversTable"></ng-container>
        </div>
        <div class="checklist-content-wrapper">
            <ng-container [ngTemplateOutlet]="vehiclesTable"></ng-container>
        </div>
    </div>
</div>

<ng-template #summaryTable>
    <div class="table-container">
        <div class="table-margin">
            <table mat-table [dataSource]="summaryData" class="checklist-summary-table svt-table alternate-rows">
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let summaryData">
                        <div class="icon-cell">
                            <mat-icon class="icon icon--summary" [svgIcon]="summaryData.icon"></mat-icon>
                            <span>{{ summaryData.description | instantTranslate:checklistService.data.language | async }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="inTime">
                    <th mat-header-cell *matHeaderCellDef>
                        <span style="margin-right: 3px">{{ 'Report.Checklist.InTime' | instantTranslate:checklistService.data.language | async }}</span>
                        <span>({{ 'Report.General.Case' | instantTranslate:checklistService.data.language | async }})</span>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ summaryData.inTime | number:'':'hu' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="dueDateSoon">
                    <th mat-header-cell *matHeaderCellDef>
                        <span style="margin-right: 3px">{{ 'Report.Checklist.DueDateSoon' | instantTranslate:checklistService.data.language | async }}</span>
                        <span>({{ 'Report.General.Case' | instantTranslate:checklistService.data.language | async }})</span>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        <div *ngIf="summaryData?.dueDateSoon > 0" class="icon-cell">
                            <mat-icon class="icon icon--calendar">date_range</mat-icon>
                            <span>{{ summaryData.dueDateSoon | number:'':'hu' }}</span>
                        </div>
                        <div *ngIf="!summaryData || summaryData?.dueDateSoon <= 0">-</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="late">
                    <th mat-header-cell *matHeaderCellDef>
                        <span style="margin-right: 3px">{{ 'Report.Checklist.Late' | instantTranslate:checklistService.data.language | async }}</span>
                        <span>({{ 'Report.General.Case' | instantTranslate:checklistService.data.language | async }})</span>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        <div *ngIf="summaryData?.late > 0" class="icon-cell">
                            <mat-icon class="icon icon--error">error_outline</mat-icon>
                            <span>{{ summaryData.late | number:'':'hu' }}</span>
                        </div>
                        <div *ngIf="!summaryData || summaryData?.late <= 0">-</div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="!checklistService.onlyTodo ? displayAllSummaryColumns : displayPartialSummaryColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: !checklistService.onlyTodo ? displayAllSummaryColumns : displayPartialSummaryColumns"></tr>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #missingPeriodTable>
    <div class="table-container">
        <div class="table-margin">
            <table mat-table [dataSource]="[1]" class="checklist-summary-table svt-table alternate-rows">
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let missingPeriodData">
                        <div class="icon-cell">
                            <mat-icon class="icon icon--summary" svgIcon="track_icon"></mat-icon>
                            <span>{{ 'Report.Checklist.MissingDays' | instantTranslate:checklistService.data.language | async }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="inTime">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InTime' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let missingPeriodData">
                        2
                    </td>
                </ng-container>

                <ng-container matColumnDef="missingDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.MissingDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let missingPeriodData">
                        <div *ngIf="missingPeriodData > 0" class="icon-cell">
                            <mat-icon class="icon icon--error">error_outline</mat-icon>
                            <span>3</span>
                        </div>
                        <div *ngIf="!missingPeriodData || missingPeriodData <= 0">-</div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="!checklistService.onlyTodo ? displayAllMissingPeriodColumns : displayPartialMissingPeriodColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: !checklistService.onlyTodo ? displayAllMissingPeriodColumns : displayPartialMissingPeriodColumns"></tr>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #driversTable>
    <div class="table-container">
        <div class="table-margin">
            <table mat-table [dataSource]="checklistService.data.driverInfo"
                   class="svt-table alternate-rows sticky-header">
                <ng-container matColumnDef="driver">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.Driver' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        <span style="font-weight: bold">{{ driverData.surname.toUpperCase() }}&nbsp;</span>
                        <span>{{ driverData.firstNames }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cardNumber">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.CardNumber' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        {{ driverData.cardNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastDownloadDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.LastDownload' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        {{ !!driverData.lastCardDownload ? _moment(driverData.lastCardDownload * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nextDownloadDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.NextDownload' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        {{ !!driverData.nextDueDate ? _moment(driverData.nextDueDate * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nextDownloadDateInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        <div class="icon-cell">
                            <span class="icon" *ngIf="!checklistService.tolerances || driverData.nextDueDatePerDay > checklistService.tolerances.driverDownloadTolerance">&nbsp;</span>
                            <mat-icon class="icon icon--error" *ngIf="driverData.nextDueDatePerDay < 0">error_outline</mat-icon>
                            <mat-icon class="icon icon--calendar" *ngIf="driverData.nextDueDatePerDay >= 0 && driverData.nextDueDatePerDay <= checklistService.tolerances?.driverDownloadTolerance">date_range</mat-icon>
                            <span class="number-wrapper">{{ driverData.nextDueDatePerDay | number:'':'hu' }}</span>
                            <span *ngIf="driverData.nextDueDatePerDay < 0">{{ '(' + ('Report.Checklist.Expired' | instantTranslate:checklistService.data.language | async) + ')' }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastActivity">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.LastActivity' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        {{ !!driverData.lastActivityDate ? _moment(driverData.lastActivityDate * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastActivityInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        <div class="icon-cell">
                            <span class="number-wrapper">{{ driverData.lastActivityDatePerDay | number:'':'hu' }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cardValidityDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.CardValidity' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        {{ !!driverData.cardExpiryDate ? _moment(driverData.cardExpiryDate * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="cardValidityDateInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let driverData">
                        <div class="icon-cell">
                            <span class="icon" *ngIf="!checklistService.tolerances || driverData.cardExpiryDatePerDay > checklistService.tolerances.driverCardValidityTolerance">&nbsp;</span>
                            <mat-icon class="icon icon--error" *ngIf="driverData.cardExpiryDatePerDay < 0">error_outline</mat-icon>
                            <mat-icon class="icon icon--calendar" *ngIf="driverData.cardExpiryDatePerDay >= 0 && driverData.cardExpiryDatePerDay <= checklistService.tolerances?.driverCardValidityTolerance">date_range</mat-icon>
                            <span class="number-wrapper">{{ driverData.cardExpiryDatePerDay | number:'':'hu' }}</span>
                            <span *ngIf="driverData.cardExpiryDatePerDay < 0">{{ '(' + ('Report.Checklist.Expired' | instantTranslate:checklistService.data.language | async) + ')' }}</span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayDriverColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayDriverColumns"></tr>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #vehiclesTable>
    <div class="table-container">
        <div class="table-margin">
            <table mat-table [dataSource]="checklistService.data.vehicleInfo"
                   class="svt-table alternate-rows sticky-header">
                <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.Vehicle' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        <span style="font-weight: bold">{{ vehicleData.registrationNumber }}&nbsp;</span>
                        <span>{{ vehicleData.identificationNumber }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastDownloadDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.LastDownload' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        {{ !!vehicleData.lastTachoDownload ? _moment(vehicleData.lastTachoDownload * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nextDownloadDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.NextDownload' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        {{ !!vehicleData.nextDueDate ? _moment(vehicleData.nextDueDate * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nextDownloadDateInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        <div class="icon-cell">
                            <span class="icon" *ngIf="!checklistService.tolerances || vehicleData.nextDueDatePerDay > checklistService.tolerances.tachoDownloadTolerance">&nbsp;</span>
                            <mat-icon class="icon icon--error" *ngIf="vehicleData.nextDueDatePerDay < 0">error_outline</mat-icon>
                            <mat-icon class="icon icon--calendar" *ngIf="vehicleData.nextDueDatePerDay >= 0 && vehicleData.nextDueDatePerDay <= checklistService.tolerances?.tachoDownloadTolerance">date_range</mat-icon>
                            <span class="number-wrapper">{{ vehicleData.nextDueDatePerDay | number:'':'hu' }}</span>
                            <span *ngIf="vehicleData.nextDueDatePerDay < 0">{{ '(' + ('Report.Checklist.Expired' | instantTranslate:checklistService.data.language | async) + ')' }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastActivity">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.LastActivity' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        {{ !!vehicleData.lastActivityDate ? _moment(vehicleData.lastActivityDate * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastActivityInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        <div class="icon-cell">
                            <span class="number-wrapper">{{ vehicleData.lastActivityDatePerDay | number:'':'hu' }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nextCalibrationDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.NextCalibration' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        {{ !!vehicleData.nextCalibrationDate ? _moment(vehicleData.nextCalibrationDate * 1000).tz(checklistService.data.timeZone).format('Report.General.DateFormat' | instantTranslate:checklistService.data.language | async) : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="nextCalibrationDateInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        <div class="icon-cell">
                            <span class="icon" *ngIf="!checklistService.tolerances || vehicleData.nextCalibrationDatePerDay > checklistService.tolerances.vehicleCalibrationTolerance">&nbsp;</span>
                            <mat-icon class="icon icon--error" *ngIf="vehicleData.nextCalibrationDatePerDay < 0">error_outline</mat-icon>
                            <mat-icon class="icon icon--calendar" *ngIf="vehicleData.nextCalibrationDatePerDay >= 0 && vehicleData.nextCalibrationDatePerDay <= checklistService.tolerances?.vehicleCalibrationTolerance">date_range</mat-icon>
                            <span class="number-wrapper">{{ vehicleData.nextCalibrationDatePerDay | number:'':'hu' }}</span>
                            <span *ngIf="vehicleData.nextCalibrationDatePerDay < 0">{{ '(' + ('Report.Checklist.Expired' | instantTranslate:checklistService.data.language | async) + ')' }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="downloadedPeriod">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.DownloadedPeriod' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        2020.01.02 - 2021.08.05
                    </td>
                </ng-container>

                <ng-container matColumnDef="downloadedPeriodInDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.InDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        93
                    </td>
                </ng-container>

                <ng-container matColumnDef="missingDays">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.Checklist.MissingDays' | instantTranslate:checklistService.data.language | async }}</th>
                    <td mat-cell *matCellDef="let vehicleData">
                        <div class="icon-cell">
                            <mat-icon class="icon icon--error">error_outline</mat-icon>
                            <span></span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayVehicleColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayVehicleColumns"></tr>
            </table>
        </div>
    </div>
</ng-template>
