import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from 'app/helpers/globals';
import { map, share } from 'rxjs/operators';
import {
    CompaniesResponse,
    Company,
    CompanyFlagsResponse,
    ReportFilesReponse,
    ReportStatisticsResponse
} from './company.model';
import { ProService } from '../pro/pro.service';
import { ViewAsTestService } from '../view-as-test.service';
import { SettingsService } from '../settings/settings.service';
import { overrideMomentTimezone } from 'app/moment.override';

@Injectable()
export class CompanyService {

    constructor(private http: HttpClient,
        private readonly proService: ProService,
        private readonly viewAsTestService: ViewAsTestService,
        private readonly settingsService: SettingsService
    ) {
    }

    getAllSubcompanies(): Observable<Company[]> {
        return this.http.get<CompaniesResponse>(API_URL + 'company/list', { withCredentials: true })
            .pipe(share(), map((response) => response.companies));
    }

    getStatisticsForReports(clientId: number) {
        return this.http.get<ReportStatisticsResponse>(API_URL + 'getStatisticsForReports/' + clientId,
            { withCredentials: true }).pipe(share(), map(response => {
                return response;
            }));
    }

    getReportFiles(clientId: number) {
        return this.http.get<ReportFilesReponse>(API_URL + 'company/getAllFiles/' + clientId,
            { withCredentials: true }).pipe(share(), map(response => {
                return response;
            }));
    }

    getCompanyFlags(clientId: number) {
        try{
            this.settingsService.getLanguageAndTimezoneByClient(clientId).subscribe(
                res=>{
                    overrideMomentTimezone(res.timezone);
                }
            );
        }catch(e){}
        return this.http.get<CompanyFlagsResponse>(API_URL + 'company/getCompanyFlags/' + clientId, { withCredentials: true })
            .pipe(share(), map(response => {
                localStorage.setItem('isPro', response.companyFlags.proClient.toString());
                this.viewAsTestService.setTypeOfAccount(response.typeOfAccount);
                this.proService.setPro({
                    // pro:'NOT_PRO'
                    // pro: 'REQUESTED'
                    pro: response.PROType === 'PENDING' ? 'NOT_PRO' : (response.PROType==='TRIAL_PRO'?'REQUESTED':response.PROType),
                    showBanner: response.PROType === 'TRIAL' || response.PROType === 'TRIAL_PRO'
                });
                return response;
            }));
    }

    postCompanyFlags(clientId: number,
        expenseClient: boolean,
        nfcClient: boolean,
        connectedClient: boolean,
        uploaderClient: boolean,
        analyserClient: boolean) {
        return this.http.post<CompanyFlagsResponse>(API_URL + 'company/setCompanyFlags/' + clientId,
            {
                expenseClient: expenseClient,
                nfcClient: nfcClient,
                connectedClient: connectedClient,
                uploaderClient: uploaderClient,
                analyserClient: analyserClient
            }, { withCredentials: true }).pipe(share(), map(response => {
                return response;
            }));
    }
}
