import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API_URL } from 'app/helpers/globals';
import { AuthService } from 'app/services/auth/auth.service';
import { ProgressBarService } from 'app/services/progress-bar/progress-bar.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';


interface RecursiveCalculated {
  totalDriverCount: number
  totalPayment: number
  totalDiscountUnitNr: number;
  totalVehicleCount: number;
}

interface Trend{
  trend:"up"|"down"
  value:number
}
interface TreeNodeDataHeader {
  clientId: number
  clientName: string
  level: number
  bill: TreeNodeData,
  recursiveCalculated?: RecursiveCalculated,
  previousBill?: TreeNodeData,
  recursiveCalculatedPrevious?: RecursiveCalculated,
  trends?:{
    driverCount:Trend,
    vehicleCount:Trend, 
    calculatedPayment:Trend,
    totalDriverCount:Trend,
    totalPayment:Trend,
    totalVehicleCount:Trend
    totalDiscountUnitNr:Trend
  }
}

interface TreeNodeData {
  year: number
  quarter: number
  billingPeriodStart: string
  billingPeriodEnd: string
  driverCount: number
  billedMonths: number
  price: number
  payment: number
  clientManagerLevel: number
  clientId: number
  clientName: string
  discountUnitNr: number;
  discountUnitPrice: number;
  vehicleCount: number;
  calculatedPayment: number;
}

interface TreeNode {
  data?: TreeNodeDataHeader;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

interface Column {
  field: string
  header: string
  sortable?: boolean
  hidden?: boolean
  specialId?: string
  alterFunction?: (value: any) => any,
  classStyle?: string,
  trend?:"up"|"down",
  trending?: (node:TreeNodeDataHeader)=> Trend
}

enum ExcelType {
  DEFAULT = 0
}

interface Bill {
  year: number;
  quarter: number;
  billingPeriodStart: string;
  billingPeriodEnd: string;
  driverCount: number;
  billedMonths: number;
  price: number;
  payment: number;
  clientManagerLevel: number;
  clientId: number;
  clientName: string;
  discountUnitNr: number;
  discountUnitPrice: number;
  vehicleCount: number;
  managerLevel1ClientId: number;
  managerLevel1Name: string;
  managerLevel2ClientId: number;
  managerLevel2Name: string;
  managerLevel3ClientId: number;
  managerLevel3Name: string;
  managerLevel4ClientId: number;
  managerLevel4Name: string;
  managerLevel5ClientId: number;
  managerLevel5Name: string | null;
  managerLevel6ClientId: number;
  managerLevel6Name: string | null;
  managerLevel7ClientId: number;
  managerLevel7Name: string | null;
}

interface Client {
  clientId: number;
  clientName: string;
  level: number;
  children: Client[];
  bill: Bill;
  route: number[];
}



@Component({
  selector: 'app-manage-sub-company-billing',
  templateUrl: './manage-sub-company-billing.component.html',
})
export class ManageSubCompanyBillingComponent implements OnInit {
  public ExcelType = ExcelType;
  public date: Date | undefined = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
  public data: TreeNode[] = [];
  public cols: Column[] = [
    { field: 'clientName', header: 'manageSubCompanyBilling.table.header.clientName', classStyle: 'w-[500px]',  sortable: true },
    { field: 'bill.driverCount', header: 'manageSubCompanyBilling.table.header.driverCount', trending: (node)=>node.trends.driverCount},
    { field: 'bill.vehicleCount', header: 'manageSubCompanyBilling.table.header.vehicleCount', trending: (node)=>node.trends.vehicleCount},
    { field: 'bill.price', header: 'manageSubCompanyBilling.table.header.clientPrice', alterFunction: (value) => value.toFixed(2) + "€" },
    { field: 'bill.discountUnitPrice', header: 'manageSubCompanyBilling.table.header.discountUnitPrice',  alterFunction: (value) => value.toFixed(2) + "€"},
    { field: 'bill.discountUnitNr', header: 'manageSubCompanyBilling.table.header.discountUnitNr', alterFunction: (value) => Math.abs(value)},
    { field: 'bill.calculatedPayment', header: 'manageSubCompanyBilling.table.header.clientPayment', alterFunction: (value) => value.toFixed(2) + "€", trending: (node)=>node.trends.calculatedPayment },
    { field: 'recursiveCalculated.totalDriverCount', header: 'manageSubCompanyBilling.table.header.totalDriverCount', sortable: true, trending: (node)=>node.trends.totalDriverCount },
    { field: 'recursiveCalculated.totalVehicleCount', header: 'manageSubCompanyBilling.table.header.totalVehicleCount', sortable: true, trending: (node)=>node.trends.totalVehicleCount },
    { field: 'recursiveCalculated.totalPayment', header: 'manageSubCompanyBilling.table.header.totalPayment', sortable: true, alterFunction: (value) => value.toFixed(2) + "€", trending: (node)=>node.trends.totalPayment },
    { field: 'generateExcel-1', header: '', specialId: 'generateExcel-1' }
  ];

  constructor(
    private readonly translate: TranslateService,
    private readonly progressBarService: ProgressBarService,
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.currentCompanyId.subscribe(() => {
      this.search();
    })
    // this.search();
  }

  buildTree = (rawData: Client) => {
    const tree: TreeNode = {}

    const buildRecursive = (client: Client, tree: TreeNode): TreeNode => {
      const node: TreeNode = {
        data: {
          clientId: client.clientId,
          clientName: client.clientName,
          level: client.level,
          bill: {...client.bill, calculatedPayment: client.bill.payment + client.bill.discountUnitNr * client.bill.discountUnitPrice},
        }
      }

      if (client.children.length) {
        node.children = client.children.map(child => buildRecursive(child, {}))
      }
      return node
    }

    return buildRecursive(rawData, tree)
  }

  calculateRecursive = (node: TreeNode) => {
    if (node.children) {
      node.children.forEach(child => {
        this.calculateRecursive(child)
      })
      node.data.recursiveCalculated = {
        totalDriverCount: node.children.reduce((acc, child) => acc + child.data.recursiveCalculated.totalDriverCount, node.data.bill.driverCount),
        totalPayment: node.children.reduce((acc, child) => acc + child.data.recursiveCalculated.totalPayment, node.data.bill.calculatedPayment),
        totalDiscountUnitNr: node.children.reduce((acc, child) => acc + child.data.bill.discountUnitNr, node.data.bill.discountUnitNr),
        totalVehicleCount: node.children.reduce((acc, child) => acc + child.data.bill.vehicleCount, node.data.bill.vehicleCount),
      }
    } else {
      node.data.recursiveCalculated = {
        totalDriverCount: node.data.bill.driverCount,
        totalPayment: node.data.bill.calculatedPayment,
        totalDiscountUnitNr: node.data.bill.discountUnitNr,
        totalVehicleCount: node.data.bill.vehicleCount,
      }
      if(node.data.bill.calculatedPayment!==0){
        // console.log(node.data)
      }
    }
  }

  getSubTreeByClientId = (clientId: number, node: TreeNode): TreeNode | undefined => {
    if (node.data?.clientId === clientId) {
      return node
    } else {
      if (node.children) {
        for (const child of node.children) {
          const result = this.getSubTreeByClientId(clientId, child)
          if (result) {
            return result
          }
        }
      }
    }
  }

  async generateExcel(clientId: number, excelType: ExcelType) {
    const dateSting = "_"+this.date?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit'})
    const subTree = this.getSubTreeByClientId(clientId, this.data[0])
    let workbook: Workbook;
    switch (excelType) {
      case ExcelType.DEFAULT:
        workbook = await this.generateExcelType1(subTree);
        break;
    }
    this.downloadWorkbook(workbook, this.translate.instant('manageSubCompanyBilling.excelType1.fileName')+dateSting);
  }

  async downloadWorkbook(workbook: Workbook, fileName: string) {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName + '.xlsx')

  }

  async generateExcelType1(tree: TreeNode) {
    const workbook = new Workbook();

    const worksheet = workbook.addWorksheet(await this.translate.get('manageSubCompanyBilling.excelType1.sheetName').toPromise());

    worksheet.columns = [
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.clientId').toPromise(), key: 'clientId', width: 10},
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.clientName').toPromise(), key: 'clientName', width: 32 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.parents').toPromise(), key: 'parents', width: 32 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.driverCount').toPromise(), key: 'driverCount', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.vehicleCount').toPromise(), key: 'vehicleCount', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.price').toPromise(), key: 'price', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.discountUnitPrice').toPromise(), key: 'discountUnitPrice', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.discountUnitNr').toPromise(), key: 'discountUnitNr', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.payment').toPromise(), key: 'payment', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.totalDriverCount').toPromise(), key: 'totalDriverCount', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.totalVehicleCount').toPromise(), key: 'totalVehicleCount', width: 10 },
      { header: await this.translate.get('manageSubCompanyBilling.excelType1.totalPayment').toPromise(), key: 'totalPayment', width: 10 },
    ];

    const getRows = (tree: TreeNode, parents: string): any[] => {
      const rows: any[] = []
      if (tree.data) {
        rows.push({
          clientId: tree.data.clientId,
          clientName: tree.data.clientName,
          parents: parents,
          driverCount: tree.data.bill.driverCount,
          price: tree.data.bill.price,
          payment: tree.data.bill.calculatedPayment,
          totalDriverCount: tree.data.recursiveCalculated?.totalDriverCount,
          totalPayment: parseFloat(tree.data.recursiveCalculated?.totalPayment.toFixed(2))
        })
      }
      if (tree.children) {
        for (const child of tree.children) {
          rows.push(...getRows(child, parents + ' > ' + child.data.clientName))
        }
      }

      //euro columns
      worksheet.getColumn('F').numFmt = '0.00€';
      worksheet.getColumn('G').numFmt = '0.00€';
      worksheet.getColumn('H').numFmt = '0';
      worksheet.getColumn('I').numFmt = '0.00€';
      worksheet.getColumn('J').numFmt = '0';
      worksheet.getColumn('K').numFmt = '0';
      worksheet.getColumn('L').numFmt = '0.00€';

      return rows
    }

    const rows = getRows(tree, tree.data?.clientName || '');

    worksheet.addRows(rows);

    return workbook;
  }

  getValue(rowData: TreeNodeDataHeader, column: Column) {
    const value = this.getValueByPath(rowData, column.field)
    if (column.alterFunction) {
      return column.alterFunction(value)
    }
    return value
  }

  getValueByPath(obj: TreeNodeDataHeader, path: string) {
    const keys = path.split('.');
    let value = obj;

    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        // Return undefined if any key in the path is missing
        return undefined;
      }
    }

    return value;
  }

  merge(previous:TreeNode, current:TreeNode):TreeNode{
    const mergeSubTree = (previous:TreeNode, current:TreeNode):TreeNode => {
      current.data.previousBill = previous.data.bill;
      current.data.recursiveCalculatedPrevious = previous.data.recursiveCalculated;
      if (current.children) {
        for (const child of current.children) {
          const previousChild = previous.children?.find(previousChild => previousChild.data.clientId === child.data.clientId)
          if (previousChild) {
            mergeSubTree(previousChild, child)
          }
        }
      }
      return current;
    }
    return mergeSubTree(previous, current);
  }

  calculateTrend(node:TreeNode):TreeNode{
    const calcTrend = (a:number, b:number):'up'|'down'|undefined => {
      if(a>b){
        return 'up'
      }
      if(a<b){
        return 'down'
      }
      return undefined;
    }
    const recursiveTrendCalculation = (node:TreeNode):TreeNode => {
      if (node.children) {
        for (const child of node.children) {
          recursiveTrendCalculation(child)
        }
      }
      if(node.data.previousBill){
        node.data.trends = {
          driverCount:{
            trend:calcTrend(node.data.bill.driverCount, node.data.previousBill.driverCount),
            value:node.data.bill.driverCount-node.data.previousBill.driverCount
          },
          calculatedPayment:{
            trend:calcTrend(node.data.bill.calculatedPayment, node.data.previousBill.calculatedPayment),
            value:node.data.bill.calculatedPayment-node.data.previousBill.calculatedPayment
          },
          totalDriverCount:{
            trend:calcTrend(node.data.recursiveCalculated?.totalDriverCount, node.data.recursiveCalculatedPrevious?.totalDriverCount),
            value:node.data.recursiveCalculated?.totalDriverCount-node.data.recursiveCalculatedPrevious?.totalDriverCount
          },
          totalPayment:{
            trend:calcTrend(node.data.recursiveCalculated?.totalPayment, node.data.recursiveCalculatedPrevious?.totalPayment),
            value:node.data.recursiveCalculated?.totalPayment-node.data.recursiveCalculatedPrevious?.totalPayment
          },
          vehicleCount:{
            trend:calcTrend(node.data.bill.vehicleCount, node.data.previousBill.vehicleCount),
            value:node.data.bill.vehicleCount-node.data.previousBill.vehicleCount
          },
          totalVehicleCount:{
            trend:calcTrend(node.data.recursiveCalculated?.totalVehicleCount, node.data.recursiveCalculatedPrevious?.totalVehicleCount),
            value:node.data.recursiveCalculated?.totalVehicleCount-node.data.recursiveCalculatedPrevious?.totalVehicleCount
          },
          totalDiscountUnitNr:{
            trend:calcTrend(node.data.recursiveCalculated?.totalDiscountUnitNr, node.data.recursiveCalculatedPrevious?.totalDiscountUnitNr),
            value:node.data.recursiveCalculated?.totalDiscountUnitNr-node.data.recursiveCalculatedPrevious?.totalDiscountUnitNr
          }

        }
      }else{
        node.data.trends = {
          driverCount:{
            trend:'up',
            value:node.data.bill.driverCount
          },
          calculatedPayment:{
            trend:'up',
            value:node.data.bill.calculatedPayment
          },
          totalDriverCount:{
            trend:'up',
            value:node.data.recursiveCalculated?.totalDriverCount
          },
          totalPayment:{
            trend:'up',
            value:node.data.recursiveCalculated?.totalPayment
          },
          vehicleCount:{
            trend:'up',
            value:node.data.bill.vehicleCount
          },
          totalVehicleCount:{
            trend:'up',
            value:node.data.recursiveCalculated?.totalVehicleCount
          },
          totalDiscountUnitNr:{
            trend:'up',
            value:node.data.recursiveCalculated?.totalDiscountUnitNr
          }
        }
      }
      return node;
    }
    return recursiveTrendCalculation(node);
  }

  async queryData(month: number, year: number, clientId: number): Promise<TreeNode> {
    const responseCurrent = await this.http.get<unknown>(API_URL + 'resellerProBilling/getBillingDataResellerPRO', {
      params: {
        month: (month+1).toString(),
        year: year.toString(),
        childs: 'true',
        clientId: clientId.toString(),
      },
      withCredentials: true
    }).toPromise()
    const resultCurrent = (responseCurrent as any).billingData as unknown as Client;
    const node = this.buildTree(resultCurrent);
    this.calculateRecursive(node);
    return node;
  }

  async regenerate(){
    const month = this.date!.getMonth();
    const year = this.date!.getFullYear();
    const response = await this.http.get<unknown>(API_URL + 'resellerProBilling/calculateBillsResellerPRO', {
      params: {
        month: month.toString(),
        year: year.toString()
      },
      withCredentials: true
    }).toPromise();
  }

  async search(value?:Date) {
    try {
      this.progressBarService.mode.next('indeterminate');
      this.progressBarService.needed.next(true);
      const tempDate = value || this.date;
      if(!tempDate){return;}
      const month = tempDate.getMonth();
      const year = tempDate.getFullYear();
      const clientId = this.authService.currentCompanyIdValue();
      // console.log(month, year, clientId)
      const responseCurrent = await this.queryData(month, year, clientId);
      const responsePrevious = await this.queryData(month==0?0:month - 1, month==0?year-1:year, clientId);
      // we assume that company tree the same or more elements in the current quarter than in the previous quarter, else error may occur
      const tree = this.merge(responsePrevious, responseCurrent);
      const trendedTree = this.calculateTrend(tree);
      this.data = [trendedTree];
      this.data[0].expanded = true;
    } catch (error) {
      console.error(error)
    } finally {
      this.progressBarService.needed.next(false);
    }
  }

}