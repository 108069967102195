import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {NotificationManagementService} from '../../services/notification-management/notification-management.service';
import {NotificationResponse} from '../../services/notification-management/notification-management.model';
import moment from './../../moment.override';
import {ProgressBarService} from '../../services/progress-bar/progress-bar.service';
import {AuthService} from '../../services/auth/auth.service';
// @ts-ignore
import filteredValues from './filteredValue.json';
import { ViewAsTestService } from 'app/services/view-as-test.service';

export interface FilterValue {
    name: string,
    filterBy: string
}

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.css']
})
export class NotificationManagementComponent implements OnInit {

    notificationList = [];
    visibleNotificationList = [];
    page = 0;
    loggedInUserId = JSON.parse(localStorage.getItem('loggedInUser')).id;
    companyId: number;

    windowWidth = window.innerWidth < 1250;
    flexWidth = 80;
    filterValue = '';
    placeholderValue = 'Filter';

    filteredBy: FilterValue = filteredValues;

    private _subscriptions: Subscription;
    private testMode$ = this.viewAsTestService.testMode$;

    constructor(
        private translate: TranslateService,
        public progressBarService: ProgressBarService,
        private authService: AuthService,
        private notificationService: NotificationManagementService,
        private readonly viewAsTestService: ViewAsTestService
    ) {
        this._subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.progressBarService.mode.next('query');
        // console.log(this.filteredBy);
        this.getCompanyName();
    }

    clickptag(filterValue, name) {
        this.filterValue = name;
        this.filterByNotificationList(filterValue);
    }

    resetField() {
        this.filterValue = '';
        this.filterByNotificationList(this.filterValue);
    }

    filterByNotificationList(value) {
        const filteredList = this.notificationList.filter(s => s.fileName.toLowerCase().includes(value.toLowerCase()) ||
            s.typeOfEvent.toLowerCase().includes(value.toLowerCase()) ||
            s.severity.toLowerCase().includes(value.toLowerCase()) ||
            s.source.toLowerCase().includes(value.toLowerCase())
        );
        this.visibleNotificationList = filteredList;
    }

    getCompanyName() {
        const subscription = this.authService.currentCompanyId.subscribe((newCompanyId) => {
            if (newCompanyId !== 0) {
                this.companyId = newCompanyId;
                this.notificationList = [];
                this.getAllNotification();
            }
        });
        this._subscriptions.add(subscription);
    }

    getAllNotification() {
        this.progressBarService.needed.next(true);
        const subscription = this.notificationService.getAllNotification(this.companyId, 10, this.page).subscribe(response => {
            this.notificationList = response;
            this.visibleNotificationList = response;
            this.progressBarService.needed.next(false);
        })
        this._subscriptions.add(subscription);
    }

    async getAutoChecklistItem(fileId: number) {
        this.progressBarService.needed.next(true);
        const response = await this.notificationService.getVechicleCheckItem(this.companyId, this.loggedInUserId , fileId);
        this.progressBarService.needed.next(false);
    }

    loadMore() {
        this.page ++;
        this.progressBarService.needed.next(true);
        const subscription = this.notificationService.getAllNotification(this.companyId, 10, this.page).subscribe(response => {
            this.notificationList = [...this.notificationList, ...response];
            this.filterByNotificationList(this.filterValue);
            this.progressBarService.needed.next(false);
        })
        this._subscriptions.add(subscription);
    }


    windowResize() {
        this.windowWidth = window.innerWidth < 1250;
        if (this.windowWidth) { this.flexWidth = 100; } else { this.flexWidth = 80; }
    }

    formatSource(v) {
        if (v === 'unsecure browser') {
            return 'browser';
        } else {
            return v;
        }
    }

    formatTime(ts) {
        return moment.parseZone(ts).myFormat('hh:mm');
    }

    isToday (someDate) {
        const today = new Date()
        return new Date(someDate).getDate() === today.getDate() &&
            new Date(someDate).getMonth() === today.getMonth() &&
            new Date(someDate).getFullYear() === today.getFullYear()
    }


    ifToday(ts) {
        if ( moment(ts) <= moment([])) {
            return true;
        } else { return false; }
    }

    formatDate(ts) {
        if (ts === 0 || ts === '' || moment(ts) >= moment([])) {
            return 'Today';
        } else {
            return moment.parseZone(ts).myFormat('LL');
        }
    }

    formatDateForEvent(ts) {
        if (ts === 0 || ts === '' || moment(ts) >= moment([])) {
            return 'Today';
        } else {
            return moment.parseZone(ts).myFormat('LLL');
        }
    }

    /**
     * MODERATE, MILD, NONE
     * @param item
     * @param which
     */
    iconBySeverity(item, which) {
        let icon, tooltip, color;
        switch (item) {
            case ('WARNING'): {
                icon = 'warning';
                tooltip = this.translate.instant('Warning');
                color = '#F99746';
                break;
            }
            case ('MILD'): {
                icon = 'warning';
                tooltip = this.translate.instant('Warning');
                color = '#F99746';
                break;
            }
            case ('MODERATE'): {
                icon = 'warning';
                tooltip = this.translate.instant('Warning');
                color = '#F99746';
                break;
            }
            case ('DANGEROUS'): {
                icon = 'error';
                tooltip =  this.translate.instant('Problem');
                color = '#f44336';
                break;
            }
            case ('ERROR'): {
                icon = 'error';
                tooltip =  this.translate.instant('Problem');
                color = '#f44336';
                break;
            }
            case ('NOTIFICATION'): {
                icon = 'notifications_active';
                tooltip =  this.translate.instant('Notifications');
                color = '#0072bb';
                break;
            }
            case ('NONE'): {
                icon = 'done_outline';
                tooltip =  this.translate.instant('Notifications');
                color = '#0cb806';
                break;
            }
            case ('PROBLEM'): {
                icon = 'warning';
                tooltip =  this.translate.instant('Warning');
                color = '#f44336';
                break;
            }
        }
        if (which === 'icon') { return icon; }
        if (which === 'tooltip') { return tooltip; }
        if (which === 'color') { return color; }
    }
}
