import { Component, Input } from '@angular/core';
import { IconText } from 'app/services/rszoft/dailychart/models/chart.model';
import { Moment } from 'moment-timezone';

export enum ChartAnnotationType {
    BORDER_CROSS,
    FERRY,
    VEHICLE,
    OFFENCE,
    TIMEOFFSETCHANGEPLUS,
    TIMEOFFSETCHANGEMINUS,
    DRIVERS,
    LASTDOWNLOADEDDATA,
    WITHOUT_CARD
}

@Component({
  selector: 'app-infoline-component',
  templateUrl: './bordercross-component.component.html',
  styleUrls: ['./bordercross-component.component.css']
})
export class BordercrossComponentComponent {

    @Input() innerText: string;
    @Input() iconTexts: IconText[];
    @Input() annotationType: ChartAnnotationType;
    @Input() currentLangCode: string;
    @Input() extraInfo: boolean;
    @Input() lastDownloadMoment: Moment;

    chartAnnotationType = ChartAnnotationType;

  constructor() {
      if (!this.annotationType) {
          this.annotationType = ChartAnnotationType.BORDER_CROSS;
      }
  }

}
