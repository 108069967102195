import { Component, Input } from '@angular/core';
import { SpinnerHorizontalPosition, SpinnerSize, SpinnerVerticalPosition } from '../loading.model';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
    @Input() size: SpinnerSize;
    @Input() verticalPosition: SpinnerVerticalPosition;
    @Input() horizontalPosition: SpinnerHorizontalPosition;
}
