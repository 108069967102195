<div style="padding: 0 0.5rem; width: 100%;">
    <div class="queryForm" style="padding: 0.6rem 0.6rem 0; width: 100%">
        <div class="queryFormField queryFormField--entitySelector" >
            <mat-form-field appearance="fill" class="mat-datepicker-toggle queryFormField queryFormField--dateTemplateSelector" style="width: 100%;padding-bottom: 0">
                <mat-label>{{'Billing.selectYear' | translate}} </mat-label>
                <mat-select panelClass="templateSelectorPanel" [(ngModel)]="selectedYear" (click)="hideTable()">
                    <mat-option [value]="year" *ngFor="let year of years" >
                        {{ year }}
                    </mat-option>
                </mat-select>
                <mat-icon matPrefix style="margin-right: 15px">event_note</mat-icon>
            </mat-form-field>
        </div>

        <div class="queryFormField queryFormField--dateSelectorContainer">
            <mat-form-field appearance="fill" class="mat-datepicker-toggle queryFormField queryFormField--dateTemplateSelector" style="width: 50rem !important; padding-bottom: 0">
                <mat-label>{{'Billing.selectQuarter' | translate}} </mat-label>
                <mat-select panelClass="templateSelectorPanel" [(ngModel)]="selectedQuarter" (click)="hideTable()">
                    <mat-option [value]="quarter" *ngFor="let quarter of quarters" (click)="selectMonth('quarter')">
                        {{ quarter }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- (click)="searchByClient();" -->
            <button id="queryButton"
                    mat-button
                    class="queryFormButton"
                    type="button"
                    [ngClass]="queryButtonUnweighted ? 'unweighted' : ''"
                    [disabled]="((progressBarService.needed | async) && (progressBarService.mode | async) === 'query')"
                    (click)="getCalculatedBillinForClient();">
                <mat-icon style="height: unset">search</mat-icon>
            </button>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="queryLoadingTemplate"></ng-container>
</div>

<ng-template #queryLoadingTemplate>
    <div *ngIf="(progressBarService.needed | async) && (progressBarService.mode | async) === 'query'"
         class="query-loading-container">
        <h4 style="font-size: 1rem">
            {{ 'Report.General.QueryInProgress' | translate }}...
        </h4>
        <button mat-stroked-button color="primary" (click)="abortQuery()">
            {{ 'Report.General.Abort' | translate }}
        </button>
    </div>
</ng-template>

<div style="padding: 0rem 1rem 1rem; width: 100%" *ngIf="tableVisibility && (!(progressBarService.needed | async) && (progressBarService.mode | async) === 'query')">
    <p-treeTable #tt [value]="visibleDatas" [columns]="cols" styleClass="p-treetable-striped" (onFilter)="onFilter($event, tt)">
        <ng-template pTemplate="caption" >
            <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="{{'Search' | translate}}" [(ngModel)]="filterValue" (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto; font-size: 12px">
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr style="line-height: 0.5rem">
                <th *ngFor="let col of cols" [ttSortableColumn]="col.field" [ngStyle]="applyColStyles(col.width, col.align, col.field)">
                    {{col.header | translate}}
                    <p-treeTableSortIcon *ngIf="col.field === 'clientName'">{{col.field}}</p-treeTableSortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ngClass]="[rowData.registrationNumber === undefined ? 'kb-row': '', (rowData.error === 1 && rowData.subcriptionTime !== undefined) ? 'kb-row-notPrepaid': '']">
                <td *ngFor="let col of cols; let i = index" [ngStyle]="applyRowStyles(col.width, col.align)">
                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                    <span *ngIf="rowData.subcriptionTime === undefined && col.header !== 'Registration number' && col.field !== 'serialNumber' && col.field !== 'price' && col.field !== 'payment' && col.field !== 'iccid' &&  col.field !== 'error'" style="font-weight: bold;">{{rowData[col.otherusage]}}</span>
                    <span *ngIf="rowData.subcriptionTime !== undefined && col.header !== 'Registration number' && col.field !== 'serialNumber' && col.field !== 'price' && col.field !== 'registrationNumber' && col.field !== 'iccid' && col.field !== 'payment' &&  col.field !== 'error'">{{rowData[col.field]}}</span>

                    <span *ngIf="col.field === 'error'"></span>

                    <span matTooltip="{{'Id:&nbsp;' + rowData.clientId}}" *ngIf="rowData.subcriptionTime === undefined && col.header === 'Registration number'" style="font-weight: bold">
                        {{rowData[col.field]}}
                    </span>
                    <span *ngIf="rowData.subcriptionTime !== undefined && col.header === 'Registration number'">
                    <span style="width: 15px; display: inline-block;">
                        <i *ngIf="rowData.dataStatus==='New'" class="pi pi-plus" style="color: green; font-size:0.6rem; font-weight: bold; padding-top: 5px;"></i>
                        <i *ngIf="rowData.dataStatus==='Deleted'" class="pi pi-minus" style="color: red; font-size:0.6rem; font-weight: bold;"></i>
                    </span>
                        {{rowData[col.otherusage]}} </span>

                    <span *ngIf="rowData.subcriptionTime !== undefined && col.field === 'payment'">{{rowData[col.field].toFixed(2)}}</span>
                    <span *ngIf="rowData.subcriptionTime === undefined && col.field === 'payment' " style="font-weight: bold">{{rowData[col.otherusage].toFixed(2)}}</span>

                    <span *ngIf="rowData.subcriptionTime !== undefined && col.field === 'price'">{{rowData[col.field].toFixed(2)}}</span>
                    <span *ngIf="rowData.subcriptionTime !== undefined && col.field === 'serialNumber'">{{rowData[col.field]}}</span>

                    <span *ngIf="rowData.subcriptionTime !== undefined && col.field === 'iccid'">{{toString(rowData[col.field])}}</span>

                    <span *ngIf="col.field === 'SUM'">
                        <mat-icon class="icon svgIcon"
                                  matTooltip="{{ 'Excel with expanded detail' | translate }}"
                                  svgIcon="excel"
                                  style="cursor: pointer; float: center; margin-left: 5px"
                                  (click)="downloadDetail(visibleDatas, rowData.clientId, rowData.clientName)"
                                  *ngIf="rowData.subcriptionTime === undefined">
                        </mat-icon>
                        <mat-icon class="icon svgIcon"
                                  matTooltip="{{ 'Detail excel' | translate }}"
                                  svgIcon="excel" style="cursor: pointer; float: right"
                                  (click)="generateExcel(visibleDatas, rowData.clientId, rowData.clientName)"
                                  *ngIf="rowData.subcriptionTime === undefined">
                        </mat-icon>
                    </span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="cols.length">{{'Billing.NoDataFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>
