<div class="breadcrumb-bar" *ngIf="isEnabled">
    <ul class="breadcrumb">
        <li *ngFor="let part of routeParts"
            style="cursor: context-menu;
            font-size: 17px;
            font-weight: 400;">
            <a routerLink="{{part.url}}">
                <!-- <mat-icon *ngIf="part.icon" class="mat-icon" svgIcon="{{part.icon}}"></mat-icon> -->
                {{part.breadcrumb | translate}}
            </a>
        </li>
    </ul>
</div>
