import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CHART_API_URL } from 'app/helpers/globals';
import { DataMatchModel, DataMatchRequestModel, Driver, Vehicle } from './models/data-match.model';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CharttranslationService } from '../charttranslation.service';
import { DriverActivity, LabelledReportContext, ReportContext, RowType, VehicleActivity } from './models/context.model';
import { TabOption } from '../report.model';
import { PrintService } from '../print.service';
import { downloadContent } from '../download.util';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
    }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class DataMatchService {
    reportContexts: LabelledReportContext[];

    private static cleanAndSortDriverResponse(response): DataMatchModel {
        for (const driver of response.driverInfos) {
            driver.id = driver.driverId;
            if (!driver.vehicles || driver.vehicles.length === 0) {
                delete driver.vehicles;
                delete driver.totalCount;
                delete driver.sumKm;
            } else {
                for (const vehicle of driver.vehicles) {
                    vehicle.registrationNumber = vehicle.vrn;
                    vehicle.firstUse = vehicle.vehicleFirstUse;
                    vehicle.lastUse = vehicle.vehicleLastUse;
                    vehicle.lastTachographDownload = vehicle.lastTachoCardDownload;
                }
                driver.vehicles.sort(Vehicle.compare);
            }
        }
        response.driverInfos.sort(Driver.compare);
        return response;
    }

    private static cleanAndSortVehicleResponse(response): DataMatchModel {
        for (const vehicle of response.vehicleInfos) {
            vehicle.id = vehicle.vehicleId;
            vehicle.lastTachographDownload = vehicle.lastVehicleCardDownload;
            if (!vehicle.drivers || vehicle.drivers.length === 0) {
                delete vehicle.drivers;
                delete vehicle.totalCount;
                delete vehicle.sumDrive;
                delete vehicle.sumAvailability;
                delete vehicle.sumWork;
                delete vehicle.sumKm;
            } else {
                for (const driver of vehicle.drivers) {
                    driver.surname = driver.surName?.toUpperCase();
                    driver.odometerBegin = driver.odometerFrom;
                    driver.odometerEnd = driver.odometerTo;

                    if (driver.beSurName && driver.beFirstNames && driver.beActivityStart && driver.beActivityEnd) {
                        driver.previousDriver = {
                            surname: driver.beSurName?.toUpperCase(),
                            firstNames: driver.beFirstNames,
                            activityStart: driver.beActivityStart,
                            activityEnd: driver.beActivityEnd,
                            odometerBegin: driver.beOdometerFrom,
                            odometerEnd: driver.beOdometerTo,
                            lastDriverCardDownload: driver.beLastDriverCardDownload
                        };
                    }
                    if (driver.aeSurName && driver.aeFirstNames && driver.aeActivityStart && driver.aeActivityEnd) {
                        driver.followingDriver = {
                            surname: driver.aeSurName?.toUpperCase(),
                            firstNames: driver.aeFirstNames,
                            activityStart: driver.aeActivityStart,
                            activityEnd: driver.aeActivityEnd,
                            odometerBegin: driver.aeOdometerFrom,
                            odometerEnd: driver.aeOdometerTo,
                            lastDriverCardDownload: driver.aeLastDriverCardDownload
                        };
                    }
                }
                vehicle.drivers.sort(Driver.compare);
            }
        }
        response.vehicleInfos.sort(Vehicle.compare);
        return response;
    }

    private static initReportData(reportContext: ReportContext, report) {
        reportContext.setMetaData(report);
        reportContext.rowCountPerEntity = [];
        reportContext.data = [];
    }

    constructor(private http: HttpClient,
                private printService: PrintService,
                private chartTranslationService: CharttranslationService) {
        this.initReportContexts();
    }

    private initReportContexts() {
        this.reportContexts = [];
        this.reportContexts.push(new LabelledReportContext(TabOption.DRIVER, new ReportContext()));
        this.reportContexts.push(new LabelledReportContext(TabOption.VEHICLE, new ReportContext()));
    }

    getReportContext(label: string): LabelledReportContext {
        return this.reportContexts.find(reportContext => {
            return reportContext.label === label;
        })
    }

    getDriverReport(dataMatchRequest: DataMatchRequestModel): Observable<DataMatchModel> {
        return this.http
            .post<any>(CHART_API_URL + 'dataMatch/driver', JSON.stringify(dataMatchRequest), httpOptions)
            .pipe(
                take(1),
                tap(response => {
                    this.chartTranslationService.setCurrentLang(response.language);
                    this.getReportContext(TabOption.DRIVER).reportContext.lastRequest = dataMatchRequest;
                }),
                map(DataMatchService.cleanAndSortDriverResponse)
            );
    }

    getVehicleReport(dataMatchRequest: DataMatchRequestModel): Observable<DataMatchModel> {
        return this.http
            .post<DataMatchModel>(CHART_API_URL + 'dataMatch/vehicle', JSON.stringify(dataMatchRequest), httpOptions)
            .pipe(
                take(1),
                tap(response => {
                    this.chartTranslationService.setCurrentLang(response.language);
                    this.getReportContext(TabOption.VEHICLE).reportContext.lastRequest = dataMatchRequest;
                }),
                map(DataMatchService.cleanAndSortVehicleResponse)
            );
    }

    processDriverReportData(report) {
        const reportContext = this.getReportContext(TabOption.DRIVER).reportContext;
        DataMatchService.initReportData(reportContext, report);
        report.driverInfos.forEach(driver => {
            const driverData: DriverActivity = {
                firstNames: driver.firstNames,
                surname: driver.surname,
                globalError: driver.errorCode,
                allDownloaded: !driver.vehicles && !driver.errorCode,
                lastDownload: driver.lastDriverCardDownload * 1000,
                rowType: RowType.DRIVER_VEHICLE
            };
            reportContext.data.push(driverData);
            reportContext.rowCountPerEntity.push(driver.vehicles?.length + 2 || 1);
            if (!!driver.vehicles) {
                driver.vehicles.forEach(vehicle => {
                    const vehicleData: DriverActivity = {
                        startDate: vehicle.firstUse * 1000,
                        endDate: vehicle.lastUse * 1000,
                        vehicle: vehicle.registrationNumber,
                        differenceCode: vehicle.errorCode,
                        odometerBegin: vehicle.odometerBegin,
                        odometerEnd: vehicle.odometerEnd,
                        odometerSum: vehicle.odometerSum,
                        lastDownload: vehicle.lastTachographDownload * 1000,
                        rowType: RowType.DETAIL
                    };
                    reportContext.data.push(vehicleData);
                });
                const summaryData: DriverActivity = {
                    caseCount: driver.totalCount,
                    odometerSum: driver.sumKm,
                    rowType: RowType.SUMMARY
                };
                reportContext.data.push(summaryData);
            }
        });
    }

    processVehicleReportData(report) {
        const reportContext = this.getReportContext(TabOption.VEHICLE).reportContext;
        DataMatchService.initReportData(reportContext, report);
        report.vehicleInfos.forEach((vehicle, index) => {
            const vehicleData: VehicleActivity = {
                registrationNumber: vehicle.registrationNumber,
                registrationNation: vehicle.registrationNation,
                globalError: vehicle.errorCode || (vehicle.lastTachographDownload * 1000 < reportContext.metaData.periodEndDate ? 'VDM01' : undefined),
                allDownloaded: !vehicle.drivers && !vehicle.errorCode,
                lastDownload: vehicle.lastTachographDownload * 1000,
                rowType: RowType.DRIVER_VEHICLE
            };
            reportContext.data.push(vehicleData);
            reportContext.rowCountPerEntity.push(vehicle.drivers?.length + 2 || 1);
            if (!!vehicle.drivers) {
                vehicle.drivers.forEach(driver => {
                    if (driver.previousDriver) {
                        const previousDriverData: VehicleActivity = {
                            startDate: driver.previousDriver.activityStart * 1000,
                            endDate: driver.previousDriver.activityEnd * 1000,
                            driver: driver.previousDriver.surname + ' ' + driver.previousDriver.firstNames,
                            differenceCode: driver.previousDriver.lastDriverCardDownload < driver.previousDriver.activityEnd ? 'VDM01' : undefined,
                            odometerBegin: driver.previousDriver.odometerBegin,
                            odometerEnd: driver.previousDriver.odometerEnd,
                            lastDownload: driver.previousDriver.lastDriverCardDownload * 1000,
                            rowType: RowType.DETAIL
                        };
                        reportContext.data.push(previousDriverData);
                        reportContext.rowCountPerEntity[index]++;
                    }
                    const driverData: VehicleActivity = {
                        startDate: driver.activityStart * 1000,
                        endDate: driver.activityEnd * 1000,
                        driver: !!driver.surname && !!driver.firstNames ? driver.surname + ' ' + driver.firstNames : undefined,
                        lastDownload: driver.lastDriverCardDownload * 1000,
                        differenceCode: driver.errorCode,
                        drive: driver.drive,
                        work: driver.work,
                        availability: driver.availability,
                        odometerBegin: driver.odometerBegin,
                        odometerEnd: driver.odometerEnd,
                        odometerSum: driver.odometerSum,
                        rowType: RowType.DETAIL
                    };
                    reportContext.data.push(driverData);
                    if (driver.followingDriver) {
                        const followingDriver: VehicleActivity = {
                            startDate: driver.followingDriver.activityStart * 1000,
                            endDate: driver.followingDriver.activityEnd * 1000,
                            driver: driver.followingDriver.surname + ' ' + driver.followingDriver.firstNames,
                            differenceCode: driver.followingDriver.lastDriverCardDownload < driver.followingDriver.activityEnd ? 'VDM01' : undefined,
                            odometerBegin: driver.followingDriver.odometerBegin,
                            odometerEnd: driver.followingDriver.odometerEnd,
                            lastDownload: driver.followingDriver.lastDriverCardDownload * 1000,
                            rowType: RowType.DETAIL
                        };
                        reportContext.data.push(followingDriver);
                        reportContext.rowCountPerEntity[index]++;
                    }
                });
                const summaryData: VehicleActivity = {
                    caseCount: vehicle.totalCount,
                    drive: vehicle.sumDrive,
                    work: vehicle.sumWork,
                    availability: vehicle.sumAvailability,
                    odometerSum: vehicle.sumKm,
                    rowType: RowType.SUMMARY
                };
                reportContext.data.push(summaryData);
            }
        });
    }

    generateDriverCsvExport(): Observable<any> {
        return this.http
            .post(
                CHART_API_URL + 'dataMatch/driver/download',
                JSON.stringify(this.getReportContext(TabOption.DRIVER)?.reportContext?.lastRequest),
                { ...httpOptions, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                tap(downloadContent)
            );
    }

    generateVehicleCsvExport(): Observable<any> {
        return this.http
            .post(
                CHART_API_URL + 'dataMatch/vehicle/download',
                JSON.stringify(this.getReportContext(TabOption.VEHICLE)?.reportContext?.lastRequest),
                { ...httpOptions, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                tap(downloadContent)
            );
    }
}
