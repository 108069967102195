import { Component, HostListener, OnInit } from '@angular/core';
import { AttestationProfile, DriverAttestationService } from 'app/services/rszoft/driver-attestation/driver-attestation.service';
import { Router } from '@angular/router';
import { PrintService } from 'app/services/rszoft/print.service';
import * as moment from 'moment-timezone';
import { DriverAttestationModel, DriverStatement } from 'app/services/rszoft/driver-attestation/driver-attestation.model';

@Component({
    selector: 'app-driver-attestation-print',
    templateUrl: './driver-attestation-print.component.html',
    styleUrls: ['../driver-attestation.component.css']
})
export class DriverAttestationPrintComponent implements OnInit {

    constructor(public driverAttestationService: DriverAttestationService,
                private printService: PrintService,
                private router: Router) {}

                attestationProfile: AttestationProfile;
                

    ngOnInit() {
        const format = 'YYYY-MM-DD';
        const startDate = moment(this.driverAttestationService.periodStartDate)?.format(format) || '';
        const endDate = moment(this.driverAttestationService.periodEndDate)?.format(format) || '';
        const timezone = this.driverAttestationService.timezone || 'UTC';
        this.attestationProfile = this.printService.attestationProfile;
        let entityOrCompany = this.printService.companyName?.replaceAll(' ', '_') || 'Company_wide';
        if (this.driverAttestationService.drivers?.length === 1) {
            entityOrCompany = this.driverAttestationService.drivers[0].surname.toUpperCase() + '_' + this.driverAttestationService.drivers[0].firstNames?.replaceAll(' ', '_') || '';
        }
        const title = document.title;
        document.title = [startDate, endDate, 'Driver', 'attestation', entityOrCompany, timezone].join('_');
        setTimeout(() => {
            window.print();
            document.title = title;
        }, 1000);
    }

    getPeriodsSelectedToPrint(driver: DriverAttestationModel): DriverStatement[] {
        return driver.statements.filter(statement => !!statement.printingOn);
    }

    @HostListener('window:afterprint', [])
    onWindowAfterPrint() {
        this.navigateBackToReport();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(_) {
        this.navigateBackToReport();
    }

    navigateBackToReport() {
        this.router.navigate(['/report/driver-attestation']);
    }
}
