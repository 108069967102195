import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    templateUrl: 'pagination.component.html'
})
export class PaginationComponent {
    @Input() actualPageSize = 0;
    @Input() pageSizeOptions = [1];
    @Input() actualPageIndex = 0;
    @Input() length = 0;
    @Output() onPaginationChanged = new EventEmitter<any>();
}
