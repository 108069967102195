import { Component, OnInit } from '@angular/core';
import {PROFILE} from '../../helpers/globals';

@Component({
  selector: 'app-mobile-apps',
  templateUrl: './mobile-apps.component.html',
  styleUrls: ['./mobile-apps.component.css']
})
export class MobileAppsComponent implements OnInit {

    currentYear = new Date().getFullYear();
    profile = PROFILE;

    constructor() { }

    ngOnInit(): void {
    }

}
