import { Component, HostListener, OnInit } from '@angular/core';
import { DailyChartResponse, DailyChartService } from 'app/services/rszoft/dailychart/daily-chart.service';
import { ReportContext } from 'app/services/rszoft/dailychart/models/context.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Dailychartinput } from 'app/services/rszoft/dailychart/models/chart.model';
import * as Highcharts from 'highcharts';
import { TabOption } from 'app/services/rszoft/report.model';
import { PrintService } from 'app/services/rszoft/print.service';
import {
    COLOR_AVAILABLE,
    COLOR_CREW_DRIVING,
    COLOR_DRIVE, COLOR_INFRINGMENT,
    COLOR_REST,
    COLOR_WORK,
    COLOR_WORKING_TIME
} from 'app/helpers/globals';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-driverreport-print',
    templateUrl: './driverreport-print.component.html',
    styleUrls: ['../driverreport/driverreport.component.css']
})
export class DriverreportPrintComponent implements OnInit {
    dailyChartResponses: DailyChartResponse[];
    tab: TabOption;
    tabOptions = TabOption;
    colorCodes: any[];
    reportContext: ReportContext;

    constructor(public dailyChartService: DailyChartService,
                public printService: PrintService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.colorCodes = [];
        this.colorCodes['COLOR_DRIVE'] = COLOR_DRIVE;
        this.colorCodes['COLOR_REST'] = COLOR_REST;
        this.colorCodes['COLOR_AVAILABLE'] = COLOR_AVAILABLE;
        this.colorCodes['COLOR_WORK'] = COLOR_WORK;
        this.colorCodes['COLOR_WORKING_TIME'] = COLOR_WORKING_TIME;
        this.colorCodes['COLOR_CREW_DRIVING'] = COLOR_CREW_DRIVING;
        this.colorCodes['COLOR_INFRINGMENT'] = COLOR_INFRINGMENT;
    }

    ngOnInit(): void {
        const tab = this.activatedRoute.snapshot.paramMap.get('tab');
        this.tab = this.dailyChartService.getDailyChartTabEnumByValue(tab);
        if (!this.tab) {
            console.log('No TAB info, which chart to print?');
            return;
        }

        const labelledReportContext = this.dailyChartService.getReportContext(this.tab);
        if (!labelledReportContext || !labelledReportContext.reportContext) {
            return;
        }
        this.dailyChartService.printingInProgress = true;
        this.reportContext = labelledReportContext.reportContext;

        this.dailyChartResponses = this.reportContext.dailyChartResponses;

        this.printService.progressBarValue = 0;
        this.printService.progressBarNeeded = true;
        this.reportContext.indexOfLastChartToLoad = 0;
        this.dailyChartService.getReportContext(tab).reportContext.dailyChartInputsPaged
            = new Array<Dailychartinput>(this.reportContext.dailyChartInputs.length);
        this.reportContext.highChartObjects = new Array<Highcharts.Chart>(this.reportContext.dailyChartInputs.length);
        this.reportContext.countOfCharts = 0;
        for (const value of this.dailyChartResponses) {
            value.dailyChartInputsTemp = new Array<Dailychartinput>(value.dailyChartInputs.length);
            this.reportContext.countOfCharts = this.reportContext.countOfCharts + value.dailyChartInputs.length;
        }
        let responseIndex = 0;
        while (responseIndex < this.reportContext.dailyChartResponses.length &&
        this.reportContext.dailyChartResponses[responseIndex].dailyChartInputs.length === 0) {
            responseIndex++;
        }
        if (responseIndex === this.reportContext.dailyChartResponses.length) {
            this.printService.progressBarNeeded = false;
            this.reportContext.indexOfLastChartToLoad = -1;

            const format = 'YYYY-MM-DD';
            const startDate = moment(this.reportContext.periodStartTime * 1000).format(format);
            const endDate = moment(this.reportContext.periodEndTime * 1000).format(format);
            const timezone = this.reportContext.dailyChartResponses[0]?.timeZone || 'UTC';

            const title = document.title;
            document.title = [startDate, endDate, 'Daily', 'chart', timezone].join('_');
            setTimeout(() => {
                window.print();
                document.title = title;
            }, 500);
        } else {
            this.dailyChartService.fillNextDailyChartInput({index: -1, responseIndex}, this.tab);
        }
    }

    getPageNumber(i: number, responseIndex: number): number {
        return (Math.floor(i / 3) + 1) + this.getEarlierPageCountByResponseIndex(responseIndex);
    }

    getEarlierPageCountByResponseIndex(responseIndex: number) {
        let sum = 0;
        for (let i = 0; i < responseIndex; i++) {
            sum = sum + (Math.round((this.dailyChartResponses[i].dailyChartInputs.length - 3) / 3) + 1 +
                ((this.dailyChartResponses[i].dailyChartInputs.length - 2) % 3 > 0 ? 1 : 0));
        }
        return sum;
    }

    getFullPageCount(): number {
        return this.getEarlierPageCountByResponseIndex(this.dailyChartResponses.length);
    }

    getDriverLastPageRemainChartCount(responseIndex: number) {
        return (this.dailyChartResponses[responseIndex].dailyChartInputs.length - 2) % 3;
    }

    isPeriodAfterLastDownload(dailyChartResponse: DailyChartResponse): boolean {
        if (this.tab === TabOption.DRIVER) {
            return this.reportContext.periodStartTime * 1000 > dailyChartResponse.driverInfo.driverCardDownloadTime;
        }
        if (this.tab === TabOption.VEHICLE) {
            return this.reportContext.periodStartTime * 1000 > dailyChartResponse.vehicleInfo.lastDownloadTime;
        }
        return false;
    }

    isActiveDayInPeriod(dailyChartResponse: DailyChartResponse): boolean {
        if (this.tab === TabOption.DRIVER) {
            return dailyChartResponse.driverInfo.driverActiveDaysInPeriod > 0;
        } else if (this.tab === TabOption.VEHICLE) {
            return dailyChartResponse.vehicleInfo.activeDaysInPeriod > 0;
        }
        return false;
    }

    @HostListener('window:afterprint', [])
    onWindowAfterPrint() {
        this.navigateBackToReport();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(_) {
        this.navigateBackToReport();
    }

    navigateBackToReport() {
        this.dailyChartService.printingInProgress = false;
        this.dailyChartService.getReportContext(this.tab).reportContext.chartDataGenerated = false;
        this.router.navigate(['/report/dailychart', {reloadResultList: true}]);
    }

}
