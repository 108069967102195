import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {filter} from 'rxjs/operators';
import {MenuService} from '../../../menu/menu.service';
import {Settings} from '../../../../app.settings.model';
import {AppSettings} from '../../../../app.settings';
import {Content} from '../../../../services/notifications.service';
import {MatSidenav} from '@angular/material/sidenav';
import {CURRENT_APPLICATION_VERSION, PROFILE} from '../../../../helpers/globals';
import {BackendAvailabilityService} from '../../../../services/backend-availability/backend-availability.service';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.template.html',
    styleUrls: ['./admin-layout.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [MenuService]
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {
    loggedInUser = this._auth.getLoggedInUser().username;
    url;
    @ViewChild(MatSidenav, {static: true}) private sideNav: MatSidenav;
    @ViewChild('content') _content: ElementRef<HTMLDivElement>;
    @Input() notificPanel;

    public menuItems: Array<any>;
    public settings: Settings;

    notifications = [] as Content[];
    profile = PROFILE;

    backgroundColor = localStorage.getItem('background');
    showToTopButton = false;
    currentApplicationVersion = CURRENT_APPLICATION_VERSION;

    updateNotificLength(notifications) {
        this.notifications = notifications;
    }

    constructor(private router: Router,
                private _auth: AuthService,
                public backendAvailabilityService: BackendAvailabilityService,
                public translate: TranslateService,
                public appSettings: AppSettings,
                public menuService: MenuService) {
        // Close sidenav after route change in mobile
        this.settings = this.appSettings.settings;
        router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange: NavigationEnd) => {
            this.url = routeChange.url;
            // if (this.isNavOver()) {
            //     this.sideNav.close();
            // }
        });
    }

    ngOnInit() {
        this.init();

        
    }

    async init(){
        this.menuItems = await this.menuService.getVerticalMenuItems();
        try {
            if (this._auth.getLoggedInUser().clientAdmin === false) {
                this.menuItems = this.menuItems.filter(item => !item.isAdminLink);
            }
        } catch (ex) {
            console.error('No loggedin user!');
            this.router.navigate(['/login']);
            // this.menuItems = this.menuItems.filter(item => !item.isAdminLink);
        }
    }

    ngAfterViewInit() {
        if (this.isMobile()) {
            this.sideNav.close();
        } else {
            this.sideNav.open();
        }
    }

    menuItemClicked() {
        if (this.isMobile()) {
            this.sideNav.close();
        }
    }

    onScroll(event: Event): void {
        this.showToTopButton = (event.target as any).scrollTop > (event.target as any).offsetHeight;
    }

    onToTopClicked() {
        this._content.nativeElement.scrollTop = 0;
    }

    isMobile() {
        if (window.innerWidth > 767 && !this.sideNav.opened) {
            // this.sideNav.open();
        }
        return window.innerWidth < 767;
    }

    async changeCompany($event) {
        await this._auth.asr();
        this._auth.changeCompanyId($event);
        // .subscribe(() => {
            
        //     // AuthService.storeSelectedCompany($event);
        // });
    }

    // isNavOver() {
    //     return window.matchMedia(`(max-width: 1200px)`).matches;
    // }
}
