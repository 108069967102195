import {NgModule} from '@angular/core';
import {RelativeTimePipe} from './relative-time.pipe';
import {ExcerptPipe} from './excerpt.pipe';
import {OrderByPipe} from './order-by.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {CompanyFilterPipe} from './company-filter.pipe';
import {DriverFilterPipe} from './driver-filter.pipe';
import {VehicleFilterPipe} from './vehicle-filter.pipe';
import {NamePipe} from './name-pipe';
import {InstantTranslatorPipe} from './instant-translator.pipe';

@NgModule({
    declarations: [
        RelativeTimePipe,
        ExcerptPipe,
        OrderByPipe,
        DateFormatPipe,
        CompanyFilterPipe,
        DriverFilterPipe,
        VehicleFilterPipe,
        NamePipe,
        InstantTranslatorPipe
    ],
    exports: [
        RelativeTimePipe,
        ExcerptPipe,
        OrderByPipe,
        DateFormatPipe,
        CompanyFilterPipe,
        DriverFilterPipe,
        VehicleFilterPipe,
        NamePipe,
        InstantTranslatorPipe
    ]
})
export class CommonPipesModule {
}
