import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CHART_API_URL } from 'app/helpers/globals';
import { map, take, tap } from 'rxjs/operators';
import { ChecklistModel, Driver, Vehicle } from './checklist.model';
import { CharttranslationService } from '../charttranslation.service';
import { SettingsService } from 'app/services/settings/settings.service';
import { CompanyTolerances } from 'app/services/settings/settings.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
    }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class ChecklistService {
    tolerances: CompanyTolerances;
    data: ChecklistModel;
    onlyTodo: boolean;

    constructor(private http: HttpClient,
                private settingsService: SettingsService,
                private chartTranslationService: CharttranslationService) {
        this.settingsService.tolerances.subscribe(tolerances => this.tolerances = tolerances);
    }

    getReport(clientId: number, onlyTodo: boolean): Observable<ChecklistModel> {
        return this.http
            .post<ChecklistModel>(CHART_API_URL + 'checklist', JSON.stringify({ clientId, onlyTodo }), httpOptions)
            .pipe(
                take(1),
                tap(result => this.data = result),
                map(result => {
                    result.driverInfo.sort((d1, d2) => Driver.compare(d1, d2, this.tolerances?.driverDownloadTolerance));
                    result.vehicleInfo.sort((v1, v2) => Vehicle.compare(v1, v2, this.tolerances?.tachoDownloadTolerance));
                    this.chartTranslationService.setCurrentLang(result.language);
                    return result;
                })
            );
    }
}
