<div class="h-screen w-screen lg:px-24">
    <div class="bgImage h-screen"></div>
    <div class="mainHeader my-6 hidden md:flex items-center">
        <div>
            <img src="assets/images/Login Page_Logo_847x128.png" alt="">
            <div class="text-lg text-white yesWeAreUsingOldAngular"
                style="padding-left: 3.5rem; width: 35vw; position: absolute;"
                [innerHTML]="'Tacho5Safe_Promotion_Description' | translate">
                Limited series of <span>5th</span> generation digital tachograph and driver card download tool is
                available now.
            </div>
        </div>
    </div>
    <div class="my-6 md:hidden items-center mx-auto">
        <div>
            <img src="assets/images/Login Page_Logo_847x128.png" alt="">
        </div>
    </div>
    <div class="mainContainer">
        <div class="col hidden md:block relative">
            <img src="assets/images/Login Page_Device_979x777.png" alt="">
            <img class="absolute bottom-3 right-1/2 w-32 h-232 text-slate-500" src="assets/images/G2V2Supported.svg" alt="">
        </div>
        <div class="col hidden lg:block" style="align-items: center;"><img
                src="assets/images/Login Page_Phone_739x943.png" alt="">
        </div>
        <mat-card class="respCard col" [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
            <mat-card-content style="font-size: 16px; margin-top: 2rem;" id="simulateclick">
                <div class="text-center pb-1 flex p-justify-center">
                    <img *ngIf="profile === 0" src="assets/images/newLogoColored.png" alt="" class="respImg px-6 pb-6">
                    <img *ngIf="profile === 1" src="assets/images/_tcd_logo_signin.jpg" alt="" height="50"
                        style="margin-bottom: 1rem;" class="respImg">
                    <img *ngIf="profile === 2" src="assets/hanseDis-login.png" alt="" height="50"
                        style="margin-bottom: 1rem;" class="respImg">
                    <p *ngIf="failedtologin" style="color: red; margin-top: 1rem; font-size: 16px;">
                        {{'Failed to login! Wrong username or password!' | translate}}</p>
                </div>
                <div>
                    <!-- <div class="w-full h-1 bg-tachosafeColor">

                    </div> -->
                    <mat-progress-bar color="tachosafe" mode="determinate"
                        class="session-progress tachosafe signInPageAlteredColor"
                        style="margin-top: -1rem;"></mat-progress-bar>
                </div>
                <form [formGroup]="signinForm" (ngSubmit)="signin()" class="smallFont">
                    <div style="margin-top: 2rem;">
                        <mat-form-field class="full-width">

                            <mat-label class="text-muted">{{'Username' | translate}}</mat-label>

                            <input matInput name="Username" type="text" id="username"
                                [formControl]="signinForm.controls['username']">
                        </mat-form-field>
                        <small
                            *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched"
                            class="form-error-msg">{{'Username is required' | translate}}</small>
                    </div>

                    <div class="">
                        <mat-form-field class="full-width">
                            <mat-label class="">{{'Password' | translate}}</mat-label>
                            <input id="password" [type]="passwordIsHidden?'password':'text'" name="password" matInput
                                [formControl]="signinForm.controls['password']" value="">
                            <mat-icon *ngIf="passwordIsHidden" (click)="passwordIsHidden=!passwordIsHidden" matSuffix>visibility</mat-icon>
                            <mat-icon *ngIf="!passwordIsHidden" (click)="passwordIsHidden=!passwordIsHidden" matSuffix>visibility_off</mat-icon>
                        </mat-form-field>
                        <small
                            *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched"
                            class="form-error-msg">{{'Password is required' | translate}}</small>
                    </div>

                    <div class="pb-1 smallFont" style="text-align: center !important; font-size: 12px;">
                        <!-- <mat-checkbox name="rememberMe" [formControl]="signinForm.controls['rememberMe']"
                            class="pb-1 text-tachosafeColor font-bold signInPageAlteredColor">{{'Remember this computer'
                            | translate}}</mat-checkbox> -->
                    </div>
                    <!--                    [disabled]="signinForm.controls['password'].value === '' || signinForm.controls['username'].value === ''"-->
                    <!-- <button class="w-full mb-4 py-2 text-lg text-white font-bold bg-tachosafeColor">{{'Sign in' | translate}}</button> -->
                    <button mat-raised-button color="tachosafe" class="mat-tachosafe full-width mb-1 smallFont">
                        {{'Sign in' | translate}}
                    </button>
                    <div class="text-center text-tachosafeColor">
                        <a [routerLink]="['/sessions/forgot-password']"
                            class="mat-primary text-center full-width smallFont text-tachosafeColor font-bold"
                            style="font-size: 13px;">
                            {{'Forgot password' | translate}}</a>
                        <span fxFlex></span>
                        <a [routerLink]="['/sessions/signup']" *ngIf="profile != 1"
                            class="mat-primary text-center full-width smallFont text-tachosafeColor font-bold"
                            style="font-size: 13px;">
                            {{'Create a new account' | translate}}
                        </a>
                    </div>
                </form>
                <mat-select [(value)]="selectedValue" placeholder="{{currentLangName | translate}}"
                    (selectionChange)="changeLang(selectedValue)" style="width: 8rem; margin: 1rem calc(50% - 4rem)"
                    class="smallFont text-tachosafe">
                    <perfect-scrollbar>
                        <mat-option value="EN">
                            <mat-icon svgIcon="EN"></mat-icon>
                            {{'English' | translate}}</mat-option>
                        <mat-option value="HU">
                            <mat-icon svgIcon="HU"></mat-icon>
                            {{'Hungarian' | translate}}</mat-option>
                        <mat-option value="RO">
                            <mat-icon svgIcon="RO"></mat-icon>
                            {{'Romanian' | translate}}</mat-option>
                        <mat-option value="DE">
                            <mat-icon svgIcon="DE"></mat-icon>
                            {{'German' | translate}}</mat-option>
                        <mat-option value="NL">
                            <mat-icon svgIcon="NL"></mat-icon>
                            {{'Dutch' | translate}}</mat-option>
                        <mat-option value="FR">
                            <mat-icon svgIcon="FR"></mat-icon>
                            {{'French' | translate}}</mat-option>
                        <mat-option value="DA">
                            <mat-icon svgIcon="DA"></mat-icon>
                            {{'Danish' | translate}}</mat-option>
                        <mat-option value="ES">
                            <mat-icon svgIcon="ES"></mat-icon>
                            {{'Spanish' | translate}}</mat-option>
                        <mat-option value="SL">
                            <mat-icon svgIcon="SL"></mat-icon>
                            {{'Slovenian' | translate}}</mat-option>
                        <mat-option value="IT">
                            <mat-icon svgIcon="IT"></mat-icon>
                            {{'Italian' | translate}}</mat-option>
                        <mat-option value="SK">
                            <mat-icon svgIcon="SK"></mat-icon>
                            {{'Slovak' | translate}}</mat-option>
                        <mat-option value="BG"><span><mat-icon svgIcon="BG"></mat-icon></span>
                            {{'Bulgarian' | translate}}
                        </mat-option>
                        <mat-option value="PT"><span><mat-icon svgIcon="PT"></mat-icon></span>
                            {{'Portuguese' | translate}}
                        </mat-option>
                        <mat-option value="CS"><span><mat-icon svgIcon="CS"></mat-icon></span>
                            {{'Czech' | translate}}
                        </mat-option>
                        <mat-option value="SV"><span><mat-icon svgIcon="SV"></mat-icon></span>
                            {{'Czech' | translate}}
                        </mat-option>
                        <mat-option value="HR"><span><mat-icon svgIcon="HR"></mat-icon></span>
                            {{'Croatian' | translate}}
                        </mat-option>
                        <mat-option value="UK"><span><mat-icon svgIcon="UK"></mat-icon></span>
                            {{'Ukrainian' | translate}}
                        </mat-option>
                        <mat-option value="GR"><span><mat-icon class="rounded-full" svgIcon="GR"></mat-icon></span>
                            {{'Greek' | translate}}
                        </mat-option>
                        <mat-option value="pt">
                            <mat-icon svgIcon="PT"></mat-icon>
                            {{'Portuguese' | translate}}
                        </mat-option>
                        <mat-option value="pl">
                            <mat-icon svgIcon="PL"></mat-icon>
                            {{'Polish' | translate}}
                        </mat-option>
                    </perfect-scrollbar>
                </mat-select>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="mainContainer">
        <div class="col" style="justify-content: left;">
            <div class="row my-4">
                <img src="assets/images/Login Page_QR_code_118x118.png" alt="">
                <div class="hidden md:block" style="margin-left: 0.5rem;">
                    <div>{{'Tacho5Safe_Promotion_Desc_0'|translate}}</div>
                    <div>{{'Tacho5Safe_Promotion_Desc_1'|translate}}</div>
                    <div>{{'Tacho5Safe_Promotion_Desc_2'|translate}}</div>
                    <div [innerHTML]="'Tacho5Safe_Promotion_Desc_3'|translate"></div>
                    <div class="text-xs" [innerHTML]="'Tacho5Safe_Promotion_Desc_3*'|translate"></div>
                </div>
            </div>

        </div>
        <div class="md:hidden">
            <div class="relative">
                <img src="assets/images/Login Page_Device_979x777.png" alt="">
                <img class="absolute top-6 right-6 w-16 h-16" src="assets/images/G2V2Supported.svg" alt="">
            </div>
        </div>
        
            <div class="col" style="display: flex; justify-content: center; align-items: center;">
                <a href="https://tachosafe.ro/product/tacho5safe/" target="_blank">
                <button
                    class="exploreButton">{{'Tacho5Safe_Promotion_ExploreNow' | translate}}</button></a></div>
            <div class="col hidden xl:block"></div>
    </div>

    <footer class="footer h-12" *ngIf="profile === 0 || profile === 2">
        <span style="text-decoration: underline;">
            <a matTooltip="Svt Electronics WebPage" matTooltipPosition="above" href="http://www.tachosafe.info/"
                target="_blank">
                {{'© SVT Electronics ' + currentYear + '.'}}
            </a>
        </span>
        <span style="margin-left: 0.3rem;">
            {{'All rights reserved' | translate}}.
        </span>
        <!--        <span style="margin-left: 0.3rem; text-decoration: underline;"><a target="_blank" routerLink="/patchnotes">{{'Version' | translate}}: {{vnumber}}</a></span>-->
        <span style="margin-left: 0.3rem; text-decoration: underline;">
            <a target="_blank"  href="https://tachosafe.info/en/terms-and-conditions">
                {{'Terms of Service' | translate}}
            </a>
        </span>
        <span style="margin-left: 0.3rem; text-decoration: underline;">
            <a target="_blank" href="https://tachosafe.info/en/cookie-policy">
                {{'Cookie Notice' | translate}}
            </a>
        </span>
        <span style="margin-left: 0.3rem; text-decoration: underline;">
            <a target="_blank" href="https://tachosafe.info/en/confidentiality-policy">
                {{'Data Privacy Notice' | translate}}
            </a>
        </span>
    </footer>
    <footer class="footer" *ngIf="profile === 1">
        <span style="margin-left: 0.3rem;">{{'All rights reserved' | translate}}.</span>
        <span style="margin-left: 0.3rem; text-decoration: underline;">
            <a target="_blank" href="https://tachocontrol-data.eu/index.php/agb">
                {{'Terms of Service' | translate}}
            </a>
        </span>
        <span style="margin-left: 0.3rem; text-decoration: underline;">
            <a target="_blank" href="https://tachocontrol-data.eu/index.php/datenschutz">
                {{'Data Privacy Notice' | translate}}
            </a>
        </span>
    </footer>
</div>

<p-toast position="bottom-center" key="bc"></p-toast>