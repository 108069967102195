import { Pipe, PipeTransform } from '@angular/core';
import { Company } from 'app/services/company/company.model';

@Pipe({
    name: 'companyFilter'
})
export class CompanyFilterPipe implements PipeTransform {

    transform(companies: Company[], companyNameFilter: any): Company[] {

        if (!companies) {
            return companies;
        }

        // prepare companyNameFilter
        if (!companyNameFilter || companyNameFilter.length === 0) {
            return companies;
        }

        let term: string;
        if (typeof companyNameFilter === 'string') {
            term = companyNameFilter.replace(/\s/g, '');
        } else {
            term = companyNameFilter.name;
        }


        // escape characters witch are part of regex constants
        const search_item = new RegExp(term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

        return companies.filter(company => search_item.test(company.name.toString().replace(/\s/g, '')));
    }

}
