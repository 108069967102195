import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_URL} from '../../helpers/globals';
import {map, share} from 'rxjs/operators';
import {DriverActivities} from '../drivers/drivers.service';
import * as Http from 'http';
import {compareActivity} from '../rszoft/report.model';

export class VehicleInfo {
    registrationNumber: string;
    identificationNumber: string;
    lastDownload: number;
    daysFromLastDownload: number;
    tachographDownloadPeriod: number;
    isRemoteDownloadable: boolean;
    lastOdometerValue: number;
    lockedInCompany:string;
    downloadingCompany: string;
    lockedInCardNumber:string;
    downloadingCardNumber: string;
    weeklyStat: any[];
    monthlyStat: any[];
    dailyStat: any[];
    nextCalibrationDate: number;
    status: string;
    tachoId: number;
    manufacturerData: { 
        check: string;
        continent: string;
        country: string;
        manufacturer: string;
        modelYear: number;
        sequentialNumber: string;
        vds: string;
        vis: string;
        wmi: string;
        icon: string;
        }

    public static sort(vehicleInfo1: VehicleInfo, vehicleInfo2: VehicleInfo): number {
        const identificationNumber1 = vehicleInfo1.identificationNumber.toLowerCase();
        const identificationNumber2 = vehicleInfo2.identificationNumber.toLowerCase();
        return identificationNumber1 < identificationNumber2 ? -1 : identificationNumber1 > identificationNumber2 ? 1 : 0;
    }
}

interface VehiclesListResponse {
    TotalRecordCount: number;
    Records: VehicleInfo[];
    Statuses: Statuses[];
    Result: string;
}

export interface Statuses {
    clientId: number;
    id: number;
    status: string;
    statusCode: number;
    vehicleRegNr: string;
    vin: string;
}

export interface VehiclesDeleteResponse {
    success: boolean;
    id: number;
    result: string;
    statusText: string;
}

export interface VehicleCatalog {
    month1: string;
    month2: string;
    month3: string;
    month4: string;
    month12: string;
    month5: string;
    month11: string;
    month6: string;
    month7: string;
    month8: string;
    month9: string;
    month10: string;
}

export interface ActivityRecord {
    type: number;
    driver: string;
    status: boolean;
    slot: boolean;
    startTime: string;
    endTime: string;
    duration: string;
}

export interface ActivityRecordResponse {
    registrationNumber: string;
    activityRecords: ActivityRecord[];
}

export class DriverActivity {
    type: number;
    driver: string;
    cardNumber: string;
    status: boolean;
    slot: boolean;
    startTime: string;
    startTimeInMin: number;
    endTime: string;
    endTimeInMin: number;
    duration: string;
    durationInMin: number;
}

export class CoDriversActivity {
    type: number;
    driver: string;
    cardNumber: string;
    status: boolean;
    slot: boolean;
    startTime: string;
    startTimeInMin: number;
    endTime: string;
    endTimeInMin: number;
    duration: string;
    durationInMin: number;
}

export class Drivers {
    cardNumber: string;
    driverName: string;
    insertionTime: number;
    endTime: number;
    odoBegin: number;
    odoEnd: number;
    odoStartTime: number;
    odoEndTime: number;
    startCountry: string;
    endCountry: string;
}

export interface ActivityRecordsDiagrammResponse {
    driverActivities: DriverActivity[];
    registrationNumber: string;
    coDrivers: string[];
    coDriverActivities: CoDriversActivity[];
    drivers: Drivers[];
}

export interface ActivityRecordsDiagrammForPeriodResponse {
    driverActivities: DriverActivity[];
    registrationNumber: string;
    coDrivers: string[];
    coDriverActivities: CoDriversActivity[];
    drivers: Drivers[];
    day: number;
}

export interface ActivityRecordsDiagrammResponseForPeriod {
    data: ActivityRecordsDiagrammForPeriodResponse[];
}

export interface VehicleEvents {
    id: number;
    eventType: number;
    eventRecordPurpose: number;
    eventBeginTime: number;
    eventEndTime: number;
    cardNumberDriverSlotBegin_cardNumber: string;
    firstNameDriverSlotBegin: string;
    lastNameDriverSlotBegin: string;
    driverIdDriverSlotBegin: number;
    cardNumberDriverSlotEnd_cardNumber: string;
    firstNameDriverSlotEnd: string;
    lastNameDriverSlotEnd: string;
    driverIdDriverSlotEnd: number;
    cardNumberCodriverSlotBegin_cardNumber: string;
    firstNameCodriverSlotBegin: string;
    lastNameCodriverSlotBegin: string;
    driverIdCodriverSlotBegin: number;
    cardNumberCodriverSlotEnd_cardNumber: string;
    firstNameCodriverSlotEnd: string;
    lastNameCodriverSlotEnd: string;
    driverIdCodriverSlotEnd: number;
    similarEventsNumber: number;
}

export interface VehicleEventsResponse {
    TotalRecordCount: number;
    Records: VehicleEvents[];
    Result: string;
}

export interface VehicleFaults {
    id: number;
    faultType: number;
    faultRecordPurpose: number;
    faultBeginTime: number;
    faultEndTime: number;
    cardNumberDriverSlotBegin_cardNumber: string;
    firstNameDriverSlotBegin: string;
    lastNameDriverSlotBegin: string;
    driverIdDriverSlotBegin: number;
    cardNumberDriverSlotEnd_cardNumber: string;
    firstNameDriverSlotEnd: string;
    lastNameDriverSlotEnd: string;
    driverIdDriverSlotEnd: number;
    cardNumberCodriverSlotBegin_cardNumber: string;
    firstNameCodriverSlotBegin: string;
    lastNameCodriverSlotBegin: string;
    driverIdCodriverSlotBegin: number;
    cardNumberCodriverSlotEnd_cardNumber: string;
    firstNameCodriverSlotEnd: string;
    lastNameCodriverSlotEnd: string;
    driverIdCodriverSlotEnd: number;
    similarFaultsNumber: number;
}

export interface VehicleFaultsResponse {
    TotalRecordCount: number;
    Records: VehicleFaults[];
    Result: string;
}

export interface VehicleInformation {
    information: Information[];
}

export interface TachoType {
    id: number;
    name: string;
    description: string;
}

export interface VehicleType {
    id: number;
    name: string;
}

export interface Group {
    id: number;
    groupName: string;
    description: string;
}

export interface Information {
    vehicleRegNr: string;
    vehicleName: string;
    fleetNumber: string;
    brandAndType: string;
    structure: string;
    enrollDate: number;
    joinDate: number;
    quitDate: number;
    enrollDateString: string;
    joinDateString: string;
    quitDateString: string;
    notes: string;
    tachoType: TachoType;
    vehicleType: VehicleType;
    group: Group;
    isActive: boolean;
    kmStandValue: string;
    active: boolean;
}

export interface VehicleInformations {
    information: Information;
    error?: any;
    message: string;
}

export interface VehiclesForGroupResponse {
    vehicles: VehiclesForGroup[];
    message: string;
}

export interface DeleteVehicleResponse {
    error: string;
    message: string;
}

export interface VehiclesForGroup {
    registrationNumber: string;
}

export interface BasicResponse {
    data: null | any | any[];
}

export interface DefaultResponse {
    message: string;
}

export interface GroupsResponse {
    message: string;
    groups: Group[];
}

export interface Group {
    id: number;
    groupName: string;
    description: string;
}

export interface DriversForGroupResponse {
    message: string;
    drivers: DriversForGroup[];
}

export interface DriversForGroup {
    id: number,
    driverFirstNames: string,
    driverSurnames: string,
}

export interface AddGroupResponse {
    message: string;
    clientGroups: Group[];
}

export interface VehicleTypeResponse {
    message: string;
    error: any;
    vehicleTypes: VehicleType[];
}

export interface VehicleType {
    id: number;
    name: string;
}

export interface TachoTypeResponse {
    message: string;
    tachoType: TachoType[];
}

export interface ActivationResult {
    result: string;
}

@Injectable({
    providedIn: 'root'
})
export class VehiclesService {

    constructor(private http: HttpClient) {
    }

    setVehicleInactive(identificationNumber: string, clientId: number) {
        return this.http.get<ActivationResult>(API_URL + 'vehicle/inactivate/' + clientId + '/' + identificationNumber, { withCredentials: true})
            .pipe(share(), map(response => {
                return response.result;
            }))
    }

    setVehicleActive(identificationNumber: string, clientId: number) {
        return this.http.get<ActivationResult>(API_URL + 'vehicle/activate/' + clientId + '/' + identificationNumber, { withCredentials: true})
            .pipe(share(), map(response => {
                return response.result;
            }))
    }

    deleteVehicle(vin: string, vehicleRegNr: string, clientId: number) {
        return this.http.post<DeleteVehicleResponse>(API_URL + 'vehicle/deleteVehicle', {
            vin: vin,
            vehicleRegNr: vehicleRegNr,
            clientId: clientId
        }, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    getAllVehiclesInVehicleReport(companyId: number): Observable<VehiclesListResponse> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http
            .get<VehiclesListResponse>(API_URL + 'vehicle/getVehiclesListV2',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response;
            }));
    }

    getAllVehicles(companyId: number): Observable<VehicleInfo[]> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http
            .get<VehiclesListResponse>(API_URL + 'vehicle/getVehiclesListV2',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                response.Statuses.map(status=>{
                    response.Records.filter(record=>record.identificationNumber == status.vin).map(record=>{
                        record.status = status.status;
                    })
                })
                return response.Records;
            }));
    }

    deleteVehicles(vehicleId: number): Observable<VehiclesDeleteResponse> {
        return this.http.delete<VehiclesDeleteResponse>(
            API_URL + 'vehicle/' + vehicleId, {withCredentials: true} // (vehicleId == undefined ? 0 : vehicleId)
        );
    }

    getVehicleCatalog(vehicleId, clientId): Observable<VehicleCatalog> {
        const params: HttpParams = new HttpParams()
            .append('vehicleId', '' + vehicleId)
            .append('clientId', '' + clientId);

        return this.http.get<VehicleCatalog>(API_URL + 'vehicle/usage/getLastYear', {
            params: params,
            withCredentials: true
        });
    }

    getVehicleActivities(vehicleId, clientId, day): Observable<ActivityRecordResponse> {
        const params: HttpParams = new HttpParams()
            .append('vehicleId', '' + vehicleId)
            .append('clientId', '' + clientId)
            .append('day', '' + day);
        return this.http.get<ActivityRecordResponse>(API_URL + 'vehicle/getActivityRecordsForDayVehicle', {
            params: params,
            withCredentials: true
        });
    }

    getDiagrammData(vehicleId, clientId, day): Observable<ActivityRecordsDiagrammResponse> {
        const params: HttpParams = new HttpParams()
            .append('vehicleId', '' + vehicleId)
            .append('clientId', '' + clientId)
            .append('day', '' + day);
        return this.http.get<ActivityRecordsDiagrammResponse>(API_URL + 'vehicle/getDiagramData', {
            params: params,
            withCredentials: true
        });
    }

    getDiagrammDataForPeriod(vehicleId, clientId, startDate, endDate): Observable<ActivityRecordsDiagrammResponseForPeriod> {
        const params: HttpParams = new HttpParams()
            .append('vehicleId', '' + vehicleId)
            .append('clientId', '' + clientId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);
        return this.http.get<ActivityRecordsDiagrammResponseForPeriod>(API_URL + 'vehicle/getDiagramDataForPeriod', {
            params: params,
            withCredentials: true
        })
    }

    getVehicleEvents(vehicleId: number, startDate: number, endDate: number): Observable<VehicleEvents[]> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('limit', '' + 50)
            .append('offset', '' + 0)
            .append('sort', '' + 'event_begin_time DESC')
            .append('vehicleId', '' + vehicleId);
        return this.http.get<VehicleEventsResponse>(API_URL + 'vehicle/getVehicleEvents',
            {params: params, withCredentials: true}).pipe(share(), map(response => {
            return response.Records;
        }));
    }

    getVehicleFaults(vehicleId: number, startDate: number, endDate: number, clientId: string): Observable<VehicleFaults[]> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('limit', '' + 50)
            .append('offset', '' + 0)
            .append('sort', '' + 'fault_begin_time DESC')
            .append('vehicleId', '' + vehicleId)
            .append('clientId', '' + clientId);
        return this.http.get<VehicleFaultsResponse>(API_URL + 'vehicle/getVehicleFaults',
            {params: params, withCredentials: true}).pipe(share(), map(response => {
            return response.Records;
        }));
    }

    getVehicleInformation(vehicleRN: string) {
        return this.http.get<VehicleInformations>(API_URL + 'vehicle/getVehicleInformation/' + vehicleRN, {withCredentials: true}).pipe(share(), map(response => {
            return response.information;
        }));
    }

    addOrUpdateVehicleInformation(itemForm) {
        return this.http.post<DefaultResponse>(API_URL + 'vehicle/addOrUpdateVehicleInformation', {
            vehicleRegNr: itemForm.vehicleRegNr,
            vehicleName: itemForm.vehicleName,
            fleetNumber: itemForm.fleetNumber,
            brandAndType: itemForm.brandAndType,
            structure: itemForm.structure,
            enrollDate: itemForm.enrollDate,
            joinDate: itemForm.joinDate,
            quitDate: itemForm.quitDate,
            notes: itemForm.notes,
            tachoType: itemForm.tachoType,
            vehicleType: itemForm.vehicleType,
            group: itemForm.group,
            kmStandValue: itemForm.kmStandValue
        }, {withCredentials: true})
    }

    getVehiclesForGroup(groupId) {
        return this.http.get<VehiclesForGroupResponse>(API_URL + 'groups/getVehiclesForGroup/' + groupId, {withCredentials: true}).pipe(share(), map(response => {
            return response.vehicles;
        }));
    }

    getDriversForGroup(groupId) {
        return this.http.get<DriversForGroupResponse>(API_URL + 'groups/getDriversForGroup/' + groupId, {withCredentials: true}).pipe(share(), map(response => {
            return response.drivers;
        }));
    }

    getGroups(clientId) {
        return this.http.get<GroupsResponse>(API_URL + 'groups/getAllGroups/' + clientId, {withCredentials: true}).pipe(share(), map(response => {
            return response.groups;
        }));
    }

    addGroup(clientId, newGroup) {
        return this.http.post<AddGroupResponse>(API_URL + 'groups/addGroup/' + clientId, newGroup, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    getAvailableVehicleTypes() {
        return this.http.get<VehicleTypeResponse>(API_URL + 'vehicle/getAllVehicleType', {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    getAvailableTachoTypes() {
        return this.http.get<TachoTypeResponse>(API_URL + 'vehicle/getAllTachoType', {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    addTachoType(newGroup) {
        return this.http.post<TachoTypeResponse>(API_URL + 'vehicle/addTachoType', newGroup, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    addVehicleType(newGroup) {
        return this.http.post<VehicleTypeResponse>(API_URL + 'vehicle/addVehicleType', newGroup, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteDriverFromGroup(groupId, driverId) {
        return this.http.post<DriversForGroupResponse>(API_URL + 'groups/deleteDriverGroup', {
            groupId: groupId,
            driverId: driverId
        }, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteVehicleFromGroup(groupId, vehicleRegNr) {
        return this.http.post<VehiclesForGroupResponse>(API_URL + 'groups/deleteVehicleGroup', {
            groupId: groupId,
            vehicleRegNr: vehicleRegNr
        }, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteGroup(groupId) {
        return this.http.post<GroupsResponse>(API_URL + 'groups/delete/' + groupId, {}, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteTachoType(tachoTypeId) {
        return this.http.post<TachoTypeResponse>(API_URL + 'vehicle/deleteTachoType/' + tachoTypeId, {}, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteVehicleType(vehicleTypeId) {
        return this.http.post<VehicleTypeResponse>(API_URL + 'vehicle/deleteVehicleType/' + vehicleTypeId, {}, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }
}
