export enum SpinnerSize {
    SMALL = 30,
    MEDIUM = 50,
    LARGE = 100
}

export enum SpinnerVerticalPosition {
    LEFT = 'flex-start',
    CENTER = 'center',
    RIGHT = 'flex-end'
}

export enum SpinnerHorizontalPosition {
    TOP = 'flex-start',
    CENTER = 'center',
    BOTTOM = 'flex-end'
}
