import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from 'app/helpers/globals';

@Injectable({
    providedIn: 'root'
})
export class CharttranslationService {

    translations: any[];
    currentLandCode: string;
    langChangeEmitter: EventEmitter<any>;

    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang('en');
        this.currentLandCode = 'EN';
        this.langChangeEmitter = new EventEmitter<any>();
        this.translations = [];
        for (const l of LANGUAGES) {
            this.translate.getTranslation(l.toLowerCase())
                .pipe(
                    map(translations => {
                        this.translations[l] = translations;
                    })
                ).subscribe();
        }
    }

    getTextByLanguageAndValue(value: string, currentLangCode?: string): Observable<string> {
        const usedLangCode = currentLangCode ? currentLangCode : this.currentLandCode;
        if (this.translations[usedLangCode]) {
            return new Observable<string>(subscriber  => {
                subscriber.next(this.translate.getParsedResult(this.translations[usedLangCode], value));
            })
        } else {
            return this.translate.getTranslation(usedLangCode.toLowerCase()).pipe(
                map(translations => {
                    this.translations[usedLangCode] = translations;
                    return this.translate.getParsedResult(translations, value);
                })
            );
        }
    }

    setCurrentLang(langCode: string) {
        this.currentLandCode = langCode;
        this.langChangeEmitter.emit(this.currentLandCode);
    }

    onLangChange(): EventEmitter<any> {
        return this.langChangeEmitter;
    }

}
