import { Injectable } from '@angular/core';
import { Moment } from 'moment-timezone';
import { TabOption } from './report.model';
import { AttestationProfile } from './driver-attestation/driver-attestation.service';

@Injectable({
    providedIn: 'root'
})
export class PrintService {
    companyName: string;
    reportName: string;
    reportGenerated: Moment;
    language: string;

    progressBarNeeded: boolean;
    progressBarValue: number;

    tab: TabOption;
    navigationURL: string;

    attestationProfile: AttestationProfile;
}
