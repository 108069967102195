import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // console.log('canActivate asr');
        return this.canAct() ;
    }

    async canAct():Promise<boolean> {
        // console.log('canAct asr');
        return (await this.authService.asr()) !== null;
    }

    

}
