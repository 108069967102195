<div class="w-screen h-screen flex flex-col">
    <app-topbar class="" [loggedInUser]="loggedInUser" [sidenav]="sidenav" [isMobile]="isMobile()"
        [notificPanel]="notificationPanel" [notifications]="notifications"
        (onCompanyChange)="changeCompany($event)"></app-topbar>
    <mat-sidenav-container class="h-full">
        <!-- Main side navigation -->
        <!--  PERSONALIZATION  -->
        <mat-sidenav #sidenav mode="side" class="sidebar-panel"
            id="{{profile === 0 || profile === 2 ? 'tachoSidenav': 'TCDSidenav'}}">

            <div class="navigation-hold flex flex-col h-full justify-between bg-white" fxLayout="column">
                <div>
                    <div id="sidenav-menu-outer" class="sidenav-menu-outer" perfectScrollbar>
                        <!--                <span *ngIf="!menuItems">loading....</span>-->
                        <app-branding class="sidenav-branding"></app-branding>
                        <app-vertical-menu *ngIf="menuItems && menuItems.length>0" [menuItems]="menuItems"
                            [menuParentId]="0" (menuItemClicked)="menuItemClicked()"></app-vertical-menu>
                    </div>

                    
                    <!-- <div class="text-4xl font-bold text-slate-800 text-center bg-yellow-400">SANDBOX</div> -->
                    <a target="_blank" href="https://tachosafe.info/en/product/tacho5safe/">   <img class="" src="assets/T5S.jpg" alt=""></a> 
                    <a target="_blank" href="https://tachosafe.info/en/product/tachosafe-tachograph-and-drivercard-remote-download/">   <img class="" src="assets/RDU.png" alt=""></a> 
                </div>
                <div class=" px-2 text-[10px]">
                    <a href="/#/release">
                        <span class="hover:underline"
                            >
                            v{{currentApplicationVersion}} {{'WhatsNew'|translate}}
                        </span>
                    </a>
                    <span class="font-bold" aria-hidden="true"> · </span>
                    <span>
                        <a  class="hover:underline" target="_blank" href="https://tachosafe.info/en/terms-and-conditions">
                            {{'Terms of Service' | translate}}
                        </a>
                    </span>
                    <span class="font-bold" aria-hidden="true"> · </span>
                    <span>
                        <a  class="hover:underline" target="_blank" href="https://tachosafe.info/en/cookie-policy">
                            {{'Cookie Notice' | translate}}
                        </a>
                    </span>
                    <span class="font-bold" aria-hidden="true"> · </span>
                    <span>
                        <a  class="hover:underline" target="_blank" href="https://tachosafe.info/en/confidentiality-policy">
                            {{'Data Privacy Notice' | translate}}
                        </a>
                    </span>
                </div>
                
                <!-- <div class="px-4 py-12 w-full h-24 flex bg-blue-500">
                    <img src="path/to/your/image.jpg" alt="Image" class="h-28 mr-2">
                    <div>TS</div>
                    <div class="flex flex-col justify-center">
                      <h2 class="text-white font-bold text-sm">Title Here</h2>
                      <p class="text-white text-xs">Short Description Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
            </div> -->

            </div>
        </mat-sidenav>
        <mat-sidenav-content class="bg-slate-50">
            <!-- <app-breadcrumb></app-breadcrumb> -->
            <app-progress-bar></app-progress-bar>
            <!--    <perfect-scrollbar>-->
            <div class="rightside-content-hold bg-slate-50" #content (scroll)="onScroll($event)"
                style="scroll-behavior: smooth">
                <router-outlet></router-outlet>
            </div>
            <!--    </perfect-scrollbar>-->
        </mat-sidenav-content>
        <mat-sidenav #notificationPanel mode="over" class="respSidenav" position="end" style="max-width: 600px;">
            <perfect-scrollbar>
                <div class="navigation-hold" fxLayout="column">
                    <app-notifications [notificPanel]="notificationPanel"
                        (notificlengthUpdate)="updateNotificLength($event)"></app-notifications>
                </div>
            </perfect-scrollbar>
        </mat-sidenav>
    </mat-sidenav-container>
    <div class="to-top-container" *ngIf="showToTopButton && (!sidenav.opened || sidenav._getWidth() <= 240)">
        <button mat-icon-button mat-raised-button color="primary" (click)="onToTopClicked()">
            <mat-icon style="color: #ffffff">keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <!--<div class="card-container backend-availability" [ngStyle]="{'display': backendAvailabilityService.alive ? 'none' : 'block'}">-->
    <!--    <div class="card-margin backend-availability&#45;&#45;card icon-cell">-->
    <!--        <span>{{ 'TryingToConnect' | translate }}</span>-->
    <!--        <mat-icon class="icon">cloud_off</mat-icon>-->
    <!--    </div>-->
    <!--</div>-->
</div>