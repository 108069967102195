import {Component, OnInit} from '@angular/core';
import {ApiinfoService} from '../../services/apiinfo.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

    constructor(private apiInfo: ApiinfoService,
                private router: Router) {
    }

    ngOnInit() {
        // setInterval(this.getApiInfo, 1000 * 60);

        setInterval(() => {
            if (this.router.url === '/maintenance') {
                this.apiInfo.getApiInfo().subscribe((info) => {
                }, err => {
                    console.log(err);
                    if (err.status === 0) {
                        // this.router.navigateByUrl('/maintenance');
                        this.router.navigate(['maintenance']);
                    } else {
                        this.router.navigate(['sessions/signin']);
                    }
                });
            }
        }, 10000);
    }

    // getApiInfo() {
    //     this.apiInfo.getApiInfo().subscribe((info) => {
    //     }, err => {
    //         if (err.status === 0) {
    //             // this.router.navigateByUrl('/maintenance');
    //             this.router.navigate(['maintenance']);
    //         } else {
    //             this.router.navigate(['sessions/signin'])
    //         }
    //     });
    // }
}
