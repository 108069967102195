import { Component, OnInit } from '@angular/core';
import { OffencesService } from 'app/services/offences/offences.service';
import { ProgressBarService } from 'app/services/progress-bar/progress-bar.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-t2-update-billing',
  templateUrl: './t2-update-billing.component.html',
  styleUrls: ['./t2-update-billing.component.css']
})
export class T2UpdateBillingComponent implements OnInit {

  years = [];
  months = [1, 2, 3, 4];
  monthsName = ['1', '2', '3', '4'];

  selectedYear: number;
  selectedMonth: number;
  queryButtonUnweighted: boolean;
  noDataFound: boolean = false;

  constructor(
    public readonly offenseService: OffencesService,
    public readonly progressBarService: ProgressBarService,
  ) {
    const currentYear = new Date().getFullYear();
    for (let i = 2021; i <= currentYear; i++) {
      this.years.push(i);
    }

    const today = new Date();
    const quarter = Math.floor((today.getMonth() + 3) / 3);
    if (quarter === 1) {
      this.selectedYear = currentYear - 1;
      this.selectedMonth = 4;
    } else {
      this.selectedYear = currentYear;
      this.selectedMonth = quarter - 1;
    }
  }

  async getAndDownload(year: number, month: number) {
    this.progressBarService.needed.next(true);
    this.noDataFound = false;
    const result = await this.offenseService.getT2UpdaterBilling(year, month).toPromise();

    //ez kell ha több hónapot össze kell mosni számszerint 2 hónap
    
    // for(const key of Object.keys(res2.billingData)){
    //   console.log(key);
    //   // if result has key
    //   if(result.billingData[key] && result.billingData[key].length > 0){
    //     // merge arrays
    //     result.billingData[key] = res2.billingData[key].concat(result.billingData[key]);
    //   }else{
    //     // add key to result
    //     result.billingData[key] = res2.billingData[key];
    //   }
    // }
    if ((result as any).error) {
      this.progressBarService.needed.next(false);
      this.noDataFound = true;
      return;
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('T2 Updater Billing');
    const header = worksheet.addRow([`Active/billable TachoSAFE RDU devices ${year}.Q${this.monthsName[month-1]}`]);
    header.alignment = { vertical: 'middle', horizontal: 'center' };
    header.font = { bold: false, size: 16 };
    worksheet.mergeCells('A1:I1');
    worksheet.mergeCells('A2:H2');
    worksheet.getCell('A2').value = 'Total updates:';
    const fileName = `Updated Tacho2Safe devices_${year}_Q${this.monthsName[month - 1]}.xls`;
    let rowIndex = 3;
    let sum = 0;
    for (const companyName in result.billingData) {
      const elem = result.billingData[companyName];
      worksheet.mergeCells(`A${rowIndex}:H${rowIndex}`);
      worksheet.getCell(`A${rowIndex}`).value = companyName;
      worksheet.getCell(`A${rowIndex}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: '3399FF' },
        fgColor: { argb: '3399FF' }
      };
      worksheet.getCell(`I${rowIndex}`).value = elem.length;
      worksheet.getCell(`I${rowIndex}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: '3399FF' },
        fgColor: { argb: '3399FF' }
      };
      sum = sum + elem.length;
      rowIndex++;
      const groupByMonth: { [key: string]: typeof elem } = elem.reduce((r, a) => {
        r[a.updateMonth] = [...r[a.updateMonth] || [], a];
        return r;
      }, {});
      for (const month in groupByMonth) {
        const elem = groupByMonth[month];
        worksheet.getCell(`A${rowIndex}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          bgColor: { argb: 'FF33CCFF' },
          fgColor: { argb: 'FF33CCFF' }
        };
        worksheet.mergeCells(`B${rowIndex}:H${rowIndex}`);
        worksheet.getCell(`B${rowIndex}`).value = `\t${month}`;
        worksheet.getCell(`B${rowIndex}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          bgColor: { argb: 'FF33CCFF' },
          fgColor: { argb: 'FF33CCFF' }
        };
        worksheet.getCell(`B${rowIndex}`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`I${rowIndex}`).value = elem.length;
        worksheet.getCell(`I${rowIndex}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          bgColor: { argb: 'FF33CCFF' },
          fgColor: { argb: 'FF33CCFF' }
        };
        rowIndex++;
        for (const device of elem) {
          worksheet.mergeCells(`B${rowIndex}:C${rowIndex}`);
          worksheet.mergeCells(`D${rowIndex}:E${rowIndex}`);
          worksheet.mergeCells(`F${rowIndex}:G${rowIndex}`);
          worksheet.mergeCells(`H${rowIndex}:I${rowIndex}`);
          worksheet.getCell(`B${rowIndex}`).value = device.userName;
          worksheet.getCell(`D${rowIndex}`).value = device.serialNumber;
          worksheet.getCell(`F${rowIndex}`).value = device.updateDate;
          worksheet.getCell(`H${rowIndex}`).value = device.sellName;
          worksheet.getCell(`H${rowIndex}`).alignment = { vertical: 'middle', horizontal: 'right' };
          rowIndex++;
        }
      }

    }
    worksheet.getCell('I2').value = sum;
    this.progressBarService.needed.next(false);
    const excelBuffer: any = workbook.xlsx.writeBuffer();
    workbook.xlsx.writeBuffer()
      .then(function (buffer) {
        // done buffering
        const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, fileName);
      });
  }

  ngOnInit(): void {
    // this.getAndDownload(2022,12);

  }

  buttonClick() {
    this.getAndDownload(this.selectedYear, this.selectedMonth);
  }

}
