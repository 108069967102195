<div class="p-4 flex flex-col gap-2">
    <div class="w-full flex flex-row gap-4">
        <div><p-calendar class="shadow-md" view="month" dateFormat="yy MM" (onSelect)="search($event)" placeholder="{{'manageSubCompanyBilling.selectInputPeriodPlaceholder'|translate}}" [(ngModel)]="date"></p-calendar>
        </div><!-- <button mat-raised-button color="primary" (click)="search()">{{'manageSubCompanyBilling.search'|translate}}</button> -->
        <div (click)="regenerate()" class="bg-red-500   rounded md p-4 font-bold text-white font-xl">Regenerate (for Testing Only)</div>
    </div>
    <p-treeTable #tt [value]="data" [columns]="cols" class="shadow-md">
        <ng-template pTemplate="caption">
            <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="{{'manageSubCompanyBilling.clientFilter'|translate}}"
                    (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of cols" [ttSortableColumn]="col.sortable?col.field:undefined" [ngClass]="col.classStyle">
                    {{col.header|translate}}
                    <p-treeTableSortIcon *ngIf="col.sortable" [field]="col.field"></p-treeTableSortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
                    <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <tr>
                <td *ngFor="let col of cols; let i = index">
                    <div class="flex flex-row items-center" *ngIf="!col.specialId">
                        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                        {{getValue(rowData, col)}}
                        <div *ngIf="col.trending && col.trending(rowData).trend=='up'" class="text-green-500 flex flex-row items-center"><mat-icon>trending_up</mat-icon>{{col.trending(rowData).value.toFixed(2)}}</div>
                        <div *ngIf="col.trending && col.trending(rowData).trend=='down'" class="text-red-500 flex flex-row items-center"><mat-icon>trending_down</mat-icon>{{col.trending(rowData).value.toFixed(2)}}</div>
                    </div>
                    <div class="flex flex-row items-center justify-center" *ngIf="col.specialId==='generateExcel-1'">
                        <mat-icon class="cursor-pointer"
                            matTooltip="{{ 'manageSubCompanyBilling.excelType1.generate' | translate }}" svgIcon="excel"
                            (click)="generateExcel(rowData.clientId, ExcelType.DEFAULT)"
                            *ngIf="rowData.subcriptionTime === undefined">
                        </mat-icon>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="cols.length">{{ 'manageSubCompanyBilling.emptyMessage' | translate }}</td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>