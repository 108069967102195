<perfect-scrollbar [ngStyle]="{'height': windowHeight +'px', 'width' : windowWidth + 'px'}">
    <div  [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
        <div style="float: right; margin-right: 1.0rem;">
            <button mat-icon-button class="mat-elevation-z3" (click)="dialogRef.close(false)">
                <mat-icon style="cursor: pointer;"> clear </mat-icon>
            </button>
        </div>

        <div>
            <h1 style="text-align: center; margin-bottom: 50px">{{"Send us your feedback" | translate}}</h1>
        </div>

        <div style="margin: 1rem;">
            <form #feedbackForm="ngForm" (ngSubmit)="submitInfo()">
                <div fxLayout="row wrap" fxLayout.lt-sm="column">
                    <div fxFlex="100" class="pr-1">
                        <span style="margin-right: 50px" fontAttributes="Bold" >{{"Is it useful for you" | translate}}</span>
                        <mat-radio-group
                            aria-label="Select an option"
                            style="margin-bottom: 75px"
                            required
                        >
                            <mat-radio-button
                                class="example-radio-button"
                                *ngFor="let option of options"
                                [value]="option"
                                (click)="setType(option)"
                            >
                                <span style="margin-right: 25px">
                                    {{ option | translate }}
                                </span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayout.lt-sm="column">

                </div>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 25px">
                    <div fxFlex="100" class="pr-1">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                required
                                type="text"
                                name="comment"
                                [(ngModel)]="feedbackInformation.comment"
                                placeholder="{{'Why is it useful for you' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" style="margin-top: 25px">
                    <div fxFlex="100" class="pr-1">
                        <span style="margin-right: 50px" fontAttributes="Bold" >{{"Do you want to get notification when we finish this feature" | translate}}</span>
                        <mat-radio-group aria-label="Select an option"
                                         style="margin-bottom: 75px"
                                         [(ngModel)]="selectedOption">
                            <mat-radio-button
                                class="example-radio-button"
                                *ngFor="let option of options"
                                [value]="option"
                                (click)="setNotification(option)"
                            >
                                <span style="margin-right: 25px">
                                    {{ option | translate }}
                                </span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div fxLayout="row" style="margin-top: 25px;">
                    <button mat-raised-button
                            color="primary"
                            style="flex: 1 1 auto; margin: 50px"
                            (click)="dialogRef.close(false)"
                            [disabled]="feedbackForm.invalid || feedbackInformation.isUseful === null || feedbackInformation.toBeNotified === null"
                    >
                        {{"Save" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</perfect-scrollbar>
