<div class="branding mat-bg-primary" [style.background-color]="getStyle()">
    <a routerLink="/dashboard" *ngIf="profile === 0">
        <img matTooltip="{{'Dashboard.Title' | translate }}"
             matTooltipPosition="right"
             src="assets/images/newLogo.png"
             alt=""
             class="app-logo-text"
             style="margin-top: 0 !important;">
    </a>
    <a routerLink="/dashboard" *ngIf="profile === 1">
        <img matTooltip="{{ 'Dashboard.Title' | translate }}"
             matTooltipPosition="right"
             src="assets/images/tcd_logo.png"
             alt=""
             class="app-logo-text"
             style="margin-top: 0 !important; height: 50px !important;">
    </a>
    <a routerLink="/dashboard" *ngIf="profile === 2">
        <img matTooltip="{{'Dashboard.Title' | translate }}"
             matTooltipPosition="right"
             src="assets/images/logohansedis.png"
             alt=""
             class="app-logo-text"
             style="margin-top: 0 !important; height: 40px !important;">
    </a>
</div>
