import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
/**
 * Used for showing an error or success message on the bottom of the page
 */
export class AlertBar {
    constructor(public snackBar: MatSnackBar,
                private translate: TranslateService) {
    }

    /**
     * Show an error in the snackBar
     * @param message Message to be shown
     * @param action Button text
     */
    openErrorSnackBar(message: string, action: string = 'OK') {
        message = this.translate.instant(message);
        this.snackBar.open(message, action, {
            panelClass: ['style-error'],
            duration: 50000,
        });
    }

    /**
     * Show success message in snackBar
     * @param message Message to be shown
     * @param action Button text
     */
    openSuccessSnackBar(message: string = 'Success', action: string = null) {
        const messageTR = this.translate.instant(message);
        const actionTR = this.translate.instant(action);
        this.snackBar.open(messageTR, actionTR, {
            panelClass: ['style-success'],
            duration: 20000,
        });
    }
}
