<div style="padding: 0 0.5rem; width: 100%;">
    <div class="queryForm" style="padding: 0.6rem 0.6rem 0; width: 100%">
        <div class="queryFormField queryFormField--entitySelector" >
            <mat-form-field appearance="fill" class="queryFormField queryFormField--entitySelector" style="margin-left: 0rem !important; width: 100%">
                <mat-label>
                    {{ 'Serial number' | translate }}
                </mat-label>
                <input
                    matInput
                    type='text'
                    placeholder="213014"
                    [(ngModel)]="serialNumber"
                    name="filterValue">
            </mat-form-field>
        </div>
        <div class="queryFormField queryFormField--dateSelectorContainer" style="margin-bottom: 16px">
            <button id="queryButton"
                    mat-button
                    class="queryFormButton"
                    (click)="search()"
                    type="button">
                <mat-icon style="height: unset">search</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="card" style="padding: 1.2rem" *ngIf="!serialNumberWriter">
    <p-table [value]="visibleData" styleClass="p-datatable-sm p-datatable-gridlines">
        <ng-template pTemplate="header">
            <tr>
                <th>Serial number</th>
                <th>Update date</th>
                <th>User name</th>
                <th>Comment</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>{{product.serialNumber}}</td>
                <td>{{formatDate(product.updateDate)}}</td>
                <td>{{product.userName}}</td>
                <td>{{product.updateComment}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">No serial numbers found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div style="padding: 0.7rem; padding-top: 0rem" [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}" class="table-container table-container--driver">
    <mat-card *ngIf="serialNumberWriter" fxLayout="row" fxLayoutWrap="wrap">
        <mat-icon fxFlex="99" fxFlex.gt-sm="3" style="color: #27bd29;">warning</mat-icon>
        <div fxFlex="99" fxFlex.gt-sm="50" style="line-height: 1.7;" >
            <span>{{'PLEASE ENTER THE SERIAL NUMBER' | translate}}!</span>
        </div>
    </mat-card>
</div>

