import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Pro, ProService } from 'app/services/pro/pro.service';
import { CancelationReason, ManageSubCompanyService } from 'app/services/manageSubCompany/manage-sub-company.service';
import { SubscriptionService } from 'app/services/subscription/subscription.service';
import { AuthService } from 'app/services/auth/auth.service';
import { ClientSubscriptionStatus } from 'app/services/subscription/subscription.model';
type ProModalData = {
    type: 'requestPro' | 'requestBasic'
} | null;

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.css'],
    providers: [MessageService]
})
export class SubscriptionComponent implements OnInit {
    featureList = [
        { "name": "Secure storage of .ddd files", "isPro": false, link: '/#/uploaded-files/uploadeddriverfiles' },
        { "name": "Digital signature validation", "isPro": false, link: '/#/' },
        { "name": "File export for authorities", "isPro": false, link: '/#/uploaded-files/uploadedvehiclefiles' },
        { "name": "Graphic overview of activities", "isPro": false, link: '/#/report/dailychart' },
        { "name": "All reports can be generated for 1 driver or 1 vehicle", "isPro": true, link: '/#/report/infringements' },
        { "name": "All reports can be generated for multiple drivers", "isPro": true, link: '/#/report/infringements' },
        { "name": "All reports can be generated for multiple vehicles", "isPro": true, link: '/#/report/infringements' },
        { "name": "Reports and data analyses", "isPro": true, link: '/#/report' },
        { "name": "Infringement detection according to EU regulations", "isPro": true, link: '/#/report/infringements' },
        { "name": "Working time calculations", "isPro": true, link: '/#/report/workhours' },
        { "name": "Working time calculations summed by countries", "isPro": true, link: '/#/report/workhoursbycountries' },
        { "name": "Daily charts and tables", "isPro": true, link: '/#/report/dailychart' },
        { "name": "Card expiration alerts", "isPro": true, link: '/#/dashboard' },
        { "name": "Calculating ERRU reputation score", "isPro": true, link: '/#/dashboard' },
        { "name": "Calculating live working times with TachoLive functions", "isPro": true, link: '/#/tacholive' },
        { "name": "Data match reports", "isPro": true, link: '/#/report/data-match' },
        { "name": "Vehicle activity report", "isPro": true, link: '/#/report/vehiclereport' },
        { "name": "To-do list for transport managers", "isPro": true, link: '/#/report/checklist' },
        { "name": "Driver attestation reports", "isPro": true, link: '/#/report/driver-attestation' },
        { "name": "Driver individual penalty point verification", "isPro": true, link: '/#/drivers' }
    ]

    pro$: Observable<Pro>

    proModalData: ProModalData = null;

    alreadyRequestedPro = false;

    cancellationReasons: CancelationReason[] = [];

    selectedReason: CancelationReason | null = null;
    reasonText: string = '';
    proRequestLoading = false;


    stripePriceData: ClientSubscriptionStatus;

    priceTableVisible = false;

    constructor(
        public readonly translate: TranslateService,
        private readonly proService: ProService,
        private readonly manageSubCompanyService: ManageSubCompanyService,
        private readonly messageService: MessageService,
        private readonly subscriptionService: SubscriptionService,
        private readonly authService: AuthService,
    ) {
        this.pro$ = this.proService.pro$;
    }

    ngOnInit(): void {
        const clientId = this.authService.getLoggedInUser().clientId;
        this.subscriptionService.getClientSubscriptionStatus(clientId).subscribe((response) => {
            this.stripePriceData = response;
            // this.stripePriceData.proUntil = (new Date()).getTime() - 1000*60*60*24*7;
        });
    }

    getPriceRange(from: number, to: number) {
        if (to > 10000000) return `${from}+`
        return `${from}-${to}`
    }

    async requestBasicModalOpen() {

        if (this.stripePriceData.stripeClient) {
            // response = await this.subscriptionService.cancelSubscription().toPromise();
            this.openCustomerPortal();
        } else {
            this.cancellationReasons = await this.manageSubCompanyService.getCancelationReasons();
            this.selectedReason = null;
            this.reasonText = '';


            this.proModalData = {
                type: 'requestBasic'
            };
        }
    }

    formatProUntilDate(){
        // const date = new Date(this.stripePriceData.proUntil);
        const date = new Date(this.stripePriceData.proUntil??0);
        const dateStr = date.toLocaleDateString();
        return dateStr;
    }



    requestProModalOpen() {
        this.proModalData = {
            type: 'requestPro'
        };
    }

    trans(name: string) {
        return this.translate.instant(name) || name;
    }

    async requestBasic() {
        this.proModalData = null;
        let response;
        if (this.stripePriceData.stripeClient) {
            // response = await this.subscriptionService.cancelSubscription().toPromise();
            this.openCustomerPortal();
        } else {
            response = await this.manageSubCompanyService.requestBasic(this.reasonText, this.selectedReason?.valueOfReason || 'OTHER');
        }

        switch (response.result) {
            case 'SUCCESS':
                this.messageService.add({
                    severity: 'success',
                    detail: this.trans('subscription.toast.basicRequestSuccess.detail'),
                    summary: this.trans('subscription.toast.basicRequestSuccess.title'),
                    life: 10 * 1000
                })
                break;
            case 'FAILED':
                this.messageService.add({
                    severity: 'error',
                    detail: this.trans('subscription.toast.basicRequestError.detail'),
                    summary: this.trans('subscription.toast.basicRequestError.title'),
                    life: 10 * 1000
                })
                break;
        }
    }

    openCustomerPortal() {
        this.subscriptionService.getCustomerPortalUrl().subscribe((url) => {
            window.open(url, '_blank');
        })
    }

    createSubscription() {
        this.subscriptionService.createSubscription().subscribe((response) => {
            //open url
            if(response.error === 'SUCCESS'){
                window.open(response.url, '_blank');
            }else{
                this.messageService.add({
                    severity: 'error',
                    detail: response.message,
                    summary: "Error",
                    life: 10 * 1000
                })
            }

        });
    }

    async requestPro() {
        if (this.stripePriceData.stripeClient) {
            this.createSubscription();
        } else {
            this.proRequestLoading = true;
            this.proModalData = null;
            const response = await this.manageSubCompanyService.requestPro();
            this.proRequestLoading = false;
            // console.log('response', response)
            switch (response.notifType) {
                case 'PRO_REQUEST_IN_24_HOUR':
                    this.messageService.add({
                        severity: 'info',
                        detail: this.trans('subscription.toast.proRequestAlreadyRequested.detail'),
                        summary: this.trans('subscription.toast.proRequestAlreadyRequested.title'),
                        life: 10 * 1000,
                    });
                    this.alreadyRequestedPro = true;
                    break;
                case 'PRO_REQUEST_FAILED':
                    this.messageService.add({
                        severity: 'error',
                        detail: this.trans('subscription.toast.proRequestError.detail'),
                        summary: this.trans('subscription.toast.proRequestError.title'),
                        life: 10 * 1000
                    })
                    break;
                case 'PRO_REQUESTED':
                    this.messageService.add({
                        severity: 'success',
                        detail: this.trans('subscription.toast.proRequestSuccess.detail'),
                        summary: this.trans('subscription.toast.proRequestSuccess.title'),
                        life: 10 * 1000
                    })
                    this.alreadyRequestedPro = true;
                    break;
            }
        }


    }

    cancelModal() {
        // console.log('cancelModal');
        this.proModalData = null;
    }


}
