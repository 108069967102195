<style>
    body {
        text-align: center;
    }

    h1 {
        font-size: 50px;
    }

    body {
        font: 20px Helvetica, sans-serif;
        color: #333;
    }

    article {
        display: block;
        text-align: left;
        width: 650px;
        margin: 0 auto;
    }

    a {
        color: #dc8100;
        text-decoration: none;
    }

    a:hover {
        color: #333;
        text-decoration: none;
    }
</style>

<div class="page-wrap height-100 mat-bg-primary">
    <div class="session-form-hold" style="padding: 1rem !important;">
        <mat-card style="width: 100%" class="respCard" [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
            <mat-card-content style="font-size: 16px; margin-top: 2rem;" id="simulateclick">
                <div class="text-center pb-1">
                    <img *ngIf="profile === 0" src="assets/images/logo_gray.png" alt="" height="40" class="respImg">
                    <img *ngIf="profile === 1" src="assets/images/tcd_logo_signin.jpg" alt="" height="50" style="margin-bottom: 1rem;" class="respImg">
                    <img *ngIf="profile === 2" src="assets/hanseDis-login.png" alt="" height="50" style="margin-bottom: 1rem;" class="respImg">
                </div>
                <hr>
                <div>
                    <a href="https://apps.apple.com/us/app/tachosafe/id1635288503" target="_blank">
                        <img src="assets/images/appstore.png"
                             alt=""
                             class="app-logo-text"
                             style="margin-top: 0 !important; cursor: pointer; width: 100%">
                    </a>
                </div>
                <div>
                    <a href="https://play.google.com/store/apps/details?id=com.svt.tachosafe.tacho5afe&hl=en&gl=US" target="_blank">
                        <img src="assets/images/googleplay.png"
                             alt=""
                             class="app-logo-text"
                             style="margin-top: 0 !important; cursor: pointer; width: 100%;">
                    </a>
                </div>
                <div>
                    <a href="https://appgallery.huawei.com/#/app/C107376609" target="_blank">
                        <img src="assets/images/appgallery.png"
                             alt=""
                             class="app-logo-text"
                             style="margin-top: 0 !important; width: 100%; cursor: pointer">
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
