<div fxLayoutAlign="center center" style="height: 100%;" class="page-wrap height-100 mat-bg-primary">

    <div fxFlex="80" fxFlex.gt-sm="70" fxFlex.sm="70">

        <mat-card style="height: 500px !important;">
            <perfect-scrollbar>
                <mat-card-content fxLayout="row" fxLayoutAlign="center center">
                    <div>
                        <h4 style="margin-top: 2rem;">Cookie Notice</h4>
                        <p>At SVT Electronics, we respect your concerns about privacy and value the relationship that we
                            have with you.
                            Like many companies, we use technology on our website to collect information that helps us
                            enhance your
                            experience and our products and services. The cookies that we use at TachoSafe allow our
                            website to work and
                            help us to understand what information and advertising is most useful to visitors.
                            Please take a moment to familiarize yourself with our cookie practices and let us know if
                            you have any questions
                            by sending us an e-mail or submitting a request through the “Contact Us” form on our
                            websites.
                            We have tried to keep this Notice as simple as possible, but if you’re not familiar with
                            terms, such as cookies,
                            IP addresses, and browsers, then read about these key terms first.</p>
                        <h4>Who is collecting it?</h4>
                        <p>By using our websites, you are consenting to our use of cookies in accordance with this
                            Cookie Notice and our
                            Privacy Notice.
                            Any personal data provided to or collected by TachoSafe via cookies and other tracking
                            technologies is
                            controlled by
                            SVT Electronics LTD (HQ: Targu Mures, Remetea str., no. 110bis, Mures County, WP: Targu
                            Mures, Braila str. no.
                            7,
                            Mures County, Romania)
                            This Cookie Notice applies to any websites, apps, branded pages on third-party platforms
                            (such as Facebook or
                            YouTube), and applications accessed or used through such websites or third-party platforms
                            (hereinafter, “our
                            websites”) which are operated by or on behalf of SVT Electronics.

                            By using our websites, you are consenting to our use of cookies in accordance with this
                            Cookie Notice and our
                            Privacy Notice. If you do not agree to our use of cookies in this way, you should set your
                            browser settings
                            accordingly, disable the cookies that we use or not use our websites at all. If you disable
                            the cookies we use,
                            this
                            may impact your user experience while on the websites.
                            The section below summarizes the different types of cookies we use on our websites, together
                            with their
                            respective
                            purpose and provides you with the ability to manage these cookies.
                            SVT Electronics will only collect, use or disclose your personal data where it is fair and
                            lawful to do so.
                            For a more detailed understanding of how we use personal data collected by TachoSafe
                            cookies, please refer to
                            our
                            Privacy Notice.</p>

                        <h4>What does cookie mean?</h4>
                        <p>Cookies, pixel tags and similar technologies (collectively ‘cookies’) are files containing
                            small amounts of
                            information which are downloaded to any internet enabled device – such as your computer,
                            smartphone or tablet –
                            when
                            you visit a website. Cookies are then sent back to the originating website on each
                            subsequent visit, or to
                            another
                            website that recognizes that cookie. Cookies do lots of different and useful jobs, such as
                            remembering your
                            preferences, generally improving your online experience, and helping us to offer you the
                            best product and
                            services.
                            There are many types of cookies. They all work in the same way, but have minor differences.
                            For a detailed list
                            of
                            cookies used on our websites, please refer to the below relevant section.</p>

                        <h4>What purpose do we use cookies for?</h4>
                        <p>We use cookies to make TachoSafe websites easier to use, to deliver a personalized experience
                            on our websites,
                            and
                            to better tailor our products, services and websites to your interests and needs. Cookies
                            are used to help speed
                            up
                            your future activities and your experience on TachoSafe websites.
                            The cookies you consent to, are also used to collect your personal data which we then
                            profile into audiences so
                            that
                            we can deliver targeted advertising tailored to your interests and limit the number of times
                            you see an
                            advertisement. For more detailed information about the profiling activities SVT Electronics
                            undertakes with your
                            personal data for advertising, please see our Privacy Notice.
                            We also insert cookies in emails and newsletters to improve our content and advertising.
                            Lastly, we use cookies to compile anonymous, aggregated statistics that allow us to
                            understand how people use
                            our
                            websites and to help us improve their structure and content and help us measure the
                            effectiveness of advertising
                            campaigns on TachoSafe website.
                            More detailed information about the types of cookies we use and for what purposes, can be
                            found in the below
                            relevant section.</p>
                        <h4>How can I control or delete cookies?</h4>
                        <p>There are many ways to manage your cookies:</p>
                        <ul style="margin-left: 2rem;">
                            <li>You can refuse your consent;</li>
                            <li>You can disable TachoSafe or third-party cookies by use of your browser settings (see
                                how here); or
                            </li>
                            <li>You can use our cookie management tool to disable TachoSafe or third-party cookies (see
                                how here).
                            </li>
                        </ul>

                        <h4>Control via your browser settings</h4>
                        <p>Most internet browsers are initially set up to automatically accept cookies. If you do not
                            want our websites to
                            store cookies on your device, you can change your browser settings so that you receive a
                            warning before certain
                            cookies are stored. You can also adjust your settings so that your browser refuses most of
                            our cookies or only
                            certain cookies from third parties. You can also withdraw your consent to cookies by
                            deleting the cookies that
                            have
                            already been stored.
                            If you disable the cookies that we use, this may impact your experience while on the
                            Tacho2Safe website, for
                            example
                            you may not be able to visit certain areas of a website or you may not receive personalized
                            information when you
                            visit a website.
                            If you use different devices to view and access the TachoSafe website (e.g., your computer,
                            smartphone, tablet)
                            you
                            will need to ensure that each browser on each device is adjusted to suit your cookie
                            preferences
                            The procedures for changing your settings and cookies differ from browser to browser. If
                            necessary, use the help
                            function on your browser or click on one of the links below to go directly to the user
                            manual for your
                            browser.</p>
                        <ul style="margin-left: 2rem;">
                            <li>Internet Explorer</li>
                            <li>Mozilla Firefox</li>
                            <li>Google Chrome</li>
                            <li>Safari</li>
                            <li>Opera</li>
                        </ul>
                        <p>There are also software products available that can manage cookies for you.
                            You can also use <a target="_blank" href="https://www.ghostery.com"
                                                style="text-decoration: underline;">www.ghostery.com</a> to evaluate the
                            use of cookies used on our websites.
                            To find out more about cookies, including how to see what cookies have been set and how to
                            manage and delete
                            them,
                            visit <a target="_blank" href="http://www.allaboutcookies.org/"
                                     style="text-decoration: underline;">www.allaboutcookies.org</a>.</p>
                        <h4>Control via our cookie management tool</h4>
                        <p>You can disable TachoSafe or third-party cookies by use of our cookie consent tool which can
                            be found here.
                            Switching off tracking cookies, for example, ensures that SVT Electronics will no longer
                            track your online
                            behavior.
                            However please note that opting-out from tracking cookies does not necessarily mean that you
                            will receive less
                            SVT
                            Electronics advertising. It just means that the advertising you receive will not be tailored
                            to your
                            interests.</p>
                        <h4>What cookies do we use?</h4>

                        <p>The cookies used on TachoSafe websites may be generally categorized as follow:</p>
                        <ul style="margin-left: 2rem;">
                            <li>Necessary Cookies. These cookies are essential to make TachoSafe websites work
                                correctly, they enable you to
                                move
                                around our websites and use our features. Examples include remembering previous actions
                                (e.g. entered text)
                                when
                                navigating back to a page in the same session.
                            </li>
                            <ul>
                                <li>Do these cookies collect personal data/identify me? These cookies do not identify
                                    you as an individual.
                                    If you
                                    do
                                    not accept these cookies, it may affect the performance of the website, or parts of
                                    it.
                                </li>
                            </ul>
                            <li>Performance Cookies. These cookies collect information about how you use our websites,
                                for instance which
                                pages
                                you go to most often, the time spent on our websites, and any issues encountered, such
                                as error messages.
                                These
                                cookies are also used to let affiliates know if you came to one of our websites from an
                                affiliate and if
                                your
                                visit
                                resulted in the use or purchase of a product or service from us, including details of
                                the product or service
                                purchased. This helps us improve the performance of our websites.
                            </li>
                            <ul>
                                <li>Do these cookies collect personal data/identify me? These cookies do not identify
                                    you as an individual.
                                    All
                                    information these cookies collect is aggregated and therefore anonymous. It is only
                                    used to improve how
                                    a
                                    website
                                    works.
                                </li>
                            </ul>
                            <li>Functionality Cookies. These cookies allow our websites to remember the choices you make
                                (such as your user
                                name,
                                language or the region you are in) to provide a more personalized online experience.
                                These cookies may also
                                be
                                used
                                to remember changes you have made to text size, fonts and other parts of web pages that
                                you can customize.
                            </li>
                            <ul>
                                <li>Do these cookies collect personal data/identify me? The information these cookies
                                    collect may include
                                    personal
                                    data that you have disclosed. If you do not accept these cookies, it may affect the
                                    performance and
                                    functionality of
                                    the website and may restrict access to content on the website.
                                </li>
                            </ul>
                            <li>Targeting or Advertising Cookies. These cookies are used to deliver content that is more
                                relevant to you and
                                your
                                interests. They are also used to deliver targeted advertising or limit the number of
                                times you see an
                                advertisement
                                as well as help measure the effectiveness of the advertising campaigns on TachoSafe.
                                These cookies may also
                                be
                                linked to site functionality provided by third-parties.
                            </li>
                            <ul>
                                <li>Do these cookies collect personal data/identify me? Most types of these cookies
                                    track consumers via
                                    their
                                    Device
                                    ID or IP address therefore they may collect personal data.
                                </li>
                            </ul>
                            <li>Third-Party Cookies. We use a number of partners that may also set cookies on your
                                device on our behalf when
                                you
                                visit our websites to allow them to deliver tailored SVT Electronics advertising within
                                their domains, for
                                example
                                Facebook and Google DoubleClick. We endeavor to identify these cookies before they are
                                used so that you can
                                decide
                                whether you wish to accept them or not. We also use a number of partners to provide
                                digital experiences and
                                functionalities on our websites. For example, while browsing SVT Electronics websites
                                you may be served
                                cookies
                                from
                                third-parties who provide some of its features on our websites (e.g., a YouTube video),
                                although you have
                                withdrawn
                                or declined your consent to our cookies. This happens because you have directly given
                                your consent to the
                                use of
                                their cookies. In such cases, you should directly withdraw your consent on the relevant
                                third-party website.
                            </li>
                            <ul>
                                <li>Do these cookies collect personal data/identify me? Based on the type of cookies
                                    used by the relevant
                                    third-party,
                                    the information these cookies collect may include personal data.
                                </li>
                            </ul>
                        </ul>
                        <h4>Duration of the cookie we use</h4>
                        <p>In terms of duration, we may use two different types of cookies on our websites:</p>
                        <ul style="margin-left: 2rem;">
                            <li>Session Cookies. These cookies are temporary cookies that remain on your device until
                                you leave our
                                websites;
                                or
                            </li>
                            <li>Persistent Cookies. These cookies remain on your device for much longer or until you
                                manually delete them
                                (how
                                long the cookie remains on your device will depend on the duration or “lifetime” of the
                                specific cookie, as
                                well
                                as
                                your browser settings, as stated below).
                            </li>
                        </ul>
                    </div>
                </mat-card-content>
            </perfect-scrollbar>
        </mat-card>
        <div fxLayout="row"
             style="margin-top: 2rem;"
             fxLayoutAlign="center center">
            <a [routerLink]="['/sessions/signin']">
                <button mat-raised-button
                        fxLayout="row"
                        fxLayoutAlign="center center">
                    BACK
                </button>
            </a>
        </div>
    </div>
</div>
