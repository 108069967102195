<div *ngFor="let menu of parentMenu" class="menu-item bg-white">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu && menu.id !== 32"
       mat-button
       fxLayout="row"
       [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       [routerLink]="[menu.routerLink]"
       routerLinkActive="active"
       (click)="onClick(menu.id)"
       [id]="'menu-item-'+menu.id">
        <!--        <mat-icon class="menu-icon">{{menu.icon}}</mat-icon>-->
            <mat-icon class="menu-icon" *ngIf="menu.icon !== 'perm_device_information'">{{menu.icon}}</mat-icon>
            <mat-icon class="menu-icon"
                      *ngIf="menu.icon === 'perm_device_information'"
                      svgIcon="tacho4safe"></mat-icon>
            <!-- <mat-icon *ngIf="menu.isProLink === true"
                      class="menu-icon"
                      style="position: absolute;width: 30px; height: 30px; left: 90%; top: 4%; background: transparent !important;"
                      svgIcon="insignia"
                      matTooltip="PRO">
            </mat-icon> -->
            <span class="menu-title">{{menu.title | translate}}</span>
            <!-- <mat-icon class="newIcon" *ngIf="menu.isNew" matTooltip="New">new_releases</mat-icon> -->
    </a>
    <!-- <a *ngIf="menu.routerLink && !menu.hasSubMenu && menu.id === 32 && companyId === 21"
       mat-button
       fxLayout="row"
       [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       [routerLink]="[menu.routerLink]"
       routerLinkActive="active"
       (click)="onClick(menu.id)"
       [id]="'menu-item-'+menu.id">
        <mat-icon class="menu-icon" *ngIf="menu.icon !== 'perm_device_information'">{{menu.icon}}</mat-icon>
        <mat-icon class="menu-icon"
                  *ngIf="menu.icon === 'perm_device_information'"
                  svgIcon="tacho4safe"></mat-icon>
        <mat-icon *ngIf="menu.isProLink === true"
                  class="menu-icon"
                  style="position: absolute;width: 30px; height: 30px; left: 90%; top: 4%; background: transparent !important;"
                  svgIcon="insignia"
                  matTooltip="PRO">
        </mat-icon>
        <span class="menu-title">{{menu.title | translate}}</span>
        <mat-icon class="newIcon" *ngIf="menu.isNew" matTooltip="New">new_releases</mat-icon>
    </a> -->
    <!-- <a *ngIf="menu.href && !menu.hasSubMenu"
       mat-button
       fxLayout="row"
       [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       [attr.href]="menu.href || ''"
       [attr.target]="menu.target || ''"
       (click)="onClick(menu.id)"
       [id]="'menu-item-'+menu.id">
            <mat-icon class="menu-icon" matBadge="15" matBadgeColor="warn">{{menu.icon}}</mat-icon>
            <span class="menu-title">{{menu.title | translate}}</span>
    </a> -->

    <a *ngIf="menu.hasSubMenu && !menu.isNew" mat-button
       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <mat-icon class="menu-icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title | translate}}</span>
        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
        <mat-icon class="newIcon" *ngIf="menu.isNew" matTooltip="New">new_releases</mat-icon>
    </a>
<!--    <a *ngIf="menu.hasSubMenu && menu.id === 27" mat-button-->
<!--       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"-->
<!--       (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">-->
<!--        <mat-icon class="menu-icon" matBadge="Pro" matBadgeColor="warn" matBadgeSize="small">{{menu.icon}}</mat-icon>-->
<!--        <span class="menu-title">{{menu.title | translate}}</span>-->
<!--        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>-->
<!--        <mat-icon class="newIcon" *ngIf="menu.isNew" matTooltip="New">new_releases</mat-icon>-->
<!--    </a>-->

<!--    <a *ngIf="menu.hasSubMenu && menu.isNew" mat-button-->
<!--       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"-->
<!--       (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">-->
<!--        <mat-icon class="menu-icon" matBadge="New" matBadgeColor="warn" matBadgeSize="small">{{menu.icon}}</mat-icon>-->
<!--        <span class="menu-title">{{menu.title | translate}}</span>-->
<!--        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>-->
<!--    </a>-->



    <div *ngIf="menu.hasSubMenu  && menu.id !== 27" class="sub-menu" [id]="'sub-menu-'+menu.id">
        <app-vertical-menu [menuItems]="menuItems" [menuParentId]="menu.id" (menuItemClicked)="menuItemClicked.emit()"></app-vertical-menu>
    </div>

    <div *ngIf="menu.hasSubMenu  && menu.id === 27" class="sub-menu" [id]="'sub-menu-'+menu.id">
        <app-vertical-menu [menuItems]="menuItems" [menuParentId]="menu.id" (menuItemClicked)="menuItemClicked.emit()"></app-vertical-menu>
    </div>
</div>

