import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ReleaseService} from '../../../services/release/release.service';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-update-release-modal',
  templateUrl: './update-release-modal.component.html',
  styleUrls: ['./update-release-modal.component.css']
})
export class UpdateReleaseModalComponent implements OnInit {

    releaseName = '';
    releaseSelectedStatus = '';
    selectedRelease = '';
    selectFeatureStatus = '';
    featureName = '';
    description = '';
    link = '';
    selectedDate: Date;
    date1 = new FormControl(new Date());
    findedValue: any;

    private _subscriptions: Subscription;

    constructor(
            public dialogRef: MatDialogRef<UpdateReleaseModalComponent>,
            public releaseService: ReleaseService,
            public translate: TranslateService,
            @Inject(MAT_DIALOG_DATA) public data: any) {
        this._subscriptions = new Subscription();
    }

    initializedValue() {
        this.findedValue = this.data.data.content.filter(item => item.id === this.selectedRelease);
        this.releaseName = this.findedValue[0].name;
        this.releaseSelectedStatus = this.findedValue[0].status;
        this.selectedDate = this.findedValue[0].scheduledDate;
        this.date1.setValue(new Date(this.findedValue[0].scheduledDate));
    }

    updateFeature() {
        const subscription = this.releaseService.updateRelease(this.findedValue[0].id, this.releaseName, this.releaseSelectedStatus, this.selectedDate.getTime())
            .subscribe((response) => {
                this.dialogRef.close(response.content);
            });
        this._subscriptions.add(subscription);
    }

    deleteFeature() {
        const subscripption = this.releaseService.deleteRelease(this.findedValue[0].id).subscribe((response) => {
            this.dialogRef.close(response.content);
        });
        this._subscriptions.add(subscripption);
    }

    ngOnInit(): void {
        // console.log(this.data);
    }

    cancel() {
        this.dialogRef.close(this.data.data);
    }
}
