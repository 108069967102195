import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceValues'
})
export class ReplaceValuesPipe implements PipeTransform {

  transform(template: string, replaceObject: any): string {
    if (!template || !replaceObject) {
      return template;
    }

    // Replace placeholders with values from the object
    for (const key of Object.keys(replaceObject)) {
      const placeholder = `{${key}}`;
      template = template.replace(placeholder, replaceObject[key]);
    }

    return template;
  }

}
