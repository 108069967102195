import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TypeOfAccount } from './company/company.model';

@Injectable({
  providedIn: 'root'
})
export class ViewAsTestService {

  private typeOfAccountSubject: BehaviorSubject<TypeOfAccount> = new BehaviorSubject<TypeOfAccount>(TypeOfAccount.TEST);
  private testModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public typeOfAccount$ = this.typeOfAccountSubject.asObservable();
  public testMode$ = this.testModeSubject.asObservable();

  public setTypeOfAccount(typeOfAccount: TypeOfAccount): void {
    this.typeOfAccountSubject.next(typeOfAccount);
    if (typeOfAccount === TypeOfAccount.TEST || typeOfAccount === TypeOfAccount.SELF_ONLY) {
      this.testModeSubject.next(true);
    } else {
      this.testModeSubject.next(false);
    }
  }

  private setTestMode(testMode: boolean): void {
    this.testModeSubject.next(testMode);
    
  }

  constructor() {
    if (process.env.NODE_ENV !== 'production') {
      globalThis.setTypeOfAccount = this.setTypeOfAccount.bind(this);
      globalThis.setTestMode = this.setTestMode.bind(this);
    }
  }

  isInTestMode(): boolean {
    return this.testModeSubject.value;
  }
}
