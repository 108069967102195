import {Injectable} from '@angular/core';
import {API_URL} from '../helpers/globals';
import {HttpClient} from '@angular/common/http';
import {map, share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiinfoService {

    constructor(private http: HttpClient) {
    }

    getApiInfo() {
        return this.http
            .get(API_URL + 'getServerStatus')
            .pipe(share(), map(response => {
                return response;
            }));
    }

}
