import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { I18N_KEY, I18N_URL } from 'app/helpers/globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoader implements TranslateLoader  {
  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-API-Key': I18N_KEY
  });

  constructor(private httpClient: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
      const apiAddress = I18N_URL + `/v2/projects/translations/${lang.toLowerCase()}`;
      return this.httpClient.get(apiAddress, { headers: this.contentHeader }).pipe(map((x) => x[lang.toLowerCase()]))
        .pipe(
          catchError(_ => this.httpClient.get(`/assets/i18n/EN.json`))
        );
  }
}

