import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(values: any, sortBy: string, desc = true): any {
        if (!values || values.length < 0 || !sortBy || sortBy.trim() === '') {
            return values;
        }

        values = values.sort((a: any, b: any) => {
            if (desc) {
                return b[sortBy] - a[sortBy];
            }
            return a[sortBy] - b[sortBy];
        });
        return values;
    }

}
