import { Component, Input, OnInit } from '@angular/core';
import { DateUtil } from '../../utils/date.util';
import * as moment from 'moment-timezone';
import { DailyChartService } from 'app/services/rszoft/dailychart/daily-chart.service';
import { TableWeek } from 'app/services/rszoft/dailychart/models/table.model';
import { TabOption } from 'app/services/rszoft/report.model';

@Component({
    selector: 'app-weekly-table',
    templateUrl: './weekly-table.component.html',
    styleUrls: ['./weekly-table.component.css']
})
export class WeeklyTableComponent implements OnInit {
    @Input() weeklyData: TableWeek;
    @Input() selectedTab: TabOption;
    @Input() lastDownloadDate: number;
    @Input() timezone: string;
    @Input() isPrint = false;

    driverDisplayColumns = ['date', 'startDate', 'endDate', 'driving', 'working', 'availability', 'allWorksInDay', 'vehicle'];
    vehicleDisplayColumns = ['date', 'startDate', 'endDate', 'driving', 'kmRange', 'kmSum', 'avgSpeed', 'driver', 'fault'];
    tabOptions = TabOption;
    _moment = moment;
    dateUtil = DateUtil;
    math = Math;

    constructor(public dailyChartService: DailyChartService) {}

    ngOnInit(): void {}

    isFinal(): boolean {
        return this.weeklyData?.days?.length > 0 && this.weeklyData.days[this.weeklyData.days.length - 1].recordDate * 1000 + 1439 < this.lastDownloadDate;
    }

    isSameDay(dayMidnight: number, startDate: number, endDate: number): boolean {
        return dayMidnight <= startDate && dayMidnight + 86400 >= endDate;
    }

}
