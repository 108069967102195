import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../helpers/globals';
import {map, share} from 'rxjs/operators';

export interface LastActivityDay {
    drivingCurrentDay: number;
    crew: boolean;
    dayStart: number;
    dayEnd: number;
    dayStartHR: string;
    dayEndHR: string;
}

export interface LastActivity {
    activity: string;
    activityLength: number;
    startTime: number;
    startTimeHR: string;
}

export interface PreviousActivity {
    activity: string;
    activityLength: number;
    startTime: number;
}

export interface RestLastDaily {
    restStart: number;
    restEnd: number;
    restLength: number;
}

export interface RestLastWeekly1 {
    start: number;
    end: number;
    length: number;
}

export interface RestLastWeekly2 {
    start: number;
    end: number;
    length: number;
}

export interface RestLastWeekly3 {
    start: number;
    end: number;
    length: number;
}

export interface Projections {
    nextWorkStart: string;
    nextWorkLength: number;
    nextWorkComments: any[];
}

export interface WorkTimes {
    maxWorkWhenNightWorkPerformed: number;
    nightWorkPerformedThisDay: boolean;
    totalWorkToday: number;
    workContinuousWithoutBreak: number;
    workContinuousWithoutBreakMax: number;
    workTimeAverageLast16Weeks: number;
    workTimeWeekly: number;
    workTimeWeeklyMax: number;
    justWorkForToday: number;
}

export interface CalculatedValues {
    baseTime: number;
    baseTimeHR: string;
    projections: Projections;
    workTimes: WorkTimes;
    drivingContinuousRemaining: number;
    drivingContinuousMax: number;
    drivingDailyRemaining: number;
    drivingDailyRemainingCurrentDay: number;
    drivingDailyMAX: string;
    drivingWeeklyRemaining: number;
    drivingWeeklyMax: number;
    drivingTwoWeeklyRemaining: number;
    drivingTwoWeeklyMax: number;
    nextDailyBreakStart: number;
    nextDailyBreakStartHR: string;
    nextDailyBreakStartAsCrew: number;
    nextDailyBreakStartAsCrewHR: string;
    nextWeeklyBreakStart: number;
    nextWeeklyBreakStartHR: string;
    moreThan9hDrivingTimesFromLastWeeklyBreak: number;
    moreThan9hDrivingTimesFromLastWeeklyBreakMax: number;
    lessThan11hBreakTimesFromLastWeeklyBreak: number;
    lessThan11hBreakTimesFromLastWeeklyBreakMax: number;
}

export interface UploadInformation {
    uploadTimeStamp: number;
    uploadTimeStampHR: string;
    minutesSinceUpload: number;
    minutesBetweenLastActivityAndUpload: number;
    minutesSinceLastActivity: number;
}

export interface DriverInfo {
    cardNumber: string;
    driverId: number;
    name: string;
}

export interface Result {
    lastActivityDay: LastActivityDay;
    lastActivity: LastActivity;
    previousActivity: PreviousActivity;
    drivingWeek: number;
    restLastDaily: RestLastDaily;
    drivingLast2Weeks: number;
    restLastWeekly1: RestLastWeekly1;
    restLastWeekly2: RestLastWeekly2;
    restLastWeekly3: RestLastWeekly3;
    calculatedValues: CalculatedValues;
    uploadInformation: UploadInformation;
    message: string;
    driverInfo: DriverInfo;
}

export interface DriverTraceResponse {
    result: Result[];
}

@Injectable({
    providedIn: 'root'
})
export class DrivertraceService {

    constructor(private http: HttpClient) {
    }

    getDriversTrace(companyId: number, timezone: string): Observable<DriverTraceResponse> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId)
            .append('timeZone', timezone);
        return this.http.get<DriverTraceResponse>(API_URL + 'debug/traceCompany',
            {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response;
            }));
    }
}
