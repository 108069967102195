import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { API_URL } from 'app/helpers/globals';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';
import { number } from '@amcharts/amcharts4/core';
import { ProService } from '../pro/pro.service';
import { CompanyService } from '../company/company.service';
import { overrideMomentTimezone } from 'app/moment.override';

export class LoggedInUser {
    id: number;
    clientId: number;
    username: string;
    active: number;
    clientAdmin: boolean;
    hasFiles: boolean;
    hasRDUAccess: boolean;
    loginTime: number;
    proUser: boolean;
    clientIsReseller: boolean;
    trialEndDate: number;
}

interface LoginResponse {
    code: number;
    message: string;
    data: {
        _svt_user: LoggedInUser
    }
}

export interface VerifyEmail {
    message: string;
}

export interface CompanyNameResponse {
    result: string;
    companyName: string;
}

export type UserRoles ={
    AllowedUpdaterClient: boolean,
    ClientIsReseller: boolean,
    ClientWithRemoteUnits: boolean,
}


@Injectable()
export class AuthService {
    private companyIdSource = new BehaviorSubject<number>(-1);
    public currentCompanyId = this.companyIdSource.asObservable();

    private userRoles = new BehaviorSubject<UserRoles>({
        AllowedUpdaterClient: false,
        ClientIsReseller: false,
        ClientWithRemoteUnits: false
    });
    public currentUserRoles$ = this.userRoles.asObservable();

    public currentCompanyIdValue(): number {
        return this.companyIdSource.getValue();
    }

    isLoggedIn = false;

    static storeLoggedInUser(newUser: LoggedInUser) {
        newUser.loginTime = new Date().getTime();
        localStorage.setItem('loggedInUser', JSON.stringify(newUser));
        localStorage.setItem('isPro', newUser.proUser.toString());
    }

    constructor(private http: HttpClient,
        private cookieService: CookieService,
        private router: Router,
        private settingsService: SettingsService,
        private readonly proService: ProService,
        private readonly companyService: CompanyService
    ) {
        // console.log('AuthService constructor');
        this.companyIdSource.next(this.getSelectedCompanyId());
        const id = this.getSelectedCompanyId();
        if(id !== 0) {
            this.companyService.getCompanyFlags(id).subscribe();
            this.settingsService.getLanguageAndTimezoneByClient(id).subscribe(
                res=>{
                    overrideMomentTimezone(res.timezone);
                }
            );
        }
    }

    async exploreNow() {
        const res = await this.http.post(API_URL + 'products/orderProduct', {}).toPromise();
        const url = res['url'];
        window.open(url, '_blank');
    }

    auth(username: string, password: string): Observable<LoginResponse> {
        // const cookieOptions = {domain: '.tachosafe.ro'} as CookieOptions;

        return this.http
            .post<LoginResponse>(API_URL + 'auth/login',
                { password: password, username: username },
                { withCredentials: true })
            .pipe(map(response => {
                if (response.code === 4200) {
                    console.log('Auth resp status ok!',response.data._svt_user.clientId);
                    this.changeCompanyId(
                        response.data._svt_user.clientId);
                    this.settingsService.getLanguageAndTimezoneByClient(response.data._svt_user.clientId).subscribe(
                        res=>{
                            overrideMomentTimezone(res.timezone);
                        }
                    );
                    // console.log('Auth resp status ok!');
                    // console.log(response);
                    this.isLoggedIn = true;
                    AuthService.storeLoggedInUser(response.data._svt_user);
                    // console.log('Get all: ');
                    // console.log(this.cookieService.getAll());
                    // console.log('XSRF-TOKEN: ' + this.cookieService.get('XSRF-TOKEN'));
                    // console.log('svt-a: ' + this.cookieService.get('svt-a'));
                    // console.log('svt-r: ' + this.cookieService.get('svt-r'));
                    // console.log('Response header');
                    // console.log();
                    // console.log();
                    // console.log(response.headers.get('svt-r'));
                    // console.log('Access-Control-Expose-Headers: ');
                    // console.log(response.headers.get('Access-Control-Expose-Headers'));
                    // console.log('Content-Type: ');
                    // console.log(response.headers.get('Content-Type'));
                    // console.log('Keys: ');
                    // console.log(response.headers.keys());
                    // console.log(response.headers.get('XSRF-TOKEN'));
                    // console.log(response.headers.get('svt-a'));
                    // console.log(response.headers.get('svt-r'));
                    // this.cookieService.removeAll();
                    // this.cookieService.put('XSRF-TOKEN', response.headers.get('XSRF-TOKEN'), {domain: '.tachosafe.ro', path: '/'});
                    // this.cookieService.put('svt-a', response.headers.get('svt-a'), {domain: '.tachosafe.ro', path: '/'});
                    // this.cookieService.put('svt-r', response.headers.get('svt-r'), {domain: '.tachosafe.ro', path: '/'});
                }
                // console.log(this.http
                //     .get<any>(API_URL + 'auth/login',
                //         {withCredentials: true})
                //     .subscribe(resp => {
                //         console.log(resp.headers.get('X-Token'));
                //     }));
                return response;
            })
            );
    }

    getDriverFilesData(page: number, sortby: string, order: string, companyId: number, limit: number, filter: string, latest: boolean): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('page', '' + page)
            .append('sortby', '' + sortby)
            .append('order', '' + order)
            .append('clientId', '' + companyId)
            .append('periodStart', '' + 0)
            .append('periodEnd', '' + Math.round(new Date().getTime() / 1000))
            .append('limit', '' + limit)
            .append('cardNumber', '' + filter)
            .append('latest', '' + latest);
        return this.http
            .get<any>(API_URL + 'getFilteredUploadedDriverFilesWithFilter',
                { params: params, withCredentials: true })
            .pipe(map(response => {
                return response;
            }));
    }

    who(): Observable<LoggedInUser> {
        // console.log('-- who --');
        return this.http
            .get<LoginResponse>(API_URL + 'auth/who', { withCredentials: true })
            .pipe(map((response) => {
                // console.log('-- who --');
                
                return response.data._svt_user;
            })
        )
    }

    async asr(): Promise<{ ClientWithRemoteUnits: boolean, PRO: boolean }> {
        // console.log('Stop here: asr');

        if (this.getLoggedInUser() === null) {
            this.router.navigateByUrl('/sessions/signin');
        }

        const response = await this.http
            .post<any>(API_URL + 'auth/asr', {}, { withCredentials: true }).toPromise();
        try{
            if(response.data.error){
                this.router.navigateByUrl('/sessions/signin');
                return null;
            }
        }catch(e){}
        // const isPro = (await this.companyService.getCompanyFlags(this.getSelectedCompanyId()).toPromise()).companyFlags.proClient;
        // this.proService.setPro(false)
        localStorage.setItem('ClientWithRemoteUnits', response['ClientWithRemoteUnits']);
        // console.log('-- asr yes--');
        

        this.userRoles.next({
            AllowedUpdaterClient: response['AllowedUpdaterClient'],
            ClientIsReseller: response['ClientIsReseller'],
            ClientWithRemoteUnits: response['ClientWithRemoteUnits'],
        });

        return {
            ClientWithRemoteUnits: response['ClientWithRemoteUnits'],
            PRO: response['PRO']
        };
    }

    logout() {
        // console.log('Stop here: logout');
        this.isLoggedIn = false;
        return this.http
            .post(API_URL + 'auth/logout', {}, { withCredentials: true })
            .subscribe(response => {
                localStorage.removeItem('loggedInUser');
                localStorage.removeItem('selectedCompanyId');
                this.cookieService.removeAll();
                this.router.navigate(['sessions/signin']);
            });
        // .catch(this.authErrorHandler.handleError);
    }

    getLoggedInUser(): LoggedInUser { // atirva staticrol
        return JSON.parse(localStorage.getItem('loggedInUser'));
    }

    changeCompanyId(companyId: number) {
        localStorage.setItem('selectedCompanyId', '' + companyId);
        this.companyIdSource.next(companyId);
        this.companyService.getCompanyFlags(companyId).subscribe();
        // console.log('changeCompanyId',companyId);
        this.settingsService.getTolerancesByClient(companyId).subscribe();
    }

    getSelectedCompanyId(): number {
        return +localStorage.getItem('selectedCompanyId');
    }

    verifyEmail(hashCode: string): Observable<VerifyEmail> {
        return this.http
            .get<VerifyEmail>(API_URL + 'user/activate/' + hashCode);
    }

    getCompanyName(companyId: string): Observable<CompanyNameResponse> {
        return this.http
            .get<CompanyNameResponse>(API_URL + 'company/getCompanyName/' + companyId, { withCredentials: true });
    }

}
