<div class="driver-attestation-container print">
    <div *ngFor="let driver of driverAttestationService.drivers">
        <div *ngFor="let period of getPeriodsSelectedToPrint(driver)">
            <app-attestation-page [company]="driverAttestationService.company"
                                  [driver]="driver"
                                  [period]="period"
                                  [timezone]="driverAttestationService.timezone"
                                  [profile]="attestationProfile"></app-attestation-page>
        </div>
    </div>
</div>
