<div class="table-container weekly-table-container" [ngClass]="[selectedTab === tabOptions.DRIVER ? 'driver-table' : 'vehicle-table', isPrint ? 'print' : '']">
    <div class="table-margin" [ngClass]="isPrint ? 'weekly-table-print' : ''">
        <table mat-table class="svt-table alternate-rows" [dataSource]="weeklyData.days" [ngClass]="selectedTab === tabOptions.DRIVER ? 'driver-table' : 'vehicle-table'">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.WeeklyTable.Date' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData">
                    {{ _moment(dailyData.recordDate * 1000).tz(timezone).format('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                    {{ _moment(dailyData.recordDate * 1000).tz(timezone).locale(dailyChartService.chartLanguage).format('dddd') }}
                </td>
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="3">
                    <div class="footer-cell-container">
                        <div>
                            {{ ('Report.WeeklyTable.WeeklySummaryFormat' | instantTranslate: dailyChartService.chartLanguage | async)
                                    .replace('#', weeklyData.weeklySummary.numberOfYear.toString())
                                    .replace('%', weeklyData.weeklySummary.numberOfWeek.toString()) }}
                        </div>
                        <div *ngIf="selectedTab === tabOptions.DRIVER && weeklyData.biWeeklySummary.previousYear === weeklyData.biWeeklySummary.actualYear">
                            {{ ('Report.WeeklyTable.Fortnight' | instantTranslate: dailyChartService.chartLanguage | async) + ' ('
                                + ('Report.WeeklyTable.WeekRange' | instantTranslate: dailyChartService.chartLanguage | async)
                                        .replace('#', weeklyData.biWeeklySummary.actualYear.toString())
                                        .replace('%', weeklyData.biWeeklySummary.previousWeek.toString())
                                        .replace('@', weeklyData.biWeeklySummary.actualWeek.toString()) + ')' }}
                        </div>
                        <div *ngIf="selectedTab === tabOptions.DRIVER && weeklyData.biWeeklySummary.previousYear !== weeklyData.biWeeklySummary.actualYear">
                            {{ ('Report.WeeklyTable.Fortnight' | instantTranslate: dailyChartService.chartLanguage | async) + ' ('
                                + ('Report.WeeklyTable.WeekRangeDifferentYear' | instantTranslate: dailyChartService.chartLanguage | async)
                                        .replace('#', weeklyData.biWeeklySummary.previousYear.toString())
                                        .replace('%', weeklyData.biWeeklySummary.previousWeek.toString())
                                        .replace('?', weeklyData.biWeeklySummary.actualYear.toString())
                                        .replace('@', weeklyData.biWeeklySummary.actualWeek.toString()) + ')' }}
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.StartDate' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData" [attr.colspan]="!!dailyData.noDataForToday ? 7 : 1">
                    <div *ngIf="!!dailyData.noDataForToday" class="no-data-row">
                        <mat-icon svgIcon="file_download_off_grey_icon"></mat-icon>
                        <span>{{ (selectedTab === tabOptions.DRIVER ? 'Report.General.NoDownloadedDataForDriver' : 'Report.General.NoDownloadedDataForVehicle') | instantTranslate: dailyChartService.chartLanguage | async }}</span>
                    </div>
                    <div *ngIf="!dailyData.noDataForToday && selectedTab === tabOptions.DRIVER">
                        {{ !!dailyData.startDate ? _moment(dailyData.startDate * 1000).tz(timezone).format('HH:mm') : '-' }}
                    </div>
                    <div *ngIf="!dailyData.noDataForToday && selectedTab === tabOptions.VEHICLE">
                        {{ !!dailyData.startOfDrive ? _moment(dailyData.startOfDrive * 1000).tz(timezone).format('HH:mm') : '-' }}
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="hidden"></td>
            </ng-container>

            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.EndDate' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    <div *ngIf="!dailyData.noDataForToday && selectedTab === tabOptions.DRIVER">
                        {{ !!dailyData.endDate ? _moment(dailyData.endDate * 1000).tz(timezone).format('HH:mm') : '-' }}
                    </div>
                    <div *ngIf="!dailyData.noDataForToday && selectedTab === tabOptions.VEHICLE">
                        {{ !!dailyData.endOfDrive ? _moment(dailyData.endOfDrive * 1000).tz(timezone).format('HH:mm') : '-' }}
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="hidden"></td>
            </ng-container>

            <ng-container matColumnDef="driving">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <mat-icon svgIcon="kormany" class="icon"></mat-icon>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    {{ !!dailyData.drive ? dateUtil.transform(dailyData.drive) : '-' }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div class="footer-cell-container">
                        <div>
                            {{ dateUtil.transform(weeklyData?.weeklySummary?.drive || 0, true, true) }}
                        </div>
                        <div *ngIf="selectedTab === tabOptions.DRIVER">
                            {{ dateUtil.transform(weeklyData?.biWeeklySummary?.drive || 0, true, true) }}
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="working">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <mat-icon svgIcon="work" class="icon"></mat-icon>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    {{ !!dailyData.work ? dateUtil.transform(dailyData.work) : '-' }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div class="footer-cell-container">
                        <div>
                            {{ dateUtil.transform(weeklyData?.weeklySummary?.work || 0, true, true) }}
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="availability">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <mat-icon svgIcon="available" class="icon"></mat-icon>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    {{ !!dailyData.availability ? dateUtil.transform(dailyData.availability) : '-' }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div class="footer-cell-container">
                        <div>
                            {{ dateUtil.transform(weeklyData?.weeklySummary?.availability || 0, true, true) }}
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="allWorksInDay">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <span>&sum;</span>&nbsp;
                        <span>{{ 'Report.WeeklyTable.NumberOfShifts' | instantTranslate: dailyChartService.chartLanguage | async | lowercase }}</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    {{ !!dailyData.workSum ? dateUtil.transform(dailyData.workSum) : '-' }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div class="footer-cell-container">
                        <div>
                            {{ dateUtil.transform(weeklyData?.weeklySummary?.workSum || 0, true, true) }}
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="resting">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <mat-icon svgIcon="bed_new_colored_icon" class="icon"></mat-icon>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
        <!--            {{ !!dailyData.rest ? dailyData.rest : '-' }}-->
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div class="footer-cell-container">
                        <div>
                            3:00
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="presence">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.WeeklyTable.Presence' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
        <!--            {{ !!dailyData.presence ? dailyData.presence : '-' }}-->
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div class="footer-cell-container">
                        <div>
                            15:22
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="kmRange">
                <th mat-header-cell *matHeaderCellDef>km</th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    <div *ngIf="!!dailyData.odometerStart && !!dailyData.odometerEnd">
                        {{ dailyData.odometerStart | number:'':'hu' }}&nbsp;-&nbsp;{{ dailyData.odometerEnd | number:'':'hu' }}
                    </div>
                    <div *ngIf="!dailyData.odometerStart || !dailyData.odometerEnd">-</div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="kmSum">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <span>&sum;</span>&nbsp;
                        <span>km</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    {{ !!dailyData.odometerSum ? (dailyData.odometerSum | number:'':'hu') : !!dailyData.odometerStart && !!dailyData.odometerEnd ? 0 : '-' }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <div>
                        {{ !!weeklyData?.weeklySummary?.odometerSum ? (weeklyData.weeklySummary.odometerSum | number:'':'hu') : '-' }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="avgSpeed">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <mat-icon svgIcon="speed_icon"
                                  class="icon"
                                  style="transform: scale(1.35)"></mat-icon><!-- 18px === scale(1.52) -->
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    {{ !!dailyData.avgSpeed ? (dailyData.avgSpeed | number:'':'hu') : '-' }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="vehicle">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.Vehicle' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    <div *ngIf="dailyData.cars?.length > 0">
                        <span *ngFor="let car of dailyData.cars; let l = last">
                            {{ car + (l ? '' : ', ') }}
                        </span>
                    </div>
                    <div *ngIf="!dailyData.cars || dailyData.cars.length === 0">-</div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="icon-cell">
                        <mat-icon class="icon" style="transform: scale(1.2)">person</mat-icon>
                    </div>
                </th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    <div *ngIf="dailyData.drivers?.length > 0">
                        <span *ngFor="let driverName of dailyData.drivers; let l = last">
                            {{ driverName + (l ? '' : ', ') }}
                        </span>
                    </div>
                    <div *ngIf="!dailyData.drivers || dailyData.drivers.length === 0">-</div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="fault">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.WeeklyTable.Faults' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
                    <div *ngIf="!dailyData.faults || dailyData.faults.length === 0">-</div>
                    <div *ngIf="!!dailyData.faults">
                        <div *ngFor="let fault of dailyData.faults">
                            <span>{{ ('Report.WeeklyTable.FaultCode.' + fault.eventFaultType) | instantTranslate: dailyChartService.chartLanguage | async }}</span>
                            <span>&nbsp;-&nbsp;</span>
                            <span>{{ _moment(fault.eventBeginTime * 1000).tz(timezone).format(isSameDay(dailyData.recordDate, fault.eventBeginTime, fault.eventEndTime) ? 'HH:mm' : ('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async)) }}</span>
                            <span *ngIf="fault.eventBeginTime !== fault.eventEndTime">&nbsp;-&nbsp;</span>
                            <span *ngIf="fault.eventBeginTime !== fault.eventEndTime">{{ _moment(fault.eventEndTime * 1000).tz(timezone).format(isSameDay(dailyData.recordDate, fault.eventBeginTime, fault.eventEndTime) ? 'HH:mm' : ('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async)) }}</span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="other">
                <th mat-header-cell *matHeaderCellDef>{{ 'Report.WeeklyTable.Other' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                <td mat-cell *matCellDef="let dailyData" [ngClass]="{'hidden': !!dailyData.noDataForToday}">
        <!--            {{ !!dailyData.other ? dailyData.other : '-' }}-->
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="selectedTab === tabOptions.DRIVER ? driverDisplayColumns : vehicleDisplayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedTab === tabOptions.DRIVER ? driverDisplayColumns : vehicleDisplayColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="selectedTab === tabOptions.DRIVER ? driverDisplayColumns : vehicleDisplayColumns, sticky: !isPrint" class="footer"></tr>
        </table>
        <div *ngIf="!isFinal()" class="not-downloaded-data">
            {{ 'Report.General.NotFinalData' | instantTranslate:dailyChartService.chartLanguage | async }}
            {{ 'Report.General.LastDownloadDate' | instantTranslate:dailyChartService.chartLanguage | async }}:
            {{ _moment(lastDownloadDate).tz(timezone).format('Report.General.TimeFormat' | instantTranslate:dailyChartService.chartLanguage | async) }}
        </div>
    </div>
</div>
