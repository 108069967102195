import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Content, NotificationsService} from '../../../services/notifications.service';
import moment from 'app/moment.override';
type Moment = typeof moment;
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/auth/auth.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(300)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(300, style({opacity: 0})))
        ])
    ]
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @Input() notificPanel;
    private subscriptions: Subscription;


    @Output() notificlengthUpdate = new EventEmitter<Content[]>();

    notifications = [] as Content[];
    dataexists = false;

    clientID: number;

    isDisabled = false;
    isOpen = false;

    markAsRead = this.translate.instant('Marked as Read');
    markAsUnread = this.translate.instant('Marked as Unread');

    constructor(private router: Router,
                private notificationservice: NotificationsService,
                private translate: TranslateService,
                private _authService: AuthService) {
        this.subscriptions = new Subscription();
    }

    ngOnInit() {
        this.getClientId();
        setTimeout(() => {
            this.isDisabled = true;
            this.updateNotificLength();
        }, 2000);
        setInterval(() => {
            this.getClientId();
            setTimeout(() => {
                this.isDisabled = true;
                this.updateNotificLength();
            }, 2000);
        }, 60000);
        this.closePanel();
    }

    changeSeen(id, i, resolved) {
        this.isDisabled = true;
        if (resolved === false) {
            const subscription = this.notificationservice.changeNotificationSeen(id, this.clientID).subscribe(response => {
                if (response.notifications) {
                    this.notifications[i].resolved = true;
                } else {
                    return;
                }
            });
            this.subscriptions.add(subscription);
        }
    }

    deleteNotification(id, i, array) {
        this.isDisabled = false;
        const subscription = this.notificationservice.deleteNotification(id, this.clientID).subscribe(response => {
            if (response.notifications) {
                array.splice(i, 1);
            } else {
                return;
            }
        });
        this.subscriptions.add(subscription);
    }

    changeAllNotification() {
        this.isDisabled = true;
        const subscription = this.notificationservice.changeAllNotificationSeen(this.clientID).subscribe(response => {
            for (let i = 0; i < this.notifications.length; i++) {
                this.notifications[i].resolved = true;
            }
        });
        this.subscriptions.add(subscription);
    }

    updateNotificLength() {
        this.notificlengthUpdate.emit(this.notifications.filter(n => n.resolved === false));
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    getClientId() {
        const subscription = this._authService.currentCompanyId.subscribe((newCompanyId) => {
            if (newCompanyId !== 0) {
                this.clientID = newCompanyId;
                // this.getNotifications(newCompanyId);
            }
        });
        this.subscriptions.add(subscription);
    }

    getNotifications(companyId) {
        this.isDisabled = true;
        const subscription = this.notificationservice.getNotifications(companyId).subscribe(response => {
            if (response.notifications) {
                this.notifications = response.notifications.content;
                this.dataexistsFuntion();
            } else {
                this.notifications = [];
                this.dataexistsFuntion();
            }
        }, err => {
            if (err.status) {
                this.notifications = [];
                console.log('Whoops something went wrong with the request!');
                this.dataexistsFuntion();
                return;
            }
        });
        this.subscriptions.add(subscription);
    }

    closePanel() {
        const subscription = this.router.events.subscribe((routeChange) => {
            if (routeChange instanceof NavigationEnd) {
                this.notificPanel.close();
            }
        });
        this.subscriptions.add(subscription);
    }

    dataexistsFuntion() {
        this.dataexists = true;
    }

    closeNotifications() {
        this.notificPanel.close();
        this.updateNotificLength();
    }

    formatDate(ts) {
        if (ts === 0 || ts === '' || moment(ts * 1000) >= moment([])) {
            return '';
        } else {
            return moment.parseZone(ts * 1000).myFormat('LLL');
        }
    }

    ifValueNull(value) {
        if (value) {
            return value;
        } else {
            return '---';
        }
    }

    formatEvents(v) {
        if (v === 'Motion data error') {
            return this.translate.instant('Motion data error');
        } else if (v === 'Card insertion while driving') {
            return this.translate.instant('Card insertion while driving');
        } else if (v === 'Downloading fault') {
            return this.translate.instant('Downloading fault');
        } else if (v === 'Time overlap') {
            return this.translate.instant('Time overlap');
        } else {
            return v;
        }
    }
}
