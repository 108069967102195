<div>
    <div class="flex flex-col w-full h-full">
        <div *ngIf="(pro$ | async).showBanner"
            class="w-full bg-[#fec15c] text-slate-800 flex items-center justify-center">
            <div class="py-1 w-full flex flex-row items-center justify-center gap-4">
                <div class="text-lg">{{'subscription.appBar.trialPeriod'|translate}} <span class="">{{trialEndDate()}}</span>.
                </div>
                <div class="text-lg"><a href="/#/subscription"><span
                            class="text-white bg-tachosafe-500 px-2 py-1 rounded-md cursor-pointer">
                            {{'subscription.appBar.Subscribe'|translate}}</span></a></div>
            </div>
        </div>
        <div class="topbar flex flex-row justify-between w-full px-2" [style.background-color]="getStyle()" >
            <div class="flex flex-row items-center">
                <div class="flex flex-row items-center gap-4">
                    <button *ngIf="isMobile" class="topbar-hamburger" (click)="changeMenuSetting()" mat-icon-button
                        id="sidenavToggle" matTooltip="{{ 'Open/Close Menu' | translate }}"
                        [ngStyle]="{'display': true ? 'inline-block' : 'none'}">
                        <mat-icon style="font-size: 25px;">menu</mat-icon>
                    </button>
                    <app-branding class="topbar-branding"></app-branding>
                    <div class="h-4 border-l-1 border-white border bg-white flex"></div>
                    <div class="text-white text-xl font-bold">
                        <span *ngIf="(currentUserRoles$|async).ClientIsReseller && companyIdIsTheSameAsLoggedInUser">{{'topBar.userType.Reseller'|translate}}</span>
                        <span *ngIf="!((currentUserRoles$|async).ClientIsReseller && companyIdIsTheSameAsLoggedInUser) && (pro$ | async).pro==='REQUESTED'">{{'topBar.userType.Pro'|translate}}</span>
                        <span *ngIf="!((currentUserRoles$|async).ClientIsReseller && companyIdIsTheSameAsLoggedInUser) && (pro$ | async).pro!=='REQUESTED'">{{'topBar.userType.Basic'|translate}}</span>
                    </div>
                </div>

            </div>
            <!-- <div *ngIf="(pro$ | async).pro !== 'REQUESTED'" class="text-white bg-tachosafe-700 text-center px-2 py-1 rounded-md">
        <span class="text-lg">{{'subscription.appBar.trialPeriod'|translate}} <span class="text-red-500">2023.01.01</span>. </span>
        <span class="text-lg"><a href="/#/subscription"><span class="text-blue-500 underline cursor-pointer"> {{'subscription.appBar.Subscribe'|translate}}</span></a></span>
    </div> -->

            <!-- <div fxFlex style="text-align: left;">
        <form style="font-size: 15px;">
            <mat-form-field style="width: 100%; margin-top: 15px">
                <mat-label>
                    {{ 'Company name' | translate }}
                </mat-label>
                <mat-select class="md:w-1/2" required disableOptionCentering panelClass="entitySelectorPanel"
                    (selectionChange)="setCompany()" [formControl]="queryDriverFormControl"
                    [disabled]="driverDataIsLoading">
                    <mat-option (click)="setCompany()" (keyup.enter)="setCompany()"
                        style="position: sticky; top: 0px; width: 100%; height: 42px; z-index: 1; background-color: white; border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
                        <ngx-mat-select-search placeholderLabel="{{ 'Company name' | translate }}"
                            noEntriesFoundLabel="{{ 'Report.General.NoEntries' | translate }}"
                            [formControl]="queryDriverFilterFormControl">
                        </ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let company of filterCompany | async" (click)="setCompany()"
                        (keyup.enter)="setCompany()" [value]="company">
                        {{ company.name }}
                    </mat-option>
                </mat-select>
                <mat-spinner *ngIf="driverDataIsLoading" diameter="30" matSuffix class="selectorLoader">
                </mat-spinner>
                               <input-->
            <!--                    matInput-->
            <!--                    type="text"-->
            <!--                    placeholder="{{currentCompanyName}}"-->
            <!--                    name="companyFilterInput"-->
            <!--                    [(ngModel)]="autocompleteFilter"-->
            <!--                    [matAutocomplete]="auto"-->
            <!--                    (click)="clearSearch()">-->
            <!--                <mat-autocomplete #auto="matAutocomplete"-->
            <!--                                  [displayWith]="displayFn"-->
            <!--                                  (optionSelected)="setCompany($event)">-->
            <!--                    <perfect-scrollbar>-->
            <!--                        <mat-option *ngFor="let company of availableCompanies | companyFilter:autocompleteFilter"-->
            <!--                                    [value]="company">-->
            <!--                            {{ company.name }}-->
            <!--                        </mat-option>-->
            <!--                    </perfect-scrollbar>-->
            <!--                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div> -->

            <div class="flex flex-row items-center justify-center gap-6">
                <div class="flex">
                    <p-dropdown [attr.size]="100" [virtualScroll]="true" itemSize="30" [options]="availableCompanies"
                        (onChange)="setCompany($event)" [showClear]="false" [ngModel]="selectedCompany"
                        optionLabel="name" [filter]="true" filterBy="name" [showClear]="true"
                        placeholder="Select a Company">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2 w-64" *ngIf="selectedCompany">
                                <div>{{selectedCompany.name}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-company pTemplate="item">
                            <div class="flex align-items-center gap-2 w-64">
                                <div>{{ company.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div *ngIf="profile === 0">
                    <mat-icon class="topbar-icon" style=" cursor: pointer" [matMenuTriggerFor]="menuAddMore"
                        #menuTrigger="matMenuTrigger" matTooltip="{{'More' | translate}}">
                        more_vert
                    </mat-icon>
                </div>

                <div *ngIf="profile === 0">
                    <a href="https://tachosafe.ro/en/support/#Helpdesk" target="_blank">
                        <mat-icon class="topbar-icon" style=" cursor: pointer"
                            matTooltip="{{'Contact support' | translate}}">
                            contact_support
                        </mat-icon>
                    </a>
                </div>

                <div *ngIf="profile === 0">
                    <mat-icon class="topbar-icon" style=" cursor: pointer" [matMenuTriggerFor]="menuAddApps"
                        #menuTrigger="matMenuTrigger" matTooltip="{{'Apps' | translate}}">
                        phone_iphone
                    </mat-icon>
                </div>

                <div *ngIf="profile === 0">
                    <mat-icon class="topbar-icon" style=" cursor: pointer" [matMenuTriggerFor]="menuAddSettings"
                        #menuTrigger="matMenuTrigger" matTooltip="{{'Settings.Text' | translate}}">
                        settings
                    </mat-icon>
                </div>
                <!-- <mat-icon [matMenuTriggerFor]="menuAddLangSelect" #menuTrigger="matMenuTrigger" class="mr-4 -mt-1" style="transform: scale(1.4);" svgIcon="{{'HU'}}"></mat-icon> -->
                <div class="cursor-pointer opacity-80" [matMenuTriggerFor]="menuAddLangSelect"
                    #menuTrigger="matMenuTrigger">
                    <div *ngIf="selectedValue=='EN'"><mat-icon style="transform: scale(1.4);" svgIcon="EN"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='HU'"><mat-icon style="transform: scale(1.4);" svgIcon="HU"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='RO'"><mat-icon style="transform: scale(1.4);" svgIcon="RO"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='DE'"><mat-icon class="text-black" style="transform: scale(1.4);"
                            svgIcon="DE"></mat-icon></div>
                    <div *ngIf="selectedValue=='NL'"><mat-icon style="transform: scale(1.4);" svgIcon="NL"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='FR'"><mat-icon style="transform: scale(1.4);" svgIcon="FR"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='DA'"><mat-icon style="transform: scale(1.4);" svgIcon="DA"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='ES'"><mat-icon style="transform: scale(1.4);" svgIcon="ES"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='SL'"><mat-icon style="transform: scale(1.4);" svgIcon="SL"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='IT'"><mat-icon style="transform: scale(1.4);" svgIcon="IT"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='SK'"><mat-icon style="transform: scale(1.4);" svgIcon="SK"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='BG'"><mat-icon style="transform: scale(1.4);" svgIcon="BG"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='PT'"><mat-icon style="transform: scale(1.4);" svgIcon="PT"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='CS'"><mat-icon style="transform: scale(1.4);" svgIcon="CS"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='SV'"><mat-icon style="transform: scale(1.4);" svgIcon="SV"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='HR'"><mat-icon style="transform: scale(1.4);" svgIcon="HR"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='GR'"><mat-icon style=" transform: scale(1.4);" svgIcon="GR"></mat-icon>
                    </div>
                    <div *ngIf="selectedValue=='PL'"><mat-icon style=" transform: scale(1.4);" svgIcon="PL"></mat-icon>
                    </div>
                </div>

                <div style="">
                    <span [matMenuTriggerFor]="menuAddProfile" matTooltipPosition="before" matTooltipShowDelay="50"
                        matTooltip="{{'Logged in as' | translate}} {{loggedInUser | uppercase}}">
                        <mat-icon svgIcon="usericon" style="width:36px; height: 36px; cursor: pointer;"
                            class="icons"></mat-icon>
                        <!-- <mat-icon class="menu-icon absolute top-0 right-0" *ngIf="(pro$|async).pro !== 'NOT_PRO'" style="width: 39px; height: 29px;"
                    svgIcon="insignia" matTooltip="PRO">
                </mat-icon> -->
                    </span>
                </div>

            </div>
            <mat-menu #menuAddMore="matMenu" xPosition="before">
                <span *ngIf="hasRDUAccess">
                    <a href="https://tacho4safe.com/rduadmin" target="_blank">
                        <div class="flex flex-row items-center gap-2 p-4 hover:bg-tachosafe-100">
                            <img class="w-8 h-8" src="assets/rduadmin.ico"
                                matTooltip="{{'Remote Units Administration' | translate}}">
                            <span class="text-xl">
                                {{'RDU Admin'| translate}}
                            </span>
                        </div>
                    </a>
                </span>
                <span>
                    <a href="https://tachosafe.ro/tacho2safeupdater/#/freedevices" target="_blank">
                        <div class="flex flex-row items-center gap-2 p-4 hover:bg-tachosafe-100">
                            <img class="w-8 h-8" src="assets/rduadmin.ico"
                                matTooltip="{{'Update Tacho2Safe devices' | translate}}">
                            <span class="text-xl">
                                {{'T2S Updater' | translate}}
                            </span>
                        </div>
                    </a>
                </span>
                <span>
                    <a href="https://tachosafe.info" target="_blank">
                        <div class="flex flex-row items-center gap-2 p-4 hover:bg-tachosafe-100">
                            <img class="w-8 h-8" src="assets/newLogoOnly.png"
                                matTooltip="{{'Tachosafe Landing page' | translate}}">
                            <span class="text-xl">
                                {{'TachoSafe site' | translate}}
                            </span>
                        </div>
                    </a>
                </span>
                <span>
                    <a href="https://tachosafe.info/wp-content/uploads/sites/8/2023/11/New-site-vs-old-site.png" target="_blank">
                        <div class="flex flex-row items-center gap-2 p-4 hover:bg-tachosafe-100">
                            <img class="w-8 h-8" src="assets/newLogoOnly.png"
                                matTooltip="{{'Changes in structure' | translate}}">
                            <span class="text-xl">
                                {{'Changes in structure' | translate}}
                            </span>
                        </div>
                    </a>
                </span>
            </mat-menu>

            <mat-menu #menuAddApps="matMenu" xPosition="before">
                <div class="flex flex-col w-full p-4">
                    <div class="flex flex-row w-full items-center text-xl gap-2">
                        <img class="w-10 h-10" src="assets/ts.webp" alt="">
                        {{'Tacho5Safe App' | translate}}
                    </div>
                    <div class="pl-2 flex-col w-full items-center justify-center">
                        <a class="flex items-center justify-center"
                            href='https://play.google.com/store/apps/details?id=com.svt.tachosafe.tacho5afe'
                            target="_blank"><img class="w-48 px-3" alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                        </a>
                        <a class="flex items-center justify-center"
                            href="https://apps.apple.com/tz/app/tachosafe/id1635288503" target="_blank">
                            <img class="w-48"
                                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                alt="Download from the App Store" style="height:50px;">
                        </a>
                        <a class="flex items-center justify-center" href="https://appgallery.huawei.com/app/C107376609"
                            target="_blank">
                            <img class="mt-2" [style]="{width: '150px'}"
                                src="https://hellopaisa.co.za/hellopaisa-2021/wp-content/uploads/2021/06/huawei-Badge-Black-300x90.png"
                                alt="Download from the App Store" style="height:50px;">
                        </a>
                    </div>
                </div>
                <div class="flex flex-col w-full p-4">
                    <div class="flex flex-row w-full items-center text-xl gap-2">
                        <img class="w-10 h-10" src="assets/Cl_App.webp" alt="">
                        {{'Vehicle Check App' | translate}}
                    </div>
                    <div class="pl-2 flex-col w-full items-center justify-center">
                        <a class="flex items-center justify-center"
                            href='https://play.google.com/store/apps/details?id=com.svt.tachosafe.checker'
                            target="_blank"><img class="w-48 px-3" alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                        </a>
                        <a class="flex items-center justify-center"
                            href="https://apps.apple.com/za/app/tachosafe-vehicle-cl/id1640250236" target="_blank">
                            <img class="w-48"
                                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                alt="Download from the App Store" style="height:50px;">
                        </a>
                        <a class="flex items-center justify-center" href="" target="_blank">
                            <img class="mt-2" [style]="{width: '150px'}"
                                src="https://hellopaisa.co.za/hellopaisa-2021/wp-content/uploads/2021/06/huawei-Badge-Black-300x90.png"
                                alt="Download from the App Store" style="height:50px;">
                        </a>
                    </div>
                </div>
            </mat-menu>

            <mat-menu #menuAddLangSelect="matMenu" xPosition="before">
                <div class="text-black flex flex-col gap-4 h-64">
                    <div (click)="changeLang('EN')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="EN"></mat-icon>{{'English' | translate}}
                    </div>
                    <div (click)="changeLang('HU')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="HU"></mat-icon>{{'Hungarian' |translate}}
                    </div>
                    <div (click)="changeLang('RO')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="RO"></mat-icon>{{'Romanian' | translate}}
                    </div>
                    <div (click)="changeLang('DE')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="DE"></mat-icon>{{'German' | translate}}
                    </div>
                    <div (click)="changeLang('NL')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="NL"></mat-icon>{{'Dutch' | translate}}
                    </div>
                    <div (click)="changeLang('FR')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="FR"></mat-icon>{{'French' | translate}}
                    </div>
                    <div (click)="changeLang('DA')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="DA"></mat-icon>{{'Danish' | translate}}
                    </div>
                    <div (click)="changeLang('ES')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="ES"></mat-icon>{{'Spanish' | translate}}
                    </div>
                    <div (click)="changeLang('SL')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="SL"></mat-icon>{{'Slovenian' |translate}}
                    </div>
                    <div (click)="changeLang('IT')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="IT"></mat-icon>{{'Italian' | translate}}
                    </div>
                    <div (click)="changeLang('SK')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="SK"></mat-icon>{{'Slovak' | translate}}
                    </div>
                    <div (click)="changeLang('BG')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="BG"></mat-icon>{{'Bulgarian' |translate}}
                    </div>
                    <div (click)="changeLang('PT')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="PT"></mat-icon>{{'Portuguese' |translate}}
                    </div>
                    <div (click)="changeLang('CS')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="CS"></mat-icon>{{'Czech' | translate}}
                    </div>
                    <div (click)="changeLang('SV')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="SV"></mat-icon>{{'Svenska' | translate}}
                    </div>
                    <div (click)="changeLang('HR')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="HR"></mat-icon>{{'Croatian' | translate}}
                    </div>
                    <div (click)="changeLang('GR')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="GR"></mat-icon>{{'Greek' | translate}}
                    </div>
                    <div (click)="changeLang('PL')"
                        class="flex flex-row items-center justify-start w-full p-2 gap-2 hover:bg-tachosafe-100 cursor-pointer">
                        <mat-icon svgIcon="PL"></mat-icon>{{'Polish' | translate}}
                    </div>
                </div>
            </mat-menu>

            <mat-menu #menuAddSettings="matMenu" xPosition="before">
                <div class="w-full flex flex-col gap-2 text-lg">
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer"
                        (click)="navigate('/settings/clientSettings')">
                        {{'Company settings'|translate}}
                    </div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer pl-6"
                        (click)="navigate('/settings/clientSettings')">
                        {{'Night shift settings'|translate}}</div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer pl-6"
                        (click)="navigate('/settings/clientSettings')">
                        {{'Download period settings'|translate}}</div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer"
                        (click)="navigate('/settings/clientSettings')">
                        {{'Report settings'|translate}}
                        <div class="text-slate-400 cursor-pointer pl-4 text-sm"
                            (click)="navigate('/settings/clientSettings')">
                            ({{'language'|translate}}/{{'timezone'|translate}}/{{'delimiter'|translate}})
                        </div>
                    </div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer"
                        (click)="navigate('/settings/clientSettings','fileFormat')">{{'File format settings'|translate}}
                    </div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer" (click)="navigate('/settings/createuser')">
                        {{'Create new user'|translate}}</div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer"
                        (click)="navigate('/settings/registerSubCompany')">
                        {{'Create subcompany'|translate}}</div>
                </div>
            </mat-menu>

            <mat-menu #menuAddProfile="matMenu" xPosition="before">
                <div class="w-full flex flex-col gap-2 text-lg">
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer"
                        (click)="navigate('/settings/changepassword')">
                        {{'Change password'|translate}}</div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer"
                        (click)="navigate('/settings/changecredentials')">{{'Change credentials'|translate}}</div>
                    <div class="px-4 hover:bg-tachosafe-100 cursor-pointer" (click)="navigate('/logout')">{{'Logout'
                        |translate}}</div>
                </div>
            </mat-menu>

            <mat-menu #menuAdd="matMenu" xPosition="before">
                <span>
                    <a href="https://tacho4safe.com/rduadmin" target="_blank" *ngIf="hasRDUAccess">
                        <button mat-menu-item style="width: 200px; display: flex; align-items: center;">
                            <img style="width: 22px; height: 22px; margin-right: 16px" src="assets/rduadmin.ico"
                                class="icons" matTooltip="{{'Remote Units Administration' | translate}}">
                            <span style="margin-left: 5% !important;">
                                {{'RDU Admin' | translate}}
                            </span>
                        </button>
                    </a>
                </span>
                <span>
                    <a href="https://tacho4safe.com/#/app" target="_blank">
                        <button mat-menu-item style="width: 200px; display: flex; align-items: center;">
                            <img style="width: 22px; height: 22px; margin-right: 16px"
                                src="assets/tacho5safeAppIcon.png" class="icons"
                                matTooltip="{{'Tacho5Safe'| translate}}">
                            <span style="margin-left: 5% !important;">
                                {{'Tacho5Safe' | translate}}
                            </span>
                        </button>
                    </a>
                </span>
                <span>
                    <a href="https://tacho4safe.statuspage.io/" target="_blank">
                        <button mat-menu-item style="width: 200px; display: flex; align-items: center;">
                            <img style="width: 22px; height: 22px; margin-right: 16px" src="assets/statuspage.svg"
                                class="icons">
                            <span style="margin-left: 5% !important;">
                                {{'Statuspage' | translate}}
                            </span>
                        </button>
                    </a>
                </span>
            </mat-menu>

            <mat-menu #menu="matMenu" class="nemtom">
                <div style="width: 60%;margin-left: 14%;margin-bottom: 0.5rem;">
                    <mat-select id="topbarselect" [(value)]="selectedValue"
                        placeholder="{{currentLangName | translate}}" (selectionChange)="changeLang(selectedValue)"
                        style="margin-bottom: 0; margin-top: -20px;" class="smallFont">
                        <perfect-scrollbar>
                            <mat-option value="EN"><span><mat-icon svgIcon="EN"></mat-icon></span>{{'English' |
                                translate}}
                            </mat-option>
                            <mat-option value="HU"><span><mat-icon svgIcon="HU"></mat-icon></span>{{'Hungarian' |
                                translate}}
                            </mat-option>
                            <mat-option value="RO"><span><mat-icon svgIcon="RO"></mat-icon></span>{{'Romanian' |
                                translate}}
                            </mat-option>
                            <mat-option value="DE"><span><mat-icon svgIcon="DE"></mat-icon></span>{{'German' |
                                translate}}
                            </mat-option>
                            <mat-option value="NL"><span><mat-icon svgIcon="NL"></mat-icon></span>{{'Dutch' |
                                translate}}
                            </mat-option>
                            <mat-option value="FR"><span><mat-icon svgIcon="FR"></mat-icon></span>{{'French' |
                                translate}}
                            </mat-option>
                            <mat-option value="DA"><span><mat-icon svgIcon="DA"></mat-icon></span>{{'Danish' |
                                translate}}
                            </mat-option>
                            <mat-option value="ES"><span><mat-icon svgIcon="ES"></mat-icon></span>{{'Spanish' |
                                translate}}
                            </mat-option>
                            <mat-option value="SL"><span><mat-icon svgIcon="SL"></mat-icon></span>{{'Slovenian' |
                                translate}}
                            </mat-option>
                            <mat-option value="IT"><span><mat-icon svgIcon="IT"></mat-icon></span>{{'Italian' |
                                translate}}
                            </mat-option>
                            <mat-option value="SK"><span><mat-icon svgIcon="SK"></mat-icon></span>{{'Slovak' |
                                translate}}
                            </mat-option>
                            <mat-option value="BG"><span><mat-icon svgIcon="BG"></mat-icon></span>{{'Bulgarian' |
                                translate}}
                            </mat-option>
                            <mat-option value="PT"><span><mat-icon svgIcon="PT"></mat-icon></span>{{'Portuguese' |
                                translate}}
                            </mat-option>
                            <mat-option value="CS"><span><mat-icon svgIcon="CS"></mat-icon></span>{{'Czech' |
                                translate}}
                            </mat-option>
                            <mat-option value="SV"><span><mat-icon svgIcon="SV"></mat-icon></span>{{'Svenska' |
                                translate}}
                            </mat-option>
                            <mat-option value="HR"><span><mat-icon svgIcon="HR"></mat-icon></span>{{'Croatian' |
                                translate}}
                            </mat-option>
                            <mat-option value="GR"><span><mat-icon svgIcon="GR"></mat-icon></span>{{'Greek' |
                                translate}}
                            </mat-option>
                            <mat-option value="PL"><span><mat-icon svgIcon="PL"></mat-icon></span>{{'Polish' |
                                translate}}
                            </mat-option>
                        </perfect-scrollbar>
                    </mat-select>
                </div>
                <!--        <a routerLink="/settings/driverspanel">-->
                <!--            <button mat-menu-item>-->
                <!--                <mat-icon>settings</mat-icon>-->
                <!--                <span style="margin-left: -10% !important;">{{'Settings.Title' | translate}}</span>-->
                <!--            </button>-->
                <!--        </a>-->

                <a routerLink="/logout">
                    <button mat-menu-item style="width: 160px">
                        <mat-icon>exit_to_app</mat-icon>
                        <span style="margin-left: 5% !important;">{{'Logout' | translate}}</span>
                    </button>
                </a>
            </mat-menu>

            <!-- <div style="height: 20px;width: 20px; margin-right: 1.5rem; margin-left: 0.7rem;">
        <a routerLink="/devices" (click)="sidenav.close()">
            <mat-icon style="width: 35px; height: 35px; margin-top: -0.6rem;" svgIcon="infosdevice_icon"
                matTooltip="{{'Devices' | translate}}" class="icons"></mat-icon>
        </a>
    </div> -->

            <!--    <a  target="_blank"  *ngIf="isAdmin">-->
            <!--        <button mat-menu-item>-->
            <!--            <img style="width: 22px; height: 22px; margin-right: 16px" src="assets/rduadmin.ico"-->
            <!--                 class="icons" matTooltip="{{'Remote Units Administration' | translate}}">-->
            <!--            <span style="margin-left: 5% !important;">-->
            <!--                    {{'RDU Admin' | translate}}-->
            <!--                </span>-->
            <!--        </button>-->
            <!--    </a>-->


            <!--    <div style="height: 20px;width: 20px; margin-right: 1.5rem; margin-left: 0.7rem;">-->
            <!--        <a href="https://tachosafe.ro/rduadmin" target="_blank">-->
            <!--            <img style="width: 35px; height: 35px; margin-top: -0.6rem;" src="assets/rduadmin.ico"-->
            <!--                      matTooltip="{{'Remote Units Administration' | translate}}" class="icons">-->
            <!--        </a>-->
            <!--    </div>-->

            <!--        <button-->
            <!--            mat-icon-button-->
            <!--            style="margin-left: 0 !important;"-->
            <!--            matTooltip="{{'Notifications' | translate}}"-->
            <!--            (click)="toggleNotific()"-->
            <!--            [style.overflow]="'visible'"-->
            <!--            [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}"-->
            <!--            class="topbar-button-right">-->
            <!--            <mat-icon class="icons">notifications_active</mat-icon>-->
            <!--            <span-->
            <!--                class="notification-number mat-bg-warn"-->
            <!--                *ngIf="notifications.length !== 0 || !notifications"-->
            <!--                style="height: 20px;width: 20px;">-->
            <!--                <span *ngIf="notifications.length > 0 && notifications"-->
            <!--                      style="font-size: 11px;">{{notifications.length}}</span>-->
            <!--                &lt;!&ndash;            <span *ngIf="notifications.length === 0 && notifications" style="font-size: 11px;">0</span>&ndash;&gt;-->
            <!--            </span>-->
            <!--        </button>-->
        </div>
    </div>
</div>