import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_URL } from 'app/helpers/globals';
import { map, share, take } from 'rxjs/operators';
import {
    ActivationResponse,
    AmazonSettingsResponse,
    APIResponse,
    CdpTdp,
    ChangePwResponse, CompanyReportSettings, CompanyTolerances,
    ConnectionServiceResponse,
    CreateUserResponse,
    EnableSettingResponse, FieldSeparator, FieldSeparatorResponse,
    ForwardedSettingsResponse, GetCredentialsResponse,
    LastDriverFilesForClientResponse,
    LastVehicleFilesForClient,
    NewSettingsResponse,
    Predefined,
    PredefinedResponse, ReportLanguage,
    ServiceListResponse,
    SettingsResponse,
    TdpCdpResponse, TestConnectionServiceResponse, TimeZone, UpdateConnectionServiceResponse
} from './settings.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    tolerances = new BehaviorSubject<CompanyTolerances>(null);

    constructor(private http: HttpClient) {}

    changePassword(userId: number, oldPassword: string, newPassword: string): Observable<ChangePwResponse> {
        return this.http
            .post<ChangePwResponse>(API_URL + 'user/changePassword', {
                userId: userId,
                oldPassword: oldPassword,
                newPassword: newPassword
            }, {withCredentials: true});
    }

    createUser(uname: string,
               pw: string,
               re_pw: string,
               u_email: string,
               u_phone: string,
               clientId: number,
               clientAdmin: boolean): Observable<CreateUserResponse> {
        return this.http
            .post<CreateUserResponse>(API_URL + 'user/new', {
                uname: uname,
                pw: pw,
                re_pw: re_pw,
                u_email: u_email,
                u_phone: u_phone,
                clientId: clientId,
                clientAdmin: clientAdmin
            }, {withCredentials: true});
    }

    activateNewUser(token: string): Observable<ActivationResponse> {
        return this.http.get<ActivationResponse>(API_URL + 'user/activate/' + token);
    }

    getForwardedSettings(companyId: number): Observable<ForwardedSettingsResponse[]> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http.get<ForwardedSettingsResponse[]>(API_URL + 'settings/forwarding/company/all',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    getServiceList(companyId: number): Observable<ServiceListResponse[]> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http.get<ServiceListResponse[]>(API_URL + 'settings/services/list',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    newSettings(clientId: number,
                serviceId: number,
                servicename: string,
                url: string,
                uname: string,
                pw: string,
                param1: string,
                param2: string,
                param3: string): Observable<NewSettingsResponse> {
        return this.http
            .post<NewSettingsResponse>(API_URL + 'settings/forwarding/newSetting', {
                clientId: clientId,
                serviceId: serviceId,
                postingClient: clientId,
                servicename: servicename,
                url: url,
                uname: uname,
                pw: pw,
                param1: param1,
                param2: param2,
                param3: param3
            }, {withCredentials: true})
    }

    getAllAmazonS3Service (clientId: number): Observable<AmazonSettingsResponse> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId);
        return this.http
            .get<AmazonSettingsResponse>(API_URL + 'amazon-s3/get-all-amazon-s3-services',
                {params: params, withCredentials: true})
            .pipe(map(response => {
                return response;
            }));
    }

    getAllConnectionService(clientId: number): Observable<ConnectionServiceResponse> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId);
        return this.http
            .get<ConnectionServiceResponse>(API_URL + 'connected-service/get-all',
                {params: params, withCredentials: true})
            .pipe(map(response => {
                return response;
            }));
    }

    enableSetting(settingId: number, clientId: number): Observable<EnableSettingResponse> {
        const params: HttpParams = new HttpParams()
            .append('settingId', '' + settingId)
            .append('clientId', '' + clientId);

        return this.http
            .get<EnableSettingResponse>(API_URL + 'settings/forwarding/enable',
                {params: params, withCredentials: true})
            .pipe(map(response => {
                return response;
            }));
    }

    disableSetting(settingId: number, clientId: number): Observable<EnableSettingResponse> {
        const params: HttpParams = new HttpParams()
            .append('settingId', '' + settingId)
            .append('clientId', '' + clientId);

        return this.http
            .get<EnableSettingResponse>(API_URL + 'settings/forwarding/disable',
                {params: params, withCredentials: true})
            .pipe(map(response => {
                return response;
            }));


    }

    getServiceHistory(serviceId: number, clientId: number): Observable<ForwardedSettingsResponse[]> {
        const params: HttpParams = new HttpParams()
            .append('serviceId', '' + serviceId)
            .append('clientId', '' + clientId);

        return this.http
            .get<ForwardedSettingsResponse[]>(API_URL + 'settings/forwarding/history',
                {params: params, withCredentials: true})
            .pipe(map(response => {
                return response;
            }));
    }

    removeServiceById(serviceId: number): Observable<ForwardedSettingsResponse[]> {
        const params: HttpParams = new HttpParams()
            .append('serviceId', '' + serviceId);

        return this.http
            .get<ForwardedSettingsResponse[]>(API_URL + 'settings/forwarding/remove',
                {params: params, withCredentials: true})
            .pipe(map(response => {
                return response;
            }));
    }

    getSettings(clientId: number): Observable<SettingsResponse> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId);

        return this.http.get<SettingsResponse>(API_URL + 'company/settings',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    sendSettings(clientId: number,
                 nightStart: number,
                 nightDuration: number,
                 card_format: string,
                 vehicle_format: string): Observable<NewSettingsResponse> {
        return this.http
            .post<NewSettingsResponse>(API_URL + 'company/settings', {
                clientId: clientId,
                nightStart: nightStart,
                nightDuration: nightDuration,
                card_format: card_format,
                vehicle_format: vehicle_format
            }, {withCredentials: true});
    }

    getPredefinedFormatDrivers(clientId: number): Observable<Predefined[]> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId);

        return this.http.get<PredefinedResponse>(API_URL + 'settings/filename/card',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response.result));
    }

    getPredefinedFormatVehicles(clientId: number): Observable<Predefined[]> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId);

        return this.http.get<PredefinedResponse>(API_URL + 'settings/filename/vehicle',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response.result));
    }

    sendCustomFormat(name: string,
                     customformat: string,
                     clientId: number,
                     type: string): Observable<NewSettingsResponse> {
        return this.http
            .post<NewSettingsResponse>(API_URL + 'settings/filename/new', {
                formatName: name,
                format: customformat,
                ownerClient: clientId,
                type: type
            }, {withCredentials: true});
    }

    getTdpCdp(clientId: number): Observable<CdpTdp> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId);

        return this.http.get<TdpCdpResponse>(API_URL + 'company/downloadPeriod',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response.result));
    }

    uploadLastFileForClient(startDate, endDate, clientId): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('startDate', startDate)
            .append('endDate', endDate);
        return this.http.get<any>(API_URL + 'astFilesForClient/' + clientId,
            {params: params, withCredentials: true});
    }

    postTdpCdp(clientId: number, cdp: number, tdp: number): Observable<APIResponse> {
        return this.http
            .post<APIResponse>(API_URL + 'company/downloadPeriod', {
                cdp: cdp,
                tdp: tdp,
                clientId: clientId
            }, {withCredentials: true});
    }

    deleteHistory(serviceId) {
        const params: HttpParams = new HttpParams()
            .append('serviceId', '' + serviceId);

        return this.http.get<ForwardedSettingsResponse[]>(API_URL + 'settings/forwarding/delete',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    changeCredentials(value): Observable<APIResponse> {
        return this.http
            .post<APIResponse>(API_URL + 'user/changeCredentials', {
                id: value.id,
                u_email: value.u_email,
                u_phone: value.u_phone,
                uname: value.uname,
                clientId: value.clientId
            }, {withCredentials: true});
    }

    lastDriverFilesForClient(startDate, endDate, clientId): Observable<LastDriverFilesForClientResponse> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);

        return this.http.get<LastDriverFilesForClientResponse>(API_URL + 'lastDriverFilesForClient/' + clientId,
            {params: params, withCredentials: true}).pipe(share());
    }

    allDriverFilesForClient(startDate, endDate, clientId): Observable<LastDriverFilesForClientResponse> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);

        return this.http.get<LastDriverFilesForClientResponse>(API_URL + 'driverFilesForClient/' + clientId,
            {params: params, withCredentials: true}).pipe(share());
    }

    allVehicleFilesForClient(startDate, endDate, clientId): Observable<LastVehicleFilesForClient> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);

        return this.http.get<LastVehicleFilesForClient>(API_URL + 'vehicleFilesForClient/' + clientId,
            {params: params, withCredentials: true}).pipe(share());
    }

    lastVehicleFilesForClient(startDate, endDate, clientId): Observable<LastVehicleFilesForClient> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);

        return this.http.get<LastVehicleFilesForClient>(API_URL + 'lastVehicleFilesForClient/' + clientId,
            {params: params, withCredentials: true}).pipe(share());
    }

    addNewConnectionService(value): Observable<UpdateConnectionServiceResponse> {
        return this.http
            .post<UpdateConnectionServiceResponse>(API_URL + 'connected-service/add-new', value, {withCredentials: true});
    }

    testAmazonS3(value): Observable<TestConnectionServiceResponse> {
        // console.log(value);
        return this.http
            .post<TestConnectionServiceResponse>(API_URL + 'settings/services/test-amazons3', value, {withCredentials: true});
    }

    testSFTP(value): Observable<TestConnectionServiceResponse> {
        return this.http
            .post<TestConnectionServiceResponse>(API_URL + 'settings/services/test-sftp', value, {withCredentials: true});
    }

    testFTP(value): Observable<TestConnectionServiceResponse> {
        return this.http
            .post<TestConnectionServiceResponse>(API_URL + 'settings/services/test-ftp', value, {withCredentials: true});
    }

    testWebhook(value): Observable<TestConnectionServiceResponse> {
        return this.http
            .post<TestConnectionServiceResponse>(API_URL + 'settings/services/test-webhook', value, {withCredentials: true});
    }

    testAzure(value): Observable<TestConnectionServiceResponse> {
        return this.http
            .post<TestConnectionServiceResponse>(API_URL + 'settings/services/test-azure', value, {withCredentials: true});
    }

    updateConnectedService(value): Observable<UpdateConnectionServiceResponse> {
        return this.http
            .post<UpdateConnectionServiceResponse>(API_URL + 'connected-service/update', value , {withCredentials: true});
    }

    deleteConnectedService(value): Observable<UpdateConnectionServiceResponse> {
        return this.http
            .post<UpdateConnectionServiceResponse>(API_URL + 'connected-service/delete',  value , {withCredentials: true});
    }

    getCredentials(userId): Observable<GetCredentialsResponse> {
        const params: HttpParams = new HttpParams()
            .append('userId', '' + userId);

        return this.http.get<GetCredentialsResponse>(API_URL + 'user/getCredentials',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    updateLanguageAndTimezone(clientId: number, languageId: number, timezoneId: number): any {
        return this.http
            .post<any>(API_URL + 'company/updateLanguageAndTimezone',
                { clientId, languageId, timezoneId },
                { withCredentials: true })
            .pipe(take(1));
    }

    getLanguageAndTimezoneByClient(clientId: number): Observable<CompanyReportSettings> {
        return this.http
            .post<CompanyReportSettings>(API_URL + 'company/searchLanguageAndTimezone',
                { clientId },
                { withCredentials: true })
            .pipe(take(1));
    }

    updateTolerances(companyTolerances: CompanyTolerances): Observable<any> {
        return this.http
            .post<any>(API_URL + 'company/updateTolerances',
                companyTolerances,
                { withCredentials: true })
            .pipe(
                take(1),
                map(result => {
                    this.tolerances.next(companyTolerances)
                    return result;
                })
            );
    }

    getTolerancesByClient(clientId: number): Observable<CompanyTolerances> {
        return this.http
            .post<CompanyTolerances>(API_URL + 'company/searchTolerances',
                { clientId },
                { withCredentials: true })
            .pipe(
                take(1),
                map(result => {
                    this.tolerances.next(result);
                    return result;
                })
            );
    }

    getTimezones(): Observable<TimeZone[]> {
        return this.http
            .post<TimeZone[]>(API_URL + 'company/searchAllTimezones',
                {},
                { withCredentials: true })
            .pipe(take(1));
    }

    getReportLanguages(): Observable<ReportLanguage[]> {
        return this.http
            .post<ReportLanguage[]>(API_URL + 'company/searchAllLanguages',
                {},
                { withCredentials: true })
            .pipe(take(1));
    }

    updateFieldSeparator(clientId: number, fieldSeparatorId: number): Observable<any> {
        return this.http
            .post(API_URL + 'company/updateFieldSeparator',
                { clientId, fieldSeparatorId },
                { withCredentials: true })
            .pipe(take(1));
    }

    getFieldSeparatorByClient(clientId: number): Observable<FieldSeparator> {
        return this.http
            .post<FieldSeparatorResponse>(API_URL + 'company/searchFieldSeparator',
                { clientId },
                { withCredentials: true })
            .pipe(
                take(1),
                map(result => result.fieldSeparator)
            );
    }

    getFieldSeparators(): Observable<FieldSeparator[]> {
        return this.http
            .post<FieldSeparator[]>(API_URL + 'company/searchAllFieldSeparators',
                {},
                { withCredentials: true })
            .pipe(take(1));
    }

}
