import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EqualValidatorDirective} from './equal-validator.directive';
import {AppAccordionDirective} from './app-accordion.directive';
import {FontSizeDirective} from './font-size.directive';
import { LoadingSpinnerDirective } from './loading-spinner/loading-spinner.directive';
import { LoadingComponent } from './loading-spinner/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        EqualValidatorDirective,
        AppAccordionDirective,
        FontSizeDirective,
        LoadingSpinnerDirective,
        LoadingComponent
    ],
    exports: [
        EqualValidatorDirective,
        AppAccordionDirective,
        FontSizeDirective,
        LoadingSpinnerDirective
    ]
})
export class CommonDirectivesModule {}
