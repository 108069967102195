<div *ngIf="visibleNotificationList.length === 0" style="padding: 0.7rem;"
     [hidden]= "((progressBarService.needed | async) && (progressBarService.mode | async) === 'query')"
     [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
    <mat-card>
        <mat-icon fxFlex="99" fxFlex.gt-sm="3" style="color: red;">warning</mat-icon>
        <div fxFlex="99" fxFlex.gt-sm="50" style="line-height: 1.7;">
            <span class="smallFont"> {{'Report.General.NoActivity' | translate}} </span>
        </div>
    </mat-card>
</div>

<div>
    <div fxLayout="row" style="padding-top: 1rem; padding-left: 1rem; padding-right: 1rem " [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
        <div fxFlex="100" style="width: 100%">
            <mat-form-field appearance="fill" style="margin-left: 0rem !important; width: 100%">
                <mat-label>{{ placeholderValue | translate}}</mat-label>
                <input
                    matInput
                    type='text'
                    placeholder="{{ placeholderValue | translate}}"
                    name="filterValue"
                    (keyup)='filterByNotificationList(filterValue)'
                    [(ngModel)]="filterValue">
                <button mat-icon-button matSuffix
                        class="resetButton"
                        (click)="resetField()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="flex flex-row gap-2 w-full" style="padding-left: 1rem;">
    <div class="" *ngFor="let item of filteredBy">
        <div class="bg-tachosafe-500 px-2 py-1 rounded-md font-bold text-white" (click)="clickptag(item.filterBy, item.value)">
            <span>{{item.name | translate}}</span>
        </div>
    </div>
</div>

<div fxLayout="row" style="margin-top: 30px" (window:resize)="windowResize()">
    <div style="width: 100%; margin-left: 45px">
        <p-timeline [value]="visibleNotificationList">
            <ng-template pTemplate="marker" let-notification>
                    <span class="material-icons" style="position: relative; padding: 13px" *ngIf="notification.typeOfEvent === 'driver card'">
                        group
                        <span class="material-icons" style="position: absolute; top: 0; right: 0; font-size: 18px; color: {{iconBySeverity(notification.severity, 'color')}}">
                            {{iconBySeverity(notification.severity, 'icon')}}
                        </span>
                    </span>
                    <span class="material-icons" style="position: relative; padding: 13px" *ngIf="notification.typeOfEvent === 'tachograph'">
                        local_shipping
                        <span class="material-icons" *ngIf="notification.severity !== 'NOTIFICATION'" style="position: absolute; top: 0; right: 0; font-size: 18px; color: {{iconBySeverity(notification.severity, 'color')}}">
                            {{iconBySeverity(notification.severity, 'icon')}}
                        </span>
                    </span>
                    <span class="material-icons" style="position: relative; padding: 13px" *ngIf="notification.typeOfEvent === 'vehicleCheck'">
                        local_shipping
                        <span class="material-icons" style="position: absolute; top: 0; right: 0; font-size: 18px; color: {{iconBySeverity(notification.severity, 'color')}}">
                            {{iconBySeverity(notification.severity, 'icon')}}
                        </span>
                    </span>
                    <span class="material-icons" style="position: relative; padding: 13px" *ngIf="notification.typeOfEvent === 'workshop card'">
                        home_repair_service
                        <span class="material-icons" style="position: absolute; top: 0; right: 0; font-size: 18px; color: {{iconBySeverity(notification.severity, 'color')}}">
                            {{iconBySeverity(notification.severity, 'icon')}}
                        </span>
                    </span>
            </ng-template>
            <ng-template pTemplate="content" let-notification>
                <div class="text-muted" style="font-size: 12px; margin-left: 30px">{{formatDateForEvent(notification.uploadTime) | translate}}</div>
                <div style="font-size: 13px; padding-top: 0; margin-left: 30px; width: 98%" *ngIf="notification.typeOfEvent === 'driver card'">
                    <!-- *ngIf="item.eventDto && item.notifications[0].todo != 'Check file upload problem'" -->
                    <div *ngIf="notification.todo !== 'Check file upload problem'" style="width: 100%">
                        <a class="timeline-body-title mr-1" *ngIf="!(testMode$ | async)">
                            <b> {{'Driver event/Uploaded' | translate: {  fileType: notification.fileType, fileName: notification.fileName, source: formatSource(notification.source)} }} </b>
                        </a>
                    </div>
                    <div *ngIf="notification.todo !== 'Check file upload problem'" class="timeline-body-content">
                        <div *ngIf="notification.userName">{{'Driver event/Uploadeded by' | translate: {userName: notification.userName, company: notification.company} }}</div>
                        <div *ngIf="!notification.userName">{{'Driver event/Uploadeded by/Unkown' | translate: {company: notification.company} }}</div>
                        <a  *ngIf="!(testMode$ | async) && (notification.todo !== 'Check file upload problem' && (notification.identificationName || notification.entityName))" class="mat-color-primary" style="cursor: pointer;" routerLink="/drivers/{{'---'}}/{{notification.entityName}}">
                            <div>{{notification.todo | translate}}</div>
                        </a>
                    </div>
                    <div *ngIf="notification.description.includes('file was already uploaded') || notification.description.includes('File was already uploaded')"
                         class="timeline-body-content">
                        <a style="cursor: pointer;" *ngIf="!(testMode$ | async)"
                           routerLink="/uploaded-files/uploadeddriverfiles">
                            <div *ngIf="notification.uploadTimeHR" style="color: #F77B14; font-weight: 400;">{{'Notification/Already uploaded' | translate: {date: formatDateForEvent(notification.uploadTimeHR)} }}</div>
                        </a>
                    </div>
                    <div *ngIf="notification.todo === 'Check file upload problem'" class="timeline-body-content">
                        <div *ngIf="notification.userName">{{'Uploaded/Error' | translate: {
                            userName: notification.userName,
                            company: notification.company,
                            source: notification.source,
                            error: notification.error | translate,
                            fileName: notification.fileName} }}
                        </div>
                    </div>
                </div>
                <div style="font-size: 13px; padding-top: 0; margin-left: 30px; width: 98%" *ngIf="notification.typeOfEvent === 'tachograph'">
                    <div *ngIf="notification.todo !== 'Check file upload problem'">
                        <a class="timeline-body-title mr-1">
                            <b> {{'Vehicle event/Uploaded' | translate: { fileType: notification.fileType, fileName: notification.fileName, source: formatSource(notification.source)} }} </b>
                        </a>
                    </div>
                    <div *ngIf="notification.todo !== 'Check file upload problem'" class="timeline-body-content">
                        <div *ngIf="notification.userName">{{'Vehicle event/Uploadeded by' | translate: {userName: notification.userName, company: notification.company} }}</div>
                        <div *ngIf="!notification.userName">{{'Vehicle event/Uploadeded by/Unknown' | translate: {company: notification.company} }}</div>
                        <div *ngIf="notification.identificationName">{{'Vehicle event/Vehicle' | translate : {name: notification.identificationName, info: notification.info} }}</div>
                        <div *ngIf="!notification.identificationName">{{'Vehicle event/Vehicle' | translate : {name: '---', info: '---'} }}</div>
                        <a *ngIf="!(testMode$ | async) && (notification.todo !== 'Check file upload problem' && notification.todo !== 'view new data' && (notification.identificationName || notification.entityName))" class="mat-color-primary" style="cursor: pointer;" routerLink="/vehicles/{{'---'}}/{{notification.entityName}}">
                            <div>{{notification.todo | translate}}</div>
                        </a>
                        <div *ngIf="notification.todo == 'view new data'">{{notification.todo | translate}}</div>
                    </div>
                    <div *ngIf="notification.description.includes('file was already uploaded') || notification.description.includes('File was already uploaded')">
                        <a *ngIf="!(testMode$ | async) && notification.notifType === 1" style="cursor: pointer;" routerLink="/uploaded-files/uploadedvehiclefiles">
                            <div *ngIf="notification.notificationDto" style="color: #F77B14; font-weight: 400;">
                                {{'Notification/Already uploaded' | translate: {date: formatDate(notification.uploadTime)} }}
                            </div>
                        </a>
                    </div>
                    <div *ngIf="notification.description.includes('file was already uploaded') || notification.description.includes('File was already uploaded')"
                         class="timeline-body-content">
                        <a style="cursor: pointer;" *ngIf=""
                           routerLink="/uploaded-files/uploadedvehiclefiles">
                            <div *ngIf="notification.uploadTime" style="color: #F77B14; font-weight: 400;">{{'Notification/Already uploaded' | translate: {date: formatDateForEvent(notification.uploadTime)} }}</div>
                        </a>
                    </div>
                    <div *ngIf="notification.todo === 'Check file upload problem'" class="timeline-body-content">
                        <div *ngIf="notification.userName">{{'Uploaded/Error' | translate: {
                            userName: notification.userName,
                            company: notification.company,
                            source: notification.source,
                            error: notification.error | translate,
                            fileName: notification.fileName} }} 
                        </div>
                    </div>
                </div>
                <div style="font-size: 13px; padding-top: 0; margin-left: 30px; width: 98%" *ngIf="notification.typeOfEvent === 'vehicleCheck'">
                    <div>
                        <b> {{'Vehicle event/Uploaded' | translate: { fileType: notification.fileType, fileName: notification.fileName, source: formatSource(notification.source)} }} </b>
                    </div>
                    <div *ngIf="notification.userName && notification.company">{{'Vehicle event/Uploadeded by' | translate: {userName: notification.userName, company: notification.company} }}</div>
                    <div *ngIf="!notification.userName && notification.company">{{'Vehicle event/Uploadeded by/Unknown' | translate: {company: notification.company} }}</div>
                    <div *ngIf="notification.userName && !notification.company">{{'Vehicle event/Uploadeded by' | translate: {userName: notification.userName, company: 'unknown' | translate} }}</div>
                    <a class="mat-color-primary" style="cursor: pointer;" (click)="getAutoChecklistItem( notification.fileId )"> <div>{{'Download' | translate}}</div> </a>
                </div>
            </ng-template>
        </p-timeline>
    </div>
</div>

<div fxLayout="row" style="width: 100%" class="container" *ngIf="notificationList.length !== 0">
    <div class="center">
        <button mat-stroked-button color="primary" (click)="loadMore()">{{'Show more'|translate}}</button>
    </div>
</div>
