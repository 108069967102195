<div *ngIf="(isPro|async).pro==='NOT_PRO'" class="p-2">


    <div class="flex flex-col border-l-8 border-orange-300 text-slate-700 bg-white p-4 shadow-lg">
        <div class="flex flex-row justify-between items-center">


            <div class="flex mb-2 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="h-12 w-12 text-orange-300">
                    <path fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd" />
                </svg>
                <span class="ml-2 mr-1 text-xl font-bold">{{'subscription.reportBanner.basic.header'|translate}}</span>
            </div>
            <a href="/#/subscription">
                <div class="w-32 rounded-md bg-orange-300 text-slate-700 p-2 text-center font-bold">{{'subscription.reportBanner.button.subscribe'|translate}}</div></a>
        </div>
        <div class="pl-8 text-lg indent-6">{{'subscription.reportBanner.basic.description'|translate}}.</div>
        <div class="pl-8 mt-4 flex justify-end gap-6">
        </div>
    </div>

</div>

<div *ngIf="(isPro|async).pro==='TRIAL'" class="p-2">

    <div class="flex flex-col border-l-8 border-orange-300 text-slate-700 bg-white p-4 shadow-lg">
        <div class="flex flex-row justify-between items-center">


            <div class="flex mb-2 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="h-12 w-12 text-orange-300">
                    <path fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd" />
                </svg>
                <span class="ml-2 mr-1 text-xl font-bold">{{'subscription.reportBanner.trial.header'|translate}}</span>
            </div>
            <a href="/#/subscription">
            <div class="w-32 rounded-md bg-orange-300 text-slate-700 p-2 text-center font-bold">{{'subscription.reportBanner.button.subscribe'|translate}}</div></a>
        </div>
        <div class="pl-8 text-lg indent-6">{{'subscription.reportBanner.trial.description'|translate}}.</div>
        <div class="pl-8 mt-4 flex justify-end gap-6">
        </div>
    </div>

</div>