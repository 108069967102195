<div class="mat-bg-primary" style="margin-top: -2rem; margin-bottom: -2rem;">
    <h4 style="margin-top: 2rem; padding: 1rem;">Patch Notes</h4>

    <p style="padding-left: 1rem;">2.29.0 (2019-07-04):</p>
    <ul style="margin-left: 2rem;">
        <li>Internationalization update</li>
    </ul>

    <p style="padding-left: 1rem;">2.28.0 (2019-06-25):</p>
    <ul style="margin-left: 2rem;">
        <li>Setttings update</li>
        <li>New notifications</li>
        <li>Other improvements</li>
        <li>Internationalization update</li>
    </ul>

    <p style="padding-left: 1rem;">2.27.0 (2019-05-17):</p>
    <ul style="margin-left: 2rem;">
        <li>Individual views data correction</li>
        <li>Visual updates</li>
        <li>DE lang update</li>
        <li>Optimization</li>
        <li>CDP,TDP added dynamically</li>
        <li>Bugfixes</li>
        <li>Uploaded files update</li>
    </ul>

    <p style="padding-left: 1rem;">2.26.0 (2019-05-07):</p>
    <ul style="margin-left: 2rem;">
        <li>Driver, vehicle view update</li>
        <li>ES, FR, NL, SL update</li>
        <li>Bugfixes</li>
        <li>Drivers, vehicles update</li>
        <li>More small changes</li>
    </ul>

    <p style="padding-left: 1rem;">2.25.0 (2019-04-15):</p>
    <ul style="margin-left: 2rem;">
        <li>New feature: Individual View</li>
        <li>New feature: Vehicle View</li>
        <li>Numerous bug-fixes</li>
        <li>Side menu behavior changed</li>
        <li>Menu structure changed</li>
        <li>How to find infringements(external function updates)</li>
    </ul>

    <p style="padding-left: 1rem;">2.24.0 (2019-04-02):</p>
    <ul style="margin-left: 2rem;">
        <li>Individual view started, driver trace table sort fix</li>
        <li>Updated menu(new structure)</li>
        <li>Sidenav behavior changed</li>
        <li>Additional bug-fixes</li>
    </ul>

    <p style="padding-left: 1rem;">2.23.0 (2019-03-26):</p>
    <ul style="margin-left: 2rem;">
        <li>Feed, notifications css/responsive update</li>
        <li>Filters, datepickers bugfixes</li>
        <li>Prepare for a bigger accident(Under Maintenance page)</li>
        <li>Notification fix</li>
        <li>OneSignal added</li>
        <li>Dashboard hotfix</li>
        <li>Infringements find function upgraded</li>
        <li>Bugfix(when clicked on row and see detailed view can't change the 'Expired-All-Uptodate')</li>
    </ul>

    <p style="padding-left: 1rem;">2.22.0 (2019-03-14):</p>
    <ul style="margin-left: 2rem;">
        <li>Internationalisation update +flags</li>
        <li>Client Admin was true in all case at settings</li>
        <li>Sk lang update(feed translate with variables)</li>
        <li>Dashboard bugfix(when no data to display)</li>
        <li>I18</li>
        <li>New notifications, events(huge update)</li>
    </ul>

    <p style="padding-left: 1rem;">2.21.0 (2019-03-11):</p>
    <ul style="margin-left: 2rem;">
        <li>Fixed FR translate</li>
        <li>Toggle sidenav</li>
        <li>Showstopper bug....new infringements comes in. Driver catalog needed an update.</li>
        <li>isRetired bugfix</li>
        <li>Devices changes, translate</li>
        <li>New notification case</li>
        <li>Update uploaded files tables, additional fixes</li>
    </ul>

    <p style="padding-left: 1rem;">2.20.0 (2019-03-05):</p>
    <ul style="margin-left: 2rem;">
        <li>Uploaded files css fix</li>
        <li>Vehicle report --not used-- fix</li>
        <li>Little css fixes</li>
        <li>getOffencesForYearAndConvert function fixes(driver catalog: show infringements on days)</li>
        <li>Infringements: added filter buttons</li>
        <li>Deploy, bug fixes, redeploy</li>
        <li>New language: French</li>
    </ul>

    <p style="padding-left: 1rem;">2.19.0 (2019-02-28):</p>
    <ul style="margin-left: 2rem;">
        <li>Work/15 infringements added</li>
        <li>Responsive, translate, css updates</li>
        <li>Added SK language</li>
    </ul>

    <p style="padding-left: 1rem;">2.18.0 (2019-02-20):</p>
    <ul style="margin-left: 2rem;">
        <li>Optimizing</li>
        <li>Feed update, translate</li>
        <li>Responsive update</li>
        <li>Css fixes</li>
    </ul>

    <p style="padding-left: 1rem;">2.17.0 (2019-02-12):</p>
    <ul style="margin-left: 2rem;">
        <li>Tables updated(all)</li>
        <li>Css update</li>
        <li>Responsive update</li>
        <li>Translation update</li>
        <li>Notification, dashboard(feed) updates. Interact with links</li>
        <li>Dashboard, vehicles, drivers behaviors changed</li>
    </ul>

    <p style="padding-left: 1rem;">2.16.0 (2019-01-31):</p>
    <ul style="margin-left: 2rem;">
        <li>Drivers table update -> charts added, new structure</li>
        <li>Vehicles table update -> charts added, new structure</li>
        <li>Css update</li>
        <li>Analysis tables looking good now</li>
        <li>Updated forms</li>
        <li>Responsive update</li>
        <li>Remember this PC</li>
        <li>Notification and event updates. Interact with links.</li>
        <li>Dashboard update</li>
    </ul>

    <p style="padding-left: 1rem;">2.15.0 (2019-01-15):</p>
    <ul style="margin-left: 2rem;">
        <li>Optimizing</li>
        <li>Devices bugfix</li>
        <li>ForkJoin deprecated -> fixed</li>
        <li>Device duplicated request bugfix</li>
        <li>Translate behavior changed</li>
        <li>Notification update</li>
        <li>Added language: IT, DA</li>
        <li>Css update, dashboard behavior changed</li>
    </ul>

    <p style="padding-left: 1rem;">2.14.0 (2019-01-08):</p>
    <ul style="margin-left: 2rem;">
        <li>Bug fixes</li>
        <li>Responsive</li>
        <li>Dashboard update</li>
        <li>Driver catalog doesn't want to refresh sometimes - bugfix</li>
        <li>Small translate fixes</li>
    </ul>

    <p style="padding-left: 1rem;">2.13.0 (2018-12-21):</p>
    <ul style="margin-left: 2rem;">
        <li>Optimizing</li>
        <li>Site behavior changed in some cases</li>
        <li>Responsive, Perfect scrollbar</li>
    </ul>

    <p style="padding-left: 1rem;">2.12.0 (2018-12-18):</p>
    <ul style="margin-left: 2rem;">
        <li>Responsive</li>
        <li>Dashboard update, 2 new service</li>
        <li>Notifications update</li>
        <li>Optimizing(reduce requests)</li>
        <li>Drivers, Vehicles detailed view, print preview update</li>
        <li>Smaller updates</li>
    </ul>

    <p style="padding-left: 1rem;">2.11.0 (2018-12-11):</p>
    <ul style="margin-left: 2rem;">
        <li>Translate NL, DE, RO, SL, ES</li>
        <li>Smaller updates</li>
        <li>amChart license</li>
        <li>Mobile responsive</li>
    </ul>

    <p style="padding-left: 1rem;">2.10.0 (2018-12-04):</p>
    <ul style="margin-left: 2rem;">
        <li>Language, Translate fix, update</li>
        <li>Sidenav, chart update</li>
        <li>Dashboard drivers duplicated bug fix</li>
        <li>Print preview updates</li>
        <li>Dashboard updated</li>
    </ul>

    <p style="padding-left: 1rem;">2.9.0 (2018-11-20):</p>
    <ul style="margin-left: 2rem;">
        <li>Css update</li>
        <li>Optimizes</li>
        <li>Detailed chart fixes</li>
        <li>Detailed view, chart new feature(back,forward,infringements)</li>
        <li>Smaller updates</li>
    </ul>

    <p style="padding-left: 1rem;">2.8.0 (2018-11-13):</p>
    <ul style="margin-left: 2rem;">
        <li>Change password, create user feedbacks</li>
        <li>Translate</li>
        <li>Site engine update</li>
        <li>Devices bug fix, detailed speed</li>
    </ul>

    <p style="padding-left: 1rem;">2.7.0 (2018-11-06):</p>
    <ul style="margin-left: 2rem;">
        <li>Settings(change password, create user)</li>
        <li>Forgot password</li>
        <li>Captcha</li>
        <li>Device settings</li>
        <li>Company, user activations</li>
        <li>Translation</li>
    </ul>

    <p style="padding-left: 1rem;">2.6.0 (2018-10-30):</p>
    <ul style="margin-left: 2rem;">
        <li>Css update, Date formats update</li>
        <li>Customizing charts</li>
        <li>Translation, loading indicator detailed views</li>
        <li>Driver trace filter + interaction changes</li>
        <li>Dashboard update, vehicles interaction change</li>
        <li>Signup update</li>
    </ul>

    <p style="padding-left: 1rem;">2.5.0 (2018-10-23):</p>
    <ul style="margin-left: 2rem;">
        <li>Drivers interaction changes</li>
        <li>Notification bell, notification window loading indicator</li>
        <li>Drivers,vehicles detailed view update</li>
        <li>Many smaller updates</li>
        <li>Vehicles, drivers table -> show all,expired,fresh</li>
    </ul>

    <p style="padding-left: 1rem;">2.4.0 (2018-10-17):</p>
    <ul style="margin-left: 2rem;">
        <li>Drivers catalog</li>
        <li>Drivers detailed view fix</li>
        <li>Vehicles detailed view</li>
        <li>Bug fixes</li>
    </ul>

    <p style="padding-left: 1rem;">2.3.0:</p>
    <ul style="margin-left: 2rem;">
        <li>Drivers catalog</li>
        <li>Drivers detailed view</li>
    </ul>

    <p style="padding-left: 1rem;">2.2.0:</p>
    <ul style="margin-left: 2rem;">
        <li>Dashboard update</li>
        <li>Translation update</li>
        <li>Vehicles update</li>
        <li>Drivers update</li>
        <li>Drivers, vehicles print preview update</li>
    </ul>

    <p style="padding-left: 1rem;">2.1.0:</p>
    <ul style="margin-left: 2rem;">
        <li>Patch notes</li>
        <li>Driver trace update</li>
        <li>Vehicles update</li>
        <li>Drivers update</li>
        <li>Uploaded files download</li>
        <li>Dashboard update</li>
    </ul>
</div>
