import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TopbarComponent } from './topbar/topbar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { CommonDirectivesModule } from '../../directives/common/common-directives.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LogoutComponent } from '../../views/sessions/logout/logout.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MyXsrfInterceptor } from '../../interceptors/my-xsrf.interceptor';
import { CompanyService } from '../../services/company/company.service';
import { CommonPipesModule } from '../../pipes/common/common-pipes.module';
import { NotificationsService } from '../../services/notifications.service';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VerticalMenuComponent } from '../menu/vertical-menu/vertical-menu.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrandingComponent } from './branding/branding.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DropdownModule } from 'primeng/dropdown';
import { ReportSubscriptionBannerComponent } from '../report-subscription-banner/report-subscription-banner.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatOptionModule,
        PerfectScrollbarModule,
        MatSelectModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatCardModule,
        CommonDirectivesModule,
        TranslateModule,
        HttpClientModule,
        CommonPipesModule,
        MatRippleModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatPaginatorModule,
        NgxMatSelectSearchModule,
        DropdownModule
    ],
    declarations: [
        AdminLayoutComponent,
        AuthLayoutComponent,
        LogoutComponent,
        VerticalMenuComponent,
        TopbarComponent,
        NotificationsComponent,
        BreadcrumbComponent,
        BrandingComponent,
        ProgressBarComponent,
        ReportSubscriptionBannerComponent
    ],
    providers: [
        CompanyService,
        NotificationsService,
        { provide: HTTP_INTERCEPTORS, useClass: MyXsrfInterceptor, multi: true },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }],
    exports: [
        ProgressBarComponent,
        ReportSubscriptionBannerComponent
    ]
})
export class AppCommonModule {
}
