import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DrivertraceService, Result } from '../../../services/drivertrace/drivertrace.service';
import { AuthService } from '../../../services/auth/auth.service';
import moment from './../../../moment.override';
import { interval, Subscription } from 'rxjs';
import { DriversService, DriverWithStatistics } from '../../../services/drivers/drivers.service';
import { PROFILE } from '../../../helpers/globals';
import { ProgressBarService } from 'app/services/progress-bar/progress-bar.service';
import { finalize } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Chart } from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { MatTableDataSource } from '@angular/material/table';
import { ProService } from 'app/services/pro/pro.service';

@Component({
    selector: 'app-drivertrace',
    templateUrl: './drivertrace.component.html',
    styleUrls: ['./drivertrace.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class DrivertraceComponent implements OnInit, OnDestroy {

    @Input() availableDrivers = [] as DriverWithStatistics[];

    private _subscriptions: Subscription;

    filterValue = '';
    driversNames = [];
    driversCardNumber = [];

    highchartDatas = [];
    highChartsVehicle = [];
    startCountry = null;
    endCountry = null;
    dateforActivitiesStart: number;
    dateforActivitiesEnd: number;

    sortload = true;
    traceResponseArray = [] as Result[];
    dataSource = new MatTableDataSource<Result>([]);

    profile = PROFILE;
    dataexists = false;
    displayedColumn = ['state', 'driver', 'drive', 'drive24', 'drivedriver', 'drivecodriver', 'work', 'dailyWork', 'weeklyWork', 'nextrest', 'nextwork'];
    expandedElement: Result | null;

    driveData = [];
    workData = [];
    availableData = [];
    restData = [];
    notInsertedData = [];
    vehicleData = [];
    charts: Chart;
    annotation = [];
    selectedCompanyId = localStorage.getItem('selectedCompanyId');
    displayedColumns = ['name', 'cardInformation', 'lastActivity', 'validThrough'];

    driverDataForADay = [{
        driveData: [],
        workData: [],
        availableData: [],
        restData: [],
        notInsertedData: [],
        vehicleData: [],
        annotation: [],
        summaryDrive: null,
        summaryRest: null,
        summaryWork: null,
        summaryAvailable: null
    }];
    selectedMultipleTimezoneDate = [];

    startCountryName = '';
    endCountryName = '';

    summaryDrive = 0;
    summaryRest = 0;
    summaryWork = 0;
    summaryAvailable = 0;
    usedVehicle = '';
    now = this.nowFormatDateWithoutYear(new Date());
    durationArray = [];

    dateChange = this.nowFormatDateWithoutYear(new Date()).toString();
    periodStart: number;
    periodEnd: number;
    diagrammLoaded = false;
    noDataText = false;

    /*
        if true -> ascendent else if false -> descendent
     */
    sortByStatusFlag = true;
    sortByDriverFlag = true;
    companyId: number;
    startOfWeek: any;

    queryRunning = false;
    allowBanner = false;

    pro$ = this.proService.pro$;

    private updateSubscription: Subscription;

    private companySpecificSubscription: Subscription;

    static formatDate(ts) {
        if (ts === 0 || ts === '') {
            return '--/--/---- --:--';
        } else {
            return moment(ts * 1000).myFormat('LL dddd HH:mm');
        }
    }

    constructor(private driverstraceService: DrivertraceService,
        private readonly proService: ProService,
        private progressBarService: ProgressBarService,
        private _authService: AuthService,
        private _translate: TranslateService,
        private _driverService: DriversService) {
        this._subscriptions = new Subscription();
        this.companySpecificSubscription = new Subscription();
    }

    ngOnInit() {
        this.startOfWeek = this.getMonday(new Date());
        // console.log(this.startOfWeek);

        this.progressBarService.mode.next('query');
        const subscription = this._authService.currentCompanyId.subscribe((newCompanyId) => {
            this.companySpecificSubscription.unsubscribe();
            if (newCompanyId !== 0) {
                this.companyId = newCompanyId;
                this.dataexists = false;
                this.traceResponseArray = [];

                this.makeDataForDrivers();
                // this.autoRefresh(newCompanyId);
                this.searchDrivers(newCompanyId);
            }
        });
        this._subscriptions.add(subscription);
    }

    getMonday(d) {
        d = new Date(d);
        const day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return Math.floor(new Date(d.setDate(diff)).setHours(0, 0, 0) / 1000);
    }

    ngOnDestroy(): void {
        if (this._subscriptions) {
            this._subscriptions.unsubscribe();
        }
        this.progressBarService.needed.next(false);
    }

    refreshPage() {
        this.getDataForDrivers(this.companyId);
        this.searchDrivers(this.companyId);
    }

    // autoRefresh(newCompanyId) {
    //     this.updateSubscription = interval(50000).subscribe(
    //         (val) => {
    //             this.getDataForDrivers(newCompanyId);
    //             this.searchDrivers(newCompanyId);
    //     });
    // }

    getDataForDrivers(newCompanyId) {
        const subscription = this._driverService.getAllDrivers(newCompanyId).subscribe((response) => {
            this.availableDrivers = response;

            this.availableDrivers.sort(function (a, b) {
                const x = a.driver.driverFirstNames.toLowerCase();
                const y = b.driver.driverFirstNames.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        });
        this._subscriptions.add(subscription);
    }

    getDiagramDataForAPeriod(item) {
        // const node = document.getElementById('new');
        // node.querySelectorAll('*').forEach(n => n.remove());
        this.diagrammLoaded = false;
        this.noDataText = false;
        const el = document.getElementById('new' + item.driverInfo.driverId);
        while (el.firstChild) { el.removeChild(el.firstChild); }

        const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
        const day = beforeOneWeek.getDay()
        const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
        const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));

        lastMonday.setUTCHours(0, 0, 0);

        this.periodStart = Math.trunc(lastMonday.getTime() / 1000);
        this.periodEnd = Math.trunc(new Date().getTime() / 1000);
        this.progressBarService.needed.next(true);
        const subscription = this._driverService
            .getDiagramDataForTrace(
                item.driverInfo.driverId,
                Math.trunc(lastMonday.getTime() / 1000),
                Math.trunc(new Date().getTime() / 1000),
                localStorage.getItem('selectedCompanyId')
            )
            .subscribe(resp => {
                this.highchartDatas = [];
                this.highChartsVehicle = [];
                resp.data.map(item => {
                    this.highchartDatas = this.highchartDatas.concat(item.activities);
                    this.highChartsVehicle = this.highchartDatas.concat(item.vehicles);
                });
                if (this.highchartDatas.length === 0) {
                    this.noDataText = true;
                }
                this.driverDataForADay = [];
                this.calculateMultipleHighchartsDatas(
                    this.highchartDatas, this.highChartsVehicle, this.startCountry, this.endCountry
                );
                this.driverDataForADay.forEach(driver => {
                    if (
                        driver.driveData.length === 0 &&
                        driver.workData.length === 0 &&
                        driver.availableData.length === 0 &&
                        driver.restData.length === 0 &&
                        driver.notInsertedData.length === 0
                    ) {
                        this.progressBarService.needed.next(false);
                    } else {
                        this.drawMultipleHighcharts(
                            item.restLastWeekly1.start,
                            item.restLastWeekly1.end,
                            item.restLastDaily.restStart,
                            item.restLastDaily.restEnd,
                            item.driverInfo.driverId.toString(),
                            driver.vehicleData,
                            driver.workData,
                            driver.driveData,
                            driver.availableData,
                            driver.restData,
                            driver.notInsertedData,
                            driver.annotation,
                            this.startOfWeek,
                            item.uploadInformation.uploadTimeStamp
                        )
                    }
                })
            });
        this._subscriptions.add(subscription);
    }

    drawMultipleHighcharts(restStart, restEnd, restWeekStart, restWeekEnd, name, vehicleData, workData, driveData, availableData, restData, notInsertedData, annotation, weekStart, lastDownload) {
        weekStart = this.getMonday(new Date());
        const that = this;
        Highcharts.setOptions({
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        Highcharts.chart(name, {
            chart: {
                type: 'area',
                height: 200,
                zoomType: 'x',
                renderTo: name,
                backgroundColor: 'rgb(250,250,250)'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '<p style="font-weight:bold">%e. %b</b>',
                    week: '%e. %b',
                    month: '%b \'%y',
                    year: '%Y'
                },
                plotBands: [{
                    color: 'rgba(252, 255, 197, 0.5)',
                    from: restStart * 1000,
                    to: restEnd * 1000
                }, {
                    color: 'rgba(252, 255, 197, 0.5)',
                    from: restWeekStart * 1000,
                    to: restWeekEnd * 1000
                }],
                plotLines: [{
                    color: '#000000',
                    width: 2,
                    value: weekStart * 1000
                }, {
                    color: '#000000',
                    width: 2,
                    value: lastDownload * 1000
                }],
                events: {
                    afterSetExtremes: function (event) {
                        const min = Math.trunc(event.min);
                        const max = Math.trunc(event.max);

                        that.calculateInDuration(min, max);
                    }
                },
                tickInterval: 3600 * 1000
            },
            exporting: {
                enabled: false
            },
            annotations: [{
                draggable: '',
                labelOptions: {
                    align: 'left',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    verticalAlign: 'top'
                },
                labels: [{
                    point: {
                        xAxis: 0,
                        yAxis: 0,
                        x: weekStart * 1000,
                        y: 5
                    },
                    text: that._translate.instant('week') + ' #' + moment(weekStart * 1000).week().toString()
                }, {
                    point: {
                        xAxis: 0,
                        yAxis: 0,
                        x: lastDownload * 1000,
                        y: 5
                    },
                    text: that._translate.instant('Last download')
                }]
            }],
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function () {
                        let name;
                        name = '';
                        return name;
                    }
                }
            },
            tooltip: {
                animation: false,
                backgroundColor: '#333333',
                style: {
                    color: '#ffffff'
                },
                borderColor: 'red',
                borderRadius: 10,
                borderWidth: 1.5,
                followPointer: false,
                formatter: function () {
                    // @ts-ignore
                    const visibileData = JSON.parse(this.point.mydata);
                    let tooltipText: string;
                    const text = JSON.parse(localStorage.getItem('diagramText'));
                    // if (this.series.index === 0) {
                    //     tooltipText = this.series.name;
                    // } else {
                    tooltipText = this.series.name +
                        '<br>' + that._translate.instant('Duration') + ': ' +
                        ((Math.floor(visibileData.duration / 60) < 10) ?
                            '0' + Math.floor(visibileData.duration / 60) :
                            Math.floor(visibileData.duration / 60)) + ':' +
                        ((visibileData.duration % 60 < 10) ?
                            '0' + Math.floor(visibileData.duration % 60) :
                            Math.floor(visibileData.duration % 60)) +
                        '<br>' + that._translate.instant('Start date') + ' :' +
                        moment(
                            new Date((visibileData.day + visibileData.startTime * 60) * 1000)
                        ).myFormat('HH:mm') +
                        '<br>' + that._translate.instant('End date') + ' :' +
                        moment(
                            new Date((visibileData.day + visibileData.endTime * 60) * 1000)
                        ).myFormat('HH:mm');
                    ;
                    return tooltipText;
                }
            },
            colors: [
                'rgba(0,0,255,0.2)',
                'rgba(255,0,0,0.2)',
                'rgba(247, 147, 29, 0.2)',
                'rgba(0, 255, 0, 0.2)',
                'rgba(255,255,255,0.2)',
                'rgba(0,0,0,0)'
            ],
            plotOptions: {
                series: {
                    animation: false,
                },
            },
            // annotations: this.annotation,
            series: [{
                name: this._translate.instant('Work'),
                data: workData,
                type: 'area'
            }, {
                name: this._translate.instant('Drive'),
                data: driveData,
                type: 'area'
            }, {
                name: this._translate.instant('Available'),
                data: availableData,
                type: 'area'
            }, {
                name: this._translate.instant('Rest'),
                data: restData,
                type: 'area'
            }, {
                name: this._translate.instant('Not inserted'),
                data: notInsertedData,
                type: 'area'
            }, {
                data: annotation,
                type: 'area',
                name: ''
            }]
        });
        this.diagrammLoaded = true;
        this.progressBarService.needed.next(false);
    }

    formatDate(ts) {
        if (ts === 0 || ts === '' || moment(ts * 1000) >= moment([])) {
            return '---';
        } else {
            return moment.parseZone(ts * 1000).myFormat('LL');
        }
    }

    formatDateWithMonth(ts) {
        if (ts === 0 || ts === '') {
            return '---';
        } else {
            return moment.parseZone(ts * 1000).myFormat('LLL');
        }
    }

    formatDurationFromMinutes(duration) {
        return (((Math.floor(duration / 60) < 10) ?
            '0' + Math.floor(duration / 60) :
            Math.floor(duration / 60)) + ':' +
            ((duration % 60 < 10) ?
                '0' + Math.floor(duration % 60) :
                Math.floor(duration % 60)))
    }

    addNewRest(item, endDate?) {
        this.restData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.restData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 1,
            mydata: JSON.stringify(item)
        });
        this.restData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 1,
            mydata: JSON.stringify(item)
        });
        this.restData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewWork(item, endDate?) {
        this.workData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.workData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 3,
            mydata: JSON.stringify(item)
        });
        this.workData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 3,
            mydata: JSON.stringify(item)
        });
        this.workData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewAvailable(item, endDate?) {
        this.availableData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.availableData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 2,
            mydata: JSON.stringify(item)
        });
        this.availableData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 2,
            mydata: JSON.stringify(item)
        });
        this.availableData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewDrive(item, endDate?) {
        this.driveData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(new Date((item.day + item.startTime * 60) * 1000)).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.driveData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 4,
            mydata: JSON.stringify(item)
        });
        this.driveData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 4,
            mydata: JSON.stringify(item)
        });
        this.driveData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewNotInsertedDate(item, endDate?) {
        this.notInsertedData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.notInsertedData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.notInsertedData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.notInsertedData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }
    /*
    multiple charts diagramm
 */
    calculateMultipleHighchartsDatas(highchartDatas, highChartsVehicle, startCountry, endCountry) {
        this.restData = [];
        this.availableData = [];
        this.driveData = [];
        this.workData = [];
        this.notInsertedData = [];
        this.vehicleData = [];
        this.summaryDrive = 0;
        this.summaryRest = 0;
        this.summaryWork = 0;
        this.summaryAvailable = 0;
        this.durationArray = [];

        this.addAnnotation(startCountry, endCountry);
        highchartDatas.forEach((item, index) => {
            if (index === (highchartDatas.length - 1)) {
                this.durationArray.push({
                    startTime: new Date((item.day + item.startTime * 60) * 1000),
                    endTime: new Date(this.periodEnd * 1000),
                    type: item.type
                });
            } else {
                this.durationArray.push({
                    startTime: new Date((item.day + item.startTime * 60) * 1000),
                    endTime: new Date((item.day + item.endTime * 60) * 1000),
                    type: item.type
                });
            }
            switch (item.type) {
                case 0: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryRest += (this.periodEnd - (item.day + item.startTime * 60)) / 60;
                        this.addNewRest(item, this.periodEnd);
                    } else {
                        this.addNewRest(item);
                        this.summaryRest += item.duration;
                    }
                    break;
                }
                case 1: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryAvailable += (this.periodEnd - (item.day + item.startTime * 60)) / 60;
                        this.addNewAvailable(item, this.periodEnd);
                    } else {
                        this.addNewAvailable(item);
                        this.summaryAvailable += item.duration;
                    }
                    break;
                }
                case 2: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryWork += (this.periodEnd - (item.day + item.startTime * 60)) / 60;
                        this.addNewWork(item, this.periodEnd);
                    } else {
                        this.addNewWork(item);
                        this.summaryWork += item.duration;
                    }
                    break;
                }
                case 3: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryDrive += (this.periodEnd - (item.day + item.startTime * 60)) / 60;
                        this.addNewDrive(item, this.periodEnd)
                    } else {
                        this.addNewDrive(item);
                        this.summaryDrive += item.duration;
                    }
                    break;
                }
                case 4: {
                    if (index === (highchartDatas.length - 1)) {
                        this.addNewNotInsertedDate(item, this.periodEnd)
                        this.summaryRest += (this.periodEnd - (item.day + item.startTime * 60)) / 60;
                    } else {
                        this.addNewNotInsertedDate(item);
                        this.summaryRest += item.duration;
                    }
                    break;
                }
            }
        });

        this.driverDataForADay.push({
            driveData: this.driveData,
            workData: this.workData,
            availableData: this.availableData,
            restData: this.restData,
            notInsertedData: this.notInsertedData,
            vehicleData: this.vehicleData,
            annotation: this.annotation,
            summaryDrive: this.summaryDrive,
            summaryRest: this.summaryRest,
            summaryWork: this.summaryWork,
            summaryAvailable: this.summaryAvailable
        });
    }

    formatFromNow(ts) {
        if (ts === '' || moment(ts * 1000) >= moment([])) {
            return '0';
        } else if (ts === 0) {
            return this._translate.instant('processingFile');
        } else {
            moment.relativeTimeThreshold('h', 24);
            moment.relativeTimeThreshold('m', 60);
            return moment.parseZone(ts * 1000).fromNow();
        }
    }

    formatLastDownload(ts) {
        ts = 0;
        if (ts === 0 || ts === '' || moment(ts * 1000) >= moment([])) {
            return '0';
        } else {
            moment.relativeTimeThreshold('h', 24);
            moment.relativeTimeThreshold('m', 60);
            return moment.parseZone(ts * 1000).fromNow();
        }
    }

    getHoursFromADate(ts, name) {
        if (ts === 0 || ts === '') {
            return '--:--';
        } else {
            const currentDay = new Date().getTime();
            const duration = currentDay - (ts * 1000);
            return Math.floor(duration / 3600000);
        }
    }

    calculateInDuration(min, max) {
        this.periodStart = min / 1000;
        this.periodEnd = max / 1000;
        for (let i = 0; i < 4; i++) {
            let total = 0;
            let selectedArray = [];
            selectedArray = this.durationArray.filter(item =>
                (item.type === i) &&
                ((item.startTime > min && item.startTime < max) ||
                    (item.endTime > min && item.endTime < max) ||
                    (item.startTime < min && item.endTime > max))
            );
            selectedArray.forEach(value => total += ((value.endTime > max) ? max : value.endTime) - ((value.startTime > min) ? value.startTime : min));
            switch (i) {
                case (0): {
                    this.summaryRest = total / 60000;
                    break;
                }
                case (1): {
                    this.summaryAvailable = total / 60000;
                    break;
                }
                case (2): {
                    this.summaryWork = total / 60000;
                    break;
                }
                case (3): {
                    this.summaryDrive = total / 60000;
                    break;
                }
            }
        }
    }

    addAnnotation(startCountry, endCountry) {
        this.annotation = [];
        if (startCountry !== null) {
            if (startCountry.entryTime !== 0) {
                this.annotation.push({
                    x: new Date(startCountry.entryTime * 1000),
                    y: 0
                });
                this.annotation.push({
                    x: new Date(startCountry.entryTime * 1000),
                    y: 4,
                    id: 'point1'
                });
                this.annotation.push({
                    x: new Date(startCountry.entryTime * 1000),
                    y: 0
                });
                this.startCountryName = startCountry.country;
            }
        }
        if (endCountry !== null) {
            if (endCountry.entryTime !== 0) {
                this.annotation.push({
                    x: new Date(endCountry.entryTime * 1000),
                    y: 0
                });
                this.annotation.push({
                    x: new Date(endCountry.entryTime * 1000),
                    y: 4,
                    id: 'point2'
                });
                this.annotation.push({
                    x: new Date(endCountry.entryTime * 1000),
                    y: 0
                });
                this.endCountryName = endCountry.country;
            }
        }
    }

    searchDrivers(newCompanyId) {
        this.progressBarService.needed.next(true);
        this.queryRunning = true;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const proSubscription = this.proService.pro$.subscribe(async pro => {
            const userIsPro = this._authService.getLoggedInUser().proUser;
            const isReseller = this._authService.getLoggedInUser().clientIsReseller;
            // console.log(pro);
            if (pro.pro !=='NOT_PRO' || ( userIsPro && isReseller ) ) {
                const resp = await this.driverstraceService.getDriversTrace(newCompanyId, timezone).toPromise()
                this.progressBarService.needed.next(false);
                this.queryRunning = false;
                resp.result.sort((a, b) => (a.calculatedValues.baseTime < b.calculatedValues.baseTime) ? 1 : -1);
                this.traceResponseArray = resp.result;
                this.dataSource.data = this.traceResponseArray;
                this.allowBanner = false;
                this.dataexistsFunction();
            } else {
                this.queryRunning = false;
                this.traceResponseArray = this.dummyData;
                this.dataSource.data = this.traceResponseArray;
                this.dataexistsFunction();
                this.allowBanner = true;
            }
        });
        this.companySpecificSubscription.add(proSubscription);
    }

    counter(i: number) {
        if (i < 0) {
            return new Array(0);
        } else {
            return new Array(i);
        }
    }

    sortByStatus() {
        if (this.sortByStatusFlag) {
            this.traceResponseArray = this.traceResponseArray.sort((a, b) => (a.calculatedValues.baseTime < b.calculatedValues.baseTime) ? 1 : -1);
            this.sortByStatusFlag = !this.sortByStatusFlag;
        } else {
            this.traceResponseArray = this.traceResponseArray.sort((a, b) => (a.calculatedValues.baseTime > b.calculatedValues.baseTime) ? 1 : -1);
            this.sortByStatusFlag = !this.sortByStatusFlag;
        }

        this.dataSource.data = this.traceResponseArray;
    }

    sortByDriverName() {
        if (this.sortByDriverFlag) {
            this.traceResponseArray = this.traceResponseArray.sort((a, b) => (a.driverInfo.name > b.driverInfo.name) ? 1 : -1);
            this.sortByDriverFlag = !this.sortByDriverFlag;
        } else {
            this.traceResponseArray = this.traceResponseArray.sort((a, b) => (a.driverInfo.name < b.driverInfo.name) ? 1 : -1);
            this.sortByDriverFlag = !this.sortByDriverFlag;
        }
        this.dataSource.data = this.traceResponseArray;
    }

    checkIfSameDate(dateTime) {
        if (this.formatDateWithoutYear(dateTime) === this.now) {
            return true;
        } else {
            return false;
        }
    }

    makeDataForDrivers() {
        for (let i = 0; i < this.availableDrivers.length; i++) {
            this.driversNames.push(this.availableDrivers[i].driver.driverFirstNames + ' ' + this.availableDrivers[i].driver.driverSurnames);
            this.driversCardNumber.push(this.availableDrivers[i].driver.cardNumber);
        }
    }

    dataexistsFunction() {
        this.dataexists = true;
    }

    sortLoadFunction() {
        this.sortload = true;
    }

    szemafor(ts) {
        if (ts > moment([]).unix()) {
            return 'piros';
        } else {
            return 'zold';
        }
    }

    nowFormatDateWithoutYear(ts) {
        if (ts === 0 || ts === '') {
            return '--/--/---- --:--';
        } else {
            this.dateChange = moment(ts).myFormat('dddd HH:mm');
            return moment(ts).myFormat('dddd HH:mm');
        }
    }

    formatDateWithoutYear(ts) {
        if (ts === 0 || ts === '') {
            return '--/--/---- --:--';
        } else {
            return moment(ts * 1000).myFormat('dddd HH:mm');
        }
    }

    formatDateWithoutDay(ts) {
        if (ts === 0 || ts === '') {
            return '--/--/---- --:--';
        } else {
            return moment(ts * 1000).myFormat('MMM Do HH:mm');
        }
    }

    checkDailyDriving(calculatedValue) {
        let drivingDailyMax = [];
        let maxValue: number;
        drivingDailyMax = calculatedValue.drivingDailyMAX.split(',').map(x => parseInt(x, 10));
        if (calculatedValue.moreThan9hDrivingTimesFromLastWeeklyBreakMax - calculatedValue.moreThan9hDrivingTimesFromLastWeeklyBreak === 0) {
            maxValue = Math.min(...drivingDailyMax);
        } else {
            maxValue = Math.max(...drivingDailyMax);
        }
        return maxValue;
    }

    formatTime(ts) {
        if (ts < 0) {
            return '- -:- -';
        }

        switch (ts) {
            case 0:
            case '':
                return '00:00';
            default:
                const hour = Math.floor(ts / 60);
                const minute = ts % 60;

                const hourf = hour < 10 ? '0' + hour : hour;
                const minutef = minute < 10 ? '0' + minute : minute;

                return hourf + ':' + minutef;
        }
    }

    formatDuration(ts) {
        if (ts === 0 || ts === '') {
            return '--:--';
        } else {
            let hour, minute;
            minute = Math.floor(ts / 60);
            hour = Math.floor(minute / 60);
            minute = minute % 60;

            if (minute < 10) {
                return hour + ':0' + minute;
            } else {
                return hour + ':' + minute;
            }
        }
    }

    getDailyHours(ts, number, number2?) {
        let hour, minute;
        minute = Math.floor(ts / 60);
        hour = Math.floor(minute / 60);

        if (number2 === undefined) {
            return hour < number;
        } else {
            return hour >= number && hour < number2;
        }
    }

    getHoursFromDate(ts) {
        let hour, minute;
        minute = Math.floor(ts / 60);
        hour = Math.floor(minute / 60);
        return hour;
    }

    sortTable(n) {
        this.sortload = false;
        let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById('coolTable');
        switching = true;
        // Set the sorting direction to ascending:
        dir = 'asc';
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName('TD')[n];
                y = rows[i + 1].getElementsByTagName('TD')[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir === 'asc') {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        this.sortLoadFunction();
                        break;
                    }
                } else if (dir === 'desc') {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        this.sortLoadFunction();
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                this.sortLoadFunction();
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount === 0 && dir === 'asc') {
                    dir = 'desc';
                    switching = true;
                    this.sortLoadFunction();
                }
            }
        }
    }

    myPrint(): void {
        const printContents = document.getElementById('forprint').innerHTML;
        const popupWin = window.open();
        popupWin.document.open();
        popupWin.document.write('<html lang="">' +
            '<head>' +
            '<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">' +
            '<title></title></head>' +
            '</head><style>' +
            'table {\n' +
            '    border-collapse: collapse;\n' +
            '    border-spacing: 0;\n' +
            '    width: 100%;\n' +
            '    border: 1px solid #ddd;\n' +
            '}\n' +
            '\n' +
            'th, td {\n' +
            '    text-align: left;\n' +
            '    padding: 3px;\n' +
            '}\n' +
            '\n' +
            'tr:nth-child(even) {\n' +
            '    background-color: #f2f2f2\n' +
            '}' +
            '</style><body onload="window.print()">' +
            printContents +
            '</html>');
        popupWin.document.close();
    }

    searchFunction() {
        let filteredArray = [];
        filteredArray = this.traceResponseArray.filter(item =>
        (item.driverInfo.name.toLowerCase().includes(this.filterValue.toLowerCase()) ||
            item.driverInfo.cardNumber.includes(this.filterValue)));
        this.dataSource.data = filteredArray;
    }

    nowT = new Date().getTime() / 1000;
    dummyData: Result[] = [
        {
            "driverInfo": {
                "cardNumber": "CARD0000000001",
                "driverId": null,
                "name": "Test Driver 1",
            },
            "lastActivity": {
                "activity": "DRIVING",
                "activityLength": 0,
                "startTime": this.nowT,
            },
            "previousActivity": {
                "activity": "WORK",
                "activityLength": 5,
                "startTime": this.nowT - 5,
            },
            "restLastDaily": {
                "restStart": this.nowT - 5 * 60 * 60,
                "restEnd": this.nowT - 5 * 60 * 60 + 677,
                "restLength": 677,
            },
            "lastActivityDay": {
                "drivingCurrentDay": 50,
                "crew": false,
                "dayStart": this.nowT,
                "dayEnd": this.nowT,
            },
            "restLastWeekly1": {
                "start": this.nowT - 24 * 60 * 60,
                "end": this.nowT - 24 * 60 * 60 + 2448,
                "length": 2448,
            },
            "drivingLast2Weeks": 2516,
            "calculatedValues": {
                "workTimes": {
                    "workTimeWeekly": 1034,
                    "workTimeWeeklyMax": 3600,
                    "workTimeAverageLast16Weeks": 1332,
                    "nightWorkPerformedThisDay": false,
                    "maxWorkWhenNightWorkPerformed": 900,
                    "workContinuousWithoutBreak": 56,
                    "workContinuousWithoutBreakMax": 360,
                    "totalWorkToday": 56,
                    "justWorkForToday": 56
                },
                "baseTime": this.nowT,
                "drivingTwoWeeklyRemaining": 2884,
                "drivingTwoWeeklyMax": 5400,
                "projections": {
                    "nextWorkStart": this.nowT,
                    "nextWorkLength": 600,
                    "nextWorkComments": [],
                    "nextWorkCommentsTrans": []
                },
                "drivingContinuousRemaining": 220,
                "drivingContinuousMax": 270,
                "drivingDailyRemaining": 550,
                "drivingDailyRemainingCurrentDay": 550,
                "drivingDailyMAX": "540,600",
                "drivingWeeklyRemaining": 2546,
                "drivingWeeklyMax": 3360,
                "nextDailyBreakStart": this.nowT,
                "nextDailyBreakStartAsCrew": this.nowT,
                "nextWeeklyBreakStart": this.nowT,
                "moreThan9hDrivingTimesFromLastWeeklyBreak": 0,
                "moreThan9hDrivingTimesFromLastWeeklyBreakMax": 2,
                "lessThan11hBreakTimesFromLastWeeklyBreak": 1,
                "lessThan11hBreakTimesFromLastWeeklyBreakMax": 3
            },
            "uploadInformation": {
                "uploadTimeStamp": this.nowT - 25 * 60,
                "minutesSinceUpload": 25,
                "minutesBetweenLastActivityAndUpload": 4,
                "minutesSinceLastActivity": 45
            }
        }
    ] as unknown as Result[];
}
