import { EventEmitter, Injectable } from '@angular/core';
import { CHART_API_URL } from 'app/helpers/globals';
import {finalize, map, share, take, tap} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'app/moment.override';
type Moment = typeof moment;
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CharttranslationService } from '../charttranslation.service';
import { CompanyService } from 'app/services/company/company.service';
import { Company } from 'app/services/company/company.model';
import { DecimalPipe } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { NamePipe } from 'app/pipes/common/name-pipe';
import { ProgressBarService } from 'app/services/progress-bar/progress-bar.service';
import { TableInfo } from './models/table.model';
import {
    CardSlot,
    ChartInfo,
    Dailychartinput,
    DataSet,
    DataTypes,
    DriversOnDay,
    DrivingStatus, Fault,
    IconText,
    InfoLineitemDescription,
    Infringment, Offence,
    OneDayActivity,
    OneWorkingTimeRow,
    Place,
    Shift,
    ShiftVehicleInfo,
    WorkingTimeDetails
} from './models/chart.model';
import { LabelledReportContext, ReportContext } from './models/context.model';
import { ReportModel, TabOption } from '../report.model';
import { PrintService } from '../print.service';
import { Observable, Subscription, zip } from 'rxjs';
import { downloadContent } from '../download.util';
import { DateIntervalOptions } from 'app/views/rszoft/report/utils/date.util';
import { OffencesResponse, OffencesService } from 'app/services/offences/offences.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
    }),
    withCredentials: true
};

export class DailyChartRequestDTO {
    driverIds: number[];
    vehicle: VehicleRequestDTO[];
    clientId: number;
    startDate: string;
    endDate: string;
}

export class VehicleRequestDTO {
    vehicleIdentificationNumber: string;
    vehicleRegistrationNumber: string;
}

export class DailyChartResponse implements ReportModel {
    driverInfo: DriverInfo = new DriverInfo();
    vehicleInfo: VehicleResponseDTO;

    chartInfo: ChartInfo = new ChartInfo();
    tableInfo: TableInfo;
    dailyChartInputsTemp: Dailychartinput[] = [];
    dailyChartInputs: Dailychartinput[] = [];
    reportGenerated: Moment;
    company: string;
    language: string;
    timeZone: string;
}

export class VehicleResponseDTO {
    country: string;
    registrationNumber: string;
    identificationNumber: string;
    odometerBegin: number;
    odometerEnd: number;
    odometerSumma: number;
    queryPeriodInDays: number;
    activeDaysInPeriod: number;
    lastDownloadTime: number;
    lastDownloadTimeMoment: Moment;
    downloadIdentifier: VehicleDownloadIdentifierDTO;
    drivers: VehicleDriverDTO[];
}

export class DriverInfo {
    driverId: number;
    cardHolderSurname: string;
    cardHolderFirstNames: string;
    cardNumber: string;
    cardIssuingMemberState: string;
    cardHolderBirthDate: number;
    cardInfo: CardInfo;
    queryPeriodInDays: number;
    driverActiveDaysInPeriod: number;
    driverCardDownloadTime: number; // stored in millisecond in database...
    driverCardDownloadTimeMoment: Moment;
    vehicles: Vehicle[] = [];
    // periodStartDate: number;
    // periodEndDate: number;

    constructor() {
        this.cardInfo = new CardInfo();
    }
}

export class VehicleDownloadIdentifierDTO {
    manufacturerName: string;
    approvalNumber: string;
    partNumber: string;
    softwareVersion: string;
    serialNumber: number;
    monthYear: string;
    sensorApprovalNumber: string;
    sensorSerialNumber: string;
    sensorMonthYear: string;
}

export class VehicleDriverDTO {
    firstName: string;
    surName: string;
    cardNumber: string;
    birthDate: number;
    birthDateMoment: Moment;
    useFrom: number;
    useFromMoment: Moment;
    useTo: number;
    useToMoment: Moment;
}

export class CardInfo {
    cardApprovalNumber: string;
    serialNumber: string;
    cardExpiryDate: number;
    valid: boolean;
}

export class Vehicle {
    vehicleRegistrationNumber: string;
    vehicleRegistrationNation: string;
    vehicleFirstUse: number;
    vehicleFirstUseMoment: Moment;
    vehicleLastUse: number;
    vehicleLastUseMoment: Moment;
    odometerBegin: number;
    odometerEnd: number;
    odometerSumma: number;
}

export enum ViewOptions {
    TABLE_VIEW,
    CHART_VIEW
}

@Injectable({
  providedIn: 'root'
})
export class DailyChartService {
    reportContexts: LabelledReportContext[];
    chartLanguage: string;

    activeSelector: string;

    interval = 1000 * 60;

    private readonly changeMenuEmitter: EventEmitter<any>;
    viewTab: ViewOptions;
    goToPrint: boolean;
    printingInProgress: boolean;
    companyName: string;

    start = new FormControl(new Date());
    end = new FormControl(new Date());
    private dailyChartReportDriverResponseObservable: Observable<any>;
    private dailyChartReportVehicleResponseObservable: Observable<any>;
    private dailyChartSubscription: Subscription;
    private dailyChartDriverRequestDto: DailyChartRequestDTO;
    private dailyChartVehicleRequestDto: DailyChartRequestDTO;

    private static sortVehicleInfoDriverList(vehicleResponseDTO: VehicleResponseDTO): VehicleResponseDTO {
        vehicleResponseDTO.drivers.sort((a, b) => {
            if (a.useFrom < b.useFrom) {
                return -1;
            }

            if (a.useFrom === b.useFrom) {
                return 0;
            }
            return 1;
        })
        return vehicleResponseDTO;
    }

    private static isTodayAfterLastDownloadDate(recordDate: number, lastDownloadedDate: number): boolean {
        const recordDateInMs = recordDate * 1000;
        return recordDateInMs > lastDownloadedDate;
    }

    private static mapOneDayActivityToWorkingTimeDetails(oneDayActivity: OneDayActivity): WorkingTimeDetails {
        const workingTimeDetails = new WorkingTimeDetails();
        if (oneDayActivity) {
            workingTimeDetails.working = oneDayActivity.dailyWorkSumInMinutesOnDay;
            workingTimeDetails.availability = oneDayActivity.dailyAvailabilitySumInMinutesOnDay;
            workingTimeDetails.driving = oneDayActivity.dailyDrivingSumInMinutesOnDay;
            workingTimeDetails.allWorksInDay = workingTimeDetails.getAllWorksSumInDay();
        }
        return workingTimeDetails;
    }

    private static mapPlaceToInfolineDescriptionItem(place: Place, recordDate: number, timestamp: number): InfoLineitemDescription {
        const infoLineitemDescription = new InfoLineitemDescription();
        if (place !== undefined) {
            infoLineitemDescription.text = place.countryShort;
            infoLineitemDescription.crossingMinute = (timestamp - recordDate) / 60;
            return infoLineitemDescription;
        } else {
            return undefined;
        }
    }

    private static isTwoEpocInOneMinute(a: number, b: number) {
        return a - b < 60 && a - b > -60;
    }

    private static isTwoCrossingMinutesInOneMinute(a: number, b: number) {
        return a - b < 1 && a - b > -1;
    }

    private static convertFaultCode(fault: Fault) {
        if (!!fault.eventFaultType) {
            if (fault.eventFaultType > 127) {
                fault.eventFaultType = 256;
            } else if (fault.eventFaultType > 9 && fault.eventFaultType < 17 ||
                fault.eventFaultType > 24 && fault.eventFaultType < 33 ||
                fault.eventFaultType > 37 && fault.eventFaultType < 49 ||
                fault.eventFaultType > 53 && fault.eventFaultType < 128) {
                fault.eventFaultType = 127;
            }
        }
    }

    constructor(private http: HttpClient,
                private translate: TranslateService,
                private snack: MatSnackBar,
                private chartTranslationService: CharttranslationService,
                private printService: PrintService,
                private companyService: CompanyService,
                private offencesService: OffencesService,
                private progressBarService: ProgressBarService,
                private decimalPipe: DecimalPipe,
                private formBuilder: FormBuilder,
                private namePipe: NamePipe) {

        this.initReportContexts();

        this.changeMenuEmitter = new EventEmitter<any>();
        this.activeSelector = 'driver';
        this.progressBarService.mode.next('determinate');
    }

    initReportContexts() {
        this.reportContexts = [];
        this.reportContexts.push(new LabelledReportContext(TabOption.DRIVER, this.initOneReportContext()));
        this.reportContexts.push(new LabelledReportContext(TabOption.VEHICLE, this.initOneReportContext()));
    }

    initOneReportContext(): ReportContext {
        return new ReportContext(this.formBuilder, this.start, this.end);
        // const reportContext = new ReportContext(this.formBuilder, this.start, this.end);
        // reportContext.chartQueryForm.valueChanges.subscribe(value => {
        //     this.reportContexts.forEach(labelledReportContext => {
        //         // if (value.rangeFormGroup.start !== labelledReportContext.reportContext.chartQueryForm.get('rangeFormGroup').get('start').value) {
        //         //     this.start.setValue(Date.parse(value.rangeFormGroup.start));
        //         // }
        //         labelledReportContext.reportContext.chartQueryForm.get('rangeFormGroup').
        //     });
            // if (value.rangeFormGroup.end !== this.end.value) {
            //     this.end.setValue(Date.parse(value.rangeFormGroup.end));
            // }
            // this.reportContexts.forEach(labelledReportContext => {
            //     if (labelledReportContext.reportContext.chartQueryForm.get('rangeFormGroup').get('start') !== value.rangeFormGroup.start) {
            //         labelledReportContext.reportContext.chartQueryForm.get('rangeFormGroup').get('start').setValue(value.rangeFormGroup.start);
            //     }
            //     if (labelledReportContext.reportContext.chartQueryForm.get('rangeFormGroup').get('end') !== value.rangeFormGroup.end) {
            //         labelledReportContext.reportContext.chartQueryForm.get('rangeFormGroup').get('end').setValue(value.rangeFormGroup.end);
            //     }
            // });
        // })
        // return reportContext;
    }

    getReportContext(label: string): LabelledReportContext {
        return this.reportContexts.find(reportContext => {
            return reportContext.label === label;
        })
    }

    getDriverDailyChartReport(dailyChartDto: DailyChartRequestDTO) {
        if (this.dailyChartReportDriverResponseObservable) {
            return null;
        } else {
            this.dailyChartDriverRequestDto = dailyChartDto;
            this.dailyChartReportDriverResponseObservable = this.http.post<DailyChartResponse[]>(CHART_API_URL + 'chart/driver/daily',
                JSON.stringify(dailyChartDto), httpOptions)
                .pipe(
                    share(),
                    take(1),
                    finalize(() => this.dailyChartReportDriverResponseObservable = null));
            return this.dailyChartReportDriverResponseObservable;
        }
    }

    getVehicleDailyChartReport(dailyChartDto: DailyChartRequestDTO) {
        if (this.dailyChartReportVehicleResponseObservable) {
            return null;
        } else {
            this.dailyChartVehicleRequestDto = dailyChartDto;
            this.dailyChartReportVehicleResponseObservable = this.http.post<DailyChartResponse[]>(CHART_API_URL + 'chart/vehicle/daily',
                JSON.stringify(dailyChartDto), httpOptions)
                .pipe(
                    share(),
                    take(1),
                    finalize(() => this.dailyChartReportVehicleResponseObservable = null));
            return this.dailyChartReportVehicleResponseObservable;
        }
    }

    generateDriverCsvExport(): Observable<any> {
        this.progressBarService.needed.next(true);
        this.progressBarService.mode.next('query');
        return this.http
            .post(
                CHART_API_URL + 'chart/driver/daily/download',
                JSON.stringify(this.dailyChartDriverRequestDto),
                { ...httpOptions, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                tap(downloadContent),
                finalize(() => this.progressBarService.needed.next(false))
            );
    }

    generateVehicleCsvExport(): Observable<any> {
        this.progressBarService.needed.next(true);
        this.progressBarService.mode.next('query');
        return this.http
            .post(
                CHART_API_URL + 'chart/vehicle/daily/download',
                JSON.stringify(this.dailyChartVehicleRequestDto),
                { ...httpOptions, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                tap(downloadContent),
                finalize(() => this.progressBarService.needed.next(false))
            );
    }

    generateReport(periodStartTime: Moment, periodEndTime: Moment, dailyChartDto: DailyChartRequestDTO, tab: TabOption) {
        this.progressBarService.value.next(2);
        this.getReportContext(tab).reportContext.periodStartTime = periodStartTime.unix();

        //Set to next Sunday if the period end date is not Sunday
        const daysToNextSunday = (7 - periodEndTime.day()) % 7; // Modulo to handle wrapping around

        // Add the calculated days to the current date to get the next Sunday
        periodEndTime = periodEndTime.add(daysToNextSunday, 'days');

        this.getReportContext(tab).reportContext.periodEndTime = periodEndTime.unix();

        switch (tab) {
            case TabOption.DRIVER:
                this.generateDriverReport(dailyChartDto, periodStartTime.unix(), periodEndTime.unix());
                break;
            case TabOption.VEHICLE:
                this.generateVehicleReport(dailyChartDto);
                break;
        }
    }

    generateDriverReport(dailyChartDto: DailyChartRequestDTO, startDate: number, endDate: number) {
        if (!!dailyChartDto.driverIds &&
                !!this.getReportContext(TabOption.DRIVER).reportContext.periodStartTime &&
                !!this.getReportContext(TabOption.DRIVER).reportContext.periodEndTime) {
            this.startDailyChartProgressBar();
            this.resetReportContext(TabOption.DRIVER);

            const obs = this.getDriverDailyChartReport(dailyChartDto);
            const offencesObs = this.offencesService.getOffenceForDrivers(startDate, endDate, dailyChartDto.driverIds, dailyChartDto.clientId);
            if (obs) {
                this.dailyChartSubscription = zip(obs, offencesObs)
                    .pipe(map(([dailyChartResponse, offencesResponse]) => this.mergeOffencesToDriverData(dailyChartResponse, offencesResponse)))
                    .subscribe(
                        (response: DailyChartResponse[]) => this.generateReportFromResponse(response, TabOption.DRIVER),
                        this.handleGenerateReportError
                    );
            }
        }
    }

    private mergeOffencesToDriverData(dailyCharts: DailyChartResponse[], offences: OffencesResponse[]): DailyChartResponse[] {
        dailyCharts.forEach(dailyChartResponse => {
            const offenceList = offences
                .filter(offence => (offence.cardNumber as any as string) === dailyChartResponse.driverInfo.cardNumber)
                .map(offence => offence.offenceByCodes.filter(oneOffence => oneOffence.severity_2006_561 !== 'NONE'));

            if (offenceList?.length === 0) { return; }
            dailyChartResponse.chartInfo.eventsOnDays.forEach(oneDayActivity => {
                oneDayActivity.offences = offenceList[0]
                    .filter(offence => offence.endTime >= oneDayActivity.recordDate && offence.startTime <= oneDayActivity.recordDate + 86399)
                    .map(offence => {
                        return {
                            startTime: offence.startTime,
                            endTime: offence.endTime,
                            type: offence.type,
                            duration: offence.value,
                            norm: offence.norm,
                            severity: offence.severity_2006_561
                        } as Offence;
                    });
                // console.log(oneDayActivity.offences);
            });
        });
        return dailyCharts;
    }

    generateVehicleReport(dailyChartDto: DailyChartRequestDTO) {
        if (!!dailyChartDto.vehicle &&
                !!this.getReportContext(TabOption.VEHICLE).reportContext.periodStartTime &&
                !!this.getReportContext(TabOption.VEHICLE).reportContext.periodEndTime) {
            this.startDailyChartProgressBar();
            this.resetReportContext(TabOption.VEHICLE);

            const obs = this.getVehicleDailyChartReport(dailyChartDto);
            if (obs) {
                obs.subscribe(
                    (response: DailyChartResponse[]) => this.generateReportFromResponse(response, TabOption.VEHICLE),
                    this.handleGenerateReportError
                );
            }
        }
    }

    private generateReportFromResponse(response: DailyChartResponse[], tab: TabOption) {
        this.getReportContext(tab).reportContext.dailyChartResponses = [];
        for (const resp of response) {
            this.chartLanguage = resp.language;
            this.printService.language = resp.language;
            this.chartTranslationService.setCurrentLang(resp.language);
            const oneDailyChartResponse = new DailyChartResponse();
            if (tab === TabOption.DRIVER) {
                oneDailyChartResponse.driverInfo = this.convertDriverInfo(resp.driverInfo, resp.timeZone);
            } else {
                oneDailyChartResponse.vehicleInfo = this.convertVehicleInfo(resp.vehicleInfo, resp.timeZone);
            }
            oneDailyChartResponse.chartInfo = resp.chartInfo;
            oneDailyChartResponse.reportGenerated = moment().tz(resp.timeZone);
            this.printService.reportGenerated = moment().tz(resp.timeZone);
            oneDailyChartResponse.language = resp.language;
            oneDailyChartResponse.timeZone = resp.timeZone;
            this.getReportContext(tab).reportContext.dailyChartInputsTemp = [];
            this.initiateDailyChartInputsFromChartInfo(oneDailyChartResponse.chartInfo.eventsOnDays,
                this.getReportContext(tab).reportContext.dailyChartInputsTemp,
                resp.timeZone, resp.language, oneDailyChartResponse.driverInfo, oneDailyChartResponse.vehicleInfo);
            if (tab === TabOption.DRIVER && resp.driverInfo.driverActiveDaysInPeriod > 0 ||
                tab === TabOption.VEHICLE && resp.vehicleInfo.activeDaysInPeriod > 0) {
                oneDailyChartResponse.dailyChartInputs = this.getReportContext(tab).reportContext.dailyChartInputsTemp;
            }
            oneDailyChartResponse.tableInfo = new TableInfo();
            this.initiateTableInfoFromResponse(resp, oneDailyChartResponse.tableInfo,
                tab === TabOption.DRIVER ?
                oneDailyChartResponse.driverInfo.driverCardDownloadTime :
                oneDailyChartResponse.vehicleInfo.lastDownloadTime);
            if (tab === TabOption.DRIVER && resp.driverInfo.driverActiveDaysInPeriod > 0 ||
                tab === TabOption.VEHICLE && resp.vehicleInfo.activeDaysInPeriod > 0) {
                Array.prototype.push.apply(this.getReportContext(tab).reportContext.dailyChartInputs,
                    this.getReportContext(tab).reportContext.dailyChartInputsTemp);
            }
            this.getReportContext(tab).reportContext.dailyChartResponses.push(oneDailyChartResponse);
        }
        this.getReportContext(tab).reportContext.chartDataGenerated = true;
        setTimeout(() => {
            this.progressBarService.mode.next('determinate');
            this.progressBarService.value.next(2);
            this.stopDailyChartProgressBar();
            this.getReportContext(tab).reportContext.chartDataReady = true;
            if (this.viewTab === ViewOptions.CHART_VIEW) {
                this.updateDailyChartInputsPaged(+localStorage.getItem('dailyChartPageSize'), 0, tab);
            }
        });
    }

    private handleGenerateReportError() {
        this.snack.open(this.translate.instant('Report.DailyChart.ErrorMessages.unableToDownloadData'), 'OK', {duration: 4000});
        this.stopDailyChartProgressBar();
    }

    updateDailyChartInputsPaged(pageSize: number, pageIndex: number, tab: TabOption) {
        this.getReportContext(tab).reportContext.dailyChartInputsPagedTemp = [];
        this.getReportContext(tab).reportContext.dailyChartInputsPagedTemp =
            (this.getReportContext(tab).reportContext.dailyChartResponses[this.getReportContext(tab).reportContext.selectedDriverIndex]?.dailyChartInputs || [])
                .slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
        this.getReportContext(tab).reportContext.dailyChartInputsPaged
            = new Array(this.getReportContext(tab).reportContext.dailyChartInputsPagedTemp.length);
        this.getReportContext(tab).reportContext.chartDataGenerated = true;
        const dailyChartResponse = this.getReportContext(tab).reportContext.dailyChartResponses[this.getReportContext(tab).reportContext.selectedDriverIndex];
        if (!!dailyChartResponse && (dailyChartResponse.driverInfo && dailyChartResponse.driverInfo.driverActiveDaysInPeriod > 0
            || dailyChartResponse.vehicleInfo && dailyChartResponse.vehicleInfo.activeDaysInPeriod > 0)) {
            // start chart update process only if there is any active day
            this.progressBarService.mode.next('determinate');
            this.progressBarService.needed.next(true);
            this.fillNextDailyChartInputPaged(tab, pageSize, pageIndex);
        } else {
            this.stopDailyChartProgressBar();
        }
    }

    fillNextDailyChartInputPaged(tab: TabOption, actualPageSize?: number, actualPageIndex?: number) {
        const indexToSet = this.getReportContext(tab).reportContext.indexOfLastChartToLoad + 1;
        const numberOfChartPerPage = this.getNumberOfDailyChartItemsOnPageByPageSizeAndPageIndex(actualPageSize,
            actualPageIndex, tab,  this.getReportContext(tab).reportContext.selectedDriverIndex);

        this.progressBarService.value.next(Math.round(100 * (indexToSet / numberOfChartPerPage)));
        if (indexToSet < numberOfChartPerPage) {
            this.getReportContext(tab).reportContext.dailyChartInputsPaged[indexToSet]
                = this.getReportContext(tab).reportContext.dailyChartInputsPagedTemp[indexToSet];
        }
        if (indexToSet === numberOfChartPerPage || this.viewTab === ViewOptions.TABLE_VIEW) {
            this.stopDailyChartProgressBar();
        }

        this.getReportContext(tab).reportContext.indexOfLastChartToLoad = indexToSet;
        this.getReportContext(tab).reportContext.onDatasSetChanged().emit();
    }

    fillNextDailyChartInput(event: any, tab: TabOption) {
        const responseIndex = event['responseIndex'];
        // const response = this.getReportContext(tab).reportContext.dailyChartResponses[responseIndex];
        const indexToSet = event['index'] + 1;
        if (indexToSet < this.getReportContext(tab).reportContext.dailyChartResponses[responseIndex].dailyChartInputs.length) {
            this.printService.progressBarValue = Math.round(100 * (this.getReportContext(tab).reportContext.indexOfLastChartToLoad
                / this.getReportContext(tab).reportContext.countOfCharts));
            this.getReportContext(tab).reportContext.dailyChartResponses[responseIndex].dailyChartInputsTemp[indexToSet]
                = this.getReportContext(tab).reportContext.dailyChartResponses[responseIndex].dailyChartInputs[indexToSet];
            this.getReportContext(tab).reportContext.indexOfLastChartToLoad++;
        }

        // const indexToSet = this.indexOfLastChartToLoad + 1;
        //
        // this.progressbarValue = Math.round(100 * (indexToSet / this.dailyChartInputs.length));
        // if (indexToSet < this.dailyChartInputs.length) {
        //     this.dailyChartInputsPaged[indexToSet] = this.dailyChartInputs[indexToSet];
        //     this.indexOfLastChartToLoad = indexToSet;
        //     this.datasetChangeEmitter.emit();
        // }
        // if (indexToSet === this.dailyChartInputs.length) {
        //     this.progressBarNeeded = false;
        //     this.onDataReady();
        // }
    }

    getNextEvent(indexToSet: number, responseIndex: number, tab: TabOption) {
        if (indexToSet + 1 === this.getReportContext(tab).reportContext.dailyChartResponses[responseIndex].dailyChartInputs.length) {
            let nextIndex = responseIndex + 1;
            if (this.getReportContext(tab).reportContext.dailyChartResponses.length > nextIndex) {
                while (nextIndex < this.getReportContext(tab).reportContext.dailyChartResponses.length &&
                        this.getReportContext(tab).reportContext.dailyChartResponses[nextIndex].dailyChartInputs.length === 0) {
                    nextIndex++;
                }
            }
            if (this.getReportContext(tab).reportContext.dailyChartResponses.length === nextIndex) {
                this.dataReadyToPrint(tab);
                this.stopDailyChartProgressBar();
                return undefined;
            } else {
                return {index: -1, responseIndex: nextIndex};
            }
        }
        return {index: indexToSet, responseIndex: responseIndex};
    }

    getNumberOfDailyChartItemsOnPageByPageSizeAndPageIndex(pageSize: number, pageIndex: number, tab: TabOption,
                                                           responseIndex?: number) {
        const remaining = this.getSumOfActivitiesOnDailyChartResponse(tab, responseIndex) - (pageSize * pageIndex);
        return remaining > pageSize ? pageSize : remaining;
    }

    getSumOfActivitiesOnDailyChartResponse(tab: TabOption, responseIndex?: number) {
        if (responseIndex === undefined) {
            return this.getReportContext(tab).reportContext.dailyChartInputs.length;
        } else {
            return this.getReportContext(tab).reportContext.dailyChartResponses[responseIndex].dailyChartInputs.length;
        }
    }

    private convertDriverInfo(driverInfo: DriverInfo, timezone: string): DriverInfo {
        const di = new DriverInfo();
        Object.assign(di, driverInfo);
        di.vehicles.map(vehicle => {
            vehicle.vehicleFirstUseMoment = moment(vehicle.vehicleFirstUse * 1000).tz(timezone);
            vehicle.vehicleLastUseMoment = moment(vehicle.vehicleLastUse * 1000).tz(timezone);
        });
        di.driverCardDownloadTimeMoment = moment(driverInfo.driverCardDownloadTime).tz(timezone);
        return di;
    }

    private convertVehicleInfo(vehicleInfo: VehicleResponseDTO, timezone: string): VehicleResponseDTO {
        const vi = new VehicleResponseDTO();
        Object.assign(vi, vehicleInfo);
        vi.drivers.map(driver => {
            driver.useFromMoment = moment(driver.useFrom * 1000).tz(timezone);
            if (driver.useTo) {
                driver.useToMoment = moment(driver.useTo * 1000).tz(timezone);
            }
            if (driver.birthDate) {
                driver.birthDateMoment = moment(driver.birthDate * 1000).tz(timezone);
            }
        })
        vi.lastDownloadTimeMoment = moment(vehicleInfo.lastDownloadTime).tz(timezone);
        return DailyChartService.sortVehicleInfoDriverList(vi);
    }

    initiateDailyChartInputsFromChartInfo(eventsOnDays: OneDayActivity[], dailyChartInputs: Dailychartinput[],
                                          timezone: string, language: string, driverInfo: DriverInfo, vehicleInfo: VehicleResponseDTO) {
        for (const oneDayActivitySet of eventsOnDays) {
            if (oneDayActivitySet.recordDate * 1000 > driverInfo?.driverCardDownloadTime) {
                continue;
            }
            const dailyChartInput = new Dailychartinput();
            dailyChartInput.dateOfChart = oneDayActivitySet.recordDate * 1000;
            dailyChartInput.workingDetailsForOneDay = DailyChartService.mapOneDayActivityToWorkingTimeDetails(oneDayActivitySet);
            dailyChartInput.workingTimeRows = this.mapShiftsToOneWorkingTimeRows(oneDayActivitySet.shifts, oneDayActivitySet.recordDate, timezone, language);
            dailyChartInput.borderCrossingItems = this.mapShiftsAndPlacesToInfolineItems(oneDayActivitySet.shifts, oneDayActivitySet.places, oneDayActivitySet.recordDate);
            dailyChartInput.vehicleItems = this.mapShiftVehicleInfoToInfoLineItems(oneDayActivitySet.shifts, oneDayActivitySet.recordDate);
            dailyChartInput.ferryItems = this.mapFerryItemsToInfoLineItems(oneDayActivitySet.ferryEntryTimes, oneDayActivitySet.recordDate);
            dailyChartInput.infringments = this.mapInfringmentItemsIntoDailyChartInput(oneDayActivitySet.infringements, timezone, language);
            dailyChartInput.offences = this.mapOffenceItemsIntoDailyChartInput(oneDayActivitySet.offences, timezone, language);
            dailyChartInput.driversOnDay = this.mapDriversOnDayToInfoLineItems(oneDayActivitySet.driversOnDay, oneDayActivitySet.recordDate, timezone);
            dailyChartInput.faults = this.mapFaults(oneDayActivitySet.faults);
            dailyChartInput.drivingsWithoutCard = oneDayActivitySet.cardlessDriving;
            dailyChartInput.noCardInsertedOnDay = this.mapDrivingsWithoutCardToInfolineItems(oneDayActivitySet.cardlessDriving, oneDayActivitySet.recordDate);
            dailyChartInput.timezone = timezone;
            dailyChartInput.driverFirstName = driverInfo.cardHolderFirstNames;
            dailyChartInput.driverLastName = driverInfo.cardHolderSurname;
            dailyChartInput.odometerStart = oneDayActivitySet.odometerStart;
            dailyChartInput.odometerEnd = oneDayActivitySet.odometerEnd;
            dailyChartInput.odometerSum = oneDayActivitySet.odometerSum;
            if (vehicleInfo) {
                dailyChartInput.vehicleRegistrationNumber = vehicleInfo.registrationNumber;
                dailyChartInput.vehicleIdentificationNumber = vehicleInfo.identificationNumber;
                if (oneDayActivitySet.recordDate * 1000 > vehicleInfo.lastDownloadTime) {
                    dailyChartInput.noDataForToday = true;
                }
            }
            const tempDataSets: DataSet[] = [];
            tempDataSets[DataTypes.DRIVING] = new DataSet(DataTypes.DRIVING);
            tempDataSets[DataTypes.WORK] = new DataSet(DataTypes.WORK);
            tempDataSets[DataTypes.AVAILABILITY] = new DataSet(DataTypes.AVAILABILITY);
            tempDataSets[DataTypes.BREAK_REST] = new DataSet(DataTypes.BREAK_REST);
            tempDataSets[DataTypes.CREW_DRIVING] = new DataSet(DataTypes.CREW_DRIVING);

            let lastDownloadedTime = undefined;
            if (vehicleInfo) {
                lastDownloadedTime = vehicleInfo.lastDownloadTime;
            } else if (driverInfo) {
                lastDownloadedTime = driverInfo.driverCardDownloadTime;
            }
            const lastDownloadedDateToday = this.isLastDownloadedDateToday(oneDayActivitySet.recordDate, lastDownloadedTime);

            for (const oneActivity of oneDayActivitySet.activities) {
                if (oneActivity.activity !== DataTypes.FAKE_ACTIVITY && !DailyChartService.isTodayAfterLastDownloadDate(oneDayActivitySet.recordDate, lastDownloadedTime)) {
                    const startInMinute = (oneActivity.start - oneDayActivitySet.recordDate) / 60;
                    let endInMinute = (oneActivity.end - oneDayActivitySet.recordDate) / 60;
                    if (lastDownloadedDateToday) {
                        const minuteOflAstDownload = (lastDownloadedTime - (oneDayActivitySet.recordDate * 1000)) / 1000 / 60;
                        if (endInMinute > minuteOflAstDownload) {
                            endInMinute = minuteOflAstDownload;
                        }
                    }
                    // we have to set empty records between valid data to have correct chart diagram
                    for (let j = tempDataSets[oneActivity.activity].data.length; j < startInMinute; j++) {
                        tempDataSets[oneActivity.activity].data[j] = [dailyChartInput.dateOfChart + (this.interval * j), null];
                    }
                    for (let i = startInMinute; i <= endInMinute; i++) {
                        tempDataSets[oneActivity.activity].data[i] = [dailyChartInput.dateOfChart + (this.interval * i), tempDataSets[oneActivity.activity].getValue()]
                    }
                    if (oneActivity.drivingStatus === DrivingStatus.CREW) {
                        for (let j = tempDataSets[DataTypes.CREW_DRIVING].data.length; j < startInMinute; j++) {
                            tempDataSets[DataTypes.CREW_DRIVING].data[j] = [dailyChartInput.dateOfChart + (this.interval * j), null];
                        }
                        for (let i = startInMinute; i <= endInMinute; i++) {
                            tempDataSets[DataTypes.CREW_DRIVING].data[i] = [dailyChartInput.dateOfChart + (this.interval * i), 0, -1];
                        }
                    }
                }
            }
            dailyChartInput.dataset.push(tempDataSets[DataTypes.DRIVING]);
            dailyChartInput.dataset.push(tempDataSets[DataTypes.WORK]);
            dailyChartInput.dataset.push(tempDataSets[DataTypes.AVAILABILITY]);
            dailyChartInput.dataset.push(tempDataSets[DataTypes.BREAK_REST]);
            dailyChartInput.dataset.push(tempDataSets[DataTypes.CREW_DRIVING]);

            if (lastDownloadedDateToday) {
                dailyChartInput.lastDownloadedTime = lastDownloadedTime;
            }
            dailyChartInputs.push(dailyChartInput);
        }
    }

    initiateTableInfoFromResponse(response, tableInfo: TableInfo, lastDownloadDate: number) {
        const weeks = [];
        response.tableInfo.years.forEach(year => {
            year.weeks.forEach(week => {
                week.numberOfYear = year.numberOfYear;
                week.days.forEach(day => {
                    day.noDataForToday = lastDownloadDate <= day.recordDate * 1000;
                    day.recordDateMoment = moment(day.recordDate * 1000).tz(response.timeZone);
                    day.faults?.forEach(fault => {
                        DailyChartService.convertFaultCode(fault);
                    });
                    day.faults?.sort(Fault.compare);
                });
            });
            weeks.push(...year.weeks);
        });
        tableInfo.queryPeriodSum = response.tableInfo.queryPeriodSum;
        tableInfo.weeks = weeks;
    }

    private isLastDownloadedDateToday(recordDate: number, lastDownloadedDate: number): boolean {
        const recordDateInMs = recordDate * 1000;
        return recordDateInMs < lastDownloadedDate && (recordDateInMs + this.getNumberOfHoursInDay(moment(recordDateInMs)) * 60 * 60 * 1000) > lastDownloadedDate;
    }

    private mapShiftsToOneWorkingTimeRows(shifts: Shift[], recordDate: number, timezone: string, language: string): OneWorkingTimeRow[] {
        const workingTimeRows = [];
        if (shifts) {
            shifts.forEach((shift) => {
                const workingTimeRow = new OneWorkingTimeRow();
                workingTimeRow.startTime = (shift.shiftFrom - recordDate) / 60;
                const diffInSeconds = shift.shiftTo - recordDate;
                // if (diffInSeconds > 60 * 60 * 24) {
                //     diffInSeconds = diffInSeconds % (60 * 60 * 24);
                // }
                workingTimeRow.endTime = diffInSeconds / 60;
                workingTimeRow.startDayString = moment(shift.shiftFrom * 1000).tz(timezone).locale(language).myFormat('dddd');
                workingTimeRow.endDayString = moment(shift.shiftTo * 1000).tz(timezone).locale(language).myFormat('dddd');
                const workingTimeDetails = new WorkingTimeDetails();
                workingTimeDetails.driving = shift.driveInShift;
                workingTimeDetails.working = shift.workInShift;
                workingTimeDetails.availability = shift.availabilityInShift;
                workingTimeDetails.allWorksInShift = shift.allWorksInShift;
                workingTimeDetails.resting = shift.loggedBreakInShift;
                workingTimeDetails.shiftSumma = shift.shiftSumma;
                workingTimeDetails.restPeriod = shift.restPeriodForShift;
                workingTimeRow.workingTimeDetails = workingTimeDetails;
                workingTimeRows.push(workingTimeRow);
            });
        }
        return workingTimeRows;
    }

    private mapShiftsAndPlacesToInfolineItems(shifts: Shift[], places: Place[], recordDate: number): InfoLineitemDescription[] {
        const borderCrossingItems = [];
        if (shifts) {
            shifts.forEach(value => {
                if (value.shiftFrom - recordDate >= 0) {
                    const item = DailyChartService.mapPlaceToInfolineDescriptionItem(value.startPlace, recordDate, value.shiftFrom);
                    if (item) {
                        borderCrossingItems.push(item);
                    }
                }
                if ((value.shiftTo - (recordDate + 24 * 60 * 60)) <= 0) {
                    const item = DailyChartService.mapPlaceToInfolineDescriptionItem(value.endPlace, recordDate, value.shiftTo);
                    if (item) {
                        borderCrossingItems.push(item);
                    }
                }
            });
            places.map(value => {
                borderCrossingItems.push(DailyChartService.mapPlaceToInfolineDescriptionItem(value, recordDate, value.entryTime));
            })
        }
        return borderCrossingItems;
    }

    private mapShiftVehicleInfoToInfoLineItems(shifts: Shift[], recordDate: number): InfoLineitemDescription[] {
        const vehicleInfo = [];
        if (shifts) {
            shifts.forEach(shift => {
                shift.shiftVehicleInfo.forEach(shiftVehicleInfo => {
                    if (shift.shiftFrom - recordDate >= 0) {
                        const item = this.mapShiftVehicleInfoToInfolineDescriptionItem(shiftVehicleInfo, recordDate, shiftVehicleInfo.vehicleFirstUse);
                        if (item) {
                            vehicleInfo.push(item);
                        }
                    }
                })
            })
        }
        return vehicleInfo;
    }

    private mapShiftVehicleInfoToInfolineDescriptionItem(shiftVehicleInfo: ShiftVehicleInfo, recordDate: number, timestamp: number): InfoLineitemDescription {
        const infoLineitemDescription = new InfoLineitemDescription();
        if (shiftVehicleInfo !== undefined) {
            const it = new IconText();
            it.text = shiftVehicleInfo.registrationNumber + ' '
                + shiftVehicleInfo.registrationNation
                + (shiftVehicleInfo.registrationNation ? ' ' : '');
            it.icon = 'track_icon';
            infoLineitemDescription.iconTexts.push(it);
            const it2 = new IconText();
            it2.text = this.decimalPipe.transform(shiftVehicleInfo.odometerBegin, '', 'hu') + ' km '
                + this.decimalPipe.transform(shiftVehicleInfo.odometerEnd, '', 'hu') + ' km ';
            it2.icon = 'location_icon';
            infoLineitemDescription.iconTexts.push(it2);
            const it3 = new IconText();
            it3.text = this.decimalPipe.transform(shiftVehicleInfo.odometerSumma, '', 'hu') + ' km '
                + shiftVehicleInfo.avgSpeed + ' km/h';
            it3.icon = 'summa_icon';
            infoLineitemDescription.iconTexts.push(it3);
            infoLineitemDescription.crossingMinute = (timestamp - recordDate) / 60;
            infoLineitemDescription.extraInfo = shiftVehicleInfo.vehicleChange;
            return infoLineitemDescription;
        } else {
            return undefined;
        }
    }

    private mapFerryItemsToInfoLineItems(ferryItems: number[], recordDate: number): InfoLineitemDescription[] {
        const infoLineitemDescriptions = [];
        if (ferryItems) {
            ferryItems.forEach(ferryItem => {
                const infoLineItem = new InfoLineitemDescription();
                infoLineItem.crossingMinute = (ferryItem - recordDate) / 60;
                infoLineitemDescriptions.push(infoLineItem);
            })
        }
        return infoLineitemDescriptions;
    }

    private mapInfringmentItemsIntoDailyChartInput(infringments: Infringment[], timezone: string, language: string): Infringment[] {
        if (infringments) {
            infringments.forEach(item => {
                item.offenceStartMoment = moment(item.offenceStart * 1000);
                item.offenceEndMoment = moment(item.offenceEnd * 1000);
                item.offenceStartDayString = moment(item.offenceStart * 1000).tz(timezone).locale(language).myFormat('dddd');
                item.offenceEndDayString = moment(item.offenceEnd * 1000).tz(timezone).locale(language).myFormat('dddd');
            })
        }
        return infringments;
    }

    private mapOffenceItemsIntoDailyChartInput(offences: Offence[], timezone: string, language: string): Offence[] {
        if (!!offences) {
            offences.forEach(item => {
                item.offenceStartMoment = moment(item.startTime * 1000);
                item.offenceEndMoment = moment(item.endTime * 1000);
                item.offenceStartDayString = moment(item.startTime * 1000).tz(timezone).locale(language).myFormat('dddd');
                item.offenceEndDayString = moment(item.endTime * 1000).tz(timezone).locale(language).myFormat('dddd');
            });
        }
        return offences;
    }

    private mapDriversOnDayToInfoLineItems(driversOnDay: DriversOnDay[], recordDate: number, timezone: string): InfoLineitemDescription[] {
        const infoLineitemDescriptions = [];
        if (driversOnDay) {
            driversOnDay = this.filterDuplications(driversOnDay);
            driversOnDay = this.sortDriversOnDay(driversOnDay);
            driversOnDay.forEach(item => {
                let infoLineitemDescription = new InfoLineitemDescription();
                const it = new IconText();
                const isCoDriver = item.cardSlot === CardSlot.CODRIVER;
                infoLineitemDescription.crossingMinute = (item.useFrom - recordDate) / 60;
                it.icon = 'kormany';
                it.text = this.namePipe.transform('', item.firstName, item.surName,
                    this.translate.instant('Report.DailyChart.nameFormat'));
                infoLineitemDescription.iconTexts.push(it);
                infoLineitemDescription.extraInfo = isCoDriver;
                const currentLang = this.translate.currentLang;
                this.translate.currentLang = this.chartLanguage;
                const dateFormat = this.translate.instant('Report.General.TimeFormat');
                this.translate.currentLang = currentLang;
                const dateInterval = moment(item.startDateOfDriving * 1000).tz(timezone).myFormat(dateFormat)
                    + ' - ' + moment(item.endDateOfDriving * 1000).tz(timezone).myFormat(dateFormat);
                infoLineitemDescription.text = dateInterval;
                if (isCoDriver) {
                    const lastInfoLineItemDescription = infoLineitemDescriptions[infoLineitemDescriptions.length - 1];
                    if (!!lastInfoLineItemDescription && ((!lastInfoLineItemDescription.crossingMinute && !infoLineitemDescription.crossingMinute)
                         || DailyChartService.isTwoCrossingMinutesInOneMinute(lastInfoLineItemDescription?.crossingMinute, infoLineitemDescription.crossingMinute))) {
                        infoLineitemDescription = infoLineitemDescriptions[infoLineitemDescriptions.length - 1];
                        infoLineitemDescription.iconTexts.push(it);
                        infoLineitemDescription.extraInfo = isCoDriver;
                        infoLineitemDescriptions[infoLineitemDescriptions.length - 1] = infoLineitemDescription;
                        return;
                    }
                }
                infoLineitemDescriptions.push(infoLineitemDescription);
                const endDateOfDriving = item.endDateOfDriving - recordDate;
                if (endDateOfDriving > 0 && endDateOfDriving < 86400) {
                    const newInfoLine = new InfoLineitemDescription();
                    newInfoLine.crossingMinute = endDateOfDriving / 60;
                    newInfoLine.text = dateInterval;
                    const newIt = new IconText();
                    newIt.icon = 'down_arrow_icon';
                    newInfoLine.iconTexts.push(newIt);
                    infoLineitemDescriptions.push(newInfoLine);
                }
            })
        }
        return infoLineitemDescriptions;
    }

    private mapDrivingsWithoutCardToInfolineItems(drivingsWithoutCard: Fault[], recordDate: number): InfoLineitemDescription[] {
        return drivingsWithoutCard?.map(withoutCard => {
            const infoLineItem = new InfoLineitemDescription();
            infoLineItem.crossingMinute = (withoutCard.eventBeginTime - recordDate) / 60;
            const it = new IconText();
            it.icon = 'no_card_icon';
            it.text = Math.floor((withoutCard.eventEndTime - withoutCard.eventBeginTime) / 60).toString();
            infoLineItem.iconTexts.push(it);
            return infoLineItem;
        });
    }

    private mapFaults(faults: Fault[]): Fault[] {
        return faults?.map(fault => {
            DailyChartService.convertFaultCode(fault);
            return fault;
        }).sort(Fault.compare) || faults;
    }

    private filterDuplications(driversOnDay: DriversOnDay[]) {
        const filteredDriversOnDay: DriversOnDay[] = [];
        driversOnDay.forEach(value => {
            if (filteredDriversOnDay.filter(item => {
                return item.firstName === value.firstName && item.surName === value.surName
                    && item.useFrom === value.useFrom && item.cardSlot === value.cardSlot
            }).length === 0) {
                filteredDriversOnDay.push(value);
            }
        })
        return filteredDriversOnDay;
    }

    private sortDriversOnDay(driversOnDay: DriversOnDay[]): DriversOnDay[] {
        return driversOnDay.sort((a, b) => {
            if (!a.useFrom && b.useFrom) {
                return -1;
            }

            if (!b.useFrom && a.useFrom) {
                return 1;
            }

            if ((!a.useFrom && !b.useFrom) || DailyChartService.isTwoEpocInOneMinute(a.useFrom, b.useFrom)) {
                if (a.cardSlot === CardSlot.DRIVER && b.cardSlot === CardSlot.CODRIVER) {
                    return -1;
                }

                if (a.cardSlot === CardSlot.CODRIVER && b.cardSlot === CardSlot.DRIVER) {
                    return 1;
                }

                if (a.cardSlot === b.cardSlot) {
                    return 0;
                }
            }

            if (a.useFrom < b.useFrom) {
                return -1;
            }

            if (b.useFrom < a.useFrom) {
                return 1;
            }

            // if (a.cardSlot === CardSlot.DRIVER && b.cardSlot === CardSlot.CODRIVER) {
            //     return -1;
            // }
            //
            // if (a.cardSlot === CardSlot.CODRIVER && b.cardSlot === CardSlot.DRIVER) {
            //     return 1;
            // }

            // if (a.cardSlot === b.cardSlot) {
            //     return 0;
            // }
        });
    }

    onMenuChange(): EventEmitter<any> {
        return this.changeMenuEmitter;
    }

    menuChanged() {
        this.changeMenuEmitter.emit();
    }

    dataReadyToPrint(tab: TabOption) {
        const format = 'YYYY-MM-DD';
        const reportContext = this.getReportContext(tab).reportContext;
        const startDate = moment(reportContext.periodStartTime * 1000).myFormat(format);
        const endDate = moment(reportContext.periodEndTime * 1000).myFormat(format);
        const timezone = reportContext.dailyChartResponses[0]?.timeZone || 'UTC';
        let entityOrCompany = this.companyName?.replaceAll(' ', '_') || 'Company_wide';
        if (reportContext.dailyChartResponses.length === 1) {
            const entityData = reportContext.dailyChartResponses[0];
            if (tab === TabOption.DRIVER) {
                entityOrCompany = entityData.driverInfo.cardHolderSurname.toUpperCase() + '_' +
                    entityData.driverInfo.cardHolderFirstNames.replaceAll(' ', '_');
            } else {
                entityOrCompany = entityData.vehicleInfo.registrationNumber.replaceAll(' ', '_');
            }
        }

        const title = document.title;
        document.title = [startDate, endDate, 'Daily', 'chart', tab.toLowerCase(), entityOrCompany, 'diagram', timezone].join('_');
        setTimeout(() => {
            window.print();
            document.title = title;
        });
    }

    getCurrentCompanyName(currentCompanyId: number) {
        this.companyService.getAllSubcompanies()
            .pipe(take(1))
            .subscribe((response) => {
                const availableCompanies = response;

                availableCompanies.sort(function (a, b) {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x < y) {
                        return -1;
                    }
                    if (x > y) {
                        return 1;
                    }
                    return 0;
                });

                const selectedLocalCompany: Company = availableCompanies.find((aCopmany) => aCopmany.id === currentCompanyId);
                this.printService.companyName = selectedLocalCompany.name;
            });
    }

    getDailyChartTabEnumByValue(value: string): TabOption {
        switch (value) {
            case 'DRIVER':
                return TabOption.DRIVER;
            case 'VEHICLE':
                return TabOption.VEHICLE;
        }
    }

    dateChange(event: MatDatepickerInputEvent<Date>) {
        // idiot hack because of datePicker weakness
        if (event.target instanceof MatStartDate) {
            this.reportContexts.forEach(value => {
                value.reportContext.chartQueryForm.get('rangeFormGroup').get('start').setValue(event.target.value);
            })
        }
        if (event.target instanceof MatEndDate) {
            this.reportContexts.forEach(value => {
                value.reportContext.chartQueryForm.get('rangeFormGroup').get('end').setValue(event.target.value);
            })
        }
        if (event.target instanceof MatStartDate || event.target instanceof MatEndDate) {
            this.reportContexts.forEach(value => {
                value.reportContext.chartQueryForm.get('preselector').setValue(DateIntervalOptions.CUSTOM);
            });
        }
    }

    private startDailyChartProgressBar() {
        this.progressBarService.needed.next(true);
        this.progressBarService.mode.next('query');
    }

    private stopDailyChartProgressBar() {
        this.progressBarService.needed.next(false);
        this.printService.progressBarNeeded = false;
    }

    public abortQuery() {
        this.dailyChartSubscription?.unsubscribe();
    }

    private resetReportContext(tab: TabOption) {
        this.getReportContext(tab).reportContext.chartDataReady = false;
        this.getReportContext(tab).reportContext.dailyChartResponses = [];
        this.getReportContext(tab).reportContext.dailyChartInputsTemp = [];
        this.getReportContext(tab).reportContext.dailyChartInputsPaged = [];
        this.getReportContext(tab).reportContext.dailyChartInputsPagedTemp = [];
        this.getReportContext(tab).reportContext.dailyChartInputs = [];
        this.getReportContext(tab).reportContext.indexOfLastChartToLoad = -1;
        this.getReportContext(tab).reportContext.selectedDriverIndex = 0;
    }

    public getNumberOfHoursInDay(m: Moment): number {
        const a = moment(m).startOf('day');
        const b = moment(m).add(1, 'day').startOf('day');
        return b.diff(a, 'hours');
    }
}
