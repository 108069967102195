import {Pipe, PipeTransform} from '@angular/core';
import {VehicleInfo} from '../../services/vehicles/vehicles.service';

@Pipe({
    name: 'vehicleFilter'
})
export class VehicleFilterPipe implements PipeTransform {

    transform(vehicles: VehicleInfo[], vehicleIdentFilter: any): VehicleInfo[] {

        if (!vehicles) {
            return vehicles;
        }

        // prepare driverNameFilter
        if (!vehicleIdentFilter || vehicleIdentFilter.length === 0) {
            return vehicles;
        }

        let term: string;

        if (typeof vehicleIdentFilter === 'string') {
            term = vehicleIdentFilter.replace(/\s/g, '');
        } else {
            term = vehicleIdentFilter.registrationNumber;
        }


        // escape characters witch are part of regex constants
        const search_item = new RegExp(term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

        return vehicles.filter(vehicle => search_item.test(vehicle.registrationNumber.toString().replace(/\s/g, '')));
    }
}
