<div  class="driverReportDataContainer print">
    <div class="driverReportOuterCont summary" *ngIf="dailyChartResponses.length > 1">
        <ng-container [ngTemplateOutlet]="rowReportName"
                      [ngTemplateOutletContext]="{dailyChartService: dailyChartService}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="rowPeriod"
                      [ngTemplateOutletContext]="{dailyChartService: dailyChartService}">
        </ng-container>
        <span *ngIf="tab === tabOptions.DRIVER">
            <div class="driverReportDataRow" *ngFor="let dailyChartResponse of dailyChartResponses; let i = index">
                <div class="driverReportDataCol1">
                    <span *ngIf="i == 0">
                        {{ 'Report.General.Drivers' | instantTranslate: dailyChartService.chartLanguage | async }}
                    </span>
                </div>
                <div class="driverReportDataCol2 margin-right_10">
                    <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                </div>
                <div class="driverReportDataCol3">
                    {{ '' | nameChart:dailyChartResponse.driverInfo.cardHolderFirstNames:dailyChartResponse.driverInfo.cardHolderSurname:('Report.DailyChart.nameFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                    {{ dailyChartResponse.driverInfo.cardNumber }}
                    {{ dailyChartResponse.driverInfo.cardIssuingMemberState }}
                </div>
            </div>
        </span>
        <span *ngIf="tab === tabOptions.VEHICLE">
            <div class="driverReportDataRow" *ngFor="let dailyChartResponse of dailyChartResponses; let i = index">
                <div class="driverReportDataCol1">
                    <span *ngIf="i == 0">
                        {{ 'Report.DailyChart.vehicles' | instantTranslate: dailyChartService.chartLanguage | async }}
                    </span>
                </div>
                <div class="tab">
                    <mat-icon svgIcon="track_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    <div>
                        <div>
                            <b>
                                {{ dailyChartResponse.vehicleInfo.country }}
                                {{ dailyChartResponse.vehicleInfo.registrationNumber }}
                            </b>
                            {{ 'Report.DailyChart.vin' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ 'Report.DailyChart.vin' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.vehicleInfo.identificationNumber }}
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>

    <div class="dailyChartResponse" *ngFor="let dailyChartResponse of dailyChartResponses; let responseIndex = index">
        <div class="driverReportOuterCont">
            <div class="driverReportDataRow">
                <ng-container [ngTemplateOutlet]="rowReportName"
                              [ngTemplateOutletContext]="{dailyChartService: dailyChartService}">
                </ng-container>
            </div>
            <div class="driverReportDataRow">
                <ng-container [ngTemplateOutlet]="rowPeriod"
                              [ngTemplateOutletContext]="{dailyChartService: dailyChartService}">
                </ng-container>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.DRIVER">
                <div class="driverReportDataCol1">{{ 'Report.DailyChart.driverCard' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    <div>
                        <div>
                            <b>
                                {{ '' | nameChart:dailyChartResponse.driverInfo.cardHolderFirstNames:dailyChartResponse.driverInfo.cardHolderSurname:('Report.DailyChart.nameFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                            </b>
                            {{ dailyChartResponse.driverInfo.cardNumber }}
                            {{ dailyChartResponse.driverInfo.cardIssuingMemberState }}
                            {{ dailyChartResponse.driverInfo.cardHolderBirthDate | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                        </div>
                        <div>
                            {{ dailyChartResponse.driverInfo.cardInfo.cardApprovalNumber }}
                            SN: {{ dailyChartResponse.driverInfo.cardInfo.serialNumber }}
                            {{ 'Report.DailyChart.validUntil' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.driverInfo.cardInfo.cardExpiryDate | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                            <mat-icon *ngIf="dailyChartResponse.driverInfo.cardInfo.valid"
                                      svgIcon="completed_icon"
                                      class="dailyChartIcons"></mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.VEHICLE">
                <div class="driverReportDataCol1">
                    {{ 'Report.General.Vehicle' | instantTranslate: dailyChartService.chartLanguage | async }}:
                </div>
                <div class="tab">
                    <mat-icon svgIcon="track_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    <div>
                        <div>
                            <b>
                                {{ dailyChartResponse.vehicleInfo.country }}
                                {{ dailyChartResponse.vehicleInfo.registrationNumber }}
                            </b>
                            {{ 'Report.DailyChart.vin' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.vehicleInfo.identificationNumber }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.VEHICLE">
                <div class="driverReportDataCol1">&nbsp;</div>
                <div class="tab">
                    <mat-icon svgIcon="location_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    {{ dailyChartResponse.vehicleInfo.odometerBegin | number: '':'hu' }} -
                    {{ dailyChartResponse.vehicleInfo.odometerEnd | number: '':'hu' }}
                    km,&nbsp;
                    <span>&sum;</span>&nbsp;
                    {{ dailyChartResponse.vehicleInfo.odometerSumma | number: '':'hu' }} km<br/>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div class="driverReportDataCol1">{{ 'Report.DailyChart.activeDays' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <span class="dailyChartIcons margin-right_10"></span>
                    <span *ngIf="tab === tabOptions.DRIVER">
                        {{ dailyChartResponse.driverInfo.driverActiveDaysInPeriod }}
                        /
                        {{ dailyChartResponse.driverInfo.queryPeriodInDays }}
                    </span>
                    <span *ngIf="tab === tabOptions.VEHICLE">
                        {{ dailyChartResponse.vehicleInfo.activeDaysInPeriod }}
                        /
                        {{ dailyChartResponse.vehicleInfo.queryPeriodInDays }}
                    </span>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div class="driverReportDataCol1">{{ 'Report.DailyChart.validation' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <span class="dailyChartIcons margin-right_10"></span>
                    {{ 'Report.DailyChart.digitalSignatureValidated' | instantTranslate: dailyChartService.chartLanguage | async }}
                    <mat-icon svgIcon="completed_icon" class="dailyChartIcons"></mat-icon>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div class="driverReportDataCol1">{{ 'Report.General.Timezone' | instantTranslate: dailyChartService.chartLanguage | async }}:</div>
                <div class="tab">
                    <mat-icon svgIcon="timezone_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                    <span>{{ dailyChartResponse.timeZone }}</span>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div
                    class="driverReportDataCol1">{{ 'Report.DailyChart.source' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <span *ngIf="tab === tabOptions.DRIVER" style="display: flex">
                        <mat-icon svgIcon="driver_card_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                        {{ 'Report.DailyChart.sourceType1' | instantTranslate: dailyChartService.chartLanguage | async }}
                        , {{ 'Report.DailyChart.downloadTime' | instantTranslate: dailyChartService.chartLanguage | async }}
                        {{ dailyChartResponse.driverInfo.driverCardDownloadTimeMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                    </span>
                    <span *ngIf="tab === tabOptions.VEHICLE" style="display: flex">
                        <mat-icon svgIcon="driver_card_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                        <span>{{ 'Report.General.Vehicle' | instantTranslate: dailyChartService.chartLanguage | async }}
                            , {{ 'Report.DailyChart.downloadTime' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.vehicleInfo.lastDownloadTimeMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.VEHICLE">
                <div
                    class="driverReportDataCol1">&nbsp;</div>
                <div class="tab">
                    <mat-icon class="dailyChartIcons margin-right_10"></mat-icon>
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.manufacturerName }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.approvalNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.partNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.softwareVersion }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.serialNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.monthYear }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.sensorApprovalNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.sensorSerialNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.sensorMonthYear }}
                </div>
            </div>
            <span *ngIf="tab === tabOptions.DRIVER">
                <div class="driverReportDataRow" *ngFor="let vehicle of dailyChartResponse.driverInfo.vehicles; let i = index">
                    <div class="driverReportDataCol1"><span *ngIf="i == 0">{{ 'Report.DailyChart.vehicles' | instantTranslate: dailyChartService.chartLanguage | async }}</span></div>
                     <div class="tab">
                        <div style="display:flex; width: 100%;">
                            <div style="display: flex; flex: 20%;">
                                <mat-icon svgIcon="track_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                                {{ vehicle.vehicleRegistrationNation }}
                                {{ vehicle.vehicleRegistrationNumber }}
                            </div>
                            <div style="display: flex; flex: 40%">
                                <mat-icon svgIcon="calendar_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                                {{ vehicle.vehicleFirstUseMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                                -
                                {{ vehicle.vehicleLastUseMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                            </div>
                            <div style="display: flex; flex: 27%">
                                <mat-icon svgIcon="location_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                                {{ vehicle.odometerBegin | number: '':'hu' }} -
                                {{ vehicle.odometerEnd | number: '':'hu' }}

                                km
                            </div>
                            <div style="display: flex; flex: 13%">
                                <span>&sum;</span>&nbsp;
                                {{ vehicle.odometerSumma | number: '':'hu' }} km<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <span *ngIf="tab === tabOptions.VEHICLE">
                <div class="driverReportDataRow" *ngFor="let driver of dailyChartResponse.vehicleInfo.drivers; let i = index">
                    <div class="driverReportDataCol1">
                        <span *ngIf="i == 0">
                            {{ 'Report.DailyChart.driversOfVehicle' | instantTranslate: dailyChartService.chartLanguage | async }}
                        </span>
                    </div>
                    <div class="tab">
                        <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                        {{ driver.surName }}
                        {{ driver.firstName }}
                        {{ driver.cardNumber }}
                        <span *ngIf="driver.birthDateMoment">{{driver.birthDateMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async)}}</span>
                        {{ driver.useFromMoment ? driver.useFromMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) : 'N/A' }}
                        -
                        {{ driver.useToMoment ? driver.useToMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) : 'N/A' }}
                    </div>
                </div>
            </span>
        </div>

        <ng-container [ngTemplateOutlet]="noDataTemplate"
                      [ngTemplateOutletContext]="{dailyChartResponse: dailyChartResponse}"></ng-container>
        <div *ngIf="isActiveDayInPeriod(dailyChartResponse)">
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>
            <div class="driverReportChartContainer" *ngFor="let item of dailyChartResponse.dailyChartInputsTemp; let i = index">
                <div>
                   <ng-container [ngTemplateOutlet]="appdailychart"
                                 [ngTemplateOutletContext]="{item: item, index: i, responseIndex: responseIndex}">
                   </ng-container>
                </div>
            </div>
        </div>

<!--        <div *ngIf="getDriverLastPageRemainChartCount(responseIndex) != 0" class="footer" [ngClass]="getDriverLastPageRemainChartCount(responseIndex) === 1 ? 'userlast1' : 'userlast2'">-->
<!--        </div>-->
    </div>
</div>

<ng-template #noDataTemplate let-dailyChartResponse="dailyChartResponse">
    <div class="noActiveDayInPeriod" *ngIf="isPeriodAfterLastDownload(dailyChartResponse)">
        <div class="noActiveDayContainer">
            <mat-icon svgIcon="file_download_off_grey_icon" class="noActiveDayIcon"></mat-icon>
            <span class="noActiveDayText" *ngIf="tab === tabOptions.DRIVER">{{ 'Report.DailyChart.noDownloadedDataInPeriodDriver' | instantTranslate: dailyChartService.chartLanguage | async }}</span>
            <span class="noActiveDayText" *ngIf="tab === tabOptions.VEHICLE">{{ 'Report.DailyChart.noDownloadedDataInPeriodVehicle' | instantTranslate: dailyChartService.chartLanguage | async }}</span>
        </div>
    </div>
    <div class="noActiveDayInPeriod" *ngIf="!isPeriodAfterLastDownload(dailyChartResponse) && !isActiveDayInPeriod(dailyChartResponse)">
        <div class="noActiveDayContainer">
            <mat-icon svgIcon="no_data_icon2" class="noActiveDayIcon"></mat-icon>
            <span class="noActiveDayText" *ngIf="tab === tabOptions.DRIVER">{{ 'Report.DailyChart.noActiveDayInPeriodDriver' | instantTranslate: dailyChartService.chartLanguage | async }}</span>
            <span class="noActiveDayText" *ngIf="tab === tabOptions.VEHICLE">{{ 'Report.DailyChart.noActiveDayInPeriodVehicle' | instantTranslate: dailyChartService.chartLanguage | async }}</span>
        </div>
    </div>
</ng-template>

<ng-template #chartLegend>
    <div class="dailyChartLegend">
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_DRIVE']}"></span>
            {{ 'Report.DailyChart.series3Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_WORK']}"></span>
            {{ 'Report.DailyChart.series4Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_AVAILABLE']}"></span>
            {{ 'Report.DailyChart.series2Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_REST']}"></span>
            {{ 'Report.DailyChart.series1Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_CREW_DRIVING']}"></span>
            {{ 'Report.DailyChart.series6Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_WORKING_TIME']}"></span>
            {{ 'Report.DailyChart.series5Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
        <div class="dailyChartLegendItem">
            <span class="dot" [style]="{'background-color': colorCodes['COLOR_INFRINGMENT']}"></span>
            {{ 'Report.DailyChart.series7Name' | instantTranslate:dailyChartService.chartLanguage | async }}
        </div>
    </div>
</ng-template>

<ng-template #appdailychart let-item='item' let-index='index' let-responseIndex='responseIndex'>
    <app-dailychart
        [dailyChartInput]="item"
        [currentLang]="dailyChartService.chartLanguage"
        [indexOfChart]="index"
        [responseIndex]="responseIndex"
        (chartLoaded)="dailyChartService.fillNextDailyChartInput($event, printService.tab)"
        (highChartObjectCreated)="reportContext.highChartObjects[reportContext.countOfCharts] = $event"
        [isPrinting]="true"
        [typeOfChart]="tab">
    </app-dailychart>
</ng-template>

<ng-template #rowReportName let-dailyChartService='dailyChartService'>
    <div class="driverReportDataRow">
        <div class="driverReportDataCol1">
            {{ 'Report.DailyChart.nameOfReport' | instantTranslate: dailyChartService.chartLanguage | async }}
        </div>
        <div class="tab">
            <mat-icon svgIcon="chart_name_icon" class="dailyChartIcons margin-right_10"></mat-icon>
            <span *ngIf="tab === tabOptions.DRIVER">
                {{ 'Report.DailyChart.dailyChartDriver' | instantTranslate: dailyChartService.chartLanguage | async }}
            </span>
            <span *ngIf="tab === tabOptions.VEHICLE">
                {{ 'Report.DailyChart.dailyChartVehicle' | instantTranslate: dailyChartService.chartLanguage | async }}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #rowPeriod let-dailyChartService='dailyChartService'>
    <div class="driverReportDataRow">
        <div class="driverReportDataCol1">
            {{ 'Report.General.Period' | instantTranslate: dailyChartService.chartLanguage | async }}:
        </div>
        <div class="tab">
            <span class="dailyChartIcons margin-right_10"></span>
            {{ reportContext.periodStartTime | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
            - {{ reportContext.periodEndTime | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
        </div>
    </div>
</ng-template>
