import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DriverActivitiesForPeriod, DriversService} from '../../../services/drivers/drivers.service';
import moment from './../../../moment.override';
import * as Highcharts from 'highcharts';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ProgressBarService} from '../../../services/progress-bar/progress-bar.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-driver-chart',
  templateUrl: './driver-chart.component.html',
  styleUrls: ['./driver-chart.component.css']
})
export class DriverChartComponent implements OnInit, OnChanges  {
    @Input() data: DriverActivitiesForPeriod;
    @Input() driverId: number;
    @Input() periodStart: number;
    @Input() periodEnd: number;
    @Input() renderTo: string;
    @Input() detectedAt: number;

    driverDataForADay = [{
        driveData : [],
        workData : [],
        availableData : [],
        restData : [],
        notInsertedData : [],
        vehicleData : [],
        annotation : [],
        summaryDrive : null,
        summaryRest : null,
        summaryWork : null,
        summaryAvailable : null
    }];

    highchartDatas = []; // highchart datas
    highChartsVehicle = [];
    noDataText = false;
    startCountry = null;
    endCountry = null;
    startOfWeek: any;

    /**
     * To header, where we calculate the summary
     */
    summaryDrive = 0;
    summaryRest = 0;
    summaryWork = 0;
    summaryAvailable = 0;

    /**
     * calculate each event visibility data
     */
    driveData = [];
    workData = [];
    availableData = [];
    restData = [];
    notInsertedData = [];
    vehicleData = [];
    annotation = [];

    /**
     * store the summaries of the different events(drive, work, available, rest...)
     */
    durationArray = [];

    startCountryName = '';
    endCountryName = '';

    rangeInDiagramm = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });
    startHour: number;
    startMin: number;
    endHour: number;
    endMin: number;
    timeout: any;
    _subscription: Subscription;

    constructor(private _translate: TranslateService,
                private progressBarService: ProgressBarService,
                private _driverService: DriversService) {
        this._subscription = new Subscription();
    }

    ngOnInit(): void {
        this.startHour = 0;
        this.startMin = 0;
        this.endHour = 23;
        this.endMin = 59;
        this.progressBarService.mode.next('query');
        this.startOfWeek = this.getMonday(new Date());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (document.getElementById('container') !== null) {
            document.getElementById('container').id = this.renderTo.toString();
        }
        this.rangeInDiagramm.controls.start.setValue(new Date(this.periodStart * 1000));
        this.rangeInDiagramm.controls.end.setValue(new Date(this.periodEnd * 1000));
        this.startHour = new Date(this.periodStart * 1000).getHours();
        this.startMin = new Date(this.periodStart * 1000).getMinutes();
        this.endHour = new Date(this.periodEnd * 1000).getHours();
        this.endMin = new Date(this.periodEnd * 1000).getMinutes();
        this.fromResponseToActivitiesSummary(changes?.data?.currentValue?.data);
    }

    checkTime() {
        this.startHour = this.startHour > 23 ? 23 : this.startHour;
        this.startHour = this.startHour < 0 ? 0 : this.startHour;
        this.endHour = this.endHour > 23 ? 23 : this.endHour;
        this.endHour = this.endHour < 0 ? 0 : this.endHour;

        this.startMin = this.startMin > 59 ? 59 : this.startMin;
        this.startMin = this.startMin < 0 ? 0 : this.startMin;
        this.endMin = this.endMin > 59 ? 59 : this.endMin;
        this.endMin = this.endMin < 0 ? 0 : this.endMin;
    }

    startTimeout() {
        this.checkTime();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => { this.reloadDiagramm() }, 3000);
    }

    reloadDiagramm() {
        const startDate = new Date(this.rangeInDiagramm.controls.start.value.getTime());
        startDate.setHours(this.startHour);
        startDate.setMinutes(this.startMin);
        const endDate = new Date(this.rangeInDiagramm.controls.end.value.getTime());
        endDate.setHours(this.endHour);
        endDate.setMinutes(this.endMin);
        this.progressBarService.needed.next(true);
        const subscription = this._driverService
            .myGetActivitiesForInfringements(
                this.driverId,
                Math.floor(startDate.getTime() / 1000),
                Math.floor(endDate.getTime() / 1000),
                localStorage.getItem('selectedCompanyId')
            )
            .pipe(finalize(() => this.progressBarService.needed.next(false)))
            .subscribe(resp => {
                this.data = resp;
                this.fromResponseToActivitiesSummary(this.data.data);
            });
        this._subscription = subscription;
    }


    getMonday(d) {
        d = new Date(d);
        const day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return Math.floor(new Date(d.setDate(diff)).setHours(0, 0, 0 ) / 1000);
    }

    fromResponseToActivitiesSummary(data) {
        this.noDataText = false;
        // document.getElementById(this.renderTo);
        // while ( el.firstChild ) { el.removeChild( el.firstChild ); }

        this.highchartDatas = [];
        this.highChartsVehicle = [];
        data.map(item => {
            this.highchartDatas = this.highchartDatas.concat(item.activities);
            this.highChartsVehicle = this.highchartDatas.concat(item.vehicles);
        });
        if (this.highchartDatas.length === 0) {
            this.noDataText = true;
        }
        this.driverDataForADay = [];
        this.calculateMultipleHighchartsDatas(
            this.highchartDatas, this.highChartsVehicle, this.startCountry, this.endCountry
        );
        // console.log(this.highchartDatas);
        this.driverDataForADay.forEach(driver => {
            if (
                driver.driveData.length === 0 &&
                driver.workData.length === 0 &&
                driver.availableData.length === 0 &&
                driver.restData.length === 0 &&
                driver.notInsertedData.length === 0
            ) {
            } else {
                this.drawMultipleHighcharts(
                    this.renderTo.toString(),
                    driver.vehicleData,
                    driver.workData,
                    driver.driveData,
                    driver.availableData,
                    driver.restData,
                    driver.notInsertedData,
                    driver.annotation,
                    this.startOfWeek
                )
            }
        })
    }

    calculateMultipleHighchartsDatas(highchartDatas, highChartsVehicle, startCountry, endCountry) {
        this.restData = [];
        this.availableData = [];
        this.driveData = [];
        this.workData = [];
        this.notInsertedData = [];
        this.vehicleData = [];
        this.summaryDrive = 0;
        this.summaryRest = 0;
        this.summaryWork = 0;
        this.summaryAvailable = 0;
        this.durationArray = [];

        this.addAnnotation(startCountry, endCountry);
        highchartDatas.forEach((item, index) => {
            this.durationArray.push({
                startTime: new Date((item.day + item.startTime * 60) * 1000),
                endTime: new Date((item.day + item.endTime * 60) * 1000),
                type: item.type
            });
            switch (item.type) {
                case 0 : {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryRest += (this.periodEnd - ( item.day + item.startTime * 60)) / 60;
                        this.addNewRest(item, this.periodEnd);
                    } else {
                        this.addNewRest(item);
                        this.summaryRest += item.duration;
                    }
                    break;
                }
                case 1: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryAvailable += (this.periodEnd - ( item.day + item.startTime * 60)) / 60;
                        this.addNewAvailable(item, this.periodEnd);
                    } else {
                        this.addNewAvailable(item);
                        this.summaryAvailable += item.duration;
                    }
                    break;
                }
                case 2: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryWork += (this.periodEnd - ( item.day + item.startTime * 60)) / 60;
                        this.addNewWork(item, this.periodEnd);
                    } else {
                        this.addNewWork(item);
                        this.summaryWork += item.duration;
                    }
                    break;
                }
                case 3: {
                    if (index === (highchartDatas.length - 1)) {
                        this.summaryDrive += (this.periodEnd - ( item.day + item.startTime * 60)) / 60;
                        this.addNewDrive(item, this.periodEnd)
                    } else {
                        this.addNewDrive(item);
                        this.summaryDrive += item.duration;
                    }
                    break;
                }
                case 4: {
                    if (index === (highchartDatas.length - 1)) {
                        this.addNewNotInsertedDate(item, this.periodEnd)
                        this.summaryRest +=  (this.periodEnd - ( item.day + item.startTime * 60)) / 60;
                    } else {
                        this.addNewNotInsertedDate(item);
                        this.summaryRest += item.duration;
                    }
                    break;
                }
            }
        });

        this.driverDataForADay.push({
            driveData : this.driveData,
            workData : this.workData,
            availableData : this.availableData,
            restData : this.restData,
            notInsertedData : this.notInsertedData,
            vehicleData : this.vehicleData,
            annotation : this.annotation,
            summaryDrive : this.summaryDrive,
            summaryRest : this.summaryRest,
            summaryWork : this.summaryWork,
            summaryAvailable : this.summaryAvailable
        });
        // console.log(this.driverDataForADay)
    }

    drawMultipleHighcharts(name, vehicleData, workData, driveData, availableData, restData, notInsertedData, annotation, weekStart) {
        const that = this;
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log(new Date(Math.floor(that.detectedAt * 1000)));
        Highcharts.setOptions( {
            time: {
                timezone: currentTimezone
            }
        });
        Highcharts.chart(that.renderTo.toString(), {
            chart: {
                type: 'area',
                height: 200,
                zoomType: 'x',
                renderTo: that.renderTo.toString(),
                backgroundColor: 'transparent'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '<p style="font-weight:bold">%e. %b</b>',
                    week: '%e. %b',
                    month: '%b \'%y',
                    year: '%Y'
                },
                events: {
                    afterSetExtremes: function(event) {
                        const min = Math.trunc(event.min);
                        const max = Math.trunc(event.max);

                        that.calculateInDuration(min, max);
                    }
                },
                tickInterval: 3600 * 1000,
                plotLines: [{
                    color: '#000000',
                    width: 2,
                    value: that.detectedAt * 1000
                }],
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter: function () {
                        let name;
                        name = '';
                        return name;
                    }
                }
            },
            tooltip: {
                animation: false,
                backgroundColor: '#333333',
                style: {
                    color: '#ffffff'
                },
                borderColor: 'red',
                borderRadius: 10,
                borderWidth: 1.5,
                followPointer: false,
                formatter: function() {
                    // @ts-ignore
                    const visibileData = JSON.parse(this.point.mydata);
                    let tooltipText: string;
                    const text = JSON.parse(localStorage.getItem('diagramText'));
                    // if (this.series.index === 0) {
                    //     tooltipText = this.series.name;
                    // } else {
                    tooltipText = this.series.name +
                        '<br>' + that._translate.instant('Duration') + ': ' +
                        ((Math.floor(visibileData.duration / 60) < 10) ?
                            '0' + Math.floor(visibileData.duration / 60) :
                            Math.floor(visibileData.duration / 60)) + ':' +
                        ((visibileData.duration % 60 < 10) ?
                            '0' + Math.floor(visibileData.duration % 60) :
                            Math.floor(visibileData.duration % 60)) +
                        '<br>' + that._translate.instant('Start date') + ' :' +
                        moment(
                            new Date((visibileData.day + visibileData.startTime * 60) * 1000)
                        ).myFormat('HH:mm') +
                        '<br>' + that._translate.instant('End date') + ' :' +
                        moment(
                            new Date((visibileData.day + visibileData.endTime * 60) * 1000)
                        ).myFormat('HH:mm');
                    ;
                    return tooltipText;
                }
            },
            colors: [
                'rgba(0,0,255,0.2)',
                'rgba(255,0,0,0.2)',
                'rgba(247, 147, 29, 0.2)',
                'rgba(0, 255, 0, 0.2)',
                'rgba(255,255,255,0.2)',
                'rgba(0,0,0,0)'
            ],
            annotations: [{
                draggable: '',
                labelOptions: {
                    useHTML: true,
                    align: 'left',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    verticalAlign: 'top'
                },
                labels: [{
                    point: {
                        x: Math.floor(that.detectedAt * 1000),
                        y: 5,
                        xAxis: 0,
                        yAxis: 0
                    },
                    useHTML: true,
                    text: '<div> <span class="material-icons-outlined" style="color: orange; font-size: 16px !important; vertical-align: top; height: 25px;"> error_outline </span>' +
                          '<span style="font-weight: bold"> ' + that._translate.instant('infringement started') + '</span> <br> <span>' + that.formatDateWithMonth(that.detectedAt) + '</span> </div>',
                }]
            }],
            plotOptions: {
                series: {
                    animation: false,
                },
            },
            // annotations: this.annotation,
            series: [{
                name: this._translate.instant('Work'),
                data: workData,
                type: 'area'
            }, {
                name: this._translate.instant('Drive'),
                data: driveData,
                type: 'area'
            }, {
                name: this._translate.instant('Available'),
                data: availableData,
                type: 'area'
            }, {
                name: this._translate.instant('Rest'),
                data: restData,
                type: 'area'
            }, {
                name: this._translate.instant('Not inserted'),
                data: notInsertedData,
                type: 'area'
            }, {
                data: annotation,
                type: 'area',
                name: ''
            }]
        });
    }

    formatDateWithMonth(ts) {
        if (ts === 0 || ts === '') {
            return '---';
        } else {
            return moment.parseZone(ts * 1000).myFormat('LLL');
        }
    }

    formatDurationFromMinutes(duration) {
        return (((Math.floor(duration / 60) < 10) ?
            '0' + Math.floor(duration / 60) :
            Math.floor(duration / 60)) + ':' +
            ((duration % 60 < 10) ?
                '0' + Math.floor(duration % 60) :
                Math.floor(duration % 60)))
    }

    calculateInDuration(min, max) {
        this.periodStart = min / 1000;
        this.periodEnd = max / 1000;
        // console.log(this.durationArray);
        for (let i = 0; i < 4; i ++ ) {
            let total = 0;
            let selectedArray = [];
            selectedArray = this.durationArray.filter(item =>
                (item.type === i) &&
                ((item.startTime > min && item.startTime < max) ||
                    (item.endTime > min  && item.endTime < max) ||
                    (item.startTime < min && item.endTime > max))
            );
            // selectedArray = this.durationArray.filter(item =>
            //     (item.type === i) &&
            //     ((new Date(item.startTime).getTime() < min && item.endTime > min && item.endTime < max) ||
            //         (item.startTime > min  && item.endTime < max) ||
            //         (item.startTime > min && item.start < max && item.endTime > max))
            // );
            // console.log('selectedArray', selectedArray);
            selectedArray.forEach(value => total += ((value.endTime > max) ? max : value.endTime) - ((value.startTime > min) ? value.startTime : min));
            switch (i) {
                case(0) : {
                    this.summaryRest = total / 60000;
                    break;
                }
                case(1) : {
                    this.summaryAvailable = total / 60000;
                    break;
                }
                case(2): {
                    this.summaryWork = total / 60000;
                    break;
                }
                case(3): {
                    this.summaryDrive = total / 60000;
                    break;
                }
            }
        }
    }

    addAnnotation(startCountry, endCountry) {
        this.annotation = [];
        if (startCountry !== null) {
            if (startCountry.entryTime !== 0) {
                this.annotation.push({
                    x: new Date(startCountry.entryTime * 1000),
                    y: 0
                });
                this.annotation.push({
                    x: new Date(startCountry.entryTime * 1000),
                    y: 4,
                    id: 'point1'
                });
                this.annotation.push({
                    x: new Date(startCountry.entryTime * 1000),
                    y: 0
                });
                this.startCountryName = startCountry.country;
            }
        }
        if (endCountry !== null) {
            if (endCountry.entryTime !== 0) {
                this.annotation.push({
                    x: new Date(endCountry.entryTime * 1000),
                    y: 0
                });
                this.annotation.push({
                    x: new Date(endCountry.entryTime * 1000),
                    y: 4,
                    id: 'point2'
                });
                this.annotation.push({
                    x: new Date(endCountry.entryTime * 1000),
                    y: 0
                });
                this.endCountryName = endCountry.country;
            }
        }
    }

    addNewRest(item, endDate?) {
        this.restData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.restData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 1,
            mydata: JSON.stringify(item)
        });
        this.restData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 1,
            mydata: JSON.stringify(item)
        });
        this.restData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewWork(item, endDate?) {
        this.workData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.workData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 3,
            mydata: JSON.stringify(item)
        });
        this.workData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 3,
            mydata: JSON.stringify(item)
        });
        this.workData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewAvailable(item, endDate?) {
        this.availableData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.availableData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 2,
            mydata: JSON.stringify(item)
        });
        this.availableData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 2,
            mydata: JSON.stringify(item)
        });
        this.availableData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewDrive(item, endDate?) {
        this.driveData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(new Date((item.day + item.startTime * 60) * 1000)).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.driveData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 4,
            mydata: JSON.stringify(item)
        });
        this.driveData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 4,
            mydata: JSON.stringify(item)
        });
        this.driveData.push({
            x: new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

    addNewNotInsertedDate(item, endDate?) {
        this.notInsertedData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.notInsertedData.push({
            x: new Date((item.day + item.startTime * 60) * 1000),
            name: moment(
                new Date((item.day + item.startTime * 60) * 1000)
            ).myFormat('LLL'),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.notInsertedData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
        this.notInsertedData.push({
            x: (endDate !== undefined) ?
                new Date(endDate * 1000) :
                new Date((item.day + item.endTime * 60) * 1000),
            name: moment(new Date((item.day + item.endTime * 60) * 1000)).myFormat(
                'LLL'
            ),
            y: 0,
            mydata: JSON.stringify(item)
        });
    }

}
