<div class="page-wrap height-100 mat-bg-primary">
    <div style="margin: auto;">
        <p style="font-size: 20px;">
            {{message}}
        </p>
        <p style="font-size: 30px; text-align: center; text-decoration: underline;">
          <a routerLink="/sessions/signin">Sign in</a>
        </p>
    </div>
    <footer class="footer">
    <span style="text-decoration: underline;"><a matTooltip="Svt Electronics WebPage" matTooltipPosition="above" href="http://www.tachosafe.info/"
                                                 target="_blank">© SVT Electronics 2020.</a></span>
        <span style="margin-left: 0.3rem;">{{'All rights reserved' | translate}}.</span>
        <span style="margin-left: 0.3rem; text-decoration: underline;"><a target="_blank" routerLink="/patchnotes">{{'Version' | translate}}: {{vnumber}}</a></span>
        <span style="margin-left: 0.3rem; text-decoration: underline;"><a target="_blank" routerLink="/disclaimer">{{'Terms of Service' | translate}}</a></span>
        <span style="margin-left: 0.3rem; text-decoration: underline;"><a target="_blank" routerLink="/cookies">{{'Cookie Notice' | translate}}</a></span>
        <span style="margin-left: 0.3rem; text-decoration: underline;"><a target="_blank" routerLink="/gdpr">{{'Data Privacy Notice' | translate}}</a></span>
    </footer>
</div>
