export class Menu {
    constructor(public id: number,
                public title: string,
                public routerLink: string,
                public href: string,
                public icon: string,
                public target: string,
                public hasSubMenu: boolean,
                public parentId: number,
                public isAdminLink: boolean = false,
                public isProLink: boolean = false,
                public isNew: boolean = false,
                public resellerOnly: boolean = false,
                public onlySVT: boolean = false) {
    }
}
