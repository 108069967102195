import {Pipe, PipeTransform} from '@angular/core';
import moment from './../../moment.override';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    transform(timeStamp: any, format: string = 'DD/MM/YYYY'): any {
        if ( moment(timeStamp * 1000).isValid() ) {
            return moment(timeStamp * 1000).myFormat(format);
        }
        return '';
    }

}
