<div>
    <mat-form-field appearance="outline" style="width: 100%; margin-top: 20px" class="respMatSelect">
        <mat-label>{{'Released name' | translate}}</mat-label>
        <mat-select
            class="pt-0"
            placeholder="{{'Released' | translate}}"
            floatPlaceholder="never"
            (selectionChange)="initializedValue()"
            [(ngModel)]="selectedRelease"
            name="periodSelector">
            <mat-option *ngFor="let item of data.data.content" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    style="width: 100%;">
    <mat-label>{{'Release Name' | translate}}</mat-label>
        <input
            class="smallFont"
            matInput
            type='text'
            [(ngModel)]="releaseName"
            value="{{releaseName}}"
            name="filterValue">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%;" class="respMatSelect">
        <mat-label>{{'Status' | translate}}</mat-label>
        <mat-select
            class="pt-0"
            placeholder="{{'Status' | translate}}"
            floatPlaceholder="never"
            [(ngModel)]="releaseSelectedStatus"
            [value]="releaseSelectedStatus"
            name="periodSelector">
            <mat-option [value]="'SCHEDULED'">{{'Scheduled' | translate}}</mat-option>
            <mat-option [value]="'RELEASED'">{{'Released' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%" appearance="outline">
        <input
            matInput
            [matDatepicker]="picker"
            placeholder="{{'Start date' | translate}}"
            [(ngModel)]="selectedDate"
            [formControl]="date1"
            disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-grid-list cols="3" rowHeight="70px">
        <mat-grid-tile>
            <button mat-stroked-button color="primary"
                    (click)="deleteFeature()"
                    style="width: 150px; margin-right: 20px">
                Delete
            </button>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-stroked-button color="primary"
                    (click)="cancel()"
                    style="width: 150px; margin-right: 20px">
                Cancel
            </button>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-flat-button color="primary"
                    (click)="updateFeature()"
                    style="width: 150px;">
                Update
            </button>
        </mat-grid-tile>
    </mat-grid-list>
</div>
