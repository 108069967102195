import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {API_URL} from '../helpers/globals';
import {map, share} from 'rxjs/operators';

export interface VehicleRegistrationNumber {
    vehicleRegNumber: string;
}

export interface RegNo {
    vehicleRegistrationNumber: VehicleRegistrationNumber;
}

export interface NotificationDto {
    error: string;
    fileName: string;
    uploadDate: number;
    uploadDateHR: number;
    offenceCount: number;
    driverName: string;
    eventCount: number;
    faultCount: number;
    event: string;
    fault: string;
    entityName: string;
    regNo: RegNo;
    km: number;
    days: number;
    numberOfCards: number;
    notifType: number;
}

export interface Content {
    id: number;
    resolved: boolean;
    severity: string;
    todo: string;
    description: string;
    summary: string;
    eventId: number;
    clientId: number;
    severityLevel: number;
    preTodo: string;
    preDescription: string;
    preSummary: string;
    timeStamp: number;
    delete: boolean;
    notificationDto: NotificationDto;
}

export interface Notifications {
    content: Content[];
}

export interface NotificationResponse {
    notifications: Notifications;
}

@Injectable()
export class NotificationsService {
    constructor(private http: HttpClient) {

    }

    getNotifications(companyId: number): Observable<NotificationResponse> {
        return this.http.get<NotificationResponse>(API_URL + 'notifications/?limit=50&clientId=' + companyId,
            {withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    deleteNotification(id: number, companyId: number): Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>(API_URL + 'notifications/deleted?notifId=' + id + '&notifClientId=' + companyId + '&notifDeleted=true',
            {}, {withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    changeNotificationSeen(id: number, companyId: number): Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>(API_URL + 'notifications/seen?notifId=' + id + '&notifClientId=' + companyId + '&notifSeen=true',
            {}, {withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }

    changeAllNotificationSeen(companyId: number): Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>(API_URL + 'notifications/unseen/all?notifClientId=' + companyId,
            {}, {withCredentials: true}).pipe(share())
            .pipe(map((response) => response));
    }
}
