import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {API_URL} from '../../helpers/globals';
import {map, share, timeout} from 'rxjs/operators';
import axios from 'axios';
import {CookieService} from 'ngx-cookie';
axios.defaults.timeout = 180000;

export interface BillingsResponse {
    billingsTreeTime: string;
    billings: BillingChildren;
    Result: string;
    debugMsgs: string[];
    Information: InformationResponse;
    errorMsgs: [];
}

export interface BillableTerminalsResponse {
    billingsTreeTime: string;
    billings: BillableTerminalsDevices;
    Result: string;
    debugMsgs: string[];
    Information: InformationResponse;
    errorMsgs: [];
}

export interface Differences {
    totalTerminalCount: number;
    id: number;
    clientName: string;
    negative: number;
    positiv: number;
}

export interface InformationResponse {
    billingPeriod: string;
    executeTime: string;
    insertTime: string;
    updateInfo: UpdateInfo;
    sample: string;
    sample2: string;
    default: string;
}

export interface UpdateInfo {
    size: number,
    neededClients: number;
}

export interface BillingData{
    billingData: BillingChildren
}
export interface BillingChildren {
    clientId: number;
    clientName: string;
    level: number;
    children: BillingChildren;
    devices?: Devices[];
}

export interface BillableTerminalsDevices {
    id: number;
    year: number;
    quarter: number;
    registrationNumber: string;
    serialNumber: string;
    saleDate: string;
    installDate: string;
    lastActivity: string;
    lastActivityDate: string;
    lastDownload: string;
    billedMonths: number;
    billingPeriodStart: string;
    prepaid: string;
    subcriptionTime: string;
    yearsIncluded: number;
    clientManagerLevel: number;
    clientId: number;
    clientName: string;
    managerLevel1ClientId: number;
    managerLevel1Name: string;
    managerLevel2ClientId: number;
    managerLevel2Name: string;
    managerLevel3ClientId: number;
    managerLevel3Name: string;
    managerLevel4ClientId: number;
    managerLevel4Name: string;
    managerLevel5ClientId: number;
    managerLevel5Name: string;
    managerLevel6ClientId: number;
    managerLevel6Name: string;
    terminalType: number;
    terminalTypeName: string;
    imsi: string;
    iccid: string;
    countryCode: string;
    operator: string;
    brand: string;
    error: boolean;
}

export interface Devices {
    id: number;
    year: number;
    quarter: number;
    registrationNumber: string;
    serialNumber: string;
    saleDate: string;
    installDate: string;
    lastActivityDate: string;
    lastDownload: string;
    billedMonths: number;
    billingPeriodStart: string;
    price: number;
    payment: number;
    prepaid: string;
    yearsIncluded: number;
    clientManagerLevel: number;
    clientId: number;
    clientName: string;
    currentClientId: number;
    currentClientName: string;
    managerLevel1ClientId: number;
    managerLevel1Name: string;
    managerLevel2ClientId: number;
    managerLevel2Name: string;
    managerLevel3ClientId: number;
    managerLevel3Name: string;
    managerLevel4ClientId: number;
    managerLevel4Name: string;
    managerLevel5ClientId: number;
    managerLevel5Name: string;
    managerLevel6ClientId: number;
    managerLevel6Name: string;
    terminalType: number;
    terminalTypeName: string;
    imsi: string;
    iccid: string;
    countryCode: string;
    operator: string;
    brand: string;
    error: number;
}

export interface DifferenceResponse {
    differences: Differences[];
}

export class Offences {
    infringmentId: number;
    offenceCode: string;
    offenceStartTime1970: number;
    offenceDuration: number;
    offenceEndTime: number;
    offenceDifference: number;
    offenceNorm: number;
    crew: boolean;
    driverId: number;
}

export interface OffenceTypes {
    '561/7.0': number;
    '561/6.1b': number;
    '561/6.3': number;
    '561/8.2b': number;
    '561/6.1a': number;
    '561/8.6b': number;
    '561/6.2': number;
    '561/8.2': number;
    '561/8.2a': number;
    '561/8.4': number;
    '561/8.6a': number;
    '15/4a': number;
    '15/4b': number;
    '15/5': number;
    '15/7': number;
}

export interface InfringementEvaluationResult {
    offenceTypeId: number;
    locale: string;
    calculatedSanction: number;
}

export interface AnalysisResult {
    driverId: number;
    periodStartEpoch: number;
    periodEndEpoch: number;
    periodStart: string;
    periodEnd: string;
    size: number;
    offenceTypes: OffenceTypes;
    infringementEvaluationResults: InfringementEvaluationResult[];
    totalSanction: number;
}

interface GetOffences {
    offences: Offences[];
    analysisResult: AnalysisResult;
}

export interface Offence {
    regulationNr: string;
    type: string;
    subType: string;
    startTime: number;
    endTime: number;
    detectedAt: number;
    value: number;
    norm: number;
    severity_2006_561: string;
    severity_2016_403: string;
    iEngineVersion: string;
    driverId: number;
}

export interface OffencesResponse {
    cardNumber: number;
    driverName: string;
    offenceByCodes: Offence[];
}

export interface SearchFieldSeparatorResponse {
    clientId: number;
    fieldSeparator: FieldSeparator;
}

export interface FieldSeparator {
    id: number;
    value: string;
    description: string;
}

export interface UpdaterCheckerResponse {
    result: string;
    updates: Updates[];
}

export interface Updates {
    id: number;
    clientId: number;
    serialNumber: string;
    updateDate: number;
    updateLink: string;
    updateComment: string;
    hashCode: string;
    userName: string;
    deleted: boolean;
    pro: boolean;
}

export interface T2UpdateBilling {
    billingData: {
        [key: string]: [{
            clientName: string;
            year: number;
            clientId: number;
            userName: string;
            serialNumber: string;
            sellName: string;
            updateDate: string;
            updateMonth: string;
            quarter: number;
        }];
    }
}

@Injectable({
    providedIn: 'root'
})
export class OffencesService {

    constructor(private http: HttpClient,
                private cookieService: CookieService) {
    }

    getAllOffences(driverId: number): Observable<Offences[]> {
        return this.getOffenceCallResult(driverId).pipe(share(), map(response => {
            return response.offences;
        }));
    }

    getAnalysisResult(driverId: number): Observable<AnalysisResult> {
        return this.getOffenceCallResult(driverId).pipe(share(), map(response => {
            return response.analysisResult;
        }));
    }

    getOffenceCallResult(driverId: number) {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId);

        return this.http.get<GetOffences>(API_URL + 'getDriverOffences',
            {params: params, withCredentials: true}).pipe(share());
    }

    getAllowedUpdaters(serialNumber: number) {
        return this.http.get<UpdaterCheckerResponse>(API_URL + 'allowedUpdaters/allBySerialNumber/' + serialNumber,
            {withCredentials: true}).pipe(share());
    }

    getOffencesForPeriod(driverId: number, startDate: number, endDate: number): Observable<OffencesResponse> {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + Math.floor(endDate / 1000));

        return this.http.get<OffencesResponse>(API_URL + 'getOffencesForPeriod', {
            params: params,
            withCredentials: true
        }).pipe(share());
    }

    getOffenceForDrivers(startDate: number, endDate: number, driverIds: number[], clientId:number) {
        return this.http
            .post<OffencesResponse[]>(API_URL + 'getOffencesForDrivers', {
                    startDate: startDate,
                    endDate: endDate,
                    driverIds: driverIds,
                    clientId: clientId},
                {withCredentials: true})
            .pipe(map(response => {
                try {
                    response.forEach(offences => {
                        offences.offenceByCodes.forEach(offence => {
                            offence.type = offence.type.replaceAll('.', '_')
                        });
                    });
                }catch (e) {
                    console.log(e)
                }
                return response;
            }));
    }

    /**
     * @param clientId
     * @param q - qarter - negyed ev
     * @param year
     * @param childs
     */
    getCalculatedBillinForClient(clientId: number, q: number, year: number, selectedMonth: number, childs: boolean) {
        let params: HttpParams;
        if (selectedMonth === -1) {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('q', '' + q)
                .append('year', '' + year)
                .append('childs', '' + childs);
        } else {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('month', '' + selectedMonth)
                .append('year', '' + year)
                .append('childs', '' + childs);
        }

        return this.http.get<BillingData>(API_URL + 'calculateBillsForClient',
            {params: params, withCredentials: true}).pipe(share());
    }

    getBillingData(clientId: number, q: number, year: number, selectedMonth: number, childs: boolean){
        let params: HttpParams;
        if (selectedMonth === -1) {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('q', '' + q)
                .append('year', '' + year)
                .append('childs', '' + childs);
        } else {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('month', '' + selectedMonth)
                .append('year', '' + year)
                .append('childs', '' + childs);
        }
        let url = API_URL + 'getBillingData2023Q1';
        if (year < 2023) {
            url = API_URL + 'getBillingDataBefore2023Q1';
        }
            return this.http.get<BillingData>(url,
            {params: params, withCredentials: true}).pipe(share());
    }

    getBillableTerminals(clientId: number, q: number, year: number, selectedMonth: number, childs: boolean){
        let params: HttpParams;
        if (selectedMonth === -1) {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('q', '' + q)
                .append('year', '' + year)
                .append('childs', '' + childs);
        } else {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('month', '' + selectedMonth)
                .append('year', '' + year)
                .append('childs', '' + childs);
        }

        let url = API_URL + 'getBillableTerminalInfo2023Q1';
        if (year < 2023) {
            url = API_URL + 'getBillableTerminalInfoBefore2023Q1';
        }

        return this.http.get<BillingData>(url,
            {params: params, withCredentials: true}).pipe(share());
    }

    getCalculateBillsForClientWithoutPriceInfos(clientId: number, q: number, year: number, selectedMonth: number, childs: boolean) {
        let params: HttpParams;
        if (selectedMonth === -1) {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('q', '' + q)
                .append('year', '' + year)
                .append('childs', '' + childs);
        } else {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('month', '' + selectedMonth)
                .append('year', '' + year)
                .append('childs', '' + childs);
        }

        return this.http.get<BillableTerminalsResponse>(API_URL + 'calculateBillsForClientWithoutPriceInfos',
            {params: params, withCredentials: true}).pipe(share());
    }

    getT2UpdaterBilling(year: number, quarter: number) {
        let params: HttpParams = new HttpParams()
        .append('year', '' + year)
        .append('q', '' + quarter);


        return this.http.get<T2UpdateBilling>(API_URL + 'getT2SUpdaterBillingData',
            {params: params, withCredentials: true}).pipe(share());
    }

    getT2PROUpdaterBilling(year: number, quarter: number) {
        let params: HttpParams = new HttpParams()
        .append('year', '' + year)
        .append('q', '' + quarter);


        return this.http.get<T2UpdateBilling>(API_URL + 'getT2SUpdaterPROBillingData',
            {params: params, withCredentials: true}).pipe(share());
    }

    /**
     * @param clientId
     * @param q - qarter - negyed ev
     * @param year
     * @param childs
     */
    getDifferences(clientId: number, q: number, year: number, selectedMonth: number, childs: boolean) {
        let params: HttpParams;
        if (selectedMonth === -1) {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('q', '' + q)
                .append('year', '' + year)
                .append('childs', '' + childs);
        } else {
            params = new HttpParams()
                .append('clientId', '' + clientId)
                .append('month', '' + selectedMonth)
                .append('year', '' + year)
                .append('childs', '' + childs);
        }

        return this.http.get<DifferenceResponse>(API_URL + 'getDifferences',
            {params: params, withCredentials: true}).pipe(share());
    }


    getBillingCSV(clientId: number, year: number, childs: boolean) {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId)
            .append('year', '' + year)
            .append('childs', '' + childs);
        return this.http.get(API_URL + 'generateBillsCsvForClient',
            {params: params, withCredentials: true, responseType: 'blob'}).pipe(share());
    }

    getOffencesForDrivers(startDate: number, endDate: number, driverIds: number[]) {
        const config = {
            headers: { 'X-XSRF-TOKEN': this.cookieService.get('XSRF-TOKEN')},
            timeout: 300000,
            withCredentials: true
        };
        return from(axios.post(API_URL + 'getOffencesForDrivers', {
            startDate: startDate,
            endDate: endDate,
            driverIds: driverIds
            // @ts-ignore
        }, config))
    }

    searchFieldSeparator(clientId: string) {
        return this.http
            .post<SearchFieldSeparatorResponse>(API_URL + 'company/searchFieldSeparator', {
                    clientId: clientId},
                {withCredentials: true})
            .pipe(map(response => {
                return response.fieldSeparator.value
            }))
    }
}
