import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { ChecklistService } from 'app/services/rszoft/checklist/checklist.service';
import { PrintService} from 'app/services/rszoft/print.service';
import { Router } from '@angular/router';

class SummaryData {
    icon: string;
    description: string;
    inTime: number;
    dueDateSoon: number;
    late: number;
}

@Component({
    selector: 'app-checklist-print',
    templateUrl: './checklist-print.component.html',
    styleUrls: ['../checklist.component.css']
})
export class ChecklistPrintComponent implements OnInit {
    summaryData: SummaryData[] = [];

    _moment = moment;

    displayAllSummaryColumns = ['description', 'inTime', 'dueDateSoon', 'late'];
    displayPartialSummaryColumns = ['description', 'dueDateSoon', 'late'];
    displayAllMissingPeriodColumns = ['description', 'inTime', 'missingDays'];
    displayPartialMissingPeriodColumns = ['description', 'missingDays'];
    displayDriverColumns = ['driver', 'cardNumber', 'lastDownloadDate', 'nextDownloadDate', 'nextDownloadDateInDays',
                            'lastActivity', 'lastActivityInDays', 'cardValidityDate', 'cardValidityDateInDays'];
    displayVehicleColumns = ['vehicle', 'lastDownloadDate', 'nextDownloadDate', 'nextDownloadDateInDays',
                             'lastActivity', 'lastActivityInDays', 'nextCalibrationDate', 'nextCalibrationDateInDays']; // 'downloadedPeriod', 'downloadedPeriodInDays', 'missingDays'

    constructor(public checklistService: ChecklistService,
                private printService: PrintService,
                private router: Router) {}

    ngOnInit() {
        this.summaryData.push(
            {
                icon: 'driver_icon',
                description: 'Report.Checklist.CardDownload',
                inTime: this.checklistService.data.driverCardDownload.inTime,
                dueDateSoon: this.checklistService.data.driverCardDownload.closeToTime,
                late: this.checklistService.data.driverCardDownload.outOfTime
            },
            {
                icon: 'driver_icon',
                description: 'Report.Checklist.CardValidity',
                inTime: this.checklistService.data.driverCardExpiry.inTime,
                dueDateSoon: this.checklistService.data.driverCardExpiry.closeToTime,
                late: this.checklistService.data.driverCardExpiry.outOfTime
            },
            // {
            //     icon: 'driver_icon',
            //     description: 'Report.Checklist.LicenceValidity',
            //     inTime: 4,
            //     dueDateSoon: 2,
            //     late: 0
            // },
            {
                icon: 'track_icon',
                description: 'Report.Checklist.TachographDownload',
                inTime: this.checklistService.data.vehicleDownload.inTime,
                dueDateSoon: this.checklistService.data.vehicleDownload.closeToTime,
                late: this.checklistService.data.vehicleDownload.outOfTime
            },
            {
                icon: 'track_icon',
                description: 'Report.Checklist.TachographCalibration',
                inTime: this.checklistService.data.vehicleCalibration.inTime,
                dueDateSoon: this.checklistService.data.vehicleCalibration.closeToTime,
                late: this.checklistService.data.vehicleCalibration.outOfTime
            }
        );

        const timezone = this.checklistService.data.timeZone;
        const company = this.printService.companyName?.replaceAll(' ', '_') || 'Company_wide';

        const title = document.title;
        document.title = ['Checklist', company, timezone].join('_');
        setTimeout(() => {
            window.print();
            document.title = title;
        }, 500);
    }

    @HostListener('window:afterprint', [])
    onWindowAfterPrint() {
        this.navigateBackToReport();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(_) {
        this.navigateBackToReport();
    }

    navigateBackToReport() {
        this.router.navigate(['/report/checklist']);
    }
}
