import {
    compareDueDate,
    comparePerson,
    compareVehicleModel,
    DueDate,
    Person,
    ReportModel,
    VehicleModel
} from '../report.model';

export class ChecklistModel implements ReportModel {
    driverCardDownload: CardDownload;
    driverCardExpiry: CardDownload;
    driverInfo: Driver[];
    made: number;
    vehicleCalibration: CardDownload;
    vehicleDownload: CardDownload;
    vehicleInfo: Vehicle[];
    language: string;
    timeZone: string;
}

export class CardDownload {
    inTime: number;
    closeToTime: number;
    outOfTime: number;
}

export class Driver implements Person, DueDate {
    id: number;
    surname: string;
    firstNames: string;
    cardNumber: string;
    cardExpiryDate: number;
    cardExpiryDatePerDay: number;
    lastCardDownload: number;
    nextDueDate: number;
    nextDueDatePerDay: number;
    lastActivityDate: number;
    lastActivityDatePerDay: number;

    public static compare(driver1: Driver, driver2: Driver, tolerance: number): number {
        if (!!tolerance && driver1.cardExpiryDatePerDay <= tolerance || driver2.cardExpiryDatePerDay <= tolerance) {
            if (driver1.cardExpiryDatePerDay < driver2.cardExpiryDatePerDay) { return -1; }
            if (driver1.cardExpiryDatePerDay > driver2.cardExpiryDatePerDay) { return 1; }
        }
        return compareDueDate(driver1, driver2, tolerance) || comparePerson(driver1, driver2);
    }
}

export class Vehicle implements VehicleModel, DueDate {
    id: number;
    registrationNumber: string;
    identificationNumber: string;
    lastTachoDownload: number;
    nextDueDate: number;
    nextDueDatePerDay: number;
    lastActivityDate: number;
    lastActivityDatePerDay: number;
    nextCalibrationDate: number;
    nextCalibrationDatePerDay: number;

    public static compare(vehicle1: Vehicle, vehicle2: Vehicle, tolerance: number): number {
        if (!!tolerance && vehicle1.nextCalibrationDatePerDay <= tolerance || vehicle2.nextCalibrationDatePerDay <= tolerance) {
            if (vehicle1.nextCalibrationDatePerDay < vehicle2.nextCalibrationDatePerDay) { return -1; }
            if (vehicle1.nextCalibrationDatePerDay > vehicle2.nextCalibrationDatePerDay) { return 1; }
        }
        return compareDueDate(vehicle1, vehicle2, tolerance) || compareVehicleModel(vehicle1, vehicle2);
    }
}
