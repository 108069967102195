import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService, LoggedInUser } from 'app/services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import moment from './../../../moment.override';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import { egretAnimations } from 'app/helpers/egret-animations';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatButton} from '@angular/material/button';
import { PROFILE } from 'app/helpers/globals';
import {MessageService} from 'primeng/api';
import {UploadedDriverFilesService} from '../../../services/uploaded-driver-files/uploaded-driver-files.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css'],
    animations: egretAnimations,
    providers: [MessageService]
})
export class SigninComponent implements OnInit, OnDestroy {
    @ViewChild(MatProgressBar, {static: true}) progressBar: MatProgressBar;
    @ViewChild(MatButton, {static: true}) submitButton: MatButton;

    selectedValue: string;
    currentLangName: string;

    profile = PROFILE;

    failedtologin = false;
    vnumber = '2.28.0';

    private _subscriptions: Subscription;

    signinForm: FormGroup;

    loggedInUser;
    currentTime: number;
    loggedInExpirationDate: number;

    hashCode = this.route.snapshot.params['hashCode'];
    currentYear = new Date().getFullYear();
    passwordIsHidden = true;

    constructor(private auth: AuthService,
                private router: Router,
                public translate: TranslateService,
                private messageService: MessageService,
                private snackBar: MatSnackBar,
                private route: ActivatedRoute) {
        this._subscriptions = new Subscription();
    }

    ngOnInit() {
        this.loggedInUser = localStorage.getItem('loggedInUser');
        if (this.loggedInUser != null) {
            const jsonLoggedInUser: LoggedInUser = JSON.parse(this.loggedInUser);
            this.currentTime = new Date().getTime();
            this.loggedInExpirationDate = new Date(jsonLoggedInUser.loginTime).setDate(new Date(jsonLoggedInUser.loginTime).getDate() + 1);

            if (this.loggedInExpirationDate < this.currentTime) {
                localStorage.removeItem('loggedInUser');
                localStorage.removeItem('selectedCompanyId');
            } else {
                this.router.navigate(['dashboard']);
            }
        }
        this.ifLinkUsed(this.hashCode);

        this.signinForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            rememberMe: new FormControl(true)
        });

        this.checkCurrentLangName();

        if (localStorage.getItem('rememberMe')) {
            this.signinForm.controls['username'].patchValue(localStorage.getItem('rememberMe'));
        }

        if (localStorage.getItem('selectedLangCode')) {
            this.selectedValue = localStorage.getItem('selectedLangCode');
        } else {
            const defaultLang = 'EN';
            this.selectedValue = defaultLang;
            localStorage.setItem('selectedLangCode', defaultLang);
        }
    }

    ifLinkUsed(hashCode) {
        if (hashCode) {
            this.auth.verifyEmail(hashCode).subscribe(resp => {
                if (resp.message === 'Activation successful!') {
                    this.snackBar.open(this.translate.instant('Activation successful'), 'OK', {
                        duration: 10000,
                    });
                } else if (resp.message === 'User is already activated') {
                    this.snackBar.open(this.translate.instant('User is already activated'), 'OK', {
                        duration: 10000,
                    });
                } else {
                    this.snackBar.open(this.translate.instant('Activation failed'), 'OK', {
                        duration: 10000,
                    });
                }
                this.router.navigateByUrl('sessions/signin');
            });
        }
    }

    ngOnDestroy() {
        if (this._subscriptions) {
            this._subscriptions.unsubscribe();
        }
    }

    checkCurrentLangName() {
        this.currentLangName = localStorage.getItem('selectedLangName');
    }

    signin() {
        this.progressBar.mode = 'indeterminate';
        this.submitButton.disabled = true;

        const signinData = this.signinForm.value;
        if(signinData.username === 'ecomotivesu'){
            return null;
        }

        if (signinData.rememberMe) {
            localStorage.setItem('rememberMe', '' + signinData.username);
        } else {
            localStorage.removeItem('rememberMe');
        }

        const subscription = this.auth.auth(signinData.username, signinData.password)
            .subscribe((response) => {
                if (response.code === 401) {
                    // @ts-ignore
                    this.messageService.add({key: 'bc', severity: 'error', summary: this.translate.instant('Login error'), detail: this.translate.instant(response.data.error)});
                    this.submitButton.disabled = false;
                    this.progressBar.mode = 'determinate';
                }
                if (localStorage.getItem('savedPage')) {
                    window.open(localStorage.getItem('savedPage'), '_self');
                    localStorage.removeItem('savedPage');
                } else {
                    this.routeAfterLogin(response.data._svt_user);
                    // this.router.navigateByUrl('/dashboard');
                }
            }, err => {
                if (err.status === 401) { // felfogjuk a hibat az auth error handler elott hogy tudjam lekezelni
                    this.submitButton.disabled = false;
                    this.failedtologin = true;
                    signinData.username = null;
                    signinData.password = null;
                    this.signinForm.controls['username'].reset();
                    this.signinForm.controls['password'].reset();
                    this.progressBar.mode = 'determinate';
                    // console.log('megvagy');
                }
                // Observable.throw(err);
            });
        this._subscriptions.add(subscription);
    }

    routeAfterLogin(loginResponse: LoggedInUser) {
        if (loginResponse.hasFiles) {
            if(loginResponse.clientIsReseller){
                this.router.navigateByUrl('/manage-sub-company');
            }else{
                this.router.navigateByUrl('/dashboard');
            }
        } else {
            this.router.navigateByUrl('/upload');
        }
    }

    exploreNowClicked() {
        this.auth.exploreNow()

    }

    changeLang(selectedValue) {
        localStorage.setItem('selectedLangCode', '' + selectedValue);
        localStorage.setItem('selectedLangName', '' + this.currentLangName);
        moment.locale(localStorage.getItem('selectedLangCode'));
        this.translate.use(selectedValue);

        if (selectedValue === 'HU') {
            localStorage.setItem('selectedLangName', '' + 'Hungarian');
        }
        if (selectedValue === 'EN') {
            localStorage.setItem('selectedLangName', '' + 'English');
        }
        if (selectedValue === 'RO') {
            localStorage.setItem('selectedLangName', '' + 'Romanian');
        }
        if (selectedValue === 'DE') {
            localStorage.setItem('selectedLangName', '' + 'German');
        }
        if (selectedValue === 'NL') {
            localStorage.setItem('selectedLangName', '' + 'Dutch');
        }
        if (selectedValue === 'FR') {
            localStorage.setItem('selectedLangName', '' + 'French');
        }
        if (selectedValue === 'DA') {
            localStorage.setItem('selectedLangName', '' + 'Danish');
        }
        if (selectedValue === 'ES') {
            localStorage.setItem('selectedLangName', '' + 'Spanish');
        }
        if (selectedValue === 'SL') {
            localStorage.setItem('selectedLangName', '' + 'Slovenian');
        }
        if (selectedValue === 'IT') {
            localStorage.setItem('selectedLangName', '' + 'Italian');
        }
        if (selectedValue === 'SK') {
            localStorage.setItem('selectedLangName', '' + 'Slovak');
        }
        if (selectedValue === 'BG') {
            localStorage.setItem('selectedLangName', '' + 'Bulgarian');
        }
        if (selectedValue === 'PT') {
            localStorage.setItem('selectedLangName', '' + 'Portuguese');
        }
        if (selectedValue === 'CS') {
            localStorage.setItem('selectedLangName', '' + 'Czech');
        }
        if (selectedValue === 'SV') {
            localStorage.setItem('selectedLangName', '' + 'Svenska');
        }
    }
}
