import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    DriverAttestationResponseModel,
    DriverAttestationRequestModel,
    DriverStatementUpdateModel, DriverStatement, DriverAttestationModel, CompanyModel
} from './driver-attestation.model';
import { Observable } from 'rxjs';
import { CHART_API_URL, API_URL } from 'app/helpers/globals';
import { take, tap } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
    }),
    withCredentials: true
};

export interface AttestationProfile{
    id: number;
    clientId: number;
    emailAddress: string;
    fullName: string;
    positionInTheCompany: string;
}

interface AttestationProfileResponse{
    result: [];
    message: string;
    error: string;
}

@Injectable({
    providedIn: 'root'
})
export class DriverAttestationService {
    company: CompanyModel;
    drivers: DriverAttestationModel[];
    periodStartDate: number;
    periodEndDate: number;
    timezone: string;

    constructor(private http: HttpClient) {}

    getDriverAttestationReport(driverAttestationRequest: DriverAttestationRequestModel): Observable<DriverAttestationResponseModel> {
        return this.http
            .post<DriverAttestationResponseModel>(CHART_API_URL + 'driverAttestation', JSON.stringify(driverAttestationRequest), httpOptions)
            .pipe(
                take(1),
                tap(response => this.drivers = response.driverInfos),
                tap(response => this.company = response.clientInfo),
                tap(response => response.driverInfos.forEach(driver => driver.statements.forEach(statement => statement.printingOn = true)))
            );
    }

    getClientAttestationReportProfiles(clientId:number): Observable<AttestationProfileResponse> {
        return this.http
            .get<any>(API_URL + 'company/getAllClientAttestationProfile/' + clientId, httpOptions);
    }

    updateClientAttestationReportProfile(profileId:number, profile:{emailAddress:string, fullName:string, positionInTheCompany:string}): Observable<AttestationProfileResponse> {
        return this.http
            .post<any>(API_URL + 'company/updateClientAttestatioProfile/' + profileId, JSON.stringify(profile), httpOptions);
    }

    createClientAttestationReportProfile(profile:{clientId:number, emailAddress:string, fullName:string, positionInTheCompany:string}): Observable<AttestationProfileResponse> {
        return this.http
            .post<any>(API_URL + 'company/addClientAttestatioProfile', JSON.stringify(profile), httpOptions);
    }

    deleteClientAttestationReportProfile(profileId:number): Observable<AttestationProfileResponse> {
        return this.http
            .post<any>(API_URL + 'company/deleteClientAttestatioProfile/' + profileId,"", httpOptions);
    }


    updateStatement(statementUpdateModel: DriverStatementUpdateModel): Observable<DriverStatement> {
        return this.http
            .post<DriverStatement>(CHART_API_URL + 'driverAttestation/update', JSON.stringify(statementUpdateModel), httpOptions)
            .pipe(take(1));
    }
}
