<div fxLayoutAlign="center center" style="height: 100%;" class="page-wrap height-100 mat-bg-primary">

    <div fxFlex="80" fxFlex.gt-sm="70" fxFlex.sm="70">

        <mat-card style="height: 500px !important;">
            <perfect-scrollbar>
                <mat-card-content fxLayout="row" fxLayoutAlign="center center">
                    <div>
                        <h3 style="margin-top: 2rem;">Disclaimer</h3>
                        <h4>General Information</h4>
                        <p>The Site is operated by SVT Electronics and all rights thereto are owned and reserved by SVT
                            Electronics LTD,
                            Targu
                            Mures, Remetea str. no. 110/Bis, Working point Targu Mures, Braila str. no. 7, Mures County
                            Romania).</p>
                        <h4>Liability for Content</h4>
                        <p>The content provided has been created with greatest care. For the accuracy, completeness,
                            reliability, usability
                            and
                            timeliness of the content, SVT Electronics cannot guarantee. The respective user is
                            therefore generally obliged
                            to
                            professionally check or to have professionally checked the suitability of all content for
                            its intended use.
                            SVT Electronics is responsible for own contents on these websites under the general laws.
                            However, SVT
                            Electronics
                            is not obliged to monitor transmitted or stored third-party information or to investigate
                            circumstances that
                            indicate illegal activity. Obligations to remove or block the use of information under the
                            general laws remain
                            unaffected. However, liability is only possible from the date of knowledge of a specific
                            infringement. Upon
                            notification of such violations, SVT Electronics will remove the content immediately.
                            This website and any product information (hereinafter "Product Information" or "Product
                            Content") are provided
                            “as
                            is" and to the extent permitted by law, without any kind of (express or implied) guarantee,
                            commitment or
                            liability.
                            Except in case of willful misconduct, SVT Electronics is not liable for damages that are
                            caused by or in
                            connection
                            with the use of this information. In any case, SVT Electronics is not responsible for and
                            assumes no liability
                            for
                            any indirect, incidental or consequential damages that are caused by or in connection with
                            the use of such
                            content.</p>
                        <h4>Liability for Links</h4>
                        <p>The contents on this website also contain links to external websites on which SVT Electronics
                            has no control.
                            Therefore, SVT Electronics cannot accept any responsibility for their content. The
                            respective provider or
                            operator
                            of these external websites is always responsible for their content. The linked websites were
                            checked at the time
                            of
                            linking for possible violations of law. Illegal contents were not recognizable. A permanent
                            control of the
                            linked
                            pages is unreasonable without concrete evidence of a violation. Upon notification of
                            violations, SVT Electronics
                            will remove such links immediately. Further SVT Electronics is not responsible for the
                            availability of these
                            sites
                            or their contents.</p>
                        <h4>Copyright Law</h4>
                        <p>The contents and works on these pages compiled by SVT Electronics are subject to copyright
                            law. Copying,
                            processing,
                            distribution and any kind of use outside the limits of copyright law require the written
                            consent of SVT
                            Electronics.
                            In case the content is not created by SVT Electronics, the copyrights of third parties are
                            being observed.
                            Contents
                            of third parties are marked as such. However, if a user becomes aware of a copyright
                            infringement, SVT
                            Electronics
                            asks the user for notification. Upon notification of such violations, SVT Electronics will
                            remove the content
                            immediately.</p>
                        <h3>Product Information</h3>
                        <h4>Information and documents from third parties</h4>
                        <p>As far as Product Information provided to the user is based on the inventory data of any
                            third parties, the date
                            of
                            the last update can be taken from the data sheet.
                            Although SVT Electronics made every effort to warrant the accuracy, completeness,
                            reliability, timeliness and
                            usefulness of information by third parties, SVT Electronics disclaims to the extent
                            permitted by law, any
                            warranty
                            or liability.</p>
                        <h4>SVT Electronics Information and Documents</h4>
                        <p>Information on SVT Electronics Products is based on SVT Electronics`s current knowledge and
                            experience and
                            complies
                            with the determinations of the admission authority. However, this does not release the user
                            to conduct own
                            examinations and tests due to the number of factors that may affect processing and
                            application of the product.
                            Since
                            the storage and use are beyond SVT Electronnics`s control and SVT Electronics cannot foresee
                            all relevant
                            circumstances, SVT Electronics excludes any liability for damages resulting from improper
                            storage and use.
                            The application of the product in such areas that are not described in this manual has not
                            been checked by SVT
                            Electronics. This applies especially for applications that are covered by a permit or
                            license by the admission
                            authority but are not recommended by SVT Electronics. SVT Electronics therefore excludes all
                            liability for any
                            damages resulting from such application.
                            Diverse, especially local or regional environment factors can influence the effect of the
                            product. For such
                            consequences SVT Electronics or SVT Electronics`s distribution partners cannot accept any
                            liability.
                            Existing proprietary rights, laws and regulations and the stipulations of approval of the
                            product and the
                            instructions are to be observed by the user of the product. All statements and information
                            made herein are
                            subject
                            to change without notice.</p>
                        <h4>General Conditions of Sale</h4>
                        <p>Except as otherwise specifically provided in writing, SVT Electronics`s General Conditions of
                            Sale shall not be
                            altered by this website, its content, materials and information.</p>
                        <h4>Changes and Updates</h4>
                        <p>Without liability whatsoever SVT Electronics may without notice modify and/or discontinue
                            operation of all or
                            portions of this Site at any time in its sole discretion, and assumes no responsibility to
                            update the Site.</p>
                        <h4>Final Provisions</h4>
                        <p>Any claims or suits associated with the Site or its use shall be governed and construed in
                            accordance with the
                            laws
                            of Romania, excluding its conflicts of law principles.</p>
                    </div>
                </mat-card-content>
            </perfect-scrollbar>
        </mat-card>
        <div fxLayout="row"
             style="margin-top: 2rem;"
             fxLayoutAlign="center center">
            <a [routerLink]="['/sessions/signin']">
                <button mat-raised-button
                        fxLayout="row"
                        fxLayoutAlign="center center">
                    BACK
                </button>
            </a>
        </div>
    </div>
</div>
