/**
 * Created by norbertbicsi on 3/1/17.
 */

import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {PROFILE} from '../../helpers/globals';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class AuthErrorHandler implements ErrorHandler {
    router: Router;

    constructor(private injector: Injector,
                private _snackBar: MatSnackBar) {
    }

    handleError(error: Response | any) {
        this.router = this.injector.get(Router);

        if (typeof error === 'object') {
            console.log(error);
            let ex = error;
            if (error.rejection !== undefined && typeof error.rejection === 'object') {
                ex = error.rejection;
            }

            console.log('-- error -- status: ' + ex.status);
            if (ex.status === 500) {
                // No rights to view
                switch (PROFILE) {
                    case 0: {
                        // window.open('https://tacho4safe.statuspage.io/', '_self');
                        // this.router.navigateByUrl('500');
                        break;
                    }
                    case 1: {
                        this.router.navigateByUrl('500');
                        break;
                    }
                    case 2: {
                        this.router.navigateByUrl('500');
                        break;
                    }
                }
                return;
            }

            if (ex.status === 401) {
                this.router.navigateByUrl('/sessions/signin');
            }

            if (ex.status === 429) {
                this._snackBar.open('Too many request', 'Ok');
            }

            if (ex.status === 404) {
                switch (PROFILE) {
                    case 0: {
                        // this.router.navigateByUrl('404');
                        // window.open('https://tacho4safe.statuspage.io/', '_self');
                        break;
                    }
                    case 1: {
                        this.router.navigateByUrl('404');
                        break;
                    }
                    case 2: {
                        this.router.navigateByUrl('404');
                        break;
                    }
                }
                return;
            }

            if (ex._body !== undefined && ex._body.indexOf('{') === 0) {
                try {
                    const responseBody = JSON.parse(ex._body);
                    console.log('-- error -- 3.1: ' + ex._body);
                    if (responseBody.data !== undefined &&
                        responseBody.data.couldYouPleaseLeave === true) {
                        console.log('end of error handler with \'couldYouPleaseLeave\'');
                        this.router.navigateByUrl('/sessions/signin');
                    }
                } catch (e) {
                    console.log('Parse error! Not a JSON: ' +
                        error.rejection._body);
                }

            }

        } else {
            console.log('-- error --: ' + error);
        }

        return;
    }

    isJSONString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

}
