<div fxLayoutAlign="center center" style="height: 100%;" class="page-wrap height-100 mat-bg-primary">

    <div fxFlex="80" fxFlex.gt-sm="70" fxFlex.sm="70">

        <mat-card style="height: 500px !important;">
            <perfect-scrollbar>
                <mat-card-content fxLayout="row" fxLayoutAlign="center center">
                    <div>
                        <h4 style="margin-top: 2rem;">Data Privacy Notice</h4>
                        <p>Protecting the security and privacy of your personal data is important to SVT Electronics.
                            Therefore, SVT
                            Electronics (for further company details, please see the “Corporate Information” below)
                            processes personal data
                            in compliance with applicable laws on data protection and data security.</p>

                        <h4>Processing of personal data related to your use of our websites, applications and online
                            platforms</h4>

                        <h4>Categories of personal data processed, purpose of the processing and legal basis </h4>
                        <p>When visiting SVT Electronics`s websites, applications or online, SVT Electronics may process
                            the following
                            personal
                            data about you:</p>
                        <ul>
                            <li>Personal data that you actively and voluntarily provide via the TachoSafe (e.g., when
                                registering,
                                contacting us
                                with your inquiries or participating in surveys, etc.), including name, e-mail address,
                                telephone number,
                                information submitted as part of a support request, etc.; and
                            </li>
                            <li>Information that is automatically sent to us by your web browser or device, such as your
                                IP-address, device
                                type,
                                browser type, referring site, sites accessed during your visit, the date and time of
                                each visitor request.
                            </li>
                        </ul>
                        <p>We process your personal data for the following purposes:</p>
                        <ul>
                            <li>To provide the SVT Electronics’s services and functions and to administer your use of
                                the TachoSafe;
                            </li>
                            <li>To answer and fulfill your specific requests; and</li>
                            <li>As reasonably necessary to enforce the applicable terms of use, to establish or preserve
                                a legal claim or
                                defense,
                                to prevent fraud or other illegal activities, including attacks on SVT Electronics’s
                                information technology
                                systems.
                            </li>
                        </ul>
                        <p>The legal basis for SVT Electronics processing data about you is that such processing is
                            necessary for the
                            purposes
                            of:</p>
                        <ul>
                            <li>SVT Electronics exercising its rights and performing its obligations in connection with
                                any contract we make
                                with
                                you (Article 6 (1) (b) General Data Protection Regulation);
                            </li>
                            <li>Compliance with SVT Electronics’s legal obligations (Article 6 (1) (c) General Data
                                Protection Regulation);
                                and/or
                            </li>
                            <li>Legitimate interests pursued by SVT Electronics (Article 6 (1) (f) General Data
                                Protection Regulation).
                                Generally
                                the legitimate interest pursued by SVT Electronics in relation to our use of your
                                personal data is the
                                efficient
                                performance or management of your use of the TachoSafe.
                            </li>
                        </ul>
                        <p>In some cases, we may ask if you consent to the relevant use of your personal data. In such
                            cases, the legal
                            basis
                            for Tacho2Safe processing that data about you may (in addition or instead) be that you have
                            consented (Article 6
                            (1)
                            (a) General Data Protection Regulation).</p>

                        <h4>Cookies</h4>
                        <p>We may use cookies within TachoSafe. You can find information on SVT Electronics’s use of
                            cookies in our Cookie
                            Notice. The Cookie Notice also provides information on how to block and object to the use of
                            cookies and the
                            processing of data collected by cookies.</p>

                        <h4>Links to other websites</h4>
                        <p>This Privacy Notice applies only to TachoSafe and not to other websites or applications
                            operated by third
                            parties.
                            We may provide links to other websites and applications which we believe may be of interest
                            to you. SVT
                            Electronics
                            is not responsible for the privacy practices of such other websites or applications.</p>

                        <h4>Processing of personal data related to your business relationship with SVT Electronics</h4>

                        <h4>Categories of personal data processed, purpose of the processing and legal basis</h4>
                        <p>In the context of the business relationship with SVT Electronics, SVT Electronics may process
                            the following
                            categories of personal data of current and future contact persons at our customers,
                            suppliers, vendors and
                            partners
                            (each a “Business Partner”):</p>
                        <ul>
                            <li>Contact information, such as full name, work address, work telephone number, work mobile
                                phone number, work
                                fax
                                number and work email address;
                            </li>
                            <li>Further information necessarily processed in a project or contractual relationship with
                                SVT Electronics or
                                voluntarily provided by the Business Partner, such as personal data relating to orders
                                placed, payments
                                made,
                                requests, and project milestones;
                            </li>
                             
                            <li>Personal data collected from publicly available resources, integrity data bases and
                                credit agencies; and
                            <li>If legally required for Business Partner compliance screenings: date of birth, ID
                                numbers, identity cards
                                and
                                information about relevant and significant litigation or other legal proceedings against
                                Business Partners.
                            </li>
                        </ul>
                        <p>SVT Electronics may process the personal data for the following purposes:</p>
                        <ul>
                            <li>Communicating with Business Partners about products, services and projects of SVT
                                Electronics or Business
                                Partners, e.g. by responding to inquiries or requests or providing you with technical
                                information about
                                purchased
                                products;
                            </li>
                            <li>Planning, performing and managing the (contractual) relationship with Business Partners;
                                e.g. by performing
                                transactions and orders of products or services, processing payments, performing
                                accounting, auditing,
                                billing
                                and
                            </li>
                            collection activities, arranging shipments and deliveries, facilitating repairs and
                            providing support services;
                            <li>Administrating and performing customer surveys, marketing campaigns, market analysis,
                                sweepstakes, contests,
                                or
                                other promotional activities or events;
                            </li>
                            <li>Maintaining and protecting the security of our products, services and websites,
                                preventing and detecting
                                security
                                threats, fraud or other criminal or malicious activities;
                            </li>
                            <li>Ensuring compliance with legal obligations (such as record keeping obligations), export
                                control and customs,
                                Business Partner compliance screening obligations (to prevent white-collar or money
                                laundering crimes), and
                                SVT
                                Electronics policies or industry standards; and
                            </li>
                            <li>Solving disputes, enforce our contractual agreements and to establish, exercise or
                                defend legal claims.
                            </li>
                        </ul>
                        <p>The legal basis for SVT Electronics processing data about you is that such processing is
                            necessary for the
                            purposes
                            of:</p>
                        <ul>
                            <li>SVT Electronics exercising its rights and performing its obligations in connection with
                                any contract we make
                                with
                                you (Article 6 (1) (b) General Data Protection Regulation),
                            </li>
                            <li>Compliance with SVT Electronics`s legal obligations (Article 6 (1) (c) General Data
                                Protection Regulation),
                                and/or
                            </li>
                            <li>Legitimate interests pursued by SVT Electronics (Article 6 (1) (f) General Data
                                Protection Regulation).
                            </li>
                        </ul>
                        <p>Generally the legitimate interest pursued by SVT Electronics in relation to our use of your
                            personal data is the
                            efficient performance or management of our business relationship with you.

                            In some cases, we may ask if you consent to the relevant use of your personal data. In such
                            cases, the legal
                            basis
                            for SVT Electronics processing that data about you may (in addition or instead) be that you
                            have consented
                            (Article
                            6 (1) (a) General Data Protection Regulation).</p>

                        <h4>Transfer and disclosure of personal data</h4>
                        <p>SVT Electronics may transfer your personal data to:</p>
                        <ul>
                            <li>third parties which provide IT services to SVT Electronics and which process such data
                                only for the purpose
                                of
                                such services (e.g., hosting or IT maintenance and support services); and
                            </li>
                            <li>courts, arbitration bodies, law enforcement authorities, regulators or attorneys if
                                necessary to comply with
                                the
                                law or for the establishment, exercise or defense of rights or legal claims.
                            </li>
                        </ul>
                        <h4>Retention periods</h4>
                        <p>Unless indicated otherwise at the time of the collection of your personal data (e.g. within a
                            form completed by
                            you), we erase your personal data if the retention of that personal data is no longer
                            necessary (i) for the
                            purposes
                            for which they were collected or otherwise processed, or (ii) to comply with legal
                            obligations (such as
                            retention
                            obligations under tax, transportation or commercial laws).</p>

                        <h4>Withdraw consent</h4>
                        <p>In case you declared your consent for the processing of certain personal data by SVT
                            Electronics, you have the
                            right
                            to withdraw the consent at any time with future effect, i.e. the withdrawal of the consent
                            does not affect the
                            lawfulness of processing based on the consent before its withdrawal. If the consent is
                            withdrawn, SVT
                            Electronics
                            may only further process the personal data where there is another legal ground for the
                            processing.</p>

                        <h4>Your rights</h4>
                        <p>Under applicable data protection law, you may have the right to:</p>
                        <ul>
                            <li>Obtain from SVT Electronics confirmation as to whether or not personal data concerning
                                you are being
                                processed,
                                and where that is the case, access to the personal data;
                            </li>
                            <li>Obtain from SVT Electronics the rectification of inaccurate personal data concerning
                                you;
                            </li>
                            <li>Obtain from SVT Electronics the erasure of your personal data;</li>
                            <li>Obtain from SVT Electronics restriction of processing regarding your personal data;</li>
                            <li>Data portability concerning personal data, which you actively provided; and</li>
                            <li>Object, on grounds relating to your particular situation, to processing of personal data
                                concerning you.
                            </li>
                        </ul>
                        <h4>Data privacy contact</h4>
                        <p>The SVT Electronics data protection officer provides support with any data privacy related
                            questions, comments,
                            concerns or complaints or in case you wish to exercise any of your data privacy related
                            rights. The SVT
                            Electronics
                            Data Protection Officer may be contacted at: dpo@svt.ro.
                            The SVT Electronics Data Protection Officer will always use best efforts to address and
                            settle any requests or
                            complaints you bring to its attention. Besides contacting the SVT Electronics Data
                            Protection Officer, you
                            always
                            have the right to approach the competent data protection authority with your request or
                            complaint.</p>
                    </div>
                </mat-card-content>
            </perfect-scrollbar>
        </mat-card>
        <div fxLayout="row"
             style="margin-top: 2rem;"
             fxLayoutAlign="center center">
            <a [routerLink]="['/sessions/signin']">
                <button mat-raised-button
                        fxLayout="row"
                        fxLayoutAlign="center center">
                    BACK
                </button>
            </a>
        </div>
    </div>
</div>
