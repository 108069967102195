import { Moment } from 'moment-timezone';
import {Fault} from './chart.model';

export class TableInfo {
    queryPeriodSum: QueryPeriodSummary;
    weeks: TableWeek[];
}

export class QueryPeriodSummary {
    startDate: number;
    endDate: number;
    drive: number;
    work?: number;
    availability?: number;
    workSum?: number;
    odometerStart?: number;
    odometerEnd?: number;
    odometerSum?: number;
    drivers?: string[];
}

export class TableWeek {
    numberOfYear: number;
    numberOfWeek: number;
    days: TableDay[];
    weeklySummary: WeeklySummary;
    biWeeklySummary?: BiWeeklySummary;
}

export class TableDay {
    recordDate: number;
    startDate?: number;
    endDate?: number;
    recordDateMoment: Moment;
    noDataForToday: boolean;
    drive: number;
    work?: number;
    availability?: number;
    workSum?: number;
    cars?: string[];
    startOfDrive?: number;
    endOfDrive?: number;
    odometerStart?: number;
    odometerEnd?: number;
    odometerSum?: number;
    avgSpeed?: number;
    drivers?: string[];
    faults?: Fault[];
}

export class WeeklySummary {
    numberOfYear: number;
    numberOfWeek: number;
    drive: number;
    work?: number;
    availability?: number;
    workSum?: number;
    cars?: string[];
    odometerSum?: number;
}

export class BiWeeklySummary {
    actualYear: number;
    actualWeek: number;
    previousYear: number;
    previousWeek: number;
    drive: number;
}
