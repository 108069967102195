import {
    Activity,
    compareActivity, compareErrorCode, comparePerson,
    compareUsage,
    compareVehicleModel, ErrorCode, Odometer, Person,
    ReportModel,
    Usage,
    VehicleModel
} from '../../report.model';

export class DataMatchRequestModel {
    clientId: number;
    driverIds?: number[];
    vehicles?: VehicleRequestModel[];
    startDate: string;
    endDate: string;
    fullCompany: boolean;
}

class VehicleRequestModel {
    vehicleRegistrationNumber: string;
    vehicleIdentificationNumber: string;
}

export class DataMatchModel implements ReportModel {
    made: number;
    language: string;
    timeZone: string;
    driverInfos?: Driver[];
    vehicleInfos?: Vehicle[];
}

interface SummaryProperties {
    totalCount?: number;
    sumKm?: number;
}

export interface NameActivityAndOdometer extends Person, Activity, Odometer {
    surname: string;
    firstNames: string;
    activityStart: number;
    activityEnd: number;
    odometerBegin?: number;
    odometerEnd?: number;
    odometerSum?: number;
    lastDriverCardDownload?: number;
}

export class Driver implements Person, Activity, ErrorCode, Odometer, SummaryProperties {
    id?: number;
    surname?: string;
    firstNames?: string;
    lastDriverCardDownload?: number;
    errorCode?: string;
    drive?: number;
    availability?: number;
    work?: number;
    activityStart?: number;
    activityEnd?: number;
    vehicles?: Vehicle[];
    odometerBegin?: number;
    odometerEnd?: number;
    odometerSum?: number;
    totalCount?: number;
    sumKm?: number;
    // FIXME: Temporary fields
    previousDriver?: NameActivityAndOdometer;
    followingDriver?: NameActivityAndOdometer;

    public static compare(driver1: Driver, driver2: Driver): number {
        if (!!driver1.vehicles && !driver2.vehicles) { return -1; }
        if (!driver1.vehicles && !!driver2.vehicles) { return 1; }
        return compareActivity(driver1, driver2) ||
            compareErrorCode(driver1, driver2) ||
            comparePerson(driver1, driver2);
    }
}

export class Vehicle implements VehicleModel, Usage, Odometer, SummaryProperties {
    id?: number;
    registrationNumber: string;
    registrationNation?: string;
    identificationNumber?: string;
    firstUse?: number;
    lastUse?: number;
    errorCode?: string;
    odometerBegin?: number;
    odometerEnd?: number;
    odometerSum?: number;
    lastTachographDownload: number;
    drivers?: Driver[];
    totalCount?: number;
    sumDrive?: number;
    sumAvailability?: number;
    sumWork?: number;
    sumKm?: number;

    public static compare(vehicle1: Vehicle, vehicle2: Vehicle): number {
        if (!!vehicle1.drivers && !vehicle2.drivers) { return -1; }
        if (!vehicle1.drivers && !!vehicle2.drivers) { return 1; }
        return compareUsage(vehicle1, vehicle2) ||
            compareErrorCode(vehicle1, vehicle2) ||
            compareVehicleModel(vehicle1, vehicle2);
    }
}
