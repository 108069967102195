import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProService } from 'app/services/pro/pro.service';

@Component({
  selector: 'app-report-subscription-banner',
  templateUrl: './report-subscription-banner.component.html',
})
export class ReportSubscriptionBannerComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly proService: ProService,
    private readonly translate: TranslateService
  ) { }

  isPro = this.proService.pro$;

  ngOnInit(): void {
    // console.log('ReportSubscriptionBannerComponent ngOnInit');
  }

  navigateToSubscription(): void {
    this.router.navigate(['/subscription']);
  }

}
