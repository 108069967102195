import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, share} from 'rxjs/operators';
import {ContentFeedDrivers, FeedResponseFeedDrivers} from '../feed/feed.service';
import {Observable} from 'rxjs';
import {AllFeed} from './notification-management.model';
import {CookieService} from 'ngx-cookie';
import { API_URL } from 'app/helpers/globals';

@Injectable({
  providedIn: 'root'
})
export class NotificationManagementService {

  constructor(
      private http: HttpClient,
      private cookieService: CookieService
  ) { }

  getAllNotification(clientId: number, limit: number, page: number) {
      const params: HttpParams = new HttpParams()
          .append('clientId', '' + clientId)
          .append('limit', '' + limit)
          .append('page', '' + page);

      return this.http.get<AllFeed>(API_URL + 'feed/getEvents',
          {params: params, withCredentials: true})
          .pipe(map((response) => response.result));
  }

  async getVechicleCheckItem(clientId: number, userId: number, id: number) {
      // @ts-ignore
      const response = await fetch(API_URL + 'vehicleCheck/item?' + new URLSearchParams({clientId, userId, id} ), {method: 'GET', credentials: 'include', headers: { 'X-XSRF-TOKEN': this.cookieService.get('XSRF-TOKEN')}});
      const textResponse = await response.text();
      const boundary = textResponse.split('\n')[0];
      const data =  textResponse.split(boundary);
      const responseBody = data[1].split('\n')[3];
      // console.log(responseBody);
      this.generatePDF(responseBody);
  }


  async generatePDF(responseBody) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const selectedLangCode = localStorage.getItem('selectedLangCode');
      const response = await fetch('https://pdfgenerator.tacho4safe.com?apiKey=4193726e-7117-4931-a70f-a9a9cf827c74-api-key-user-2e7fe032-64b7-430e-a47d-ef02ab8fffa2', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                languageCode: selectedLangCode.toLowerCase(),
                templateName: 'VehicleCheck',
                body: JSON.parse(responseBody),
                timeZone: timezone
            })
        });
      const fileURL = URL.createObjectURL(await response.blob());

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'tacho4safe_vehicle_check_' + new Date().getFullYear() + '_' + (new Date().getMonth() + 1) + '.pdf';
      link.click();
  }
}
