import { FormGroup } from '@angular/forms';
import { DataMatchModel, DataMatchRequestModel } from './data-match.model';
import { MetaData, TabOption } from 'app/services/rszoft/report.model';
import { DriverWithStatistics } from 'app/services/drivers/drivers.service';
import { VehicleInfo } from 'app/services/vehicles/vehicles.service';
import { ReplaySubject } from 'rxjs';

export enum RowType {
    DRIVER_VEHICLE,
    DETAIL,
    SUMMARY
}

export class DriverActivity {
    firstNames?: string;
    surname?: string;
    globalError?: string;
    allDownloaded?: boolean;
    lastDownload?: number;
    caseCount?: number;
    rowType: RowType;
    startDate?: number;
    endDate?: number;
    vehicle?: string;
    differenceCode?: string;
    odometerBegin?: number;
    odometerEnd?: number;
    odometerSum?: number;
}

export class VehicleActivity {
    registrationNumber?: string;
    registrationNation?: string;
    globalError?: string;
    allDownloaded?: boolean;
    lastDownload?: number;
    caseCount?: number;
    rowType: RowType;
    startDate?: number;
    endDate?: number;
    driver?: string;
    differenceCode?: string;
    drive?: number;
    work?: number;
    availability?: number;
    odometerBegin?: number;
    odometerEnd?: number;
    odometerSum?: number;
}

export class ReportContext {
    data: DriverActivity[] | VehicleActivity[];
    metaData: MetaData = {} as MetaData;
    rowCountPerEntity: number[];

    queryForm: FormGroup;
    selectedEntities = [];
    availableEntities: DriverWithStatistics[] | VehicleInfo[] = [];
    filteredEntities = new ReplaySubject<any>(1);

    lastRequest: DataMatchRequestModel;

    setSelectedEntities(entities) {
        this.selectedEntities = entities;
    }

    isQueryFormValid(tab: TabOption): boolean {
        return (tab === TabOption.DRIVER && this.queryForm.get('drivers').value !== null && this.queryForm.get('drivers').value.length !== 0 ||
                tab === TabOption.VEHICLE && this.queryForm.get('vehicles').value !== null && this.queryForm.get('vehicles').value.length !== 0)
            && this.queryForm.get('rangeFormGroup').get('start').value !== null
            && this.queryForm.get('rangeFormGroup').get('end').value !== null;
    }

    calculateRowsForPage(actualPageIndex: number, actualPageSize: number): DriverActivity[] {
        const startCount = this.rowCountPerEntity.slice(0, actualPageIndex * actualPageSize).reduce((sum, current) => sum + current, 0);
        const contentCount = this.rowCountPerEntity.slice(actualPageIndex * actualPageSize, (actualPageIndex + 1) * actualPageSize).reduce((sum, current) => sum + current, 0);
        return this.data.slice(startCount, startCount + contentCount);
    }

    setAvailableEntities(response, tab: TabOption) {
        this.availableEntities = response;

        if (tab === TabOption.DRIVER) {
            (this.availableEntities as DriverWithStatistics[]).sort(DriverWithStatistics.sort);
        } else {
            (this.availableEntities as VehicleInfo[]).sort(VehicleInfo.sort);
        }
        if (this.selectedEntities.length === 0) {
            this.filteredEntities.next(this.availableEntities.slice());
        }
    }

    setQueryForm(queryForm: FormGroup) {
        this.queryForm = queryForm;
    }

    setMetaData(report: DataMatchModel) {
        this.metaData = {
            ...this.metaData,
            made: report.made * 1000,
            language: report.language,
            timezone: report.timeZone,
        };
    }
}

export class LabelledReportContext {
    label: string;
    reportContext: ReportContext;

    constructor(label: string, reportContext: ReportContext) {
        this.label = label;
        this.reportContext = reportContext;
    }
}
