<div class="w-full h-full bg-slate-100 p-2 flex flex-col manageSubCompanyPage">
    <p-toast position="top-right"></p-toast>

    <p-confirmDialog></p-confirmDialog>

    <p-dialog [modal]="true" header="Reset usage record" [(visible)]="!!pauseSubscriptionModalData">
        <div *ngIf="!!pauseSubscriptionModalData" class="flex flex-col gap-2">
            <div class="flex flex-row gap-2 items-center">
                <div>Pause subscription for {{pauseSubscriptionModalData.rowData.name}}</div>
                <div>
                    <!-- date picker -->
                    <mat-form-field class="removePadding" appearance="outline">
                        <mat-label>{{'Choose a date'|translate}}</mat-label>
                        <input [(ngModel)]="pauseSubscriptionModalData.timestamp"
                            matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="flex flex-row items-center justify-center">
                    <button (click)="setSubscriptionPaused()" class="bg-tachosafe-500 text-white font-bold rounded-md px-2 py-1 text-lg">Save</button>
                </div>
            </div>
        </div>

    </p-dialog>
    
    <p-dialog [modal]="true" header="Reset usage record" [(visible)]="!!resetUsageRecordModalData">
        <div *ngIf="!!resetUsageRecordModalData" class="flex flex-col gap-2">
            <div class="flex flex-row gap-2 items-center">
                <div>Reset usage record for: {{resetUsageRecordModalData.rowData.name}}</div>
                <div>
                    <mat-form-field>
                        <mat-label>Timestamp</mat-label>
                        <input matInput [(ngModel)]="resetUsageRecordModalData.timestamp" >
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Value</mat-label>
                        <input matInput [(ngModel)]="resetUsageRecordModalData.value" >
                    </mat-form-field>
                </div>
                <div class="flex flex-row items-center justify-center">
                    <button (click)="setUsageRecord()" class="bg-tachosafe-500 text-white font-bold rounded-md px-2 py-1 text-lg">Save</button>
                </div>
            </div>
        </div>

    </p-dialog>

    <p-dialog [style]="{width: '50vw'}" header="{{'manageSubCompany.trialModal.title'|translate}}" [modal]="true"
        [(visible)]="!!trialPeriodModalData">
        <div class="w-full flex flex-col gap-2" *ngIf="!!trialPeriodModalData">
            <div>{{'manageSubCompany.trialModal.description.1'|translate}}</div>
            <div>{{'manageSubCompany.trialModal.description.2'|translate}}</div>
            <div class="flex flex-row gap-4 items-center">
                <div>{{'manageSubCompany.trialModal.description.selectDays'|translate}}</div>
                <mat-form-field>
                    <mat-label>{{'manageSubCompany.trialModal.freeDays'|translate}}</mat-label>
                    <input [(ngModel)]="trialPeriodModalData.days" (change)="trialPeriodDaysChanged()" min="1" max="31" matInput type="number">
                </mat-form-field>
            </div>
            <div class="flex flex-row justify-end gap-2 w-full mt-6">
                <button (click)="cancelModal(trialPeriodModalData)"
                    class="bg-slate-200 rounded-md px-2 py-1 mr-8">{{'manageSubCompany.proModal.button.cancel'|translate}}</button>
                <button (click)="enableTrialForCompany(trialPeriodModalData.rowData)"
                    class="bg-tachosafe-500 text-white rounded-md px-2 py-1">{{'manageSubCompany.proModal.button.turnOn'|translate}}</button>
            </div>

        </div>
    </p-dialog>

    <p-dialog [style]="{width: '50vw'}" header="{{(!!blockModalData && !isBlocked(blockModalData.rowData)?
        'manageSubCompanyComponent.confirmationDialog.lockCompany.header':
        'manageSubCompanyComponent.confirmationDialog.unlockCompany.header')|translate}}" [modal]="true"
        [(visible)]="!!blockModalData">
        <div class="w-full flex flex-col gap-2" *ngIf="!!blockModalData">
            <div *ngIf="!isBlocked(blockModalData.rowData)">
                {{'manageSubCompanyComponent.confirmationDialog.lockCompany.message'|translate}}
            </div>
            <div *ngIf="isBlocked(blockModalData.rowData)">
                {{'manageSubCompanyComponent.confirmationDialog.lockCompany.message'|translate}}
            </div>
            <div *ngIf="!isBlocked(blockModalData.rowData)">
                {{'manageSubCompanyComponent.confirmationDialog.lockCompany.reason'|translate}}
            </div>
            <div *ngIf="!isBlocked(blockModalData.rowData)">
                <textarea cols="60" pInputTextarea [(ngModel)]="blockModalData.reason"></textarea>
            </div>
            <div class="flex flex-row justify-end gap-2 w-full mt-6">
                <button (click)="cancelModal(blockModalData)"
                    class="bg-slate-200 rounded-md px-2 py-1 mr-8">{{'manageSubCompanyComponent.confirmationDialog.button.cancel'|translate}}</button>
                <button *ngIf="!isBlocked(blockModalData.rowData)"
                    [disabled]="blockModalData.reason==='' || blockModalData.reason===undefined"
                    [ngClass]="{'opacity-50': blockModalData.reason==='' || blockModalData.reason===undefined}"
                    (click)="blockClient(blockModalData.rowData, '', true)"
                    class="bg-red-500 rounded-md px-2 py-1 text-white">{{'manageSubCompanyComponent.confirmationDialog.button.block'|translate}}</button>
                <button *ngIf="isBlocked(blockModalData.rowData)"
                    (click)="unblockClient(blockModalData.rowData, '', true)"
                    class="bg-red-500 rounded-md px-2 py-1 text-white">{{'manageSubCompanyComponent.confirmationDialog.button.unlock'|translate}}</button>
            </div>
        </div>
    </p-dialog>

    <p-dialog [style]="{width: '50vw'}"
        header="{{(!!proModalData && proModalData.rowData.pro===false?'manageSubCompany.proModal.turnOn':'manageSubCompany.proModal.turnOff')|translate}}"
        [modal]="true" [(visible)]="!!proModalData">
        <div class="w-full">

            <div *ngIf="!!proModalData && proModalData.rowData.pro===false">
                <div class="font-bold">{{proModalData.rowData.name}}</div>
                <div>{{'manageSubCompany.proModal.description.turnOn'|translate}}</div>
                <div class="flex flex-row gap-4 items-center">
                    <mat-checkbox [(ngModel)]="proModalData.proEnds.enabled" matInput type="checkbox"></mat-checkbox>
                    <span
                        [ngClass]="{'opacity-50':!proModalData.proEnds.enabled}"></span>{{'manageSubCompany.proModal.description.turnOffProAutomaticallyOn'|translate}}
                    <!-- date picker -->
                    <mat-form-field class="removePadding" appearance="outline">
                        <mat-label>{{'Choose a date'|translate}}</mat-label>
                        <input [disabled]="!proModalData.proEnds.enabled" [(ngModel)]="proModalData.proEnds.endTime"
                            matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="flex flex-row justify-end gap-2 w-full mt-6">
                    <button (click)="cancelModal(proModalData)"
                        class="bg-slate-200 rounded-md px-2 py-1 mr-8">{{'manageSubCompany.proModal.button.cancel'|translate}}</button>
                    <button (click)="enableProForCompany(proModalData.rowData)"
                        class="bg-tachosafe-500 text-white rounded-md px-2 py-1">{{'manageSubCompany.proModal.button.turnOn'|translate}}</button>
                </div>
            </div>

            <div *ngIf="!!proModalData && proModalData.rowData.pro===true">
                <div class="font-bold">{{proModalData.rowData.name}}</div>
                <div>{{'manageSubCompany.proModal.description.turnOff'|translate}}</div>
                <div class="flex flex-row justify-end gap-2 w-full mt-6">
                    <button (click)="cancelModal(proModalData)"
                        class="bg-slate-200 rounded-md px-2 py-1 mr-8">{{'manageSubCompany.proModal.button.cancel'|translate}}</button>
                    <button (click)="disableProForCompany(proModalData.rowData, '', false)"
                        class="bg-tachosafe-500 rounded-md px-2 py-1 text-white">{{'manageSubCompany.proModal.button.turnOffWithPeriod'|translate}}</button>
                    <button (click)="disableProForCompany(proModalData.rowData, '', true)"
                        class="bg-red-500 rounded-md px-2 py-1 text-white">{{'manageSubCompany.proModal.button.turnOffImmediately'|translate}}</button>
                </div>
            </div>
        </div>
    </p-dialog>
    <div class="w-full p-4 bg-white flex flex-col mb-2 shadow-md">
        <div class="text-2xl font-semibold mb-4">{{'manageSubCompany.dashboard.title'|translate}}</div>
        <div>{{'manageSubCompany.dashboard.description'|translate}}</div>
    </div>
    <div class="flex flex-col gap-2 w-full h-full">
        <div *ngIf="userIsAdmin" class=" shadow-md bg-white w-full">
            <!-- <p-table *ngIf="loading" [value]="tempData" [columns]="cols"
                [tableStyle]="{ 'min-width': '10rem' }">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of cols" [pSortableColumn]="col.sortable?col.field:undefined"
                            [ngClass]="col.classStyle">
                            <div class="flex flex-row items-center gap-2" [ngClass]="col.classStyle">
                                {{col.header|translate}}
                                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                    *ngIf="col.specialId==='proReq'" field="proReq" matchMode="equals" display="menu">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="proTypes"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <div class="rounded-full px-2 py-1 bg-tachosafe-500 text-white font-bold text-center"
                                                    *ngIf="option.value==='PRO'">
                                                    {{'manageSubCompany.table.badge.PRO'|translate}}</div>
                                                <div class="rounded-full px-2 py-1 bg-slate-500 text-white font-bold text-center"
                                                    *ngIf="option.value==='BASIC'">
                                                    {{'manageSubCompany.table.badge.BASIC'|translate}}</div>
                                                <div class="rounded-full px-2 py-1 bg-yellow-400 text-slate-800 font-bold text-center"
                                                    *ngIf="option.value==='REQUESTED'">
                                                    {{'manageSubCompany.table.badge.REQUESTED'|translate}}</div>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td><p-skeleton></p-skeleton></td>
                        <td><p-skeleton></p-skeleton></td>
                        <td><p-skeleton></p-skeleton></td>
                        <td><p-skeleton></p-skeleton></td>
                        <td><p-skeleton></p-skeleton></td>
                        <td><p-skeleton></p-skeleton></td>
                    </tr>
                </ng-template>
            </p-table> -->

            <p-table id="dt1" #dt1 [value]="data.companies" [columns]="cols" [paginator]="true" [loading]="loading"
                [rows]="5" [rowsPerPageOptions]="[5,10, 25, 50]"
                [tableStyle]="{'min-width': '10rem' }">
                <ng-template pTemplate="caption">
                    <div class="flex flex-row justify-between items-center w-full">
                        <div class="flex flex-row gap-2 w-full items-center grow">
                            <div class="flex flex-row gap-2">
                                <button pButton [matTooltip]="'manageSubCompany.table.clear'|translate"
                                    class="p-button-outlined mr-4" icon="pi pi-filter-slash"
                                    (click)="clear(dt1)"></button>
                                <div class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input #dt1InputText pInputText type="text"
                                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                        [placeholder]="'manageSubCompany.table.SearchKeyword'|translate" />
                                </div>
                            </div>
                            <div class="flex flex-row gap-2 h-min w-full grow text-sm">
                                <div (click)="clear(dt1)"
                                    class="px-2 py-1 rounded-md font-bold text-white bg-blue-500 shadow-md cursor-pointer">
                                    {{'manageSubCompany.table.badge.All'|translate}} ({{getAllTagCount()}})</div>
                                <div *ngFor="let tag of companyTags; let i = index"
                                    (click)="applyTagFilter(tag.value)"
                                    [ngClass]="'rounded-md px-2 py-1 font-bold shadow-md cursor-pointer'+getTagClassStyle(tag.value)">
                                    {{('manageSubCompany.table.badge.'+removeTagPrefix(tag.value))|translate}}
                                    ({{getTagCount(tag.value)}})</div>
                            </div>
                        </div>
                        <button routerLink="/settings/registerSubCompany"
                            class="whitespace-nowrap border-green-500 border border-2 text-green-500  rounded-md px-2 py-1 text-center flex flex-row items-center justify-center cursor-pointer gap-2">
                            <mat-icon>add</mat-icon>
                            {{'manageSubCompany.table.addNewSubcompany'|translate}}</button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of cols" [pSortableColumn]="col.sortable?col.field:undefined"
                            [ngClass]="col.classStyle">
                            <div class="flex flex-row items-center gap-2" [ngClass]="col.classStyle">
                                {{col.header|translate}}
                                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                <!-- <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                    *ngIf="col.specialId==='tags'" field="tags" matchMode="contains" display="menu">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="companyTags"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <div
                                                    [ngClass]="'rounded-md px-2 py-1 font-bold w-min'+getTagClassStyle(option.value)">
                                                    {{('manageSubCompany.table.badge.'+option.value)|translate}}</div>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter> -->
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" >
                    <tr>
                        <td *ngFor="let col of cols; let i = index">
                            <div *ngIf="col.field === 'driverCount'">
                                <div class="flex flex-row items-center" [ngClass]="col.classStyle">
                                    {{totals.driverCount}}
                                </div>
                            </div>
                            <div *ngIf="col.field === 'vehicleCount'">
                                <div class="flex flex-row items-center" [ngClass]="col.classStyle">
                                    {{totals.vehicleCount}}
                                </div>
                            </div>
                            <div *ngIf="col.field === 'fileCount'">
                                <div class="flex flex-row items-center" [ngClass]="col.classStyle">
                                    {{totals.fileCount}}
                                </div>
                            </div>
                            <div *ngIf="col.field === 'fileCountInPrevMonth'">
                                <div class="flex flex-row items-center" [ngClass]="col.classStyle">
                                    {{totals.fileCountInPrevMonth}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td *ngFor="let col of cols; let i = index">
                            <div class="flex flex-row items-center" *ngIf="!col.specialId" [ngClass]="col.classStyle">
                                {{getValue(rowData,col)}}{{col.suffix}}
                            </div>
                            <div class="flex flex-col w-full items-start" *ngIf="col.specialId==='name'"
                                [ngClass]="col.classStyle">
                                <div [matTooltip]="('manageSubCompany.table.nameToltip'|translate)+': ' +rowData.responsiblePartnerName "
                                    class="text-lg">{{rowData.name}}</div>
                                <div class="text-red-500 w-full truncate">{{rowData.blockReason}}</div>
                            </div>
                            <div class="flex flex-row items-end" *ngIf="col.specialId==='priceInfo'"
                                [ngClass]="col.classStyle">
                                <div *ngIf="!!rowData.priceInfos[col.metadata]">
                                    <span class="font-bold text-xl mr-2">{{getValue(rowData,col)}}</span>
                                </div>
                                <div *ngIf="!rowData.priceInfos[col.metadata]">
                                    <span class="font-bold text-xl mr-2">-</span>
                                </div>
                            </div>
                            <div class="flex flex-row items-center" *ngIf="col.specialId==='totalDiscount'"
                                [ngClass]="col.classStyle">
                                {{getValue(rowData,col)}}{{col.suffix}}
                            </div>
                            <div class="flex flex-row items-center text-sm gap-2" [ngClass]="col.classStyle"
                                *ngIf="col.specialId==='tags'">
                                <div *ngFor="let tag of rowData.tags; let i = index" class=""
                                    [ngClass]="{'opacity-20': rowData.responsiblePartnerId !== clientId && removeTagPrefix(tag) === 'REQUESTED'}">
                                    <div [matTooltip]="('manageSubCompany.table.badge.tooltip'|translate)"
                                        [ngClass]="'px-2 py-1 rounded-md font-bold text-white'+getTagClassStyle(tag)">
                                        {{('manageSubCompany.table.badge.'+removeTagPrefix(tag))|translate}}</div>
                                </div>
                                <div *ngIf="!!rowData.trialEndDate  && containsTag(rowData, 'TAG_TRIAL')"
                                    class="w-min whitespace-nowrap rounded-md bg-slate-200 px-2 py-1 text-slate-700">
                                    {{'manageSubCompany.table.trialEndsOn'|translate}}: <span
                                        class="font-bold text-slate-900">{{formatDate(rowData.trialEndDate)}}</span>
                                </div>
                                <div *ngIf="!!rowData.proEndDate"
                                    class="w-min whitespace-nowrap rounded-md bg-slate-200 px-2 py-1 text-slate-700">
                                    {{'manageSubCompany.table.proEndsOn'|translate}}: <span
                                        class="font-bold text-slate-900">{{formatDate(rowData.proEndDate)}}</span>
                                </div>
                            </div>
                            <div *ngIf="col.specialId==='actions'">
                                <p-splitButton class="hideIcon" [model]="generateSplitButtonItems(rowData)">
                                </p-splitButton>
                            </div>
                            <!-- <div matTooltip="{{'manageSubCompany.proChangeTooltip'|translate}}"
                                (click)="proSwitchClicked(rowData)"
                                class="flex flex-row items-center text-lg cursor-pointer"
                                *ngIf="col.specialId==='proReq'" [ngClass]="col.classStyle">
                                <div class="rounded-md px-2 py-1 bg-tachosafe-500 text-white font-bold"
                                    *ngIf="rowData[col.field]==='PRO'">{{'manageSubCompany.table.badge.PRO'|translate}}
                                </div>
                                <div class="rounded-md px-2 py-1 bg-slate-500 text-white font-bold"
                                    *ngIf="rowData[col.field]==='BASIC'">
                                    {{'manageSubCompany.table.badge.BASIC'|translate}}</div>
                                <div class="rounded-md px-2 py-1 bg-yellow-400 text-slate-800 font-bold"
                                    *ngIf="rowData[col.field]==='REQUESTED'">
                                    {{'manageSubCompany.table.badge.REQUESTED'|translate}}</div>
                            </div> -->
                            <div class="flex flex-row items-center text-2xl cursor-pointer"
                                *ngIf="col.specialId==='blocked'" [ngClass]="col.classStyle">
                                <div
                                    class="p-2 shadow-md bg-slate-300 rounded-md flex flex-col items-center justify-center">
                                    <mat-icon (click)="blockClientClicked(rowData)"
                                        matTooltip="{{'manageSubCompany.lockTooltip.open'|translate}}"
                                        *ngIf="rowData[col.field]==='NOT_BLOCKED'" class="text-white">block</mat-icon>
                                    <mat-icon (click)="blockClientClicked(rowData)"
                                        matTooltip="{{'manageSubCompany.lockTooltip.close'|translate}}"
                                        *ngIf="rowData[col.field]==='BLOCKED'" class="text-red-500">block</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf="!userIsAdmin" class=" shadow-md bg-white w-full h-full flex flex-col items-center justify-center">
            <h1>{{'manageSubCompany.dashboard.youAreNotAnAdmin'|translate}}</h1>
        </div>
        <!-- <div class=" shadow-md p-8 bg-white ">
            <div *ngIf="data.companies.length==0" class="flex items-center justify-center w-full h-full">
                <p-skeleton shape="circle" size="20rem"></p-skeleton>
            </div>
            <p-chart *ngIf="data.companies.length!=0" type="pie" [data]="chartData" [options]="chartOptions"></p-chart>
        </div> -->


        <!-- <div class="bg-white p-4 shadow-md bg-white">
            <div *ngIf="data.companies.length==0" class="flex flex-col w-full h-full">
                <p-skeleton class="w-1/2 h-12 mb-6" height="2rem"></p-skeleton>
                <p-skeleton class="w-full h-12" styleClass="mb-2"></p-skeleton>
                <p-skeleton class="w-full h-12" styleClass="mb-2"></p-skeleton>
                <p-skeleton class="w-full h-12" styleClass="mb-2"></p-skeleton>
            </div>
            <div *ngIf="data.companies.length!=0">
                <h1 class="text-2xl font-semibold mb-4 text-gray-800">{{'manageSubCompany.membership.title' |
                    translate}} </h1>

                <div class="mb-4 flex flex-row justify-between">
                    <p class="text-lg text-gray-700">{{'manageSubCompany.membership.numberOf' | translate}}: </p><span
                        class="font-semibold text-black text-lg">{{data.companies.length}}</span>
                </div>

                <div class="mb-4 flex flex-row justify-between">
                    <p class="text-lg text-gray-700">{{'manageSubCompany.membership.numberOfPro' | translate}}: </p>
                    <span class="font-semibold text-black text-lg">{{proCount()}}</span>
                </div>

                <div class="mb-2 flex flex-row justify-between">
                    <p class="text-lg text-gray-700">{{'manageSubCompany.membership.payment' | translate}}: </p><span
                        class="font-semibold text-black text-lg">{{totalPayment()}}€</span>
                </div>
            </div>
        </div> -->
    </div>
</div>