import { getVariableValueAsNumber } from 'app/helpers/number_utility';
import * as moment from 'moment-timezone';
import { FormGroup } from '@angular/forms';

export enum DateIntervalOptions {
    CUSTOM,
    ACTUAL_WEEK,
    LAST_WEEK,
    CURRENT_MONTH,
    LAST_MONTH,
    CURRENT_YEAR,
    LAST_YEAR
}

export class DateUtil {
    private static ONE_DAY_EPOCH_SEC = 86400;

    public static transform(minutes: number, fullHour?: boolean, duration?: boolean): string {
        const days = !!minutes && minutes > 1440 ? Math.floor((minutes - 1) / 1440) : 0;
        if (minutes && (minutes > 1440 || minutes < -1440)) {
            minutes = minutes % 1440;
        }
        minutes = getVariableValueAsNumber(minutes);
        if (minutes < 0) {
            // Ha a shift még az előző napon kezdődött minusz értéket kapott a minutes, ami rossz kiírást eredményezett a munkavegzes mellett
            minutes = (this.ONE_DAY_EPOCH_SEC + minutes * 60) / 60;
        }
        const hours = Math.floor(minutes / 60);
        const minutesLeft = minutes % 60;
        if (fullHour && days * 24 + hours < 10) {
            return `0${!!duration && !!days ? days * 24 + hours : hours}:${minutesLeft < 10 ? '0' : ''}${minutesLeft}`
        } else {
            return `${!!duration && !!days ? days * 24 + hours : hours}:${minutesLeft < 10 ? '0' : ''}${minutesLeft}`
        }
    }

    public static generateFromToDatePeriod(from, to, timezone, dateFormat): string {
        return moment(from).tz(timezone).format(dateFormat) + ' - ' + moment(to).tz(timezone).format(dateFormat);
    }

    public static getMondayForDate(d: Date): Date {
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    public static getSundayForDate(d: Date): Date {
        const date = this.getMondayForDate(d);
        const diff = date.getDate() + 6;
        return new Date(date.setDate(diff));
    }

    public static setDateInterval(event, form: FormGroup) {
        switch (event) {
            case DateIntervalOptions.ACTUAL_WEEK:
                const startDate = new Date();
                form.get('rangeFormGroup').get('start').setValue(DateUtil.getMondayForDate(startDate));
                form.get('rangeFormGroup').get('end').setValue(DateUtil.getSundayForDate(startDate));
                break;
            case DateIntervalOptions.LAST_WEEK:
                const startDate2 = new Date();
                startDate2.setDate(startDate2.getDate() - 7);
                form.get('rangeFormGroup').get('start').setValue(DateUtil.getMondayForDate(startDate2));
                form.get('rangeFormGroup').get('end').setValue(DateUtil.getSundayForDate(startDate2));
                break;
            case DateIntervalOptions.CURRENT_MONTH:
                const today = new Date();
                const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                form.get('rangeFormGroup').get('start').setValue(firstDay);
                form.get('rangeFormGroup').get('end').setValue(today);
                break;
            case DateIntervalOptions.CURRENT_YEAR:
                const now = new Date();
                const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
                form.get('rangeFormGroup').get('start').setValue(firstDayOfYear);
                form.get('rangeFormGroup').get('end').setValue(now);
                break;
            case DateIntervalOptions.LAST_MONTH:
                const t = new Date();
                const fDay = new Date(t.getFullYear(), t.getMonth() - 1, 1);
                const lastDay = new Date(t.getFullYear(), t.getMonth(), 0);
                form.get('rangeFormGroup').get('start').setValue(fDay);
                form.get('rangeFormGroup').get('end').setValue(lastDay);
                break;
            case DateIntervalOptions.LAST_YEAR:
                const day = new Date();
                const firstDayOfLastYear = new Date(day.getFullYear() - 1, 0, 1);
                const lastDayOfLastYear = new Date(day.getFullYear() - 1, 11, 31);
                form.get('rangeFormGroup').get('start').setValue(firstDayOfLastYear);
                form.get('rangeFormGroup').get('end').setValue(lastDayOfLastYear);
                break;
            default:
                break;
        }
    }
}
