import {Pipe, PipeTransform} from '@angular/core';
import {DriverWithStatistics} from '../../services/drivers/drivers.service';

@Pipe({
    name: 'driverFilter'
})
export class DriverFilterPipe implements PipeTransform {

    transform(drivers: DriverWithStatistics[], driverNameFilter: any): DriverWithStatistics[] {

        if (!drivers) {
            return drivers;
        }

        // prepare driverNameFilter
        if (!driverNameFilter || driverNameFilter.length === 0) {
            return drivers;
        }

        let term: string;

        if (typeof driverNameFilter === 'string') {
            term = driverNameFilter.replace(/\s/g, '');
        } else {
            term = driverNameFilter.driver.driverFirstNames + driverNameFilter.driver.driverSurnames;
        }


        // escape characters witch are part of regex constants
        const search_item = new RegExp(term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

        return drivers.filter(driver => search_item.test((driver.driver.driverSurnames + driver.driver.driverFirstNames).toString().replace(/\s/g, '')));
    }
}
