import { Dailychartinput } from './chart.model';
import * as Highcharts from 'highcharts';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DailyChartResponse } from '../daily-chart.service';

export class ReportContext {
    chartDataReady: boolean;
    dailyChartResponses: DailyChartResponse[];
    dailyChartInputs: Dailychartinput[];
    dailyChartInputsTemp: Dailychartinput[];
    dailyChartInputsPaged: Dailychartinput[];
    dailyChartInputsPagedTemp: Dailychartinput[];
    chartDataGenerated: boolean;
    indexOfLastChartToLoad: number;
    highChartObjects: Highcharts.Chart[];
    private datasetChangeEmitter: EventEmitter<any>;
    countOfCharts: number;
    selectedDriverIndex: number;
    chartQueryForm: FormGroup;
    periodStartTime: number;
    periodEndTime: number;

    constructor(formBuilder: FormBuilder, start: FormControl, end: FormControl) {
        this.chartDataReady = false;
        this.dailyChartResponses = [];
        this.dailyChartInputsTemp = [];
        this.dailyChartInputsPaged = [];
        this.dailyChartInputsPagedTemp = [];
        this.indexOfLastChartToLoad = -1;
        this.highChartObjects = [];
        this.datasetChangeEmitter = new EventEmitter();
        this.countOfCharts = 0;
        this.selectedDriverIndex = 0;

        this.chartQueryForm = formBuilder.group({
            rangeFormGroup: formBuilder.group({
                start,
                end
            }),
            preselector: formBuilder.control({})
        });
    }

    onDatasSetChanged(): EventEmitter<any> {
        return this.datasetChangeEmitter;
    }

    destroy() {
        this.chartDataGenerated = false;
        this.chartDataReady = false;
        this.dailyChartInputsTemp = [];
        this.dailyChartResponses = [];
        this.dailyChartInputsPaged = [];
    }
}

export class LabelledReportContext {
    label: string;
    reportContext: ReportContext;

    constructor(label: string, reportContext: ReportContext) {
        this.label = label;
        this.reportContext = reportContext;
    }

    destroy() {
        this.reportContext.destroy();
    }
}
