import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, share} from 'rxjs/operators';
import {API_URL} from '../../helpers/globals';

export interface GetAllReleaseResponse {
    content: Releases[],
    status: boolean
}

export interface Releases {
    id: number,
    name: string,
    state: boolean,
    status: string,
    scheduledDate: number,
    releasedDate: number,
    userId: number,
    isDeleted: number,
    features: Features[]
}

export interface Features {
    id: number,
    releaseId: number,
    userID: number,
    name: string,
    description: string,
    type: string,
    link: string,
    isDeleted: number
}

export interface AddReleaseResponse {
    content: AddRelease,
    status: boolean
}

export interface AddRelease {
    id: number,
    name: string,
    status: string,
    scheduledDate: number,
    releasedDate: number,
    userId: number,
    isDeleted: number,
    features: any
}

export interface UpdateFeatureResponse {
    content: UpdateFeature,
    status: boolean
}

export interface UpdateFeature {
    id: number,
    releaseId: number,
    userId: number,
    name: string,
    description: string,
    type: string,
    link: string,
    isDeleted: number
}

export interface UpdateReleseResponse {
    content: UpdateRelease,
    status: boolean
}

export interface UpdateRelease {
    id: number,
    name: string,
    status: string,
    scheduledDate: number,
    releasedDate: number,
    userId: number,
    isDeleted: number,
    features: any
}

export interface DeleteResponse {
    content: boolean,
    status: boolean
}

@Injectable()
export class ReleaseService {

    constructor(private http: HttpClient) { }

    getAllReleases(limit?: number) {
        const params: HttpParams = new HttpParams()
            .append('limit', '' + limit);

        return this.http.get<GetAllReleaseResponse>(API_URL + 'releaseControl/getAll',
            {params: params, withCredentials: true}).pipe(share());
    }

    addNewRelease(name: string, status: string, scheduler: number) {
        return this.http
            .post<AddReleaseResponse>(API_URL + 'releaseControl/addRelease', {
                    name: name,
                    scheduledDate: scheduler,
                    status: status
                }, {withCredentials: true});
    }

    addNewFeature(releaseId: string, featureName: string, description: string, type: string, link: string) {
        return this.http
            .post<AddReleaseResponse>(API_URL + 'releaseControl/addFeature', {
                releaseId: releaseId,
                name: featureName,
                description: description,
                type: type,
                link: link
            }, {withCredentials: true});
    }

    updateFeature(id: number, name: string, description: string, type: string, link: string) {
        return this.http
            .post<UpdateFeatureResponse>(API_URL + 'releaseControl/updateFeature', {
                id: id,
                name: name,
                description: description,
                type: type,
                link: link
            }, {withCredentials: true});
    }

    deleteFeature(id: number) {
        return this.http
            .post<DeleteResponse>(API_URL + 'releaseControl/deleteFeature', {
                id: id
            }, {withCredentials: true});
    }

    updateRelease(id: number, name: string, status: string, scheduledDate: number) {
        return this.http
            .post<UpdateReleseResponse>(API_URL + 'releaseControl/updateRelease', {
                id: id,
                name: name,
                status: status,
                scheduledDate: scheduledDate
            }, {withCredentials: true});
    }

    deleteRelease(id: number) {
        return this.http
            .post<DeleteResponse>(API_URL + 'releaseControl/deleteRelease', {
                id: id
            }, {withCredentials: true});
    }
}
