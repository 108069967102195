import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameChart'
})
export class NamePipe implements PipeTransform {

    transform(value: string, firstName: string, lastName: string, format: string): any {
        if (!format || !format.includes('%s' && '%f')) {
            return 'Invalid format parameter';
        }
        let returnValue;
        lastName = lastName ? lastName.toUpperCase() : '';
        // firstName = firstName ? firstName.toLowerCase() : '';
        // if (firstName.charAt(0)) {
        //     firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        // }
        returnValue = format.replace(/%s/g, lastName);
        returnValue = returnValue.replace(/%f/g, firstName);
        return returnValue;
    }

}
