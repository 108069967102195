import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_URL} from '../../helpers/globals';
import {map, share} from 'rxjs/operators';
import {VehicleCatalog} from '../vehicles/vehicles.service';
import {Customer} from '../../views/analysis/work-hours-by-countrycode/customer';
import {OffencesResponse} from '../offences/offences.service';
import {PlacesResponse, Result} from './drivers.model';

export class DriverPersonalInfo {
    id: number;
    driverFirstNames: string;
    driverSurnames: string;
    cardNumber: string;
    driverLicence: string;
    lastDownload: number;
    daysFromLastActivity: number;
    daysFromLastDownload: number;
    cardDownloadPeriod: number;
    phone?: string;
    cardExpiryDate: number;
    isRetired: number;
    nfcVerificationStatus: string;
    firstActivityDate: number;
    lastActivityDate: number;
    status: string;
}

export class DriverWithStatistics {
    driver: DriverPersonalInfo;
    statistic: {
        newInfringementCount: number;
        deprecatedInfringementCount: number;
        driverId: number;
        totalInfringementCount: number;
    }
    infringementsCount: {
        driverId: number;
        mi: number;
        si: number;
        vsi: number;
        msi: number;
    }

    public static sort(driverWithStatistics1: DriverWithStatistics, driverWithStatistics2: DriverWithStatistics): number {
        const surname1 = driverWithStatistics1.driver.driverSurnames.toLowerCase();
        const surname2 = driverWithStatistics2.driver.driverSurnames.toLowerCase();
        return surname1 < surname2 ? -1 : surname1 > surname2 ? 1 : 0;
    }
}

interface DriversListResponse {
    TotalRecordCount: number;
    Records: DriverWithStatistics[];
    InfringementCounts:{driverId: number, mi:number, si:number, vsi:number, msi:number}[];
    Result: string;
}

export interface DriversDeleteResponse {
    success: boolean;
    driverId: number;
    result: string;
    statusText: string;
}

interface DriverActivity {
    type: number;
    day: number;
    cardSlot: number;
    startTime: number;
    endTime: number;
    duration: number;
    drivingStatus: number
}

interface Vehicles {
    vehicleIdentificationNumber: number;
    vehicleRegistrationNumber: string;
    vehicleRegistrationNation: string;
    vehicleFirstUse: number;
    vehicleLastUse: number;
    odoStart: number;
    odoEnd: number;
}
interface StartCountry {
    entryTime: number;
    country: string;
    vehicleOdoValue: number;
}

interface EndCountry {
    entryTime: number;
    country: string;
    vehicleOdoValue: number;
}

export class DriverActivities {
    driverName: string;
    cardNumber: string;
    day?: number;
    activityRecords: DriverActivity[];
}

interface ActivityDatas {
    day: string;
    driverName: string;
    cardNumber: string;
    activities: DriverActivity[];
    vehicles: Vehicles[];
    startCountry: StartCountry;
    endCountry: EndCountry;
}

export class DriverActivitiesADay {
    data: ActivityDatas;
}

export class DriverActivitiesForPeriod {
    data: ActivityDatas[];
}

export interface MonthlyStat {
    year: number;
    month: number;
    week: number;
    day: number;
    valueKM: number;
    workTimeMinutes: number;
}

export interface MonthlyStatMain {
    monthlyStat: MonthlyStat[];
}

export interface WeeklyStat {
    year: number;
    month: number;
    week: number;
    day: number;
    weekOfYear: number;
    valueKM: number;
    workTimeMinutes: number;
}

export interface WeeklyStatMain {
    weeklyStat: WeeklyStat[];
}

export interface DailyStat {
    year: number;
    month: number;
    week: number;
    day: number;
    valueKM: number;
    workTimeMinutes: number;
}

export interface DailyStatMain {
    dailyStat: DailyStat[];
}

export interface DriverFaultsResponse {
    Records: DriverFaults[];
}

export interface DriverFaults {
    id: number
    fault_type: number;
    fault_begin_time: number;
    fault_end_time: number;
    vehicle_registration_number: string;
    cardHolderSurname: string;
    cardHolderFirstnames: string;
    cardNumber: string;
}

export interface DriverEventsResponse {
    Records: DriverEvents[];
}

export interface DriverEvents {
    id: number;
    event_type: number;
    event_begin_time: number;
    event_end_time: number;
    vehicle_registration_number: string;
    cardHolderSurname: string;
    cardHolderFirstnames: string;
    cardNumber: string;
}

export interface DriverGroup {
    id: number;
    groupName: string;
    description: string;
}

export interface DriverCard {
    id: number;
    cardIssuingMemberState: string;
    cardNumber: string;
    cardReplacementIndex: number;
    cardRenewalIndex: number;
    cardIssuingAuthorityName: string;
    cardIssueDate: number;
    cardValidityBegin: number;
    cardExpiryDate: number;
    cardHolderSurname: string;
    cardHolderFirstNames: string;
    cardHolderBirthDate: string;
    cardHolderPreferedLanguage: string;
    retired: boolean;
}

export interface Information {
    driverId: number;
    driverAddress: string;
    driverPhoneNumber: string;
    driverEmailAddress: string;
    driverQuitDate: number;
    driverJoinDate: number;
    driverSettlingDate: number;
    driverQuitDateString: string;
    driverJoinDateString: string;
    driverSettlingDateString: string;
    licenceExpireDateString: string;
    blockedDateString: string;
    laborStandardsIsIgnored: boolean;
    notes: string;
    blockedDate: number;
    isActive: boolean;
    identificationNumber?: any;
    licenceExpireDate: number;
    internalBalance: number;
    vacationBalance: number;
    birthDate: string;
    licenceNr: string;
    authorityName: string;
    driverGroup: DriverGroup;
    driverCard: DriverCard;
    baseCourse: BaseCourse;
    lastCourse: LastCourse;
    nextCourse: NextCourse;
    courseNR: number;
    finishedCourseNR: number;
    verificationInterval: number;
    rfid: string;
    verificationStatus: string;
    clientId: number;
    active: boolean;
}

export interface BaseCourse {
    courseId: number;
    courseName: string;
    courseType: string;
    courseStartDate: number;
    courseEndDate: number;
    courseTransitTime: number;
    courseDescription: string;
}

export interface LastCourse {
    courseId: number;
    courseName: string;
    courseType: string;
    courseStartDate: number;
    courseEndDate: number;
    courseTransitTime: number;
    courseDescription: string;
}

export interface NextCourse {
    courseId: number;
    courseName: string;
    courseType: string;
    courseStartDate: number;
    courseEndDate: number;
    courseTransitTime: number;
    courseDescription: string;
}

export interface DriverInformations {
    information: Information;
    error?: any;
    message: string;
}

export interface DriverGroupsResponse {
    message: string;
    groups: DriverGroup[];
}

export interface DriversForGroupResponse {
    message: string;
    drivers: DriversForGroup[];
}

export interface DriversForGroup {
    id: number;
    driverFirstNames: string;
    driverSurnames: string;
}

export interface BasicResponse {
    data: null | any | any[];
}

export interface BasicMessageResponse {
    data: null | any | any[];
    message: string;
}

export interface DefaultResponse {
    message: string;
}

export interface AddGroupResponse {
    message: string;
    clientGroups: DriverGroup[];
}

export interface VehiclesForGroupResponse {
    vehicles: VehiclesForGroup[];
    message: string;
}

export interface VehiclesForGroup {
    registrationNumber: string;
}

export interface CoursesResponse {
    message: string;
    courses: Courses[];
}

export interface Courses {
    courseId: number;
    courseName: string;
    courseType: string;
    courseStartDate: number;
    courseEndDate: number;
    courseTransitTime: number;
    courseDescription: string;
}

export interface ExpenseHeader {
    groupName: string;
    periodStart: string;
    periodEnd: string;
    actDay: string;
}

export interface Expense {
    id: number;
    clientId: number;
    name?: any;
    value: number;
    hour: number;
}

export interface ExpenseRow {
    day: string;
    dayName: string;
    comment: string;
    deleted: boolean;
    paidWork: number;
    paidWorkString: string;
    expense: Expense;
    expenseValueString: string;
    shiftStartString: string;
    shiftEndString: string;
    shiftStart: number;
    shiftEnd: number;
}

export interface ExpenseTotal {
    driverName: string;
    totalExpense: number;
    totalPaidWork: number;
    totalPaidWorkString: string;
}

export interface ExpenseHeaderAndRow {
    expenseHeader: ExpenseHeader;
    expenseRows: ExpenseRow[];
    expenseTotal: ExpenseTotal;
    hasMissingData: boolean;
    lasActivity: number;
    driverId: number;
}

export interface ExpensesResponse {
    expenseHeaderAndRows: ExpenseHeaderAndRow[];
    message: string;
}

export interface ExpensesResponseForDriver {
    expenseHeaderAndRows: ExpenseHeaderAndRow;
    message: string;
}

export interface Expens {
    id: number;
    clientId: number;
    name: string;
    value: number;
    hour: number;
    drivingToInitial: boolean;
    workToInitial: boolean;
    availableToInitial: boolean;
    cardInsertionToInitial: boolean;
    restToInitial: boolean;
    initialRestMinHour: number;
    drivingToFinal: boolean;
    workToFinal: boolean;
    availableToFinal: boolean;
    cardExtractionToFinal: boolean;
    restToFinal: boolean;
    finalMinRestHour: number;
    calcEverythingToPW: boolean;
    calcWorkToPW: boolean;
    calcAvailableToPW: boolean;
    calcRestToPW: boolean;
    calcDrivingToPW: boolean;
    cipwRestMaxMinutes: number;
    frNone: boolean;
    frAddition: boolean;
    frSubstraction: boolean;
    frMinutesAddition: number;
    frMinutesSubtraction: number;
}

export interface ExpensResponse {
    message: string;
    expenses: Expens[];
}

export interface ExpensesPerClient {
    id: number;
    clientId: number;
    name: string;
    value: number;
    hour: number;
    initialMinRestHour: number;
    initialSelection: string;
    finalMinRestHour: number;
    finalSelection: string;
    calcWorkToPW: boolean;
    calcAvailableToPW: boolean;
    calcRestToPW: boolean;
    calcDrivingToPW: boolean;
    cipwRestMaxMinutes: number;
    flatRateSelection: string;
    frMinutesAddition: number;
    frMinutesSubtraction: number;
}

export interface ExpensesPerClientResponse {
    message: string;
    expenses: ExpensesPerClient[];
}

export interface GroupExpenses {
    id: number;
    groupName: string;
    description: string;
}

export interface GroupResponseForExpenses {
    groups: GroupExpenses[];
    message: string;
}

export interface DriverNameResponse {
    name: string;
    error: string;
}

export interface DeleteDriverResponse {
    error: string;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class DriversService {

    constructor(private http: HttpClient) {
    }

    getWorkPerCountry(clientId: number, startDate: number, endDate: number, driverIds: number[]) {
        return this.http
            .post<Result>(API_URL + 'getWorkPerCountry', {
                    clientId: clientId,
                    startDate: startDate,
                    endDate: endDate,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    driverIds: driverIds},
                {withCredentials: true})
            .pipe(map(response => {
                return response.result;
            }));
    }

    getAllDrivers(companyId: number): Observable<DriverWithStatistics[]> { // company id need to be selected so we can know wich comeny's vehicles we are interested in
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http
            .get<DriversListResponse>(API_URL + 'getDriversListV2',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response.Records;
            }));
    }

    generateDriverInfringements(driverId: number): Promise<any>{
        return this.http
            .post<any>(API_URL + 'generateDriverInfringements',{
                driverId: driverId
            },
            {withCredentials: true})
            .toPromise()
        }

    getCustomersMedium() {
        return this.http.get<any>('./assets/customers-medium.json')
            .toPromise()
            .then(res => <Customer[]>res.data)
            .then(data => {
                return data;
            });
    }

    getDriverName(id: number): Observable<DriverNameResponse> {
        return this.http.get<DriverNameResponse>(API_URL + 'getDriverName/' + id, {withCredentials: true}).pipe(share());
    }

    deleteDrivers(driverId: number): Observable<DriversDeleteResponse> {

        return this.http
            .get<DriversDeleteResponse>(API_URL + 'driver/' + driverId,
                {withCredentials: true}
            ).pipe(share());
    }

    retireDrivers(driverId: number): Observable<DriversDeleteResponse> {

        return this.http
            .get<DriversDeleteResponse>(API_URL + 'driver/retire/' + driverId,
                {withCredentials: true}
            ).pipe(share());
    }

    focusDrivers(driverId: number): Observable<DriversDeleteResponse> {

        return this.http
            .get<DriversDeleteResponse>(API_URL + 'driver/focus/' + driverId,
                {withCredentials: true}
            ).pipe(share());
    }

    // get method - now we use POST method
    // getWorkPerCountry(clientId: number, driverId: number, startDate: number, endDate: number, timeZone: string): Observable<WorkHoursPerCountryResponse> {
    //     const params: HttpParams = new HttpParams()
    //         .append('clientId', '' + clientId)
    //         .append('driverId', '' + driverId)
    //         .append('startDate', '' + startDate)
    //         .append('endDate', '' + endDate)
    //         .append('timeZone', '' + timeZone);
    //     return this.http.get<WorkHoursPerCountryResponse>(API_URL + 'getWorkPerCountry',
    //         {params: params, withCredentials: true}).pipe(share());
    // }

    mygetActivitiesForPeriod(driverId: number, startDate: number, endDate: number, clientId: string): Observable<DriverActivities> {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('clientId', '' + clientId);
        return this.http.get<DriverActivities>(API_URL + 'getActivityRecordsForPeriod',
            {params: params, withCredentials: true}).pipe(share());
    }

    myGetActivitiesForADay(driverId: number, day: number, clinetId: string): Observable<DriverActivitiesADay> {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId)
            .append('day', '' + day)
            .append('clientId', '' + clinetId);
        return this.http.get<DriverActivitiesADay>(API_URL + 'getDiagramData',
            {params: params, withCredentials: true}).pipe(share());
    }

    myGetActivitiesForAPeriod(driverId: number, startDate: number, endDate: number, clinetId: string): Observable<DriverActivitiesForPeriod> {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId)
            .append('clientId', '' + clinetId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);
        return this.http.get<DriverActivitiesForPeriod>(API_URL + 'getDiagramDataForPeriod',
            {params: params, withCredentials: true}).pipe(share());
    }

    myGetActivitiesForInfringements(driverId: number, startDate: number, endDate: number, clinetId: string): Observable<DriverActivitiesForPeriod> {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId)
            .append('clientId', '' + clinetId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);
        return this.http.get<DriverActivitiesForPeriod>(API_URL + 'getDiagramDataForInfringements',
            {params: params, withCredentials: true}).pipe(share());
    }

    getDiagramDataForTrace(driverId: number, startDate: number, endDate: number, clinetId: string): Observable<DriverActivitiesForPeriod> {
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId)
            .append('clientId', '' + clinetId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate);
        return this.http.get<DriverActivitiesForPeriod>(API_URL + 'getDiagramDataForTrace',
            {params: params, withCredentials: true}).pipe(share());
    }

    getDriverCatalog(driverId, companyId) {
        return this.http.get<VehicleCatalog>(API_URL + 'driver/' + companyId + '/' + driverId + '/lastYear', {withCredentials: true}).pipe(share());
    }

    refreshDriverCatalog(driverId, companyId) {
        return this.http.get<VehicleCatalog>(API_URL + 'driver/' + companyId + '/' + driverId + '/calculateLastYear', {withCredentials: true}).pipe(share());
    }

    getDriverStatisticMonthly(driverId): Observable<MonthlyStat[]> { // company id need to be selected so we can know wich comeny's vehicles we are interested in
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId);

        return this.http
            .get<MonthlyStatMain>(API_URL + 'getDriverStatisticMonthly',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response.monthlyStat;
            }));
    }

    getDriverStatisticWeekly(driverId): Observable<WeeklyStat[]> { // company id need to be selected so we can know wich comeny's vehicles we are interested in
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId);

        return this.http
            .get<WeeklyStatMain>(API_URL + 'getDriverStatisticWeekly',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response.weeklyStat;
            }));
    }

    getDriverStatisticDaily(driverId): Observable<DailyStat[]> { // company id need to be selected so we can know wich comeny's vehicles we are interested in
        const params: HttpParams = new HttpParams()
            .append('driverId', '' + driverId);

        return this.http
            .get<DailyStatMain>(API_URL + 'getDriverStatisticDaily',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response.dailyStat;
            }));
    }

    getDriverEvents(driverId: number, startDate: number, endDate: number): Observable<DriverEvents[]> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('limit', '' + 50)
            .append('offset', '' + 0)
            .append('sort', '' + 'event_begin_time DESC')
            .append('driverId', '' + driverId);
        return this.http.get<DriverEventsResponse>(API_URL + 'getDriverEvents',
            {params: params, withCredentials: true}).pipe(share(), map(response => {
            return response.Records;
        }));
    }

    getDriverFaults(driverId: number, startDate: number, endDate: number): Observable<DriverFaults[]> {
        const params: HttpParams = new HttpParams()
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('limit', '' + 50)
            .append('offset', '' + 0)
            .append('sort', '' + 'fault_begin_time DESC')
            .append('driverId', '' + driverId);
        return this.http.get<DriverFaultsResponse>(API_URL + 'getDriverFaults',
            {params: params, withCredentials: true}).pipe(share(), map(response => {
            return response.Records;
        }));
    }

    getDriverInformation(driverId: number) {
        return this.http.get<DriverInformations>(API_URL + 'getDriverInformation/' + driverId, {withCredentials: true}).pipe(share(), map(response => {
            return response.information;
        }));
    }

    addOrUpdateDriverInformation(itemForm) {
        return this.http.post<DefaultResponse>(API_URL + 'addOrUpdateDriverInformation', {
            driverId: itemForm.driverId,
            driverAddress: itemForm.driverAddress,
            driverPhoneNumber: itemForm.driverPhoneNumber,
            driverEmailAddress: itemForm.driverEmailAddress,
            driverQuitDate: itemForm.driverQuitDate,
            driverJoinDate: itemForm.driverJoinDate,
            blockedDate: itemForm.blockedDate,
            driverSettlingDate: itemForm.driverSettlingDate,
            notes: itemForm.notes,
            identificationNumber: itemForm.identificationNumber,
            licenceExpireDate: itemForm.licenceExpireDate,
            internalBalance: itemForm.internalBalance,
            vacationBalance: itemForm.vacationBalance,
            birthDate: itemForm.birthDate,
            licenceNr: itemForm.licenceNr,
            driverGroup: itemForm.driverGroup,
            cardNumber: itemForm.cardNumber,
            cardIssueDate: itemForm.cardIssueDate,
            cardIssuingAuthorityName: itemForm.cardIssuingAuthorityName,
            baseCourse: itemForm.baseCourse,
            lastCourse: itemForm.lastCourse,
            nextCourse: itemForm.nextCourse,
            cardValidityBegin: itemForm.cardValidityBegin,
            cardExpiryDate: itemForm.cardExpiryDate,
            cardHolderSurname: itemForm.cardHolderSurname,
            cardHolderFirstNames: itemForm.cardHolderFirstNames,
            cardHolderPreferedLanguage: itemForm.cardHolderPreferedLanguage,
            verificationInterval: itemForm.verificationInterval,
            rfid: itemForm.rfid,
            verificationStatus: itemForm.verificationStatus,
        }, {withCredentials: true})
    }

    deleteDriver(driverId, clientId) {
        return this.http.post<DeleteDriverResponse>(API_URL + 'deleteDriver', {
            driverId: driverId,
            clientId: clientId
        }, {withCredentials: true })
    }

    getDriverGroups(clientId) {
        return this.http.get<DriverGroupsResponse>(API_URL + 'groups/getAllGroups/' + clientId, {withCredentials: true}).pipe(share(), map(response => {
            return response.groups;
        }));
    }

    setDriverActive(driverId) {
        return this.http.get<DeleteDriverResponse>(API_URL + 'driver/activate/' + localStorage.getItem('selectedCompanyId') + '/'  + driverId, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    setDriverInactive(driverId) {
        return this.http.get<DeleteDriverResponse>(API_URL + 'driver/inactivate/' + localStorage.getItem('selectedCompanyId') + '/' + driverId, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    setDriverRetire(driverId) {
        return this.http.get<DeleteDriverResponse>(API_URL + 'driver/retire/' + localStorage.getItem('selectedCompanyId') + '/' + driverId, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    getDriversForGroup(groupId, clientId) {
        return this.http.get<DriversForGroupResponse>(
            API_URL + 'groups/getDriversForGroup/' + clientId + '/' + groupId ,
            {withCredentials: true}
            ).pipe(share(), map(response => {
            return response;
        }));
    }

    getVehiclesForGroup(groupId) {
        return this.http.get<VehiclesForGroupResponse>(API_URL + 'groups/getVehiclesForGroup/' + groupId, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    addDriverGroup(clientId, newGroup) {
        return this.http.post<AddGroupResponse>(API_URL + 'groups/addGroup/' + clientId, newGroup, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    addDriversToGroup(clientId, groupId, driverIds) {
        return this.http.post<DriversForGroupResponse>(API_URL + 'groups/addDriversToGroup/' + clientId + '/' + groupId,
            {driverIds: driverIds},
            {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    addVehiclesToGroup(clientId, groupId, vehicleRegNums) {
        return this.http.post<VehiclesForGroupResponse>(API_URL + 'vehicle/addVehiclesToGroup/' + clientId + '/' + groupId,
            {vehicleRegNums: vehicleRegNums},
            {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    getAvailableCourses(clientId) {
        return this.http.get<CoursesResponse>(API_URL + 'courses/getAllCourses/' + clientId, {withCredentials: true}).pipe(share(), map(response => {
            return response.courses;
        }));
    }

    addDriverCourses(clientId, newCourse) {
        return this.http.post<CoursesResponse>(API_URL + 'courses/addCourse/' + clientId, newCourse, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteDriverFromGroup(groupId, driverId) {
        return this.http.post<DriversForGroupResponse>(API_URL + 'groups/deleteDriverGroup',
            {
                groupId: groupId,
                driverId: driverId
            }, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteVehicleFromGroup(groupId, vehicleRegNr) {
        return this.http.post<VehiclesForGroupResponse>(API_URL + 'groups/deleteVehicleGroup',
            {
                groupId: groupId,
                vehicleRegNr: vehicleRegNr
            }, {withCredentials: true}).pipe(share(), map(response => {
            return response;
        }));
    }

    deleteGroup(groupId) {
        return this.http
            .post<DriverGroupsResponse>(API_URL + 'groups/delete/' + groupId, {},
                {withCredentials: true}).pipe(share(), map(response => {
                return response;
            }));
    }

    deleteCourse(courseId) {
        return this.http
            .post<CoursesResponse>(API_URL + 'courses/delete/' + courseId, {},
                {withCredentials: true}).pipe(share(), map(response => {
                return response;
            }));
    }

    getExpenses(companyId, groupId, startDate, endDate, expenseIds) {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId)
            .append('groupId', '' + groupId)
            .append('driverId', '' + 0)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('expenseIds', '' + expenseIds);

        return this.http
            .get<ExpensesResponse>(API_URL + 'spesen/getExpenseData',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response;
            }));
    }

    getExpensesForDriver(companyId, driverId, startDate, endDate, expenseIds) {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId)
            .append('groupId', '' + 0)
            .append('driverId', '' + driverId)
            .append('startDate', '' + startDate)
            .append('endDate', '' + endDate)
            .append('expenseIds', '' + expenseIds);

        return this.http
            .get<ExpensesResponseForDriver>(API_URL + 'spesen/getExpenseData',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                return response;
            }));
    }

    deleteDayInformation(driverId: number, timestamp: number) {
        return this.http.post<BasicMessageResponse>(API_URL + 'dayInformation/deleteDayInformation/' + timestamp + '/' + driverId, {}, {withCredentials: true}).pipe(share(), map(response => {
            return response.message;
        }));
    }

    restoreDayInformation(driverId: number, timestamp: number) {
        return this.http.post<BasicMessageResponse>(API_URL + 'dayInformation/restoreDayInformation/' + timestamp + '/' + driverId, {}, {withCredentials: true}).pipe(share(), map(response => {
            return response.message;
        }));
    }

    addCommentToDayInformation(driverId: number, timestamp: number, comment: string, userId: number, clientId: number) {
        return this.http.post<BasicMessageResponse>(API_URL + 'dayInformation/addInformation/' + timestamp + '/' + driverId,
            {
                clientId: clientId,
                userId: userId,
                comment: comment
            },
            {withCredentials: true}).pipe(share(), map(response => {
            return response.message;
        }));
    }

    addExpenseToClient(value, clientId) {
        return this.http.post<ExpensesPerClientResponse>(API_URL + 'expenses/addExpenseToClient/' + clientId,
            {
                name: value.name,
                value: value.value,
                hour: value.hour,
                drivingToInitial: value.drivingToInitial,
                workToInitial: value.workToInitial,
                availableToInitial: value.availableToInitial,
                cardInsertionToInitial: value.cardInsertionToInitial,
                restToInitial: value.restToInitial,
                initialRestMinHour: value.initialRestMinHour,
                drivingToFinal: value.drivingToFinal,
                workToFinal: value.workToFinal,
                availableToFinal: value.availableToFinal,
                lastActivityToFinal: value.lastActivityToFinal,
                cardExtractionToFinal: value.cardExtractionToFinal,
                calcEverythingToPW: value.calcEverythingToPW,
                calcWorkToPW: value.calcWorkToPW,
                calcAvailableToPW: value.calcAvailableToPW,
                calcRestToPW: value.calcRestToPW,
                cipwRestMaxMinutes: value.cipwRestMaxMinutes,
                frNone: value.frNone,
                frAddition: value.frAddition,
                frSubstraction: value.frSubstraction,
                frMinutesAddition: value.frMinutesAddition,
                frMinutesSubtraction: value.frMinutesSubtraction
            },
            {withCredentials: true}).pipe(share(), map(response => {
            return response.expenses;
        }));
    }

    getAllDriversWithoutStat(companyId: number): Observable<DriverWithStatistics[]> { // company id need to be selected so we can know wich comeny's vehicles we are interested in
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http
            .get<DriversListResponse>(API_URL + 'getDriversListV2',
                {params: params, withCredentials: true})
            .pipe(share(), map(response => {
                const infringementsCount = response.InfringementCounts;
                return response.Records.map(driver=>{
                    return {
                        ...driver,
                        infringementsCount:infringementsCount.find(d=>d.driverId===driver.driver.id)
                    }
                });
            }));
    }

    addExpenseToClientGroup(clientId: number, groupId: number, expenseid: number) {
        return this.http.post<GroupResponseForExpenses>(API_URL + 'expenses/addExpenseToClientGroup/' + clientId + '/' + expenseid + '/' + groupId,
            {},
            {withCredentials: true}).pipe(share(), map(response => {
            return response.groups;
        }));
    }

    getAllGroupForExpense(expenseid: number) {
        return this.http.get<GroupResponseForExpenses>(API_URL + 'expenses/getAllGroupForExpense/' + expenseid,
            {withCredentials: true}).pipe(share(), map(response => {
            return response.groups;
        }));
    }

    getAllExpensesForGroup(groupId: number) {
        return this.http.get<ExpensResponse>(API_URL + 'expenses/getAllExpensesForGroup/' + groupId,
            {withCredentials: true}).pipe(share(), map(response => {
            return response.expenses;
        }));
    }

    getAllExpensePerClient(clientId: number) {
        return this.http.get<ExpensesPerClientResponse>(API_URL + 'expenses/getAllExpensePerClient/' + clientId,
            {withCredentials: true}).pipe(share(), map(response => {
            return response.expenses;
        }));
    }
    //
    // updateExpenseForClient(id, clientId, name, value, hour,
    //                        initialSelection, initialMinRestHour,
    //                        finalSelection, finalMinRestHour,
    //                        calcDrivingToPW, calcWorkToPW, calcAvailableToPW, calcRestToPW, cipwRestMaxMinutes,
    //                        flatRateSelection, frMinutesAddition, frMinutesSubtraction) {
    //     return this.http.post<ExpensesPerClientResponse>(API_URL + 'expenses/updateExpense/' + clientId, {
    //         id: id,
    //         clientId: 21,
    //         name: name,
    //         value: value,
    //         hour: hour,
    //         initialSelection: initialSelection,
    //         initialMinRestHour: initialMinRestHour,
    //         finalSelection: finalSelection,
    //         finalMinRestHour: finalMinRestHour,
    //         calcWorkToPW: calcWorkToPW,
    //         calcAvailableToPW: calcAvailableToPW,
    //         calcRestToPW: calcRestToPW,
    //         calcDrivingToPW: calcDrivingToPW,
    //         cipwRestMaxMinutes: cipwRestMaxMinutes,
    //         flatRateSelection: flatRateSelection,
    //         frMinutesAddition: frMinutesAddition,
    //         frMinutesSubtraction: frMinutesSubtraction
    //     }, {withCredentials: true}).pipe(share(), map(response => {
    //         return response.expenses;
    //     }));
    // }

    updateExpenseForClient(id, value) {
        return this.http.post<ExpensesPerClientResponse>(API_URL + 'expenses/updateExpense/' + id, {
            id: value.id,
            clientId: value.clientId,
            name: value.name,
            value: value.value,
            hour: value.hour,
            initialSelection: value.initialSelection,
            initialMinRestHour: value.initialMinRestHour,
            finalSelection: value.finalSelection,
            finalMinRestHour: value.finalMinRestHour,
            calcWorkToPW: value.calcWorkToPW,
            calcAvailableToPW: value.calcAvailableToPW,
            calcRestToPW: value.calcRestToPW,
            calcDrivingToPW: value.calcDrivingToPW,
            cipwRestMaxMinutes: value.cipwRestMaxMinutes,
            flatRateSelection: value.flatRateSelection,
            frMinutesAddition: value.frMinutesAddition,
            frMinutesSubtraction: value.frMinutesSubtraction
        }, {withCredentials: true}).pipe(share(), map(response => {
            return response.expenses;
        }));
    }

    deleteExpenseFromClient(clientId: number, expenseId: number) {
        return this.http.get<ExpensesPerClientResponse>(API_URL + 'expenses/deleteExpenseFromClient/' + clientId + '/' + expenseId,
            {withCredentials: true}).pipe(share(), map(response => {
            return response.expenses;
        }));
    }

    deleteGroupFromExpenses(groupId: number, expenseId: number) {
        return this.http.get<GroupResponseForExpenses>(API_URL + 'expenses/deleteGroupFromExpenses/' + groupId + '/' + expenseId,
            {withCredentials: true}).pipe(share(), map(response => {
            return response.groups;
        }));
    }
}
