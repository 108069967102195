import { environment } from 'environments/environment';

let curr_domain= window.location.hostname;
let api_url= '';
let report_url = '';

switch (curr_domain) {
    case 'localhost':
        api_url = 'http://localhost:4200/TachoSafe/';
        break;
    case 'tachosafe.local':
        api_url = 'http://tachosafe.local:8081/TachoSafe/';
        break;
    case 'trackgps-staging.tacho4safe.com':
        api_url = 'https://api-staging.tacho4safe.com/TachoSafe/';
        report_url = 'https://rszoft-report-staging.tacho4safe.com/TachoSafe/';
        break;
    // case 'tachoanalitics.trackgps.ro':
    //     api_url = 'https://api-tachoanalitics.trackgps.ro/TachoSafe/';
    //     report_url = 'https://rszoft-report-tachoanalitics.trackgps.ro/TachoSafe/';
    //     break;
    default:
        if(window.location.hostname.split('.').length <= 2){
            api_url = `https://api.${window.location.hostname}/TachoSafe/`;
            report_url = `https://rszoft-report.${window.location.hostname}/charts-generator-api/v0/`;
        }else{
            api_url = `https://api-${window.location.hostname}/TachoSafe/`;
            report_url = `https://rszoft-report-${window.location.hostname}/charts-generator-api/v0/`;
        }
        break;
}

export const API_URL = api_url;
export const DEVICE_QR_URL = `${window.location.href}/#/app`;
export const CHART_API_URL =  report_url;
export const CURRENT_APPLICATION_VERSION = environment.appVersion;
// export const COLOR_DRIVE = '#BB271A';
// export const COLOR_REST = '#117B00';
// export const COLOR_AVAILABLE = '#B66302';
// export const COLOR_WORK = '#6C2CF5';
// export const COLOR_WORKING_TIME = '#606060';
// export const COLOR_CREW_DRIVING = '#808000';
export const COLOR_CHART_GREY = '#777777';

// export const COLOR_DRIVE = '#a1794a';
// export const COLOR_REST = '#2b6f39';
// export const COLOR_AVAILABLE = '#606060';
// export const COLOR_WORK = '#182d48';
// export const COLOR_WORKING_TIME = '#d38fc5';
// export const COLOR_CREW_DRIVING = '#c2d8f2';
// export const COLOR_INFRINGMENT = '#BC1E14';

export const COLOR_DRIVE = '#FF0000';
export const COLOR_REST = '#00FF00';
export const COLOR_AVAILABLE = '#F7931D';
export const COLOR_WORK = '#0000FF';
export const COLOR_WORKING_TIME = '#d38fc5';
export const COLOR_CREW_DRIVING = '#c2d8f2';
export const COLOR_INFRINGMENT = '#BC1E14';

export const LANGUAGES = [
    'HU', 'EN', 'RO', 'DE', 'NL', 'FR', 'DA', 'ES', 'SL', 'IT', 'SK', 'BG', 'CS'
]
export const TIMEZONES = [
    // 'UTC', 'CET', 'America/Argentina/Cordoba', 'Pacific/Pago_Pago', 'Asia/Ulan_Bator'
    'UTC', 'WET', 'CET', 'EET'
]

export const NUMBER_OF_CHART_TO_OPEN_DIALOG = 14;
export const NUMBER_OF_CHART_TO_PRINT_DIALOG = 85;
export const PROFILE = environment.PROFILE

export const I18N_URL = environment.I18N_URL;
export const I18N_KEY = environment.I18N_KEY;

// export const TRACKING_ID = environment.TRACKING_ID;

