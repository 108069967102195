import { ProType } from "../pro/pro.service";

export class Company {
    id: number;
    name: string;
}

export interface CompaniesResponse {
    companies: Company[];
}

export interface ReportStatisticsResponse {
    downloadedReportNr: number;
    createdReportNr: number;
    deletedReportNr: number;
    nrOfDrivers: number;
}

export interface ReportFile {
    id: number;
    fileHash: string;
    fileName: string;
    timestamp: number;
    clientId: number;
    userId: number;
    deleted: boolean;
    reportType: number;
    downloaded: boolean;
    ipAddress: string;
    driverId: number;
}

export interface ReportFilesReponse {
    result: string;
    files: ReportFile[];
}

export enum TypeOfAccount {
    NORMAL = 0,
    TEST = 1,
    SELF_ONLY = 2
}

export interface CompanyFlagsResponse {
    companyFlags: Flags;
    result: string;
    PROType:ProType;
    typeOfAccount:TypeOfAccount;
}

export interface Flags {
    expenseClient: boolean,
    nfcClient: boolean,
    connectedClient: boolean,
    uploaderClient: boolean,
    analyserClient: boolean
    proClient: boolean
}
