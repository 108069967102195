import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from 'app/services/progress-bar/progress-bar.service';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
    value: number;
    mode: ProgressBarMode;
    needed: boolean;

    constructor(private progressBarService: ProgressBarService) {}

    ngOnInit(): void {
        this.progressBarService.value.subscribe(value => this.value = value);
        this.progressBarService.mode.subscribe(mode => this.mode = mode);
        this.progressBarService.needed.subscribe(needed => this.needed = needed);
    }
}
