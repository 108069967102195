<div  class="driverReportDataContainer print">
    <div class="driverReportOuterCont summary" *ngIf="reportContext.dailyChartResponses?.length > 1">
        <ng-container [ngTemplateOutlet]="rowReportName"
                      [ngTemplateOutletContext]="{dailyChartService:dailyChartService}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="rowPeriod"
                      [ngTemplateOutletContext]="{dailyChartService:dailyChartService}">
        </ng-container>
        <span *ngIf="tab === tabOptions.DRIVER">
            <div class="driverReportDataRow" *ngFor="let dailyChartResponse of reportContext.dailyChartResponses let i = index;">
                <div class="driverReportDataCol1">
                    <span *ngIf="i == 0">
                        {{ 'Report.General.Drivers' | instantTranslate: dailyChartService.chartLanguage | async }}
                    </span>
                </div>
                <div class="driverReportDataCol2 margin-right_10">
                    <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                </div>
                <div class="driverReportDataCol3">
                    {{ '' | nameChart:dailyChartResponse.driverInfo.cardHolderFirstNames:dailyChartResponse.driverInfo.cardHolderSurname:('Report.DailyChart.nameFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                    {{dailyChartResponse.driverInfo.cardNumber}}
                    {{dailyChartResponse.driverInfo.cardIssuingMemberState}}
                </div>
            </div>
        </span>
        <span *ngIf="tab === tabOptions.VEHICLE">
            <div class="driverReportDataRow" *ngFor="let dailyChartResponse of reportContext.dailyChartResponses let i = index;">
                <div class="driverReportDataCol1">
                    <span *ngIf="i == 0">
                        {{ 'Report.DailyChart.vehicles' | instantTranslate: dailyChartService.chartLanguage | async }}
                    </span>
                </div>
                <div class="tab">
                    <mat-icon svgIcon="track_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    <div>
                        <div>
                            <b>
                                {{ dailyChartResponse.vehicleInfo.country }}
                                {{ dailyChartResponse.vehicleInfo.registrationNumber }}
                            </b>
                            {{ 'Report.DailyChart.vin' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ 'Report.DailyChart.vin' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.vehicleInfo.identificationNumber }}
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>

    <div class="dailyChartResponse" *ngFor="let dailyChartResponse of reportContext.dailyChartResponses">
        <div class="driverReportOuterCont">
            <div class="driverReportDataRow">
                <ng-container [ngTemplateOutlet]="rowReportName"
                              [ngTemplateOutletContext]="{dailyChartService:dailyChartService}">
                </ng-container>
            </div>
            <div class="driverReportDataRow">
                <ng-container [ngTemplateOutlet]="rowPeriod"
                              [ngTemplateOutletContext]="{dailyChartService:dailyChartService}">
                </ng-container>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.DRIVER">
                <div class="driverReportDataCol1">{{ 'Report.DailyChart.driverCard' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    <div>
                        <div>
                            <b>
                                {{ '' | nameChart:dailyChartResponse.driverInfo.cardHolderFirstNames:dailyChartResponse.driverInfo.cardHolderSurname:('Report.DailyChart.nameFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                            </b>
                            {{ dailyChartResponse.driverInfo.cardNumber }}
                            {{ dailyChartResponse.driverInfo.cardIssuingMemberState }}
                            {{ dailyChartResponse.driverInfo.cardHolderBirthDate | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                        </div>
                        <div>
                            {{ dailyChartResponse.driverInfo.cardInfo.cardApprovalNumber }}
                            SN: {{ dailyChartResponse.driverInfo.cardInfo.serialNumber }}
                            {{ 'Report.DailyChart.validUntil' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.driverInfo.cardInfo.cardExpiryDate | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                            <mat-icon *ngIf="dailyChartResponse.driverInfo.cardInfo.valid"
                                      svgIcon="completed_icon"
                                      class="dailyChartIcons"></mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.VEHICLE">
                <div class="driverReportDataCol1">
                    {{ 'Report.General.Vehicle' | instantTranslate: dailyChartService.chartLanguage | async }}:
                </div>
                <div class="tab">
                    <mat-icon svgIcon="track_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    <div>
                        <div>
                            <b>
                                {{ dailyChartResponse.vehicleInfo.country }}
                                {{ dailyChartResponse.vehicleInfo.registrationNumber }}
                            </b>
                            {{ 'Report.DailyChart.vin' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.vehicleInfo.identificationNumber }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.VEHICLE">
                <div class="driverReportDataCol1">&nbsp;</div>
                <div class="tab">
                    <mat-icon svgIcon="location_icon" class="dailyChartIcons margin-right_10" style="align-self: flex-start"></mat-icon>
                    {{ dailyChartResponse.vehicleInfo.odometerBegin | number: '':'hu' }} -
                    {{ dailyChartResponse.vehicleInfo.odometerEnd | number: '':'hu' }}
                    km,&nbsp;
                    <span>&sum;</span>&nbsp;
                    {{ dailyChartResponse.vehicleInfo.odometerSumma | number: '':'hu' }} km<br/>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div class="driverReportDataCol1">{{ 'Report.DailyChart.activeDays' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <span class="dailyChartIcons margin-right_10"></span>
                    <span *ngIf="tab === tabOptions.DRIVER">
                        {{dailyChartResponse.driverInfo.driverActiveDaysInPeriod}}
                        /
                        {{dailyChartResponse.driverInfo.queryPeriodInDays}}
                    </span>
                    <span *ngIf="tab === tabOptions.VEHICLE">
                        {{dailyChartResponse.vehicleInfo.activeDaysInPeriod}}
                        /
                        {{dailyChartResponse.vehicleInfo.queryPeriodInDays}}
                    </span>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div class="driverReportDataCol1">{{ 'Report.DailyChart.validation' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <span class="dailyChartIcons margin-right_10"></span>
                    {{ 'Report.DailyChart.digitalSignatureValidated' | instantTranslate: dailyChartService.chartLanguage | async }}
                    <mat-icon svgIcon="completed_icon" class="dailyChartIcons"></mat-icon>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div class="driverReportDataCol1">{{ 'Report.General.Timezone' | instantTranslate:dailyChartService.chartLanguage | async }}:</div>
                <div class="tab">
                    <mat-icon svgIcon="timezone_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                    <span>{{ dailyChartResponse.timeZone }}</span>
                </div>
            </div>
            <div class="driverReportDataRow">
                <div
                    class="driverReportDataCol1">{{ 'Report.DailyChart.source' | instantTranslate: dailyChartService.chartLanguage | async }}</div>
                <div class="tab">
                    <span *ngIf="tab === tabOptions.DRIVER" style="display: flex">
                        <mat-icon svgIcon="driver_card_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                        {{ 'Report.DailyChart.sourceType1' | instantTranslate: dailyChartService.chartLanguage | async }}
                        , {{ 'Report.DailyChart.downloadTime' | instantTranslate: dailyChartService.chartLanguage | async }}
                        {{ dailyChartResponse.driverInfo.driverCardDownloadTimeMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                    </span>
                    <span *ngIf="tab === tabOptions.VEHICLE" style="display: flex">
                        <mat-icon svgIcon="driver_card_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                        <span>{{ 'Report.General.Vehicle' | instantTranslate: dailyChartService.chartLanguage | async }}
                            , {{ 'Report.DailyChart.downloadTime' | instantTranslate: dailyChartService.chartLanguage | async }}
                            {{ dailyChartResponse.vehicleInfo.lastDownloadTimeMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="driverReportDataRow" *ngIf="tab === tabOptions.VEHICLE">
                <div
                    class="driverReportDataCol1">&nbsp;</div>
                <div class="tab">
                    <mat-icon class="dailyChartIcons margin-right_10"></mat-icon>
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.manufacturerName }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.approvalNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.partNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.softwareVersion }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.serialNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.monthYear }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.sensorApprovalNumber }}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.sensorSerialNumber}}
                    {{ dailyChartResponse.vehicleInfo.downloadIdentifier.sensorMonthYear }}
                </div>
            </div>
            <span *ngIf="tab === tabOptions.DRIVER">
                <div class="driverReportDataRow" *ngFor="let vehicle of dailyChartResponse.driverInfo.vehicles let i = index;">
                    <div class="driverReportDataCol1"><span *ngIf="i == 0">{{ 'Report.DailyChart.vehicles' | instantTranslate: dailyChartService.chartLanguage | async }}</span></div>
                     <div class="tab">
                        <div style="display:flex; width: 100%;">
                            <div style="display: flex; flex: 20%;">
                                <mat-icon svgIcon="track_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                                {{vehicle.vehicleRegistrationNation}}
                                {{vehicle.vehicleRegistrationNumber}}
                            </div>
                            <div style="display: flex; flex: 40%">
                                <mat-icon svgIcon="calendar_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                                {{ vehicle.vehicleFirstUseMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                                -
                                {{ vehicle.vehicleLastUseMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                            </div>
                            <div style="display: flex; flex: 27%">
                                <mat-icon svgIcon="location_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                                {{ vehicle.odometerBegin | number: '':'hu' }}&nbsp;-&nbsp;{{ vehicle.odometerEnd | number: '':'hu' }}&nbsp;km
                            </div>
                            <div style="display: flex; flex: 13%">
                                <span>&sum;</span>&nbsp;
                                {{ vehicle.odometerSumma | number: '':'hu' }}&nbsp;km<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <span *ngIf="tab === tabOptions.VEHICLE">
                <div class="driverReportDataRow" *ngFor="let driver of dailyChartResponse.vehicleInfo.drivers let i = index;">
                    <div class="driverReportDataCol1">
                        <span *ngIf="i == 0">
                            {{ 'Report.DailyChart.driversOfVehicle' | instantTranslate: dailyChartService.chartLanguage | async }}
                        </span>
                    </div>
                    <div class="tab">
                        <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10"></mat-icon>
                        {{driver.surName}}
                        {{driver.firstName}}
                        {{driver.cardNumber}}
                        <span *ngIf="driver.birthDateMoment">{{ driver.birthDateMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}</span>
                        {{ driver.useFromMoment ? driver.useFromMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) : 'N/A' }}
                        -
                        {{ driver.useToMoment ? driver.useToMoment.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) : 'N/A' }}
                    </div>
                </div>
            </span>
        </div>

        <ng-container [ngTemplateOutlet]="tableView"
                      [ngTemplateOutletContext]="{ dailyChartResponse: dailyChartResponse }">
        </ng-container>
    </div>
</div>

<ng-template #tableView let-dailyChartResponse='dailyChartResponse'>
    <div class="weekly-tables-container print">
        <div *ngFor="let weeklyTableInput of dailyChartResponse.tableInfo.weeks"
             class="weekly-table-wrapper print">
            <app-weekly-table [weeklyData]="weeklyTableInput"
                              [selectedTab]="tab"
                              [lastDownloadDate]="tab === tabOptions.DRIVER ?
                                                        dailyChartResponse.driverInfo.driverCardDownloadTime :
                                                        dailyChartResponse.vehicleInfo.lastDownloadTime"
                              [timezone]="dailyChartResponse.timeZone"
                              [isPrint]="true"></app-weekly-table>
        </div>
    </div>

    <div class="table-container weekly-table-container print" [ngClass]="tab === tabOptions.DRIVER ? 'driver-table' : 'vehicle-table'">
        <div class="weekly-table-print table-margin">
            <h4 class="footer print" style="padding: 12px 24px; margin: 0; text-align: left">{{ 'Report.General.Summary' | instantTranslate: dailyChartService.chartLanguage | async }}</h4>
            <table mat-table [dataSource]="[dailyChartResponse.tableInfo.queryPeriodSum]" class="svt-table"
                   [ngClass]="tab === tabOptions.DRIVER ? 'driver-table' : 'vehicle-table'">
                <ng-container matColumnDef="period">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.Period' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ _moment(summaryData.startDate * 1000).tz(dailyChartResponse.timeZone).format('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}&nbsp;-
                        {{ _moment(summaryData.endDate * 1000).tz(dailyChartResponse.timeZone).format('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="driving">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="kormany" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ dateUtil.transform(summaryData?.drive || 0, true, true) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="working">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="work" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ dateUtil.transform(summaryData?.work || 0, true, true) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="availability">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="available" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ dateUtil.transform(summaryData?.availability || 0, true, true) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="allWorksInDay">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <span>&sum;</span>&nbsp;
                            <span>{{ 'Report.WeeklyTable.NumberOfShifts' | instantTranslate: dailyChartService.chartLanguage | async | lowercase }}</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ dateUtil.transform(summaryData?.workSum || 0, true, true) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="resting">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="bed_new_colored_icon" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        -
                    </td>
                </ng-container>

                <ng-container matColumnDef="presence">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.WeeklyTable.Presence' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                    <td mat-cell *matCellDef="let summaryData">
                        -
                    </td>
                </ng-container>

                <ng-container matColumnDef="kmRange">
                    <th mat-header-cell *matHeaderCellDef>km</th>
                    <td mat-cell *matCellDef="let summaryData">
                        <div *ngIf="!!summaryData.odometerStart && !!summaryData.odometerEnd">
                            {{ summaryData.odometerStart | number:'':'hu' }}&nbsp;-&nbsp;{{ summaryData.odometerEnd | number:'':'hu' }}
                        </div>
                        <div *ngIf="!summaryData.odometerStart || !summaryData.odometerEnd">-</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="kmSum--long">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <span>&sum;</span>&nbsp;
                            <span>km</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        {{ !!summaryData.odometerSum ? (summaryData.odometerSum | number:'':'hu') : '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.Vehicle' | instantTranslate: dailyChartService.chartLanguage | async }}</th>
                    <td mat-cell *matCellDef="let summaryData">
                        <div *ngIf="summaryData.cars?.length > 0">
                                <span *ngFor="let car of summaryData.cars; let l = last">
                                    {{ car + (l ? '' : ', ') }}
                                </span>
                        </div>
                        <div *ngIf="!summaryData.cars || summaryData.cars.length === 0">-</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="driver">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon class="icon" style="transform: scale(1.2)">person</mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let summaryData">
                        <div *ngIf="summaryData.drivers?.length > 0">
                                <span *ngFor="let driverName of summaryData.drivers; let l = last">
                                    {{ driverName + (l ? '' : ', ') }}
                                </span>
                        </div>
                        <div *ngIf="!summaryData.drivers || summaryData.drivers.length === 0">-</div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tab === tabOptions.DRIVER ? driverDisplayColumns : vehicleDisplayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: tab === tabOptions.DRIVER ? driverDisplayColumns : vehicleDisplayColumns" class="footer"></tr>
            </table>
            <ng-container [ngTemplateOutlet]="notDownloadedDataTemplate"
                          [ngTemplateOutletContext]="{
                                    'lastDownloadTime': tab === tabOptions.DRIVER ?
                                                        dailyChartResponse.driverInfo.driverCardDownloadTime :
                                                        dailyChartResponse.vehicleInfo.lastDownloadTime,
                                    'periodEnd': dailyChartResponse.tableInfo.queryPeriodSum.endDate,
                                    'timezone': dailyChartResponse.timeZone
                              }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #notDownloadedDataTemplate let-lastDownloadTime='lastDownloadTime' let-periodEnd='periodEnd' let-timezone='timezone'>
    <div *ngIf="lastDownloadTime <= periodEnd * 1000" class="not-downloaded-data">
        {{ 'Report.General.NotFinalData' | instantTranslate:dailyChartService.chartLanguage | async }}
        {{ 'Report.General.LastDownloadDate' | instantTranslate:dailyChartService.chartLanguage | async }}:
        {{ _moment(lastDownloadTime).tz(timezone).format('Report.General.TimeFormat' | instantTranslate:dailyChartService.chartLanguage | async) }}
    </div>
</ng-template>

<ng-template #rowReportName let-dailyChartService='dailyChartService'>
    <div class="driverReportDataRow">
        <div class="driverReportDataCol1">
            {{ 'Report.DailyChart.nameOfReport' | instantTranslate: dailyChartService.chartLanguage | async }}
        </div>
        <div class="tab">
            <mat-icon svgIcon="chart_name_icon" class="dailyChartIcons margin-right_10"></mat-icon>
            <span *ngIf="tab === tabOptions.DRIVER">
                {{ 'Report.DailyChart.dailyChartDriver' | instantTranslate: dailyChartService.chartLanguage | async }}
            </span>
            <span *ngIf="tab === tabOptions.VEHICLE">
                {{ 'Report.DailyChart.dailyChartVehicle' | instantTranslate: dailyChartService.chartLanguage | async }}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #rowPeriod let-dailyChartService='dailyChartService'>
    <div class="driverReportDataRow">
        <div class="driverReportDataCol1">
            {{ 'Report.General.Period' | instantTranslate: dailyChartService.chartLanguage | async }}:
        </div>
        <div class="tab">
            <span class="dailyChartIcons margin-right_10"></span>
            {{ reportContext.periodStartTime | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
            - {{ reportContext.periodEndTime | dateFormat:('Report.General.DateFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}
        </div>
    </div>
</ng-template>
