import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../helpers/globals';

export interface ExperimentalResponse {
    filled: number
}

export interface DefaultResponse {
    result: string;
}

@Injectable()
export class FeedbackService {

    constructor(private http: HttpClient) {}

    getExperimental(clientId: string, userId: string, pageId: number): Observable<ExperimentalResponse> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + clientId)
            .append('userId', '' + userId)
            .append('pageId', '' + pageId);
        return this.http.get<ExperimentalResponse>(API_URL + 'experimental/pageStatus',
            {params: params, withCredentials: true})
    }

    postExperimental(itemForm) {
        return this.http.post<DefaultResponse>(API_URL + 'experimental/savePageStatus', {
            clientId: itemForm.clientId,
            userId: itemForm.userId,
            pageId: itemForm.pageId,
            isUseful: itemForm.isUseful,
            comment: itemForm.comment,
            toBeNotified: itemForm.toBeNotified
        }, {withCredentials: true})
    }
}
