import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { Settings } from 'app/app.settings.model';
import { AppSettings } from 'app/app.settings';
import { CompanyService } from 'app/services/company/company.service';
import { AuthService } from 'app/services/auth/auth.service';
import { AlertBar } from 'app/helpers/alert-bar';
import { TranslateService } from '@ngx-translate/core';
import { PROFILE } from 'app/helpers/globals';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Flags } from 'app/services/company/company.model';
import { Menu } from '../menu.model';
import { ViewAsTestService } from 'app/services/view-as-test.service';

@Component({
    selector: 'app-vertical-menu',
    templateUrl: './vertical-menu.component.html',
    styleUrls: ['./vertical-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MenuService]
})
export class VerticalMenuComponent implements OnInit, AfterViewInit {
    @Input('menuItems') menuItems:Menu[];
    @Input('menuParentId') menuParentId;
    @Output() menuItemClicked = new EventEmitter<any>();

    parentMenu: Array<Menu> = [];


    profile = PROFILE;
    companyId: number;

    public settings: Settings;

    constructor(public appSettings: AppSettings,
                public menuService: MenuService,
                public router: Router,
                private companySerivce: CompanyService,
                private authService: AuthService,
                private alert: AlertBar,
                private translate: TranslateService,
                private focusMonitor: FocusMonitor,
                private readonly viewAsTestService: ViewAsTestService) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {

        this.authService.currentUserRoles$.subscribe((newRoles) => {
            const currentUser = this.authService.getLoggedInUser();
            const isInTestMode = this.viewAsTestService.isInTestMode();
            // console.log('newRoles', newRoles);
            if(newRoles.ClientIsReseller){
                this.parentMenu = this.menuItems
                .filter(item => item.parentId === this.menuParentId)
                .filter(item => (currentUser && currentUser.clientId === 21) ||  item.onlySVT == false)
                .filter(item => !isInTestMode || item.id !== 10); // hide connected services
            }else{
                this.parentMenu = this.menuItems
                .filter(item => item.resellerOnly === false)
                .filter(item => item.parentId === this.menuParentId).filter(item => (currentUser && currentUser.clientId === 21) ||  item.onlySVT == false)
                .filter(item => !isInTestMode || item.id !== 10); // hide connected services
            }
            // this.parentMenu = this.parentMenu.filter(item => item.);
            // console.log('this.parentMenu', this.parentMenu);
            // console.log('newRoles', newRoles);
        });

        this.authService.currentCompanyId.subscribe((newCompanyId) => {
            if (newCompanyId !== 0) {
                this.companyId = newCompanyId;
                this.getPermissions(newCompanyId);
            }
        });
        // this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);

        this.router.events.subscribe((event) => {
            // console.log('event', event);
            if(event instanceof NavigationEnd){
                const url = event.urlAfterRedirects;
                if(url.includes('/uploaded-files')){
                    setTimeout(() => {
                        document.getElementById('sub-menu-7').classList.add('expanded', 'show');
                    }, 100);

                }
                
            }
        });
    }

    ngAfterViewInit() {
        this.focusMonitor.stopMonitoring(document.getElementById('menu-item-1'));
    }

    onClick(menuId) {
        // if (menuId === 6 && this.companyPermissions.analyserClient === false) { // analyserClient
        //     this.alert.openSuccessSnackBar(this.translate.instant('You can\'t access this feature'), 'OK');
        //     return;
        // } else if (menuId === 26 && this.companyPermissions.expenseClient === false) { // expenseClient
        //     this.alert.openSuccessSnackBar(this.translate.instant('You can\'t access this feature'), 'OK');
        //     return;
        // } else if (menuId === 22 && this.companyPermissions.connectedClient === false) { // connectedClient
        //     this.alert.openSuccessSnackBar(this.translate.instant('You can\'t access this feature'), 'OK');
        //     return;
        // } else if (menuId === 11 && this.companyPermissions.uploaderClient === false) { // uploaderClient
        //     this.alert.openSuccessSnackBar(this.translate.instant('You can\'t access this feature'), 'OK');
        //     return;
        // } else {
            this.menuService.toggleMenuItem(menuId);
            this.menuService.expandActiveSubMenu(this.menuItems);
            this.menuService.closeOtherSubMenus(this.menuItems, menuId);
        // }
        if (!this.parentMenu.find(menuItem => menuItem.id === menuId)?.hasSubMenu) {
            this.menuItemClicked.emit();
        }
        if(menuId === 13){
           //reload
           setTimeout(() => {
               window.location.reload();
           }, 10);
        }
    }

    getPermissions(clientId) {
        // this.companySerivce.getCompanyFlags(clientId).subscribe(resp => {
        //     this.companyPermissions = resp.companyFlags;
        // }, err => {
        //     if (err.status) {
        //         console.log('Whoops something went wrong with the request!');
        //         return;
        //     }
        // });
    }

}
