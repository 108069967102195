<h2>
    <b>{{'ConfirmDialog.confirmAction' | translate}}</b>
</h2>
<hr>
<div *ngIf="multipleStrings()" class="w-full flex flex-col gap-2">
    <span *ngFor="let m of message" >{{m|translate}}</span>
</div>
<div *ngIf="!multipleStrings()" mat-dialog-content>
    {{message | translate}}
</div>
<div mat-dialog-actions style="float: right; margin-top: 15px">
    <button mat-stroked-button color="warn" (click)="onNoClick()">{{'No' | translate}}</button>
    <button mat-raised-button
            color="warn"
            style="width: 150px"
            [mat-dialog-close]="true"
            cdkFocusInitial>
        {{'Yes' | translate}}
    </button>

</div>
