import { Component, OnInit } from '@angular/core';
import {OffencesService, Updates} from '../../services/offences/offences.service';
import {Subscription} from 'rxjs';
import moment from './../../moment.override';

@Component({
  selector: 'app-updater-checker',
  templateUrl: './updater-checker.component.html',
  styleUrls: ['./updater-checker.component.css']
})
export class UpdaterCheckerComponent implements OnInit {

    private _subscriptions: Subscription;

    serialNumber: number;
    visibleData: Updates[];
    serialNumberWriter = true;

    constructor(
        private offencesService: OffencesService
    ) {
        this._subscriptions = new Subscription();
    }

    ngOnInit(): void {

    }

    search() {
        const subscription = this.offencesService.getAllowedUpdaters(this.serialNumber)
            .subscribe((data) => {
                this.visibleData = data.updates;
                this.serialNumberWriter = false;
            })
        this._subscriptions.add(subscription);
    }

    formatDate(ts) {
        if (ts === 0 || ts === '' || moment(ts * 1000) >= moment([])) {
            return '';
        } else {
            return moment.parseZone(ts * 1000).myFormat('LLL');
        }
    }
}
