import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'app/helpers/globals';
import { AuthService } from '../auth/auth.service';
import { TypeOfAccount } from '../company/company.model';

export enum CompanyTag {
  PRO = 'TAG_PRO',
  BASIC = 'TAG_BASIC',
  REQUESTED = 'TAG_REQUESTED',
  BLOCKED = 'TAG_BLOCKED',
  PARTNER = 'TAG_PARTNER',
  TRIAL = 'TAG_TRIAL',
  STRIPE = 'TAG_STRIPE',
  SELF_ONLY = 'TAG_SELF_ONLY',
  TEST = 'TAG_TEST',
  TRIAL_PRO= 'TAG_TRIAL_PRO'
}

export type SubCompanyResponse = {
  result: 'SUCCESS'|'FAILED',
  companies:{
    id: number,
    name: string,
    pro: boolean,
    trialEndDate: number,
    proEndDate: number,
    responsiblePartnerId: number,
    responsiblePartnerName: string,
    driverCount: number,
    vehicleCount: number,
    fileCount: number,
    fileCountInPrevMonth: number,
    partner: boolean,
    blocked: 'BLOCKED'|'NOT_BLOCKED',
    pendingRequest: boolean,
    proType: 'PENDING'|'TRIAL'|'NOT_PRO'|'REQUESTED'|'TRIAL_PRO',
    blockReason: string,
    tags: CompanyTag[],
    isStripeClient: boolean,
    typeOfAccount: TypeOfAccount,
  }[]
}

type RequestProResponse = {
  notifType: 'PRO_REQUEST_IN_24_HOUR'|'PRO_REQUEST_FAILED'|'PRO_REQUESTED',
  message: 'SUCCESS'|'FAILED',
  result:string
}

type RequestBasicResponse = {
  result: 'SUCCESS'|'FAILED',
  message:string
}

export type CancelationReason = {
  id: number,
  nameOfReason: string,
  valueOfReason: string,
}

type CancelationReasonResponse = {
  result: 'SUCCESS'|'FAILED',
  reasons: CancelationReason[]
}

@Injectable({
  providedIn: 'root'
})
export class ManageSubCompanyService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
  ) { }

  async addTrialPeriod(clientId: number, days: number){
    const user = this.authService.getLoggedInUser();
    const response = await this.httpClient.post(API_URL+'promotions/add',{
      name: `TRIAL_PERIOD_BY_USER:${user.id}_TO_CLIENT:${clientId}`,
      validFrom: new Date().getTime(),
      validUntil: new Date().getTime() + days*24*60*60*1000,
      triggerId: 17,
    },{withCredentials: true}).toPromise();
    const promotionId = response['Promotion']['id'];
    const res = await this.httpClient.post(API_URL+'promotions/addDiscount',{
      promotionId:promotionId,
      toClientId:clientId,
      globalDiscount:false
    },{withCredentials: true}).toPromise();
  }

  async getSubCompanies(isSvt:boolean):Promise<SubCompanyResponse> {
    const res = await this.httpClient.get<SubCompanyResponse>(API_URL+'company/listForPRO',{withCredentials: true}).toPromise();
    if(res.result === 'FAILED'){
      return {
        result: 'FAILED',
        companies: []
      }
    }
    res.companies = res.companies.map(c=>{
      const tags = [];
      switch(c.proType){
        case 'TRIAL':
          tags.push(CompanyTag.TRIAL);
          break;
        case 'REQUESTED':
          tags.push(CompanyTag.PRO);
          break;
        case 'PENDING':
          tags.push(CompanyTag.REQUESTED);
          break;
        case 'NOT_PRO':
          tags.push(CompanyTag.BASIC);
          break;
        case 'TRIAL_PRO':
          tags.push(CompanyTag.TRIAL_PRO);
      }
      // if(c.pro){
      //   tags.push(CompanyTag.PRO);
      // }else{
      //   tags.push(CompanyTag.BASIC);
      // }
      // if(c.pendingRequest){
      //   tags.push(CompanyTag.REQUESTED);
      // }
      if(c.blocked){
        tags.push(CompanyTag.BLOCKED);
      }
      if(c.partner){
        tags.push(CompanyTag.PARTNER);
      }
      if(c.isStripeClient && isSvt){
        tags.push(CompanyTag.STRIPE);
      }
      if(c.typeOfAccount === TypeOfAccount.SELF_ONLY){
        tags.push(CompanyTag.SELF_ONLY);
      }
      if(c.typeOfAccount === TypeOfAccount.TEST){
        tags.push(CompanyTag.TEST);
      }
      return {
        ...c,
        // blocked: (c.blocked as unknown as boolean)?'BLOCKED':'NOT_BLOCKED',
        // proReq: c.pro?'PRO':(c.pendingRequest?'REQUESTED':'BASIC')
        tags
      }
    });
    return res;
  }

  async enableProForCompany(clientId:number, proEnds:number|undefined) {
    const user = this.authService.getLoggedInUser();
    return await this.httpClient.post(API_URL+'resellerProBilling/enablePro',{
      resellerClientId: user.clientId,
      resellerUserId: user.id,
      clientId: clientId,
      periodEnd: proEnds
    },{withCredentials: true}).toPromise();
  }

  async disableProForCompany(clientId:number, reason: string, reasonType:string, immediate: boolean) {
    const user = this.authService.getLoggedInUser();
    return await this.httpClient.post<RequestBasicResponse>(API_URL+'resellerProBilling/disablePro',{
      resellerClientId: user.clientId,
      resellerUserId: user.id,
      clientId: clientId,
      immediately: immediate,
      reasonType:reasonType,
      reason:reason
    },{withCredentials: true}).toPromise();
  }

  async disableTrialForCompany(clientId:number) {
    return await this.httpClient.get<RequestBasicResponse>(API_URL+`resellerProBilling/cancelTrialPeriod/${clientId}`,{withCredentials: true}).toPromise();
  }

  async blockClient(clientId: number, reason: string){
    const user = this.authService.getLoggedInUser();
    return await this.httpClient.post(API_URL+'resellerProBilling/blockClient',{
      resellerClientId: user.clientId,
      resellerUserId: user.id,
      clientId: clientId,
      reason:reason
    }, {withCredentials: true}).toPromise();
  }

  async unblockClient(clientId: number){
    const user = this.authService.getLoggedInUser();
    return await this.httpClient.post(API_URL+'resellerProBilling/unblockClient',{
      resellerClientId: user.clientId,
      resellerUserId: user.id,
      clientId: clientId,
    }, {withCredentials: true}).toPromise();
  }

  async promoteToPartner(clientId: number, comment:string){
    return await this.httpClient.post(API_URL+'resellerProBilling/saveResellers',{
      clientIds: [clientId],
      comment: comment
    }, {withCredentials: true}).toPromise();
  }

  async requestPro(){
    const user = this.authService.getLoggedInUser();
    return await this.httpClient.post<RequestProResponse>(API_URL+'resellerProBilling/proRequest',{
      clientId: user.clientId,
      userId: user.id,
    }, {withCredentials: true}).toPromise();
  }

  async requestBasic(reason: string, reasonType: string){
    const user = this.authService.getLoggedInUser();
    return await this.disableProForCompany(user.clientId,reason,reasonType, false);
  }

  async getCancelationReasons():Promise<CancelationReason[]>{
    const response =  await this.httpClient.get<CancelationReasonResponse>(API_URL+'resellerProBilling/getReasons',{withCredentials: true}).toPromise();
    return response.reasons || [];
  }

  async setUsageRecord(id: any, timestamp: number, value: number) {
    return await this.httpClient.post<{message:'FAILED'|'Success', result:string}>(API_URL+`products/updateSubscriptionUsage/${id}/${timestamp}/${value}`,{},{withCredentials: true}).toPromise();
  }

  async setSubscriptionPaused(id: any, timestamp) {
    return await this.httpClient.get<{message:'FAILED'|'SUCCESS', result:string}>(API_URL+`products/pausePaymentCollection/${id}/${timestamp}`,{withCredentials: true}).toPromise();
  }

  async changeTypeOfAccount(clientId: number, type: TypeOfAccount){
    const user = this.authService.getLoggedInUser();
    return await this.httpClient.post<{message:'FAILED'|'SUCCESS', result:string}>(API_URL+'company/setTypeOfAccount',{
      clientId: clientId,
      typeOfAccount: type
    }, {withCredentials: true}).toPromise();
  }
}
