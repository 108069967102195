import { TimeZone } from "./services/settings/settings.model";

const moment = require('moment-timezone');
let currentTimezone = 'UTC';
let offset = 0;


// Override moment.js to use the +03:00 timezone
moment.fn.myFormat = function (format) {
    return this.add(0, 'hours').format(format);
};

export const getCurrentTimezone = () => {
    return currentTimezone;
}
export const getDSTOffset = () => {
    if(currentTimezone=='UTC') return 0;
    return moment().isDST() ? 1: 0;

}
export const getOffset = () => {
    // let localOffset = new Date().getTimezoneOffset()/60;
    if(currentTimezone=='UTC') return offset*60;
    return -(offset+(moment().isDST() ? +1: 0))*60;
}

export const overrideMomentTimezone = (timezone:TimeZone) => {
    let localOffset = new Date().getTimezoneOffset()/60;
    let hours = 0;
    //UTC
    if(timezone.id==1){
        hours = 0;
        currentTimezone = 'UTC';
    }
    //WET
    if(timezone.id==2){
        hours = 0;
        currentTimezone = 'WET';
        if(moment().isDST()){
            localOffset = localOffset + 1;
        }
    }
    //CET
    if(timezone.id==3){
        hours = 1;
        currentTimezone = 'CET';
        if(moment().isDST()){
            localOffset = localOffset + 1;
        }
    }
    //EET
    if(timezone.id==4){
        hours = 2;
        currentTimezone = 'EET';
        if(moment().isDST()){
            localOffset = localOffset + 1;
        }
    }
    offset = hours
    moment.fn.myFormat = function (format) {
        return this.add(hours+localOffset, 'hours').format(format);
    };
}

export default moment;
