import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ProType = 'REQUESTED'|'TRIAL'|'NOT_PRO'|'PENDING'|'TRIAL_PRO';

export type Pro = {
  pro: ProType,
  showBanner:boolean
}

@Injectable({
  providedIn: 'root'
})
export class ProService {

  private proSubject: BehaviorSubject<Pro> = new BehaviorSubject<Pro>({
    pro: 'NOT_PRO',
    showBanner:false
  });

  public pro$ = this.proSubject.asObservable();

  public setPro(pro: Pro): void {
    console.log('setPro', pro);
    // if(this.proSubject.value.pro !== pro.pro){
      this.proSubject.next(pro);
    // }
  }

  constructor() { 
    // console.log('ProService constructor');
    //Remove after testing
    globalThis.setPro = this.setPro.bind(this);
  }
}
