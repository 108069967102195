import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { PROFILE } from '../helpers/globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProgressBarService } from 'app/services/progress-bar/progress-bar.service';

@Injectable()
export class MyXsrfInterceptor implements HttpInterceptor {
    profile = PROFILE;

    constructor(private cookieService: CookieService,
                private progressBarService: ProgressBarService,
                private translate: TranslateService,
                private snack: MatSnackBar,
                private _router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("Intercept start");
        // Get the auth header from the service.
        const xsrfToken = this.cookieService.get('XSRF-TOKEN');
        // console.log("XSRF in cookie: " + xsrfToken);

        if (xsrfToken) {
            // Clone the request to add the new header.
            const authReq = req.clone(
                {headers: req.headers.set('X-XSRF-TOKEN', xsrfToken)});
            // Pass on the cloned request instead of the original request.

            return next.handle(authReq).pipe(catchError(error => {
                if (!!error?.error?.errorCode) {
                    this.progressBarService.needed.next(false);
                    const errorCode = error.error.errorCode;
                    const message = this.translate.instant('ErrorMessage.' + (errorCode || 'General'));
                    this.snack.open(message, 'OK', {duration: 4000});
                }
                return throwError(error);
            }));
        }
        return next.handle(req).pipe(tap(event => {
            }, err => {
                // General errorr
                if (err.error instanceof Error) {
                    // A client-side or network error occurred. Handle it accordingly.
                    console.log('An error occurred:', err.error.message);
                    return null;
                }
                // HTTP request error handler - 401
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this._router.navigateByUrl('sessions/signin');
                    return err;
                }
                // HTTP request error handler - 404
                if (err instanceof HttpErrorResponse && (err.status === 404 || err.status === 403)) {
                    switch (PROFILE) {
                        case 0: {
                            // window.open('https://tacho4safe.statuspage.io/', '_self');
                            // this._router.navigateByUrl('sessions/404');
                            break;
                        }
                        case 1: {
                            this._router.navigateByUrl('sessions/404');
                            break;
                        }
                        case 2: {
                            this._router.navigateByUrl('sessions/404');
                            break;
                        }
                    }
                    return err;
                }
                // HTTP request error handler - 500
                if (err instanceof HttpErrorResponse && err.status === 500) {
                    switch (PROFILE) {
                        case 0: {
                            // window.open('https://tacho4safe.statuspage.io/', '_self');
                            // this._router.navigateByUrl('sessions/ 500');
                            break;
                        }
                        case 1: {
                            this._router.navigateByUrl('sessions/ 500');
                            break;
                        }
                        case 2: {
                            this._router.navigateByUrl('sessions/ 500');
                            break;
                        }
                    }
                    return err;
                }

                return err;
            }
        ));
    }
}
