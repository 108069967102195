import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CHART_API_URL } from 'app/helpers/globals';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
    }),
    withCredentials: true
};

interface ActuatorResponse {
    status: string;
}

@Injectable({
    providedIn: 'root'
})
export class BackendAvailabilityService {
    private interval;
    private ping = 1000 * 1000; // 10 seconds
    private snackBarRef: MatSnackBarRef<any>;
    alive = true;

    constructor(private http: HttpClient,
                private snack: MatSnackBar,
                private translate: TranslateService) {
        this.interval = setInterval(() => this.http
                .get<ActuatorResponse>(CHART_API_URL.replace('v0', 'actuator') + 'health', httpOptions)
                .subscribe(
                    response => {
                        this.alive = response.status === 'UP';
                        if (!this.alive) {
                            this.openSnackBar();
                        } else {
                            this.closeSnackBar();
                        }
                    },
                    () => {
                        this.alive = false;
                        this.openSnackBar();
                    }
                ), this.ping);
    }

    openSnackBar() {
        this.snackBarRef = this.snack.openFromComponent(IconSnackBarComponent, {
            data: {
                message: this.translate.instant('TryingToConnect'),
                icon: 'cloud_off'
            }
        });
    }

    closeSnackBar() {
        this.snackBarRef?.dismiss();
        this.snackBarRef = null;
    }
}

@Component({
    selector: 'app-icon-snack-bar',
    template: `<div class="icon-cell">
        <span>{{ data?.message }}</span>&nbsp;
        <mat-icon class="icon">{{ data?.icon }}</mat-icon>
    </div>`
})
export class IconSnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
