import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SettingsService} from '../../../services/settings/settings.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-user-activation',
    templateUrl: './user-activation.component.html',
    styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent implements OnInit, OnDestroy {

    vnumber = '2.28.0';
    message = '';

    private _subscriptions: Subscription;

    constructor(private route: ActivatedRoute,
                private _settingsService: SettingsService) {
        this._subscriptions = new Subscription();
    }

    ngOnInit() {
        const activationResponse = this.route.snapshot.params['response'];

        const subscription = this._settingsService.activateNewUser(activationResponse).subscribe((response) => {
            this.message = response.message;
        });
        this._subscriptions.add(subscription);
    }

    ngOnDestroy(): void {
        if (this._subscriptions) {
            this._subscriptions.unsubscribe();
        }
    }
}
