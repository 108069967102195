<div *ngIf="annotationType == chartAnnotationType.BORDER_CROSS" class="circle">
    <span *ngIf="innerText && innerText != ''">
        {{innerText}}
    </span>
    <span *ngIf="!innerText || innerText == ''">
        -
    </span>
</div>

<div *ngIf="annotationType == chartAnnotationType.FERRY">
    <mat-icon svgIcon="ferry_icon" class="dailyChartIcons"></mat-icon>
    {{ 'Report.DailyChart.ferryInfoText' | instantTranslate: currentLangCode | async }}
</div>

<div *ngIf="annotationType == chartAnnotationType.LASTDOWNLOADEDDATA">
    <div style="display: flex;">
        <mat-icon svgIcon="driver_card_icon" class="dailyChartIcons"></mat-icon>
        {{ 'Report.DailyChart.sourceType1' | instantTranslate: currentLangCode | async }}
        {{ 'Report.DailyChart.downloadTime' | instantTranslate: currentLangCode | async }}
        {{ lastDownloadMoment.format('Report.General.TimeFormat' | instantTranslate: currentLangCode | async) }}.
    </div>
    <div style="display: flex; color: orange">
        <div class="dailyChartIcons">&nbsp;</div>
        {{ 'Report.DailyChart.lastDownloadInfoText' | instantTranslate: currentLangCode | async }}
    </div>
</div>

<div *ngIf="annotationType == chartAnnotationType.VEHICLE">

    <span *ngIf="iconTexts">
        <span *ngFor="let it of iconTexts; let i = index" [ngStyle]="i === iconTexts.length -1 ? {'white-space': 'nowrap'} : {'white-space': 'unset'}">
            <span>
                <mat-icon svgIcon="{{it.icon}}" class="dailyChartIcons" style="padding-top: 4px; margin-right: unset;"></mat-icon>
                {{it.text}}
            </span>
        </span>
    </span>

<!--    <mat-icon svgIcon="track_icon" class="dailyChartIcons" style="padding-top: 6px;"></mat-icon>-->
<!--    {{innerText}}-->

    <p *ngIf="extraInfo" >
        <span class="borderedbox">
            <mat-icon svgIcon="change_vehicle_icon" class="dailyChartIcons" style="padding-top: 6px;"></mat-icon>
            {{ 'Report.DailyChart.vehicleChanged' | instantTranslate: currentLangCode | async }}
        </span>
    </p>
</div>

<div *ngIf="annotationType == chartAnnotationType.TIMEOFFSETCHANGEPLUS" class="borderedbox red flex">
    <mat-icon svgIcon="red_clock_icon" class="dailyChartIcons"></mat-icon>
    {{ 'Report.DailyChart.timezoneChangePlus' | instantTranslate: currentLangCode | async }}
</div>

<div *ngIf="annotationType == chartAnnotationType.TIMEOFFSETCHANGEMINUS" class="borderedbox red flex">
    <mat-icon svgIcon="red_clock_icon" class="dailyChartIcons"></mat-icon>
    {{ 'Report.DailyChart.timezoneChangeMinus' | instantTranslate: currentLangCode | async }}
</div>

<div *ngIf="annotationType == chartAnnotationType.DRIVERS">
<!--    <mat-icon svgIcon="kormany" class="dailyChartIcons" style="position:relative; top: 3px; margin-right: unset"></mat-icon>-->
<!--    <mat-icon *ngIf="extraInfo" svgIcon="kormany" class="dailyChartIcons"  style="position:relative; top: 3px;  margin-right: unset"></mat-icon>-->
<!--    <span style="margin-left: 10px;">{{innerText}}</span>-->
    <span *ngIf="iconTexts" [matTooltip]="innerText">
        <span *ngFor="let it of iconTexts; let i = index">
            <mat-icon [svgIcon]="it.icon" class="dailyChartIcons" style="position:relative; top: 3px; margin-right: unset; margin-left: unset;"></mat-icon>
            <mat-icon *ngIf="extraInfo && i === 1" [svgIcon]="it.icon" class="dailyChartIcons" style="position:relative; top: 3px; margin-right: unset; margin-left: unset;"></mat-icon>
            <span style="margin-left: 5px; margin-right: 10px;">{{ it.text }}</span>
        </span>
    </span>
</div>

<div *ngIf="annotationType === chartAnnotationType.WITHOUT_CARD">
    <span *ngFor="let it of iconTexts" style="position: relative">
        <mat-icon [svgIcon]="it.icon" class="dailyChartIcons" style="position:relative; top: 3px; margin-right: unset; margin-left: unset;"></mat-icon>
        <span>&nbsp;- - -</span>
    </span>
</div>
