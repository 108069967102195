<style>
    body {
        text-align: center;
    }

    h1 {
        font-size: 50px;
    }

    body {
        font: 20px Helvetica, sans-serif;
        color: #333;
    }

    article {
        display: block;
        text-align: left;
        width: 650px;
        margin: 0 auto;
    }

    a {
        color: #dc8100;
        text-decoration: none;
    }

    a:hover {
        color: #333;
        text-decoration: none;
    }
</style>
<div class="page-wrap height-100 mat-bg-primary">
    <article>
        <h1>We’ll be back soon!</h1>
        <div>
            <p>Sorry for the inconvenience but we’re performing some maintenance at the moment. If you need to, you can
                always
                <a href="https://tachosafe.freshdesk.com">contact us</a>, otherwise we’ll be back online shortly!</p>
            <p>— The Team</p>
        </div>
    </article>
</div>
