<div class="w-full h-full bg-slate-50 py-2">
    <p-toast position="top-right"></p-toast>
    <p-dialog [draggable]="false" [(visible)]="priceTableVisible">
        <ng-template pTemplate="header">
            <div class="font-bold text-2xl">
                {{'subscription.modal.priceTable.header'|translate}}
            </div>
        </ng-template>
        <div *ngIf="!!stripePriceData">
            <p>{{'subscription.modal.priceTable.description'|translate}}</p>
            <div class="flex flex-col gap-2 w-full">
                <div *ngFor="let price of stripePriceData.priceTable"
                    class="flex flex-row w-full justify-between gap-24">
                    <span
                        [ngClass]="{'font-bold text-tachosafe-500':price.current}">{{'subscription.modal.priceTable.used'|translate|replaceValues:
                        {value: getPriceRange(price.minValue, price.maxValue) } }}</span>
                    <span
                        [ngClass]="{'font-bold text-tachosafe-500':price.current}">{{'subscription.modal.priceTable.usedPrice'|translate|replaceValues:
                        {value:(price.price/100).toFixed(2)} }}</span>
                </div>
            </div>
            <p class="mt-6">{{'subscription.modal.priceTable.description2'|translate|replaceValues:
                {value:stripePriceData.driverCount} }}</p>
        </div>
    </p-dialog>

    <p-dialog [draggable]="false" closeIcon="false" [(visible)]="!!proModalData"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <div class="font-bold">
                <span *ngIf="!!proModalData && proModalData.type === 'requestPro'">
                    {{'subscription.modal.requestPro.title'|translate}}
                </span>
                <span *ngIf="!!proModalData && proModalData.type === 'requestBasic'">
                    {{'subscription.modal.requestBasic.title'|translate}}
                </span>
            </div>
        </ng-template>
        <div *ngIf="!!proModalData">
            <div class="flex flex-col gap-2">
                <span *ngIf="proModalData.type === 'requestPro'">
                    {{'subscription.modal.requestPro.description_0'|translate}}
                </span>
                <span *ngIf="proModalData.type === 'requestBasic'">
                    {{'subscription.modal.requestBasic.description_0'|translate}}
                </span>
                <span *ngIf="proModalData.type === 'requestPro'">
                    {{'subscription.modal.requestPro.description_1'|translate}}
                </span>
                <span *ngIf="proModalData.type === 'requestBasic'">
                    {{'subscription.modal.requestBasic.description_1'|translate}}
                </span>
            </div>
            <div *ngIf="proModalData.type === 'requestBasic'" class="w-full flex flex-col py-2">
                <div>{{'subscription.modal.requestBasic.reasonOfCancellation'|translate}}:</div>
                <div class="w-full flex flex-col gap-2 p-2">
                    <div *ngFor="let reason of cancellationReasons" class="w-full flex flex-row gap-2">
                        <p-radioButton [inputId]="reason.valueOfReason" name="reason" [value]="reason"
                            [(ngModel)]="selectedReason"></p-radioButton>
                        <label [for]="reason.valueOfReason">{{('subscription.cancellationReason.'+reason.valueOfReason)
                            |translate}}</label>
                    </div>
                </div>
            </div>
            <div *ngIf="!!selectedReason && selectedReason.valueOfReason === 'OTHER'" class="w-full p-2">
                <textarea cols="60" pInputTextarea [(ngModel)]="reasonText"></textarea>
            </div>
            <div class="flex flex-row justify-end mt-8 gap-4">
                <button (click)="cancelModal()"
                    class="bg-slate-200 rounded-md px-2 py-1">{{'subscription.modal.button.cancel'|translate}}</button>
                <button *ngIf="proModalData.type === 'requestPro'" (click)="requestPro()"
                    class="bg-tachosafe-500 text-white rounded-md px-2 py-1">{{'subscription.modal.button.requestPro'|translate}}</button>
                <button [ngClass]="{'opacity-50': selectedReason===null}" [disabled]="selectedReason===null"
                    *ngIf="proModalData.type === 'requestBasic'" (click)="requestBasic()"
                    class="bg-tachosafe-500 text-white rounded-md px-2 py-1">{{'subscription.modal.button.requestBasic'|translate}}</button>
            </div>
        </div>
    </p-dialog>

    <div class="flex-col md:flex-row flex gap-12 px-auto items-center justify-center pt-24 px-6">
        <div class="flex flex-col gap-8 rounded-xl bg-white p-8 shadow-md relative overflow-hidden">
            <div *ngIf="(pro$|async).pro !== 'REQUESTED'"
                class="bg-tachosafe-500 text-white absolute top-10 -right-16 rotate-45 font-extrabold px-24 z-10 text-xl flex items-center justify-center">
                {{'subscription.activeCard'|translate}}</div>
            <div class="text-center text-6xl font-bold text-tachosafe-500">{{'subscription.card.basic'|translate}}</div>
            <div class="flex flex-col gap-2">
                <div class="grid grid-cols-1 md:grid-cols-2 items-center justify-start gap-2 text-slate-700">
                    <a class="flex flex-row items-center gap-2 hover:underline" *ngFor="let feature of featureList"
                        [href]="feature.link">
                        <div>
                            <svg *ngIf="feature.isPro===false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                fill="currentColor" class="h-6 w-6 text-lime-500">
                                <path fill-rule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                    clip-rule="evenodd" />
                            </svg>
                            <svg *ngIf="feature.isPro===true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                fill="currentColor" class="h-6 w-6 text-tachosafe-500 opacity-50">
                                <path fill-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div>{{feature.name|translate}}</div>
                    </a>
                </div>
            </div>
            <button *ngIf="(pro$|async).pro !== 'REQUESTED'"
                class="rounded-md bg-tachosafe-500 opacity-50 p-2 px-4 py-2 font-medium text-white text-2xl">{{'subscription.basic.currentlyActive'|translate}}</button>
            <div *ngIf="(pro$|async).pro === 'REQUESTED'" class="w-full flex flex-row items-center justify-center">
                <button (click)="requestBasicModalOpen()"
                    class="w-full rounded-md bg-white text-tachosafe-500 opacity-80 p-2 px-4 py-2 opacity-80 hover:opacity-100 font-medium text-xl shadow-md">{{'subscription.basic.goBackToBasic'|translate}}</button>
            </div>
        </div>


        <div *ngIf="!stripePriceData || !(stripePriceData.stripeClient)"
            class="bg-white flex flex-col gap-8 rounded-xl p-8 shadow-md relative overflow-hidden">
            <div *ngIf="(pro$|async).pro === 'REQUESTED'"
                class="bg-tachosafe-500 text-white absolute top-10 -right-16 rotate-45 font-extrabold px-24 z-10 text-xl flex items-center justify-center">
                {{'subscription.activeCard'|translate}}</div>
            <div class="text-center text-6xl font-bold text-tachosafe-500">{{'subscription.card.pro'|translate}}</div>

            <span *ngIf="alreadyRequestedPro"
                class="text-red-500 text-lg">{{'subscription.pro.alreadyRequestedPro'|translate}}</span>
            <div class="flex flex-col gap-2">
                <div class="grid grid-cols-1 md:grid-cols-2 items-center justify-start gap-2 text-slate-700">
                    <a class="flex flex-row items-center gap-2 hover:underline" *ngFor="let feature of featureList"
                        [href]="feature.link">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="h-6 w-6 text-lime-500">
                                <path fill-rule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div>{{feature.name|translate}}</div>
                    </a>
                </div>
            </div>
            <div class="flex flex-col gap-2">
                <div class="w-full rounded-md shadow-md">
                    <button *ngIf="(pro$|async).pro !== 'REQUESTED' && !!stripePriceData"
                        [disabled]="alreadyRequestedPro" (click)="requestPro()"
                        [ngClass]="{'opacity-80': alreadyRequestedPro}"
                        class="bg-tachosafe-500 w-full rounded-md p-2 px-4 py-2 font-medium text-white text-2xl flex flex-row items-center justify-center gap-4">
                        <svg aria-hidden="true" *ngIf="proRequestLoading"
                            class="w-8 h-8 mr-2 text-white animate-spin fill-lime-500" viewBox="0 0 100 101" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                        {{'subscription.pro.button.requestPro'|translate}}


                    </button>
                    <button *ngIf="(pro$|async).pro !== 'REQUESTED' && !stripePriceData"
                        [disabled]="alreadyRequestedPro" [ngClass]="{'opacity-80': alreadyRequestedPro}"
                        class="bg-tachosafe-500 w-full rounded-md p-2 px-4 py-2 font-medium text-white text-2xl flex flex-row items-center justify-center gap-4">
                        <svg aria-hidden="true" *ngIf="proRequestLoading"
                            class="w-8 h-8 mr-2 text-white animate-spin fill-lime-500" viewBox="0 0 100 101" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                        <svg aria-hidden="true" *ngIf="!stripePriceData"
                            class="w-8 h-8 mr-2 text-white animate-spin fill-lime-500" viewBox="0 0 100 101" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>


                    </button>
                    <div *ngIf="(pro$|async).pro === 'REQUESTED'"
                        class="bg-tachosafe-500 opacity-50 w-full rounded-md p-2 px-4 py-2 font-medium text-white text-2xl text-center">
                        {{'subscription.pro.currentlyActive'|translate}}</div>
                </div>
            </div>
        </div>

        <div *ngIf="!!stripePriceData && stripePriceData.stripeClient"
            class="bg-white flex flex-col gap-8 rounded-xl p-8 shadow-md relative overflow-hidden">
            <div *ngIf="(pro$|async).pro === 'REQUESTED'"
                class="bg-tachosafe-500 text-white absolute top-10 -right-16 rotate-45 font-extrabold px-24 z-10 text-xl flex items-center justify-center">
                {{'subscription.activeCard'|translate}}</div>
            <div class="text-center text-6xl font-bold text-tachosafe-500">{{'subscription.card.pro'|translate}}</div>

            <span *ngIf="alreadyRequestedPro"
                class="text-red-500 text-lg">{{'subscription.pro.alreadyRequestedPro'|translate}}</span>
            <div class="flex flex-col gap-2">
                <div class="grid grid-cols-1 md:grid-cols-2 items-center justify-start gap-2 text-slate-700">
                    <a class="flex flex-row items-center gap-2 hover:underline" *ngFor="let feature of featureList"
                        [href]="feature.link">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="h-6 w-6 text-lime-500">
                                <path fill-rule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div>{{feature.name|translate}}</div>
                    </a>
                </div>
            </div>
            <div class="flex flex-col gap-2">
                <div class="w-full rounded-md shadow-md">
                    <button *ngIf="(pro$|async).pro !== 'REQUESTED'" [disabled]="alreadyRequestedPro"
                        (click)="requestPro()" [ngClass]="{'opacity-80': alreadyRequestedPro}"
                        class="bg-tachosafe-500 w-full rounded-md p-2 px-4 py-2 font-medium text-white text-2xl flex flex-row items-center justify-center gap-4">
                        <svg aria-hidden="true" *ngIf="proRequestLoading"
                            class="w-8 h-8 mr-2 text-white animate-spin fill-lime-500" viewBox="0 0 100 101" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>

                        <div (click)="createSubscription()" *ngIf="!stripePriceData.stripeClient">
                            {{'subscription.pro.button.requestPro'|translate}}
                        </div>

                        <div *ngIf="stripePriceData.stripeClient">
                            {{'subscription.pro.button.requestProUnderSvt'|translate|replaceValues:{value:(stripePriceData.unitPrice/100).toFixed(2)}
                            }}
                        </div>

                    </button>
                    <div *ngIf="(pro$|async).pro === 'REQUESTED'"
                        class="bg-tachosafe-500 opacity-50 w-full rounded-md p-2 px-4 py-2 font-medium text-white text-2xl text-center">
                        {{'subscription.pro.currentlyActive'|translate}}</div>
                </div>

                <div class="flex flex-col items-center justify-center gap-2">
                    <span *ngIf="!!stripePriceData.proUntil">{{'subscription.pro.paragraph.proUntil'|translate|replaceValues: { value:
                        formatProUntilDate() } }}
                    </span>
                    <div class="flex flex-row items-center justify-center gap-2">
                        <div class="flex flex-row gap-2"
                            *ngIf="stripePriceData.driverCount>0 && (pro$|async).pro !== 'REQUESTED'">{{'subscription.pro.paragraph.monthlyCostForActiveDrivers'|translate|replaceValues:
                            {value:(stripePriceData.driverCount*stripePriceData.unitPrice/100).toFixed(2)} }}
                            <div class="cursor-pointer text-tachosafe-500" (click)="priceTableVisible=true">
                                <mat-icon>help</mat-icon>
                            </div>
                        </div>
                        <div class="flex flex-row gap-2"
                            *ngIf="stripePriceData.driverCount==0 && (pro$|async).pro !== 'REQUESTED'">{{'subscription.pro.paragraph.monthlyCostForNoActiveDrivers'|translate}}
                            <div class="cursor-pointer text-tachosafe-500" (click)="priceTableVisible=true">
                                <mat-icon>help</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(pro$|async).pro === 'REQUESTED'" class="flex flex-row gap-2">
                        <a class="text-tachosafe-500 cursor-pointer underline" target="_blank"
                            (click)="openCustomerPortal()"
                            >{{'subscription.pro.paragraph.manageOnStripe'|translate}}</a>
                            <div class="cursor-pointer text-tachosafe-500" (click)="priceTableVisible=true">
                                <mat-icon>help</mat-icon>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>