import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {CharttranslationService} from '../../services/rszoft/charttranslation.service';

@Pipe({
    name: 'instantTranslate'
})
export class InstantTranslatorPipe implements PipeTransform {

    translations: any[];

    constructor(private charttranslationServcie: CharttranslationService) {
    }

    transform(value: any, currentLangCode?: string): Observable<string> {
        if (currentLangCode) {
            return this.charttranslationServcie.getTextByLanguageAndValue(value, currentLangCode);
        } else {
            return this.charttranslationServcie.getTextByLanguageAndValue(value);
        }
    }

}
