import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../helpers/globals';
import {
    AllSubscriptionResponse,
    CancelResponse,
    ClientSubscriptionStatus,
    CreateSubscriptionResponse, Product,
    ProductResponse
} from './subscription.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class SubscriptionService {

    constructor(
      private http: HttpClient
    ) { }

    getAllProducts(): Observable<Product[]> {
        return this.http
            .get<Product[]>(API_URL + 'products/getAllProducts', {withCredentials: true})
            .pipe(map(response => { return response; }));
    }

    createSubscription() {
        return this.http
            .post<CreateSubscriptionResponse>(API_URL + 'products/createSubscription', {}, {withCredentials: true } )
            .pipe(map(response => {
                return response;
            }));
    }

    getAllSubscription(): Observable<AllSubscriptionResponse> {
        return this.http
            .get<AllSubscriptionResponse>(API_URL + 'products/getAllSubscription', {withCredentials: true})
            .pipe(map(response => { return response; }));
    }

    cancelSubscription(): Observable<CancelResponse> {
        return this.http.post<CancelResponse>(API_URL + 'products/cancelSubscription', {}, {withCredentials: true } )
    }

    getClientSubscriptionStatus(clientId:number): Observable<ClientSubscriptionStatus> {
        return this.http.get<ClientSubscriptionStatus>(API_URL + 'products/getClientSubscriptiosStatus/' + clientId, {withCredentials: true})
            .pipe(map(response => { 
                console.log(response)
                if(!response.stripeClient) return response
                response.priceTable.forEach((item, index) => {
                    if(item.minValue<=response.driverCount && item.maxValue>=response.driverCount){
                        item.current = true;
                    }else{
                        item.current = false;
                    }
                });
                return response; }));
    }

    getCustomerPortalUrl(): Observable<string> {
        return this.http.get<{message:string}>(API_URL + 'products/getCustomerPortal', {withCredentials: true})
            .pipe(map(response => { return response?.message??''; }));
    }
}
