export const environment = {
    production: true,
    appVersion: require('../../package.json').version,
    //API_URL: ' https://api.tacho4safe.com/TachoSafe/',
    //CHART_API_URL: 'https://rszoft-report.tacho4safe.com/charts-generator-api/v0/',
    //DEVICE_QR_URL: 'https://tacho4safe.com/#/app',
    PROFILE: 0,
    PRINT_TEST: false,
    I18N_URL: 'https://i18n.tacho5safe.com',
    I18N_KEY: 'tgpak_gvptiytqoe4ha4deonwdmzlonbrg23lhorsw23lbnaytg'
};

// API_URL
//      1. https://tachosafe.ro:8087/TachoSafe/-test
//      2. https://api.tacho4safe.com/TachoSafe/ - live
//      3. https://api.tachodownload.com/TachoSafe/ - TCD
//      4. https://api.hdtach.de/TachoSafe/ - hanseDis
//      + tachosafe.ro:8082/TachoSafe, https://gateway.tachosafe.ro/ - gatewaytest, tachosafe.ro:8081-updater

// PROFILES
//      1. Tacho4Safe - 0
//      2. TCD - 1
//      3. HanseDis - 2
