import {Injectable} from '@angular/core';
import {Settings} from './app.settings.model';

/**
 * Application settings
 */
@Injectable()
export class AppSettings {
    /**
     * Settings object
     */
    public settings = new Settings(
        'vertical',
        'default'
    );
}

