import { Component, OnInit } from '@angular/core';
import {ProgressBarService} from '../../services/progress-bar/progress-bar.service';
import {BillingData, OffencesService} from '../../services/offences/offences.service';
import {Subscription} from 'rxjs';
import {filter, finalize} from 'rxjs/operators';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-billable-terminals',
  templateUrl: './billable-terminals.component.html',
  styleUrls: ['./billable-terminals.component.css']
})
export class BillableTerminalsComponent implements OnInit {

    private _subscriptions: Subscription;

    years = [];
    quarters = [1, 2, 3, 4];
    months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    selectedYear: number;
    selectedQuarter: number;
    selectedMonth = '';

    filterValue: '';
    queryButtonUnweighted: boolean;
    firstLoad = 1;
    selectedTab = 'True';

    firstCall = true;
    companyId: number;
    showChild: boolean;

    tableVisibility = false;

    selectedQuarterMonths = [];
    filteredValues: any;

    visibleDatas: any;

    levelColor = {
        1: 'FF0D47A1',
        2: 'FF1976D2',
        3: 'FF2196F3',
        4: 'FF42A5F5',
        5: 'FF64B5F6',
        6: 'FFBBDEFB',
        7: 'FFE1F5FE'
    }

    countCells = 100;

    cols = [
        { field: 'clientName', header: 'Registration number', width: '19rem', otherusage: 'registrationNumber', align: 'left', third: 'clientId' },
        { field: 'registrationNumber', header: '', width: '0rem', otherusage: '', align: '', third: ''  },
        { field: 'error', header: '', width: '0rem', otherusage: '', align: '', third: ''  },
        { field: 'terminalTypeName', header: 'Terminal type', width: '3rem', otherusage: '', align: 'left', third: ''},
        { field: 'serialNumber', header: 'Serial Number',  width: '6rem', otherusage: '', align: 'left', third: ''},
        { field: 'iccid', header: 'SIM serial', width: '7rem', otherusage: '', align: 'left', third: ''},
        { field: 'brand', header: 'Service', width: '2.5rem', otherusage: '', align: 'left', third: ''},
        { field: 'lastDownload', header: 'Report.Checklist.LastDownload',  width: '7rem', otherusage: '', align: 'left', third: '' },
        { field: 'installDate', header: 'Install date',  width: '7rem', otherusage: '' , align: 'left'},
        { field: 'subcriptionTimeInMonths', header: 'Months included', width: '3rem', otherusage: '', align: 'right', third: ''},
        { field: 'billedMonths', header: 'Billed months',  width: '2rem' , otherusage: 'sum', align: 'end', third: ''},
        { field: 'SUM', header: 'Excel',  width: '4rem', otherusage: '', align: 'center', third: '' }
    ];

    visibleDataWithoutParent = [];
    billableTerminalSubscription: Subscription;

    constructor(
        public progressBarService: ProgressBarService,
        public offencesService: OffencesService,
        public authService: AuthService
    ) {
        this._subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.progressBarService.mode.next('query');

        this.changeChildVisibility(this.selectedTab);
        this.selectQuarter();
        this.initializeSelectedValue();
        this.calculateActualQuarter();

        this.authService.currentCompanyId
            .pipe(filter(companyId => !!companyId && companyId !== 0))
            .subscribe(companyId => {
                this.companyId = companyId;
                if (this.firstCall) {
                    this.firstCall = !this.firstCall;
                } else {
                    // console.log('cegcsere', companyId);
                    this.getCalculatedBillinForClient();
                }
            })
    }

    onFilter(event, dt) {
        this.filteredValues = event.filteredValue;
        return this.filteredValues;
    }

    abortQuery() {
        this.queryButtonUnweighted = false;
        this.progressBarService.needed.next(false);
        this.billableTerminalSubscription?.unsubscribe();
        this._subscriptions = null;
    }

    changeChildVisibility(event) {
        this.showChild = event === 'True';
        this.selectedTab = event;
    }

    selectQuarter() {
        const month = new Date().getMonth();
        if (month === 11 || month === 0 || month === 1 ) {
            this.selectedQuarter = 1;
        } else if (month === 2 || month === 3 || month === 4) {
            this.selectedQuarter = 2;
        } else if (month === 5 || month === 6 || month === 7 ) {
            this.selectedQuarter = 3;
        } else {
            this.selectedQuarter = 4;
        }

        if (this.selectedQuarter === 1 ) {
            this.selectedQuarter = 4;
            this.selectedYear = new Date().getFullYear() - 1;
        } else {
            this.selectedQuarter = this.selectedQuarter - 1;
            this.selectedYear = new Date().getFullYear()
        }
    }

    applyColStyles(width, align, column) {
        let styles;
        styles = {'width' : width, 'text-align': align, 'padding': ' 15px 0px 15px 0px'};
        if (column === 'clientName') {
            styles.padding = '15px 0px 15px 15px';
        }
        return styles;
    }

    applyRowStyles(width, align) {
        const styles = {'width' : width, 'text-align': align, 'line-height': '0.5rem', 'padding': ' 0px 5px 0px 0px'};
        return styles;
    }

    toString(rowDatum: any) {
        return rowDatum.toString().split()[0].split('\x00')[0];
    }

    initializeSelectedValue() {
        const currentYear = new Date().getFullYear();
        this.years.push(currentYear);
        this.years.push(currentYear - 1);
        this.years.push(currentYear - 2);
    }

    calculateActualQuarter() {
        let currentQuarterMonths = [];
        if (this.selectedQuarter === 1) {
            currentQuarterMonths = [1, 2, 3];
        } else if (this.selectedQuarter === 2) {
            currentQuarterMonths = [4, 5, 6];
        } else if (this.selectedQuarter === 3) {
            currentQuarterMonths = [7, 8, 9];
        } else {
            currentQuarterMonths = [10, 11, 12];
        }
        this.selectedQuarterMonths = currentQuarterMonths;
    }

    hideTable() {
        this.tableVisibility = false;
    }

    selectMonth(which) {
        (which === 'month') ? this.selectedQuarter = 0 : this.selectedMonth = '';
        this.calculateActualQuarter();
    }

    async getCalculatedBillinForClient() {
        const generateDeviceNode = (oldData, newData) => {
            let dataStatus = "Existed";
            const old: any = {

            };
            if (oldData == null && newData == null) return;
            else if (oldData == null && newData != null) {
                dataStatus = "New";
            } else if (oldData != null && newData == null) {
                dataStatus = "Deleted";
                old.billedMonths = 0;
            }
            const newDeviceNode = { ...oldData, ...newData, ...old, dataStatus };
            return newDeviceNode;
        }

        const generateTree = (oldData, newData) => {
            let dataStatus = "Existed";
            if (oldData == null && newData == null) return;
            else if (oldData == null && newData != null) {
                dataStatus = "New";
            } else if (oldData != null && newData == null) {
                dataStatus = "Deleted";
            }
            const newNode = { ...oldData, ...newData, children: [], devices: [], dataStatus };

            const oldChildren = oldData?.children || [];
            const newChildren = newData?.children || [];
            const allChildren = [];
            for (const oc of oldChildren) {
                allChildren.push({ oc: oc, nc: null });
            }
            for (const nc of newChildren) {
                const index = allChildren.findIndex(x => x.oc != null && x.oc.clientId == nc.clientId);
                if (index == -1) {
                    allChildren.push({ oc: null, nc: nc });
                } else {
                    allChildren[index].nc = nc;
                }
            }

            const oldDevices = oldData?.devices || [];
            const newDevices = newData?.devices || [];
            const allDevices = [];
            for (const od of oldDevices) {
                allDevices.push({ od: od, nd: null });
            }
            for (const nd of newDevices) {
                const index = allDevices.findIndex(x => x.od != null && x.od.serialNumber === nd.serialNumber);
                if (index == -1) {
                    allDevices.push({ od: null, nd: nd });
                } else {
                    allDevices[index].nd = nd;
                }
            }
            newNode.children = allChildren.map(x => generateTree(x.oc, x.nc));
            newNode.devices = allDevices.map(x => generateDeviceNode(x.od, x.nd));
            return newNode
        }

        this.progressBarService.needed.next(true);
        const billingDataOldPromise = this.offencesService.getBillableTerminals(
            this.companyId,
            this.selectedQuarter - 1 < 1 ? 4 : this.selectedQuarter - 1,
            this.selectedQuarter - 1 < 1 ? this.selectedYear - 1 : this.selectedYear,
            (this.selectedMonth !== '') ? this.months.indexOf(this.selectedMonth) + 1 : -1,
            this.showChild
        ).toPromise();
        const billingDataPromise = this.offencesService.getBillableTerminals(
            this.companyId,
            this.selectedQuarter,
            this.selectedYear,
            (this.selectedMonth !== '') ? this.months.indexOf(this.selectedMonth) + 1 : -1,
            this.showChild
        ).toPromise();
        let [billingDataOld, billingData]: [BillingData, BillingData] = [null, null]
        try {
            [billingDataOld, billingData] = await Promise.all([billingDataOldPromise, billingDataPromise]);
            if ((billingData as any).error != null) {
                this.visibleDatas = [];
                this.tableVisibility = false;
                this.progressBarService.needed.next(false);
                return;
            }
        } catch (error) {
            this.visibleDatas = [];
            this.tableVisibility = false;
            this.progressBarService.needed.next(false);
            return;
        }

        const billingTree = generateTree((billingDataOld as any).billableTerminalInfo, (billingData as any).billableTerminalInfo)
        this.visibleDataWithoutParent = [];
        const calculatedData = this.calculateData(billingTree);
        this.withoutParent(calculatedData, parseInt(localStorage.getItem('selectedCompanyId' ), 10), this.visibleDataWithoutParent);
        this.visibleDatas = this.visibleDataWithoutParent;
        this.tableVisibility = true;
        this.progressBarService.needed.next(false);


        // this.progressBarService.needed.next(true);
        // this.queryButtonUnweighted = false;
        // this.firstLoad += 1;
        // this.billableTerminalSubscription = this.offencesService.getCalculateBillsForClientWithoutPriceInfos(
        //     this.companyId,
        //     this.selectedQuarter,
        //     this.selectedYear,
        //     (this.selectedMonth !== '') ? this.months.indexOf(this.selectedMonth) + 1 : -1,
        //     this.showChild)
        //     .pipe(finalize(() => this.progressBarService.needed.next(false)))
        //     .subscribe((data) => {
        //         this.filteredValues = undefined;
        //         this.queryButtonUnweighted = true;
        //         // @ts-ignore
        //         if (data.billings === 'No billing data found!') {
        //             this.visibleDatas = [];
        //             this.tableVisibility = false;
        //         } else {
        //             this.visibleDataWithoutParent = [];
        //             const calculatedData = this.calculateData(data.billings);
        //             this.withoutParent(calculatedData, parseInt(localStorage.getItem('selectedCompanyId' ), 10), this.visibleDataWithoutParent);
        //             this.visibleDatas = this.visibleDataWithoutParent;
        //             this.tableVisibility = true;
        //         }
        //     });
    }

    withoutParent(elem, clientId, visibleDataWithoutParent) {
        if (elem.data.clientId === clientId) {
            visibleDataWithoutParent.push(elem);
        } else if (elem.children !== undefined ) {
            elem.children.map(x => this.withoutParent(x, clientId, visibleDataWithoutParent));
        }
    }

    calculateData(x) {
        if (x.children.length > 0) {
            return {
                expanded: false,
                data: {clientId: x.clientId,
                    clientName: x.clientName,
                    level: x.level,
                    dataStatus: x.dataStatus,
                    devices: x.devices},
                children: [...x.children.map(y => this.calculateData(y)), ...x.devices.map(d => ({data: d}))]
            };
        } else {
            return {
                expanded: false,
                data: {clientId: x.clientId,
                    level: x.level,
                    clientName: x.clientName,
                    dataStatus: x.dataStatus,
                },
                children: x.devices.map(d => ({data: {...d,  registrationNumber: d.registrationNumber + (d.unmountDate?` (unmounted ${d.unmountDate})`:'')}}))
            };
        }
    }

    downloadDetail(elem, clientId, clientName) {
        const options = {
            filename: './streamed-workbook.xlsx',
            useStyles: true,
            useSharedStrings: true
        };
        const workbook = new Excel.Workbook(options);
        const worksheet = workbook.addWorksheet('Billings', {properties: {tabColor: {argb: 'FFC0000'}}});

        worksheet.columns = [
            { header: 'ManagerLevel 3', key: 'l3', width: 20 },
            { header: 'ManagerLevel 4', key: 'l4', width: 20 },
            { header: 'ManagerLevel 5', key: 'l5', width: 20 },
            { header: 'ManagerLevel 6', key: 'l6', width: 20},
            { header: 'Reg. Num.', key: 'regnum', width: 30},
            { header: 'SN', key: 'SN', width: 30},
            { header: 'Device type', key: 'type', width: 15},
            { header: 'SIM serial', key: 'simSerial', width: 25},
            { header: 'Install date', key: 'installDate', width: 15},
            { header: 'Months included', key: 'mi', width: 15},
            { header: 'Billed months', key: 'billedMonths', width: 15}
        ];

        this.createJSONCSVFormatDetail((this.filteredValues !== undefined) ? this.filteredValues[0] : elem[0], clientId, worksheet);

        worksheet.views = [
            {state: 'frozen', ySplit: 1}
        ];
        const fileName = 'tacho4safe_billing_expanded' + '_' + this.selectedYear + '_' + this.selectedQuarter + '_' + clientName + '.xlsx';
        const excelBuffer: any = workbook.xlsx.writeBuffer();
        workbook.xlsx.writeBuffer()
            .then(function(buffer) {
                // done buffering
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                saveAs(data, fileName);
            });
    }

    createJSONCSVFormatDetail(elem, clientId, worksheet) {
        if ( elem.data.clientId === clientId ) {
            this.JSONCreator(elem, worksheet);
        } else if (elem.children !== undefined) {
            elem.children.map(x => this.createJSONCSVFormatDetail(x, clientId, worksheet));
        }
        return worksheet;
    }

    JSONCreator(elem, worksheet) {
        if (elem.children === undefined) {
            worksheet.addRow({
                l3: (elem.data.managerLevel3Name !== null ? elem.data.managerLevel3Name : ' '),
                l4: (elem.data.managerLevel4Name !== null ? elem.data.managerLevel4Name : ' '),
                l5: (elem.data.managerLevel5Name !== null ? elem.data.managerLevel5Name : ' '),
                l6: (elem.data.managerLevel6Name !== null ? elem.data.managerLevel6Name : ' '),
                regnum: elem.data.registrationNumber,
                SN: elem.data.serialNumber,
                type: elem.data.terminalTypeName,
                simSerial: this.toString(elem.data.iccid),
                installDate: elem.data.installDate,
                mi: elem.data.subcriptionTimeInMonths,
                billedMonths: elem.data.billedMonths
            });
        } else {
            elem.children.map(x => this.JSONCreator(x, worksheet));
        }
        return worksheet
    }

    generateExcel(visibleData, clientId, clientName): void {
        const options = {
            filename: './streamed-workbook.xlsx',
            useStyles: true,
            useSharedStrings: true
        };
        const workbook = new Excel.Workbook(options);
        const worksheet = workbook.addWorksheet('Billings', {properties: {tabColor: {argb: 'FFC0000'}}});

        worksheet.columns = [
            { header: '', key: 'l1', width: 2 },
            { header: '', key: 'l2', width: 2 },
            { header: '', key: 'l3', width: 2 },
            { header: '', key: 'l4', width: 2 },
            { header: '', key: 'l5', width: 2 },
            { header: '', key: 'l6', width: 2 },
            { header: '', key: 'l7', width: 2 },
            { header: 'Client name', key: 'clientname', width: 30},
            { header: 'Serial number', key: 'SN', width: 30},
            { header: 'Device type', key: 'type', width: 15},
            { header: 'Service provider', key: 'provider', width: 20},
            { header: 'Last download', key: 'lastDownlaod', width: 15},
            { header: 'Install date', key: 'installDate', width: 15},
            { header: 'Months included', key: 'mi', width: 15},
            { header: 'Billed months', key: 'billedMonths', width: 15}
        ];

        worksheet.addRow({id: 1, name: 'Bhavik Patel', DOB: new Date(1989, 1, 1)});
        worksheet.views = [
            {state: 'frozen', ySplit: 1}
        ];

        this.excelByClient((this.filteredValues !== undefined) ? this.filteredValues[0] : visibleData[0], clientId, worksheet);

        const fileName = 'tacho4safe_billing' + '_' + this.selectedYear + '_' + this.selectedQuarter + '_' + clientName + '.xlsx';
        const excelBuffer: any = workbook.xlsx.writeBuffer();
        workbook.xlsx.writeBuffer()
            .then(function(buffer) {
                // done buffering
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                saveAs(data, fileName);
            });
    }

    excelByClient(visibleData, clientId, worksheet) {
        if ( visibleData.data.clientId === clientId ) {
            this.excelDetailedRow(visibleData, worksheet);
        } else if (visibleData.children !== undefined) {
            visibleData.children.map(x => this.excelByClient(x, clientId, worksheet));
        }
        return worksheet;
    }

    excelDetailedRow(elem, worksheet) {
        if (elem.children === undefined) {
            this.countCells += 1;
            worksheet.addRow({
                clientname: elem.data.registrationNumber,
                SN: elem.data.serialNumber,
                type: elem.data.terminalTypeName,
                provider: elem.data.brand,
                lastDownlaod: new Date(elem.data.lastDownload),
                installDate: new Date(elem.data.installDate),
                mi: (elem.data.subcriptionTimeInMonths !== 0 ? elem.data.subcriptionTimeInMonths : ''),
                billedMonths: elem.data.billedMonths
            });
        } else {
            worksheet.addRow({
                l1: (elem.data.level === 1 ? 1 : ''),
                l2: (elem.data.level === 2 ? 2 : ''),
                l3: (elem.data.level === 3 ? 3 : ''),
                l4: (elem.data.level === 4 ? 4 : ''),
                l5: (elem.data.level === 5 ? 5 : ''),
                l6: (elem.data.level === 6 ? 6 : ''),
                l7: (elem.data.level === 7 ? 7 : ''),
                clientname: elem.data.clientName
            });
            const row = worksheet.lastRow;
            const fill =  {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: this.levelColor[elem.data.level.toString()]}
            };
            const fonts = {
                bold: true
            }
            for (let i = 'A'.charCodeAt(0); i <= 'O'.charCodeAt(0); i++ ) {
                worksheet.getCell(String.fromCharCode(i) + row._number).fill = fill;
                worksheet.getCell(String.fromCharCode(i) + row._number).font = fonts;
            }
            worksheet.mergeCells('H' + row._number + ':O' + row._number);
            elem.children.map(x => this.excelDetailedRow(x, worksheet));
        }
        return worksheet;
    }
}
